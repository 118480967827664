import { all, fork } from "@redux-saga/core/effects"
import autofillBankAccountSaga from "flows/autofillBankAccount"
import { autofillCompanySaga } from "flows/autofillCompany"
import autopopulateOwnershipStructureSaga from "flows/autopopulateOwnershipStructure"
import deletePersonsFromApplicationSaga from "flows/deletePersonsFromApplication"
import downloadFilePromiseSaga from "flows/downloadFilePromise"
import newApplicationSaga from "flows/newApplication"
import newPersonInCompanySaga from "flows/newPersonInCompany"
import prefillApplicationDataSaga from "flows/prefillApplicationData"
import { updateTradingNameSaga } from "flows/updateTradingName"
import viewHomeOverviewSaga from "flows/viewHomeOverview"
import ibanSaga from "modules/iban/sagas"
import { MeState } from "modules/me/types"
import voyagerSaga from "modules/voyager/sagas"
import { PagesApplicationState } from "pages/Applications/types"
import { localizeReducer, LocalizeState } from "react-localize-redux"
import { pagesRootReducer } from "reducers/pagesRootReducers"
import { ReducerRegistry } from "utils/ReducerRegistry"

export interface IAppState {
    localize: LocalizeState
    pages: {
        applications: PagesApplicationState
    }
    me: MeState
}

ReducerRegistry.register({
    localize: localizeReducer,
    pages: pagesRootReducer,
})

export function* rootSaga() {
    yield all([
        fork(viewHomeOverviewSaga),
        fork(voyagerSaga),
        fork(ibanSaga),
        fork(autofillCompanySaga),
        fork(autofillBankAccountSaga),
        fork(newApplicationSaga),
        fork(newPersonInCompanySaga),
        fork(autopopulateOwnershipStructureSaga),
        fork(deletePersonsFromApplicationSaga),
        fork(prefillApplicationDataSaga),
        fork(updateTradingNameSaga),
        fork(downloadFilePromiseSaga),
    ])
}
