import { put } from "@redux-saga/core/effects"
import { Problem, Resource, State } from "ketting"
import { MERCHANT_API_ROOT } from "modules/common"
import { catchError, forkWatcher } from "modules/common/actions"
import { HALClient } from "modules/common/HALClient"
import * as actions from "modules/me/actions"
import { MeDto, UpdateMeDto } from "modules/me/types"
import { ActionType, getType } from "typesafe-actions"

export function* handleGetMe() {
    try {
        const resource: Resource<MeDto> = yield HALClient.go(`${MERCHANT_API_ROOT}/me`)
        const me: State<MeDto> = yield resource.get()

        yield put(actions.getMeOk(me.data))
    } catch (e) {
        if (e instanceof Problem) {
            yield put(actions.getMeError(e))
            yield put(catchError(e))
        } else {
            throw e
        }
    }
}

export function* handleUpdateMe(action: ActionType<typeof actions.updateMe>) {
    const { data: updateMe } = action.payload
    try {
        const resource: Resource<UpdateMeDto> = yield HALClient.go(`${MERCHANT_API_ROOT}/me`)
        const updatedMe: State<MeDto> = yield resource.post({
            data: updateMe,
            headers: {
                "Content-Type": "application/json",
            },
        })

        yield put(actions.updateMeOk(updatedMe.data, updateMe))
    } catch (e) {
        yield put(catchError(e))
    }
}

export function* meRootSaga() {
    yield put(forkWatcher(getType(actions.getMe), handleGetMe))
    yield put(forkWatcher(getType(actions.updateMe), handleUpdateMe))
}
