import { Problem } from "ketting"
import {
    createApplicationOk,
    fetchApplicationOk,
    fetchApplicationsOk,
} from "modules/applications/actions"
import {
    IApplicationData,
    IApplicationRelations,
    IApplicationsData,
    IApplicationsRelations,
} from "modules/applications/types"
import { MerchantApiRequest, MerchantApiResponse, MERCHANT_API_ROOT } from "modules/common"
import { catchError, forkWatcher } from "modules/common/actions"
import { put, take } from "redux-saga/effects"
import { ActionType, getType, isActionOf } from "typesafe-actions"
import UrlParse from "url-parse"
import * as actions from "./actions"

// Exported for testing purposes
// DO NOT USE from outside this file
export function* handleFetchApplications(action: ActionType<typeof actions.fetchApplications>) {
    const { page, per_page, query, uri: applicationsUri } = action.payload

    try {
        const queryParams = {}
        if (page) {
            Object.assign(queryParams, {
                page: page.toString(),
            })
        }
        if (per_page) {
            Object.assign(queryParams, {
                per_page: per_page.toString(),
            })
        }
        if (query) {
            Object.assign(queryParams, {
                query,
            })
        }
        const uri = new UrlParse(applicationsUri, true)
        uri.set("query", {
            ...uri.query,
            ...queryParams,
        })

        const merchantApiRequest = new MerchantApiRequest<IApplicationsData, IApplicationData>(
            uri.toString(),
            { noCache: true }
        )
        const {
            relations,
            repr,
        }: MerchantApiResponse<
            IApplicationsData,
            IApplicationsRelations
        > = yield merchantApiRequest.get()

        yield put(fetchApplicationsOk(repr, relations, applicationsUri, page, per_page, query))
    } catch (e) {
        yield put(catchError(e))
    }
}

// Exported for testing purposes
// DO NOT USE from outside this file
export function* handleFetchApplication(action: ActionType<typeof actions.fetchApplication>) {
    const { uri, noCache } = action.payload

    try {
        const merchantApiRequest = new MerchantApiRequest(uri, {
            noCache,
        })
        const {
            relations,
            repr,
        }: {
            relations: IApplicationRelations
            repr: IApplicationData
        } = yield merchantApiRequest.get()

        yield put(fetchApplicationOk(repr, relations, uri, noCache))
    } catch (e) {
        if (e instanceof Problem) {
            yield put(actions.fetchApplicationError(e, uri, noCache))
        } else {
            yield put(catchError(e))
        }
    }
}

export function* handleRefreshApplication(action: ActionType<typeof actions.refreshApplication>) {
    const { uri } = action.payload

    yield put(actions.fetchApplication(uri, true))
}

// Exported for testing purposes
// DO NOT USE from outside this file
export function* handleCreateApplication(action: ActionType<typeof actions.createApplication>) {
    const applicationBase = action.payload
    try {
        const merchantApiRequest = new MerchantApiRequest(`${MERCHANT_API_ROOT}/applications`, {
            contentType: "application/json",
        })
        const {
            relations,
            repr,
        }: {
            relations: IApplicationRelations
            repr: IApplicationData
        } = yield merchantApiRequest.post(applicationBase)

        yield put(createApplicationOk(repr, relations, applicationBase))
    } catch (e) {
        yield put(catchError(e))
    }
}

// Exported for testing purposes
// DO NOT USE from outside this file
export function* handleUpdateApplication(action: ActionType<typeof actions.updateApplication>) {
    const { data, uri } = action.payload

    try {
        // Take care of updates to `physical_delivery`, as `physical_delivery` request should always include `delivery_delay`
        // Should probably not throw and bad request from backend, as it's not how the rest of `applications` works
        if (data.business_model && data.business_model.physical_delivery) {
            yield put(actions.fetchApplication(uri))
            const { payload: preApplication }: ActionType<typeof fetchApplicationOk> = yield take(
                (a: ActionType<typeof fetchApplicationOk>) => {
                    return isActionOf(fetchApplicationOk, a) && a.meta.uri === uri
                }
            )

            if (preApplication.data.business_model) {
                const { delivery_delay: preDeliveryDelay } = preApplication.data.business_model

                if (!preDeliveryDelay) {
                    // if delivery delay is empty set default value
                    data.business_model.delivery_delay = "less5days"
                } else {
                    // if delivery delay is not empty set it to existing value
                    data.business_model.delivery_delay = preDeliveryDelay
                }
            }
        }

        const merchantApiRequest = new MerchantApiRequest(uri)
        yield merchantApiRequest.patch(data)
        const {
            relations,
            repr,
        }: {
            relations: IApplicationRelations
            repr: IApplicationData
        } = yield merchantApiRequest.get()

        yield put(actions.updateApplicationOk(repr, relations, uri))
    } catch (e) {
        yield put(catchError(e))
    }
}

// Exported for testing purposes
// DO NOT USE from outside this file
export function* handleStampApplication(action: ActionType<typeof actions.stampApplication>) {
    const { uri, applicationUri } = action.payload

    try {
        const merchantApiRequest = new MerchantApiRequest(uri)
        yield merchantApiRequest.put({})
        yield put(actions.stampApplicationOk(uri, applicationUri))
    } catch (e) {
        yield put(catchError(e))
    }
}

// Exported for testing purposes
// DO NOT USE from outside this file
export function* handleRemoveStampApplication(
    action: ActionType<typeof actions.removeStampApplication>
) {
    const { uri, applicationUri } = action.payload
    try {
        const merchantApiRequest = new MerchantApiRequest(uri)
        yield merchantApiRequest.delete()
        yield put(actions.removeStampApplicationOk(uri, applicationUri))
    } catch (e) {
        if (e instanceof Problem) {
            yield put(actions.removeStampApplicationError(e, uri, applicationUri))
        }
        yield put(catchError(e))
    }
}

export default function* applicationsSaga() {
    yield put(forkWatcher(getType(actions.fetchApplications), handleFetchApplications))
    yield put(forkWatcher(getType(actions.fetchApplication), handleFetchApplication))
    yield put(forkWatcher(getType(actions.refreshApplication), handleRefreshApplication))
    yield put(forkWatcher(getType(actions.createApplication), handleCreateApplication))
    yield put(forkWatcher(getType(actions.updateApplication), handleUpdateApplication))
    yield put(forkWatcher(getType(actions.stampApplication), handleStampApplication))
    yield put(forkWatcher(getType(actions.removeStampApplication), handleRemoveStampApplication))
}
