import {
    BoxEdges,
    Grid,
    LoadingScreen,
    Paragraph,
    Separator,
    Spacer,
    ThemeContext,
} from "@clearhaus/design-system"
import {
    Brandmark,
    Message,
    SettingsButton,
    SettingsNavigationItem,
} from "apps/ConfirmSubscription/components"
import { ConfirmSubscriptionContext } from "apps/ConfirmSubscription"
import { SupportDropdown } from "components/common"
import {
    SpecificLanguageLocalizationLoader,
    useInitializeSpecificLanguage,
} from "components/localization"
import React from "react"
import { useContext } from "react"
import { Translate } from "react-localize-redux"

const texts = {
    address: <Translate id={"subscription_confirmation.address"} />,
}

const namespaces = ["subscription_confirmation", "navigation"]

const styles = {
    display: "flex",
    flexDirection: "column" as "column",
    minHeight: "100vh",
}

export const Page: React.FC = () => {
    const { defaults } = useContext(ThemeContext)

    const { isLoading: localizationIsLoading } = useInitializeSpecificLanguage("en")
    const { isLoading: dataIsLoading } = useContext(ConfirmSubscriptionContext)

    const languageParam = new URLSearchParams(window.location.search).get("lang")
    const language = languageParam ? languageParam : "en"

    if (dataIsLoading || localizationIsLoading) {
        return <LoadingScreen />
    }

    return (
        <SpecificLanguageLocalizationLoader language={language} namespaces={namespaces}>
            <Grid container>
                <Grid row direction="column">
                    <Grid item style={styles}>
                        <Grid row alignItems="center" justify="center">
                            <Grid item>
                                <BoxEdges mx={12}>
                                    <Brandmark />
                                </BoxEdges>
                            </Grid>
                            <Grid item auto>
                                <SettingsNavigationItem />
                            </Grid>
                            <Grid item auto>
                                <SupportDropdown />
                            </Grid>
                        </Grid>
                        <Grid row>
                            <Grid item>
                                <Separator customColor={defaults.colors.neutrals[400]} />
                            </Grid>
                        </Grid>
                        <Grid row alignItems="center" justify="center" grow={1}>
                            <Grid item auto>
                                <BoxEdges my={64}>
                                    <Grid
                                        row
                                        direction="column"
                                        alignItems="center"
                                        justify="center"
                                    >
                                        <Grid item>
                                            <Message />
                                        </Grid>
                                        <Spacer size={16} />
                                        <Grid item auto>
                                            <SettingsButton />
                                        </Grid>
                                    </Grid>
                                </BoxEdges>
                            </Grid>
                        </Grid>
                        <Grid row alignItems="center" justify="center">
                            <Grid item>
                                <BoxEdges my={32}>
                                    <Paragraph align="center" light>
                                        {texts.address}
                                    </Paragraph>
                                </BoxEdges>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </SpecificLanguageLocalizationLoader>
    )
}
