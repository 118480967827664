type EmitCB = (reducers: object) => void
class ReducerRegistry {
    public static instance: ReducerRegistry = new ReducerRegistry()

    private _reducers: object = {}
    private _emitChange: null | EmitCB = null

    public getReducers() {
        return { ...this._reducers }
    }

    public setChangeListener(listener: EmitCB) {
        if (this._emitChange != null) {
            throw new Error("Can only set the listener for a ReducerRegistry once.")
        }
        this._emitChange = listener
    }

    public register(newReducers: object) {
        this._reducers = { ...this._reducers, ...newReducers }

        if (this._emitChange !== null) {
            this._emitChange(this.getReducers())
        }
    }
}

const instance = ReducerRegistry.instance
export { instance as ReducerRegistry }
