import { fork, put, take, takeEvery } from "@redux-saga/core/effects"
import { cancelAction, catchError, forkWatcher } from "modules/common/actions"
import { Uri } from "modules/common/types"
import {
    createPerson,
    createPersonOk,
    deletePerson,
    deletePersonOk,
    fetchPeople,
    fetchPerson,
    fetchPersonOk,
    updatePerson,
    updatePersonOk,
} from "modules/people/actions"
import { personProviderReducer } from "modules/people/Providers/PersonProvider/reducer"
import { IPersonData, IPersonRelations } from "modules/people/types"
import { ActionType, createCustomAction, getType, isActionOf } from "typesafe-actions"
import { ReducerRegistry } from "utils/ReducerRegistry"
import { SagaRegistry } from "utils/SagaRegistry"

const INITIALIZE_PERSON_PROVIDER = "@@people/INITIALIZE_PERSON_PROVIDER"
const PERSON_PROVIDER = "@@people/PERSON_PROVIDER"
const PERSON_PROVIDER_FETCH_PERSON = "@@people/PERSON_PROVIDER/FETCH_PERSON"
const PERSON_PROVIDER_FETCH_PERSON_OK = "@@people/PERSON_PROVIDER/FETCH_PERSON_OK"
const PERSON_PROVIDER_DELETE_PERSON = "@@people/PERSON_PROVIDER/DELETE_PERSON"
const PERSON_PROVIDER_DELETE_PERSON_OK = "@@people/PERSON_PROVIDER/DELETE_PERSON_OK"
const PERSON_PROVIDER_CREATE_PERSON = "@@people/PERSON_PROVIDER/CREATE_PERSON"
const PERSON_PROVIDER_CREATE_PERSON_OK = "@@people/PERSON_PROVIDER/CREATE_PERSON_OK"
const PERSON_PROVIDER_UPDATE_PERSON = "@@people/PERSON_PROVIDER/UPDATE_PERSON"
const PERSON_PROVIDER_UPDATE_PERSON_OK = "@@people/PERSON_PROVIDER/UPDATE_PERSON_OK"
const TEARDOWN_PERSON_PROVIDER = "@@people/TEARDOWN_PERSON_PROVIDER"
const INITIALIZE_EMPTY_PROVIDER = "@@people/PROVIDERS/PERSON/INITIALIZE/EMPTY"
const TEARDOWN_EMPTY_PROVIDER = "@@people/PROVIDERS/PERSON/TEARDOWN/EMPTY"
export const initializeEmptyProvider = createCustomAction(
    INITIALIZE_EMPTY_PROVIDER,
    (id: string) => ({
        payload: { id },
    })
)
export const teardownEmptyProvider = createCustomAction(TEARDOWN_EMPTY_PROVIDER, (id: string) => ({
    payload: { id },
}))

export const initializePersonProvider = createCustomAction(
    INITIALIZE_PERSON_PROVIDER,
    (personUri: Uri) => ({ payload: { personUri } })
)
const personProviderConstructor = (personUri: Uri) =>
    createCustomAction(`${PERSON_PROVIDER}/${personUri}`)
export const personProviderUpdatePerson = createCustomAction(
    PERSON_PROVIDER_UPDATE_PERSON,
    (data: IPersonData, personUri: Uri) => ({
        payload: { data, personUri },
    })
)
export const personProviderUpdatePersonOk = createCustomAction(
    PERSON_PROVIDER_UPDATE_PERSON_OK,
    (data: IPersonData, relations: IPersonRelations, personUri: Uri) => ({
        meta: { personUri },
        payload: { data, relations },
    })
)
export const personProviderFetchPerson = createCustomAction(
    PERSON_PROVIDER_FETCH_PERSON,
    (personUri: Uri) => ({ payload: { personUri } })
)
export const personProviderFetchPersonOk = createCustomAction(
    PERSON_PROVIDER_FETCH_PERSON_OK,
    (personData: IPersonData, personRelations: IPersonRelations, personUri: Uri) => {
        return {
            meta: {
                personUri,
            },
            payload: {
                data: personData,
                relations: personRelations,
            },
        }
    }
)
export const personProviderDeletePerson = createCustomAction(
    PERSON_PROVIDER_DELETE_PERSON,
    (personUri: Uri) => ({ payload: { personUri } })
)
export const personProviderDeletePersonOk = createCustomAction(
    PERSON_PROVIDER_DELETE_PERSON_OK,
    (personUri: Uri) => ({ meta: { personUri } })
)
export const personProviderCreatePerson = createCustomAction(
    PERSON_PROVIDER_CREATE_PERSON,
    (peopleUri: Uri, providerId: string) => ({ payload: { peopleUri, providerId } })
)
export const personProviderCreatePersonOk = createCustomAction(
    PERSON_PROVIDER_CREATE_PERSON_OK,
    (
        personData: IPersonData,
        personRelations: IPersonRelations,
        peopleUri: Uri,
        providerId: string
    ) => {
        return {
            meta: {
                peopleUri,
                providerId,
            },
            payload: {
                data: personData,
                relations: personRelations,
            },
        }
    }
)

export const teardownPersonProvider = createCustomAction(
    TEARDOWN_PERSON_PROVIDER,
    (personUri: Uri) => ({ payload: { personUri } })
)

const currentUpdateCache: { [key: string]: IPersonData | null } = {}
function* handleUpdatePersonOk(action: ActionType<typeof updatePersonOk>) {
    const { data: personData, relations: personRelations } = action.payload
    yield put(personProviderUpdatePersonOk(personData, personRelations, personRelations.self))
    currentUpdateCache[personRelations.self] = null
}

export function* handlePersonProviderUpdatePerson(
    action: ActionType<typeof personProviderUpdatePerson>
) {
    const { data: personData, personUri } = action.payload

    // If there is an update running, cancel it, merge the old update with this
    if (currentUpdateCache[personUri]) {
        yield put(cancelAction(getType(updatePerson)))
        Object.assign(currentUpdateCache[personUri] as IPersonData, personData)
    } else {
        currentUpdateCache[personUri] = personData
    }

    try {
        yield put(updatePerson(currentUpdateCache[personUri] as IPersonData, personUri))
    } catch (e) {
        yield put(catchError(e))
    }
}

export function* handlePersonProviderFetchPerson(
    action: ActionType<typeof personProviderFetchPerson>
) {
    const { personUri } = action.payload

    try {
        yield put(fetchPerson(personUri))
        const { payload: person }: ActionType<typeof fetchPersonOk> = yield take(
            (a: ActionType<typeof fetchPersonOk>) =>
                isActionOf(fetchPersonOk, a) && a.meta.uri === personUri
        )
        yield put(personProviderFetchPersonOk(person.data, person.relations, personUri))
    } catch (e) {
        yield put(catchError(e))
    }
}

export function* handlePersonProviderCreatePerson(
    action: ActionType<typeof personProviderCreatePerson>
) {
    const { peopleUri, providerId } = action.payload

    try {
        yield put(createPerson({}, peopleUri))
        const { payload: createPersonOkPayload }: ActionType<typeof createPersonOk> = yield take(
            (a: ActionType<typeof createPersonOk>) =>
                isActionOf(createPersonOk, a) && a.meta.uri === peopleUri
        )
        yield put(
            personProviderCreatePersonOk(
                createPersonOkPayload.data,
                createPersonOkPayload.relations,
                peopleUri,
                providerId
            )
        )
        yield put(fetchPeople(peopleUri, true))
    } catch (e) {
        yield put(catchError(e))
    }
}

export function* handlePersonProviderDeletePerson(
    action: ActionType<typeof personProviderDeletePerson>
) {
    const { personUri } = action.payload

    try {
        yield put(deletePerson(personUri))
        yield take(
            (a: ActionType<typeof deletePersonOk>) =>
                isActionOf(deletePersonOk, a) && a.meta.uri === personUri
        )
        yield put(personProviderDeletePersonOk(personUri))
    } catch (e) {
        yield put(catchError(e))
    }
}

/**
 * Since handlePersonProvider returns a function, we'll save a copy of this function in a cache,
 * to ensure references to a function is used rather than a new function on every run
 */
const personProvidersCache: { [key: string]: GeneratorFunction } = {}
export const handlePersonProvider = (personUri: Uri) => {
    if (personProvidersCache[`${personUri}`]) {
        return personProvidersCache[`${personUri}`]
    }

    personProvidersCache[`${personUri}`] = function* () {
        yield takeEvery((a: ActionType<typeof personProviderFetchPerson>) => {
            return isActionOf(personProviderFetchPerson, a) && a.payload.personUri === personUri
        }, handlePersonProviderFetchPerson)
        yield takeEvery(
            (a: ActionType<typeof personProviderUpdatePerson>) =>
                isActionOf(personProviderUpdatePerson, a) && a.payload.personUri === personUri,
            handlePersonProviderUpdatePerson
        )
        yield takeEvery((a: ActionType<typeof updatePersonOk>) => {
            return isActionOf(updatePersonOk, a) && a.meta.uri === personUri
        }, handleUpdatePersonOk)
        yield takeEvery((a: ActionType<typeof personProviderDeletePerson>) => {
            return isActionOf(personProviderDeletePerson, a) && a.payload.personUri === personUri
        }, handlePersonProviderDeletePerson)
    } as GeneratorFunction

    return personProvidersCache[`${personUri}`]
}

export function* handleInitializePersonProvider(
    action: ActionType<typeof initializePersonProvider>
) {
    const { personUri } = action.payload

    yield put(
        forkWatcher(getType(personProviderConstructor(personUri)), handlePersonProvider(personUri))
    )
    yield put(personProviderConstructor(personUri)())
    yield put(personProviderFetchPerson(personUri))
}

function* handleTeardownPersonProvider(action: ActionType<typeof teardownPersonProvider>) {
    const { personUri } = action.payload

    yield put(cancelAction(getType(personProviderConstructor(personUri)), false))
}

export function* watchPersonProvider() {
    yield takeEvery(getType(initializePersonProvider), handleInitializePersonProvider)
    yield takeEvery(getType(teardownPersonProvider), handleTeardownPersonProvider)

    yield takeEvery(getType(personProviderCreatePerson), handlePersonProviderCreatePerson)
}

function* personProviderRootSaga() {
    yield fork(watchPersonProvider)
}

SagaRegistry.register(personProviderRootSaga)
ReducerRegistry.register({
    personProviders: personProviderReducer,
})
