import { ISO8601DateTime, Uri } from "modules/common/types"
import { IncidentStatusType } from "modules/statuspage/types"
import { Reducer } from "redux"
import { ActionType, getType } from "typesafe-actions"
import { initializeProvider, providerFetchOk, teardownProvider } from "./sagas"

export interface IServiceMessageType {
    title: string
    status?: IncidentStatusType
    scheduled_for?: ISO8601DateTime
    scheduled_until?: ISO8601DateTime
    shortlink?: Uri
    langauge?: string
}

export interface IServiceMessagesProviderState {
    messages: IServiceMessageType[]
}

export interface IServiceMessagesProviderStateRoot {
    serviceMessages: IServiceMessagesProviderState
}

type ActionTypes = ActionType<
    typeof initializeProvider | typeof teardownProvider | typeof providerFetchOk
>

export const providerReducer: Reducer<IServiceMessagesProviderState, ActionTypes> = (
    currentState,
    action
) => {
    let nextState

    if (!currentState) {
        currentState = {
            messages: [],
        }
    }

    nextState = currentState

    switch (action.type) {
        case getType(initializeProvider): {
            nextState = {
                messages: [],
            }
            break
        }
        case getType(teardownProvider): {
            nextState = {
                messages: [],
            }
            break
        }
        case getType(providerFetchOk): {
            const { data } = action.payload
            nextState = {
                messages: data,
            }
            break
        }
    }

    return nextState
}
