import { CheckSquareColoredIcon } from "@clearhaus/design-system"
import { ConfirmSubscriptionContext } from "apps/ConfirmSubscription"
import { EmptyState } from "components/common/EmptyState/EmptyState"
import React, { useContext } from "react"
import { Translate } from "react-localize-redux"

const texts = {
    title: <Translate id={"subscription_confirmation.enabled_title"} />,
    descriptionCompany: <Translate id={"subscription_confirmation.enabled_description_company"} />,
    descriptionEmail: <Translate id={"subscription_confirmation.enabled_description_email"} />,
}

export const Enabled: React.FC = () => {
    const { data } = useContext(ConfirmSubscriptionContext)

    return (
        <EmptyState>
            <EmptyState.Icon icon={<CheckSquareColoredIcon />} />
            <EmptyState.Title>{texts.title}</EmptyState.Title>
            <EmptyState.Description>
                {texts.descriptionCompany} {data?.name}. {texts.descriptionEmail} {data?.email}.
            </EmptyState.Description>
        </EmptyState>
    )
}
