import Auth0 from "modules/authentication/Auth0"
import { TRACK_JS_TOKEN } from "modules/common"
import { setTrackJSConfigured, trackJsConfigured } from "modules/trackjs"
import { AnyAction } from "redux"
import { TrackJS } from "trackjs"
import { devMode } from "utils/envHelpers"
import { removeSensitiveDataFromAction, timer } from "./helpers"

// tslint:disable:no-console
const TrackJSLogger = () => <A extends AnyAction>(next: (action: A) => A) => (action: A) => {
    if (!trackJsConfigured) {
        try {
            const idTokenPayload = Auth0.instance.getIdTokenPayload()
            const metadataClaim = idTokenPayload
                ? (Object.keys(idTokenPayload).find((key) =>
                      key.match(/app_metadata/)
                  ) as "https://clearhaus.com/app_metadata")
                : null
            if (idTokenPayload && metadataClaim && idTokenPayload[metadataClaim]) {
                TrackJS.configure({
                    userId: idTokenPayload.email,
                })
                TrackJS.addMetadata("user_uuid", idTokenPayload[metadataClaim].uuid)
                TrackJS.addMetadata("user_scope", idTokenPayload[metadataClaim].roles[0])
            }

            setTrackJSConfigured()
            // tslint:disable-next-line:no-empty
        } catch {}
    }

    const startTime = timer.now()
    try {
        const returnValue = next(action)
        const took = timer.now() - startTime
        const actionCopy = ({
            took,
        } as unknown) as A

        Object.assign(actionCopy, removeSensitiveDataFromAction(action as any))
        if (TRACK_JS_TOKEN && !devMode) {
            if (actionCopy.type !== "@@localize/ADD_TRANSLATION_FOR_LANGUAGE") {
                TrackJS.console.log(actionCopy)
            }
        }

        return returnValue
    } catch (err) {
        if (TRACK_JS_TOKEN && TrackJS) {
            TrackJS.track(err)
        }
        if (devMode) {
            console.info(`%c <${action.type}>`, "color: red")
            console.error(err)
            console.info(`%c </${action.type}>`, "color: red")
        }
        return
    }
}

export { TrackJSLogger }
