import { BoxEdges, ChevronDownIcon, Grid, ThemeContext } from "@clearhaus/design-system"
import { makeStyles } from "@clearhaus/design-system/utils/styles/makeStyles"
import React, { useContext } from "react"
import { styles } from "../styles"

interface IChevronProps {
    show?: boolean
    margin?: "s" | "none"
    light?: boolean
}

const useStyles = makeStyles(styles)
export const Chevron: React.FC<IChevronProps> = (props) => {
    const { show = false, margin, light } = props

    const classes = useStyles()
    const { defaults } = useContext(ThemeContext)

    const color = light ? defaults.colors.neutrals[100] : defaults.colors.neutrals[800]
    const ml = margin === "s" ? 8 : 0

    if (!show) {
        return null
    }

    return (
        <Grid item className={classes.chevron} auto>
            <BoxEdges ml={ml}>
                <ChevronDownIcon size={18} color={color} />
            </BoxEdges>
        </Grid>
    )
}
