import { deepEqual } from "fast-equals"
import {
    IApplicationData,
    IApplicationRelations,
    IApplicationsData,
    IApplicationsRelations,
} from "modules/applications/types"
import { IStoreItemValue, RxStore } from "modules/common/RxStore"
import { Uri } from "modules/common/types"
import { useEffect, useMemo, useState } from "react"
import { useDispatch } from "react-redux"
import { BehaviorSubject, combineLatest, of } from "rxjs"
import { debounceTime, distinctUntilChanged, map, shareReplay, switchMap } from "rxjs/operators"
import { fetchApplication, fetchApplications } from "./actions"

export const useApplication = (applicationUri?: Uri) => {
    const dispatch = useDispatch()

    const [applicationUriSubject] = useState(() => {
        return new BehaviorSubject(applicationUri)
    })

    const application = useMemo(() => {
        return applicationUriSubject.pipe(
            debounceTime(50),
            map((uri) => {
                if (!uri) {
                    return of(undefined)
                }

                dispatch(fetchApplication(uri))

                return RxStore.get<IApplicationData, IApplicationRelations>(uri).pipe(
                    distinctUntilChanged<IStoreItemValue<IApplicationData, IApplicationRelations>>(
                        deepEqual
                    ),
                    shareReplay(1)
                )
            }),
            switchMap((o) => o),
            distinctUntilChanged<
                IStoreItemValue<IApplicationData, IApplicationRelations> | undefined
            >(deepEqual),
            shareReplay(1)
        )
    }, [applicationUri])

    useEffect(() => {
        applicationUriSubject.next(applicationUri)
    }, [applicationUri])

    return application
}

export const useApplications = (uri?: Uri, page?: number, per_page?: number, query?: string) => {
    const dispatch = useDispatch()
    const [applicationsUriSubject] = useState(() => {
        return new BehaviorSubject(uri)
    })
    const [pageSubject] = useState(() => {
        return new BehaviorSubject(page)
    })
    const [perPageSubject] = useState(() => {
        return new BehaviorSubject(per_page)
    })
    const [querySubject] = useState(() => {
        return new BehaviorSubject(query)
    })

    const applications = useMemo(() => {
        return combineLatest([
            applicationsUriSubject,
            pageSubject,
            perPageSubject,
            querySubject,
        ]).pipe(
            debounceTime(100),
            map(([uri, page, per_page, query]) => {
                if (!uri) {
                    return of(null)
                }

                dispatch(fetchApplications(uri, page, per_page, query))

                return RxStore.get<IApplicationsData, IApplicationsRelations>(uri, {
                    page,
                    per_page,
                    query,
                })
            }),
            switchMap((o) => o),
            distinctUntilChanged<IStoreItemValue<IApplicationsData, IApplicationsRelations>>(
                deepEqual
            ),
            shareReplay(1)
        )
    }, [pageSubject, perPageSubject, querySubject])

    useEffect(() => {
        applicationsUriSubject.next(uri)
    }, [uri])

    useEffect(() => {
        pageSubject.next(page)
    }, [page])

    useEffect(() => {
        perPageSubject.next(per_page)
    }, [per_page])

    useEffect(() => {
        querySubject.next(query)
    }, [query])

    return applications
}
