import { Problem } from "ketting"
import { MerchantApiRequest, MerchantApiResponse } from "modules/common"
import { catchError, forkWatcher } from "modules/common/actions"
import {
    fetchContact,
    fetchContactError,
    fetchContactOk,
    updateContact,
    updateContactError,
    updateContactOk,
} from "modules/contacts/actions"
import { IContactData, IContactRelations } from "modules/contacts/types"
import { call, put } from "redux-saga/effects"
import { ActionType, getType } from "typesafe-actions"

export function* handleFetchContact(action: ActionType<typeof fetchContact>) {
    const { uri } = action.payload

    try {
        const merchantApiRequest = new MerchantApiRequest(uri)
        const { repr, relations }: MerchantApiResponse<IContactData, IContactRelations> =
            yield call([merchantApiRequest, merchantApiRequest.get])
        yield put(fetchContactOk(repr, relations, uri))
    } catch (e) {
        if (e instanceof Problem) {
            yield put(fetchContactError(e, uri))
            yield put(catchError(e))
        } else {
            throw e
        }
    }
}

export function* handleUpdateContact(action: ActionType<typeof updateContact>) {
    const { data, uri } = action.payload

    try {
        const merchantApiRequest = new MerchantApiRequest(uri)
        const { relations, repr }: MerchantApiResponse<IContactData, IContactRelations> =
            yield call([merchantApiRequest, merchantApiRequest.patch], data)
        yield put(updateContactOk(repr, relations, data, uri))
    } catch (e) {
        if (e instanceof Problem) {
            yield put(updateContactError(e, data, uri))
            yield put(catchError(e))
        } else {
            throw e
        }
    }
}

export default function* contactsSaga() {
    yield put(forkWatcher(getType(fetchContact), handleFetchContact))
    yield put(forkWatcher(getType(updateContact), handleUpdateContact))
}
