import { fork, put, race, take, takeEvery } from "@redux-saga/core/effects"
import { Problem } from "ketting"
import { fetchAccount, fetchAccountError, fetchAccountOk } from "modules/accounts/actions"
import { accountProviderReducer } from "modules/accounts/Providers/Account/reducer"
import { IAccountData, IAccountRelations } from "modules/accounts/types"
import { cancelAction, forkWatcher } from "modules/common/actions"
import { Uri } from "modules/common/types"
import { ActionType, createCustomAction, getType, isActionOf } from "typesafe-actions"
import { ReducerRegistry } from "utils/ReducerRegistry"
import { SagaRegistry } from "utils/SagaRegistry"

const ACCOUNT_PROVIDER_FETCH = "@@accounts/PROVIDERS/ACCOUNT/FETCH"
const ACCOUNT_PROVIDER_FETCH_OK = "@@accounts/PROVIDERS/ACCOUNT/FETCH_OK"
const ACCOUNT_PROVIDER_FETCH_ERROR = "@@accounts/PROVIDERS/ACCOUNT/FETCH_ERROR"
export const accountProviderFetch = createCustomAction(
    ACCOUNT_PROVIDER_FETCH,
    (accountUri: Uri, noCache?: boolean) => ({ payload: { accountUri, noCache } })
)
export const accountProviderFetchOk = createCustomAction(
    ACCOUNT_PROVIDER_FETCH_OK,
    (
        accountData: IAccountData,
        accountRelations: IAccountRelations,
        accountUri: Uri,
        noCache?: boolean
    ) => ({
        meta: { accountUri, noCache },
        payload: { accountData, accountRelations },
    })
)
export const accountProviderFetchError = createCustomAction(
    ACCOUNT_PROVIDER_FETCH_ERROR,
    (e: Problem, accountUri: Uri, noCache?: boolean) => ({
        payload: e,
        meta: { accountUri, noCache },
    })
)

export function* handleAccountProviderFetch(action: ActionType<typeof accountProviderFetch>) {
    const { accountUri, noCache } = action.payload

    yield put(fetchAccount(accountUri, noCache))
    const [success, error]: [
        ActionType<typeof fetchAccountOk> | undefined,
        ActionType<typeof fetchAccountError> | undefined
    ] = yield race([
        take((a: ActionType<typeof fetchAccountOk>) => {
            return (
                isActionOf(fetchAccountOk, a) &&
                a.meta.uri === accountUri &&
                a.meta.noCache === noCache
            )
        }),
        take((a: ActionType<typeof fetchAccountError>) => {
            return (
                isActionOf(fetchAccountError, a) &&
                a.meta.uri === accountUri &&
                a.meta.noCache === noCache
            )
        }),
    ])

    if (success) {
        const { relations, data } = success.payload
        yield put(accountProviderFetchOk(data, relations, accountUri, noCache))
    } else if (error) {
        yield put(accountProviderFetchError(error.payload, accountUri, noCache))
    }
}

const ACCOUNT_PROVIDER = "@@accounts/PROVIDERS/ACCOUNT"
const ACCOUNT_PROVIDER_INITIALIZE = "@@accounts/PROVIDERS/ACCOUNT/INITIALIZE"
const ACCOUNT_PROVIDER_TEARDOWN = "@@accounts/PROVIDERS/ACCOUNT/TEARDOWN"
export const accountProviderInitialize = createCustomAction(
    ACCOUNT_PROVIDER_INITIALIZE,
    (accountUri: Uri, noCache?: boolean) => ({ payload: { accountUri, noCache } })
)
export const accountProviderTeardown = createCustomAction(
    ACCOUNT_PROVIDER_TEARDOWN,
    (accountUri: Uri) => ({ payload: { accountUri } })
)
const accountProviderConstructor = (accountUri: Uri) =>
    createCustomAction(`${ACCOUNT_PROVIDER}/${accountUri}`)
const accountProviderSagaCache: { [key: string]: GeneratorFunction } = {}
export const handleAccountProvider = (accountUri: Uri) => {
    const fnFromCache = accountProviderSagaCache[`${accountUri}`]
    if (fnFromCache) {
        return fnFromCache
    }

    accountProviderSagaCache[`${accountUri}`] = function* () {
        yield takeEvery((a: ActionType<typeof accountProviderFetch>) => {
            return isActionOf(accountProviderFetch, a) && a.payload.accountUri === accountUri
        }, handleAccountProviderFetch)
    } as GeneratorFunction

    return accountProviderSagaCache[`${accountUri}`]
}

export function* handleAccountProviderInitialize(
    action: ActionType<typeof accountProviderInitialize>
) {
    const { accountUri, noCache } = action.payload

    yield put(
        forkWatcher(
            getType(accountProviderConstructor(accountUri)),
            handleAccountProvider(accountUri)
        )
    )
    yield put(accountProviderConstructor(accountUri)())
    yield put(accountProviderFetch(accountUri, noCache))
}

export function* handleAccountProviderTeardown(action: ActionType<typeof accountProviderTeardown>) {
    const { accountUri } = action.payload

    yield put(cancelAction(getType(accountProviderConstructor(accountUri)), false))
}

export function* watchAccountProviderSaga() {
    yield takeEvery(getType(accountProviderInitialize), handleAccountProviderInitialize)
    yield takeEvery(getType(accountProviderTeardown), handleAccountProviderTeardown)
}

function* accountProviderRootSaga() {
    yield fork(watchAccountProviderSaga)
}

SagaRegistry.register(accountProviderRootSaga)
ReducerRegistry.register({
    accountProvider: accountProviderReducer,
})
