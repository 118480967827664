export * from "./middleware"
import { TRACK_JS_TOKEN } from "modules/common"
import { redactTokens } from "modules/trackjs/helpers"
import { TrackJS } from "trackjs"

if (TRACK_JS_TOKEN) {
    const VERSION = process.env.CIRCLE_SHA1 || process.env.GIT_HASH || "development"
    TrackJS.install({
        application: "midgard",
        token: TRACK_JS_TOKEN,
        version: VERSION,
        onError: redactTokens,
    })
    if (!window.onerror) {
        window.onerror = (e) => {
            TrackJS.track(e)
        }
    }
}

export let trackJsConfigured = false
export const setTrackJSConfigured = (v = true) => {
    trackJsConfigured = v
}
