import { murmur3 } from "murmurhash-js"

const isEmptyObject = (obj: object) => {
    for (const _key of Object.keys(obj)) {
        return false
    }
    return true
}

export const createObjectFromExpression = (path: string, value: any) => {
    const pathParts = path.split(".")
    const pathPartsLength = pathParts.length
    const result = {}
    let currentLocation = result
    pathParts.forEach((pathPart, index) => {
        Object.defineProperty(currentLocation, pathPart, {
            configurable: true,
            enumerable: true,
            value: index === pathPartsLength - 1 ? value : {},
            writable: true,
        })
        if (currentLocation.hasOwnProperty(pathPart)) {
            currentLocation = (currentLocation as any)[pathPart]
        }
    })
    currentLocation = value

    return result
}

export function objectDiff<L extends { [key: string]: any }, R extends { [key: string]: any }>(
    leftObj: L,
    rightObj: R,
    ignoreProperties?: string[]
) {
    const result: { [key: string]: any } = {}
    const keys = [...new Set(Object.keys(leftObj).concat(Object.keys(rightObj)))]
    keys.forEach((key) => {
        if (Array.isArray(ignoreProperties) && ignoreProperties.indexOf(key) !== -1) {
            return
        }
        if (
            (!rightObj.hasOwnProperty(key) || rightObj[key] === undefined) &&
            leftObj.hasOwnProperty(key)
        ) {
            result[key] = leftObj[key]
        } else if (
            rightObj.hasOwnProperty(key) &&
            (!leftObj.hasOwnProperty(key) || leftObj[key] === undefined)
        ) {
            result[key] = rightObj[key]
        } else if (typeof rightObj[key] !== typeof leftObj[key]) {
            throw new Error(`property '${key}' is not of same type on both sides, aborting...`)
        } else if (
            typeof leftObj[key] !== "object" &&
            (Array.isArray(leftObj[key]) || leftObj[key] !== rightObj[key])
        ) {
            result[key] = rightObj[key]
        } else if (typeof leftObj[key] === "object") {
            result[key] = objectDiff(leftObj[key], rightObj[key])
        }
    })

    for (const key in result) {
        if (typeof result[key] === "object" && isEmptyObject(result[key])) {
            delete result[key]
        }
    }

    return result
}

export function objectChecksum(obj: { [key: string]: any }, excludeKeys?: string[]) {
    const sortedKeys = Object.keys(obj)
        .filter((k) => (excludeKeys ? excludeKeys.indexOf(k) === -1 : true))
        .sort()
    const objValueParts = []
    for (const key of sortedKeys) {
        if (obj[key]) {
            objValueParts.push(obj[key])
        }
    }

    return murmur3(objValueParts.join("-"))
}
