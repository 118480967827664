import { deepEqual } from "fast-equals"
import { Problem } from "ketting"
import { HttpError } from "ketting/dist/http/error"
import Auth0 from "modules/authentication/Auth0"
import { MerchantApiRequest, MerchantApiResponse, TRACK_JS_TOKEN } from "modules/common"
import { Uri } from "modules/common/types"
import { BehaviorSubject, combineLatest, from, Observable, of, Subject } from "rxjs"
import {
    catchError,
    debounceTime,
    distinctUntilChanged,
    map,
    mergeMap,
    shareReplay,
    takeUntil,
    tap,
} from "rxjs/operators"
import { TrackJS } from "trackjs"

/**
 * Return value
 * {
 *     status: "loading" | "idle" | "error" | "success",
 *     error: Problem | Error | undefined
 *     data: any
 *     embedded: any[]
 *     relations: relations
 * }
 */

export type ResponseStatus = "loading" | "idle" | "error" | "success"
type ResponseError = Problem | HttpError | Error | undefined

export type MerchantApiResponseQueryReturn<
    Data = unknown,
    Relations = unknown,
    Embedded = unknown
> = {
    data: Data | undefined
    status: ResponseStatus
    error: ResponseError
    relations?: Relations
    embedded?: Embedded | null
}

class MerchantApiResponseQuery {
    static instance = new MerchantApiResponseQuery()

    // Internal states
    private _statuses: Map<Uri, BehaviorSubject<ResponseStatus>> = new Map()
    private _errors: Map<Uri, BehaviorSubject<ResponseError>> = new Map()
    private _data: Map<Uri, BehaviorSubject<MerchantApiResponse<any, any, any> | undefined>> =
        new Map()
    private _dead: Map<Uri, Subject<boolean>> = new Map()
    private _forceUpdate: Map<Uri, Subject<boolean>> = new Map()

    private _cache: Map<Uri, Observable<any>> = new Map()

    private _prepareResponse(uri: Uri) {
        if (!this._statuses.has(uri)) {
            this._statuses.set(uri, new BehaviorSubject("idle"))
        }
        if (!this._errors.has(uri)) {
            this._errors.set(uri, new BehaviorSubject(undefined))
        }
        if (!this._data.has(uri)) {
            this._data.set(uri, new BehaviorSubject(undefined))
        }
        if (!this._dead.has(uri)) {
            this._dead.set(uri, new Subject())
        }
    }

    private _clearResponseCache(uri: Uri) {
        this._cache.delete(uri)
        this._statuses.delete(uri)
        this._errors.delete(uri)
        this._data.delete(uri)
        this._dead.delete(uri)
    }

    private _handleError(e: Problem | HttpError | Error) {
        if ((e instanceof Problem || e instanceof HttpError) && e.status === 401) {
            Auth0.instance.login()
        } else {
            if (TRACK_JS_TOKEN) {
                TrackJS.track(e)
            } else {
                // tslint:disable-next-line:no-console
                console.error(e)
            }
        }
    }

    public invalidate(uri: Uri) {
        if (this._cache.has(uri)) {
            this._forceUpdate.get(uri)?.next(true)
        }
    }

    public latestStatusValue(uri: Uri) {
        if (this._statuses.has(uri)) {
            return this._statuses.get(uri)!.getValue()
        } else {
            return "idle"
        }
    }

    public latestErrorValue(uri: Uri) {
        if (this._errors.has(uri)) {
            return this._errors.get(uri)!.getValue()
        } else {
            return undefined
        }
    }

    public latestDataValue<D extends object>(uri: Uri) {
        if (this._data.has(uri)) {
            return this._data.get(uri)!.getValue()?.repr as D
        } else {
            return undefined
        }
    }

    public latestRelationsValue<R extends object>(uri: Uri) {
        if (this._data.has(uri)) {
            return this._data.get(uri)!.getValue()?.relations as R
        } else {
            return undefined
        }
    }

    public latestEmbeddedValue<E extends object>(uri: Uri) {
        if (this._data.has(uri)) {
            return this._data.get(uri)!.getValue()?.embeddedResources as E
        } else {
            return undefined
        }
    }

    public get<TData = unknown, TRelations = unknown, TEmbedded = unknown>(
        uri: Uri,
        invalidateCache?: boolean
    ): Observable<MerchantApiResponseQueryReturn<TData, TRelations, TEmbedded>> {
        if (!this._forceUpdate.has(uri)) {
            this._forceUpdate.set(uri, new Subject())
        }
        const forceUpdateSubject = this._forceUpdate.get(uri)!

        if (this._cache.has(uri)) {
            if (invalidateCache) {
                this._forceUpdate.get(uri)?.next(true)
            }
            return this._cache.get(uri) as Observable<
                MerchantApiResponseQueryReturn<TData, TRelations, TEmbedded>
            >
        }

        this._prepareResponse(uri)

        const source = forceUpdateSubject.pipe(
            tap(() => {
                const statusSubject = this._statuses.get(uri)
                if (!statusSubject) {
                    throw new Error(
                        `'${uri}' MerchantApiResponse is in an unusable state - Invalid statusSubject`
                    )
                }

                statusSubject.next("loading")
            }),
            mergeMap(() => {
                const merchantApiRequest = new MerchantApiRequest<TData, TRelations, TEmbedded>(
                    uri,
                    { noCache: true }
                )
                return from(merchantApiRequest.get())
            }),
            catchError((e: Problem | HttpError | Error) => {
                this._handleError(e)

                const errorSubject = this._errors.get(uri)
                const statusSubject = this._statuses.get(uri)
                if (errorSubject && statusSubject) {
                    errorSubject.next(e)
                    statusSubject.next("error")
                } else {
                    throw new Error(
                        `'${uri}' MerchantApiResponse is in an unusable state - ErrorSubject is ${errorSubject}, StatusSubject is ${statusSubject}`
                    )
                }

                return of(undefined)
            }),
            tap((resp) => {
                const errorSubject = this._errors.get(uri)
                const statusSubject = this._statuses.get(uri)
                const dataSubject = this._data.get(uri)
                if (!statusSubject) {
                    throw new Error(
                        `'${uri}' MerchantApiResponse is in an unusable state - Invalid statusSubject`
                    )
                }
                if (!errorSubject) {
                    throw new Error(
                        `'${uri}' MerchantApiResponse is in an unusable state - Invalid errorSubject`
                    )
                }
                if (!dataSubject) {
                    throw new Error(
                        `'${uri}' MerchantApiResponse is in an unusable state - Invalid dataSubject`
                    )
                }

                if (!resp) {
                    statusSubject.next("error")
                } else {
                    errorSubject.next(undefined)
                    statusSubject.next("success")
                    dataSubject.next(resp)
                }
            }),
            takeUntil(this._dead.get(uri)!),
            shareReplay(1)
        )

        const statusSubject = this._statuses.get(uri)!
        const errorSubject = this._errors.get(uri)!
        const dataSubject = this._data.get(uri)!

        this._cache.set(
            uri,
            combineLatest([dataSubject, statusSubject, errorSubject]).pipe(
                debounceTime(25),
                map(([source, status, error]) => {
                    let data
                    let relations
                    let embedded

                    if (source) {
                        data = source.repr
                        relations = source.relations
                        embedded = source.embeddedResources
                    }

                    const result: MerchantApiResponseQueryReturn<TData, TRelations, TEmbedded> = {
                        data,
                        relations,
                        embedded,
                        status,
                        error,
                    }
                    return result
                }),
                distinctUntilChanged(deepEqual),
                takeUntil(this._dead.get(uri)!),
                shareReplay(1)
            )
        )

        // Internal subscription - Automatically terminates when a notification is sent on this._dead.get(uri)
        source.subscribe()
        // Trigger first request
        forceUpdateSubject.next(true)

        return this._cache.get(uri)! as Observable<
            MerchantApiResponseQueryReturn<TData, TRelations, TEmbedded>
        >
    }

    public put() {
        throw Error(`Not implemented yet!`)
    }

    public post<TDto = unknown, TData = unknown, TRelations = unknown, TEmbedded = unknown>(
        body: TDto,
        uri: Uri,
        noFollow = false
    ): Observable<MerchantApiResponseQueryReturn<TData | boolean, TRelations, TEmbedded>> {
        const statusSubject = new BehaviorSubject<ResponseStatus>("idle")
        const errorSubject = new BehaviorSubject<ResponseError>(undefined)
        const dataSubject = new BehaviorSubject<
            MerchantApiResponse<TData, TRelations, TEmbedded> | undefined
        >(undefined)
        const terminateSubject = new Subject()

        const source = of(uri).pipe(
            tap(() => {
                statusSubject.next("loading")
            }),
            mergeMap((uri) => {
                const merchantApiRequest = new MerchantApiRequest<TData, TRelations, TEmbedded>(
                    uri,
                    { noCache: true }
                )

                return from(merchantApiRequest.post(body, noFollow))
            }),
            catchError((e: Problem | HttpError | Error) => {
                this._handleError(e)

                statusSubject.next("error")
                errorSubject.next(e)
                terminateSubject.next(true)

                return of(false)
            }),
            tap((resp) => {
                if (typeof resp !== "boolean") {
                    dataSubject.next(resp)
                    statusSubject.next("success")
                    this.invalidate(uri)
                }
                terminateSubject.next(true)
            }),
            takeUntil(terminateSubject)
        )

        source.subscribe()

        return combineLatest([dataSubject, statusSubject, errorSubject]).pipe(
            debounceTime(25),
            map(([source, status, error]) => {
                let data
                let relations
                let embedded
                if (source !== undefined && typeof source !== "boolean") {
                    data = source.repr
                    relations = source.relations
                    embedded = source.embeddedResources
                }

                return {
                    data,
                    relations,
                    embedded,
                    status,
                    error,
                }
            })
        )
    }

    public delete(
        uri: Uri
    ): Observable<MerchantApiResponseQueryReturn<boolean, undefined, undefined>> {
        const statusSubject = new BehaviorSubject<ResponseStatus>("idle")
        const errorSubject = new BehaviorSubject<ResponseError>(undefined)
        const dataSubject = new BehaviorSubject<boolean | undefined>(undefined)
        const terminateSubject = new Subject()

        const source = of(uri).pipe(
            tap(() => {
                statusSubject.next("loading")
            }),
            mergeMap((uri) => {
                const merchantApiRequest = new MerchantApiRequest(uri, { noCache: true })

                return from(merchantApiRequest.delete())
            }),
            catchError((e: Problem | HttpError | Error) => {
                this._handleError(e)

                statusSubject.next("error")
                errorSubject.next(e)

                return of("error")
            }),
            tap((resp) => {
                if (resp !== "error") {
                    if (this._cache.has(uri)) {
                        // Set cache observable as dead
                        this._dead.get(uri)!.next(true)

                        // Clean up
                        this._clearResponseCache(uri)
                    }
                    statusSubject.next("success")
                }
                dataSubject.next(resp !== "error")
                terminateSubject.next(true)
            }),
            takeUntil(terminateSubject)
        )

        source.subscribe()

        return combineLatest([dataSubject, statusSubject, errorSubject]).pipe(
            debounceTime(25),
            map(([data, status, error]) => {
                return {
                    data,
                    status,
                    error,
                }
            })
        )
    }
}

const instance = MerchantApiResponseQuery.instance
export { instance as MerchantApiResponseQuery }
