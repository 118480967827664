import { all, cancel, fork, put, takeEvery, takeLatest } from "@redux-saga/core/effects"
import { Problem } from "ketting"
import { HttpError } from "ketting/dist/http/error"
import Auth0 from "modules/authentication/Auth0"
import { TRACK_JS_TOKEN } from "modules/common"
import { cancelAction, catchError, forkWatcher } from "modules/common/actions"
import { CancelableSagaRegistry } from "modules/common/CancelableSagaRegistry"
import { TrackJS } from "trackjs"
import { ActionType, getType } from "typesafe-actions"

function* handleCatchError(action: ActionType<typeof catchError>) {
    const typedError = action.payload as Problem & HttpError & Error
    if (typedError && typedError.status === 401 && Auth0.instance) {
        Auth0.instance.login()
        return
    }

    if (TRACK_JS_TOKEN) {
        if (typedError.status === 400) {
            if (typedError.body) {
                TrackJS.console.error(typedError.body)
                TrackJS.track(typedError)
            } else if (typedError.response && typedError.response.body) {
                typedError.response.json().then((res) => {
                    TrackJS.console.error(res)
                    TrackJS.track(typedError)
                })
            }
        } else {
            TrackJS.track(typedError)
        }
    } else {
        // tslint:disable-next-line:no-console
        console.error(typedError)
    }
}

function* handleForkWatcher(action: ActionType<typeof forkWatcher>) {
    const actionName = action.payload
    const actionHandler = action.meta

    // @ts-ignore
    const taskFromFork = yield fork(function* () {
        yield takeEvery(actionName, actionHandler)
    })

    CancelableSagaRegistry.registerSaga({
        action: actionName,
        actionHandler,
        task: taskFromFork,
    })
}

function* handleCancelAction(action: ActionType<typeof cancelAction>) {
    const actionName = action.payload
    const { reFork } = action.meta
    const registeredSagas = CancelableSagaRegistry.getSagasByAction(actionName)
    for (const saga of registeredSagas) {
        if (saga.task && saga.task.isRunning()) {
            yield cancel(saga.task)
            CancelableSagaRegistry.deregisterSaga(saga)
            if (reFork) {
                yield put(forkWatcher(saga.action, saga.actionHandler))
            }
        }
    }

    return
}

function* watchCatchError() {
    yield takeEvery(getType(catchError), handleCatchError)
}

function* watchForkWatcher() {
    yield takeEvery(getType(forkWatcher), handleForkWatcher)
}

function* watchCancelAction() {
    yield takeLatest(getType(cancelAction), handleCancelAction)
}

export default function* commonSagas() {
    yield all([fork(watchCatchError), fork(watchForkWatcher), fork(watchCancelAction)])
}
