import { rootReducer } from "modules/root/reducer"
import { ReducerRegistry } from "utils/ReducerRegistry"
import { SagaRegistry } from "utils/SagaRegistry"
import rootRootSaga from "./sagas"

export * from "./actions"
export type { IRootData } from "./types"
import useRoot from "./useRoot"
export { useRoot }

SagaRegistry.register(rootRootSaga)
ReducerRegistry.register({
    root: rootReducer,
})
