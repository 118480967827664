import { ISO3166_1_alpha2, Uri } from "modules/common/types"

export const ACTION_PREFIX = "@@companies"
export const FETCH_COMPANY = "@@companies/FETCH_COMPANY"
export const FETCH_COMPANY_OK = "@@companies/FETCH_COMPANY_OK"
export const FETCH_COMPANY_ERROR = "@@companies/FETCH_COMPANY_ERROR"
export const UPDATE_COMPANY = "@@companies/UPDATE_COMPANY"
export const UPDATE_COMPANY_OK = "@@companies/UPDATE_COMPANY_OK"
export const UPDATE_COMPANY_ERROR = "@@companies/UPDATE_COMPANY_ERROR"
export const FETCH_COMPANY_AND_PEOPLE = "@@companies/FETCH_COMPANY_AND_PEOPLE"

export type OwnershipStructure =
    | "sole_director"
    | "sole_not_director"
    | "one_or_more_25"
    | "none_25"

export interface ICompanyData {
    name?: string
    registration_number?: string
    email?: string
    phone?: string
    address_line_1?: string
    address_line_2?: string | null
    city?: string
    zipcode?: string
    country?: ISO3166_1_alpha2
    ownership_structure?: OwnershipStructure
    ownership_structure_comment?: string | null
    form?: "Forening" | "Other" | "Iværksætterselskab" | null
}

export interface ICompanyRelations {
    application: Uri
    people: Uri
    comments: Uri | Uri[]
    files?: Uri
    self: Uri
}

export interface ICompany extends ICompanyData {
    relations: ICompanyRelations
}
