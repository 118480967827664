export const ACTION_PREFIX = "@@voucher/"
export const GET_VOUCHER = "@@voucher/GET_VOUCHER"
export const GET_VOUCHER_OK = "@@voucher/GET_VOUCHER_OK"
export const GET_VOUCHER_ERROR = "@@voucher/GET_VOUCHER_ERROR"

export interface IVoucherData {
    id?: string
    code: string
    gateway_id: string
    gateway_name: string
    instant?: boolean
}

export interface CutterVoucherResponse {
    code: string
    gateway_id: string
    gateway_name: string
    id: string
    referral_partner_id?: string
    referral_partner_name?: string
}
