export default (languageCode: string) => {
    let locale
    switch (languageCode) {
        case "da":
            locale = "da_DK"
            break
        case "en":
            locale = "en_GB"
            break
        case "de":
            locale = "de_DE"
            break
        default:
            locale = "zu_ZU"
            break
    }

    return locale
}
