import { AlertTriangleColoredIcon, Button } from "@clearhaus/design-system"
import { Grid } from "@clearhaus/design-system/Grid"
import { LocalizationLoader } from "components/common"
import { EmptyState } from "components/common/EmptyState/EmptyState"
import React, { useCallback } from "react"
import { Translate } from "react-localize-redux"
import { useHistory } from "react-router"

const icon = <AlertTriangleColoredIcon />

const texts = {
    title: <Translate id="common.not_found.heading" />,
    description: <Translate id="common.not_found.description" />,
    button: <Translate id="common.not_found.button" />,
}

const NotFound: React.FC = () => {
    const history = useHistory()

    const handleOnClick = useCallback(() => {
        history.replace("/")
    }, [])

    return (
        <LocalizationLoader namespace={"common"}>
            <Grid row alignItems="center" grow={1}>
                <Grid item>
                    <EmptyState>
                        <EmptyState.Icon icon={icon} />
                        <EmptyState.Title>{texts.title}</EmptyState.Title>
                        <EmptyState.Description>{texts.description}</EmptyState.Description>
                        <EmptyState.Actions>
                            <Grid row alignItems="center" justify="center">
                                <Grid item auto>
                                    <Button onClick={handleOnClick}>{texts.button}</Button>
                                </Grid>
                            </Grid>
                        </EmptyState.Actions>
                    </EmptyState>
                </Grid>
            </Grid>
        </LocalizationLoader>
    )
}

export { NotFound }
export default NotFound
