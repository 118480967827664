import { put } from "@redux-saga/core/effects"
import { Problem } from "ketting"
import {
    IAccount,
    IAccountData,
    IAccountRelations,
    IAccountsData,
    IAccountsRelations,
} from "modules/accounts/types"
import { MerchantApiRequest, MerchantApiResponse } from "modules/common"
import { catchError, forkWatcher } from "modules/common/actions"
import { call } from "redux-saga/effects"
import { ActionType, getType } from "typesafe-actions"
import UrlParse from "url-parse"
import * as actions from "./actions"

export function* handleFetchAccount(action: ActionType<typeof actions.fetchAccount>) {
    const { uri: accountUri, noCache } = action.payload
    try {
        const merchantApiRequest = new MerchantApiRequest<IAccountData, IAccountRelations>(
            accountUri,
            { noCache }
        )
        const { relations, repr }: MerchantApiResponse<IAccountData, IAccountRelations> =
            yield call([merchantApiRequest, merchantApiRequest.get])

        // @ts-ignore
        if (repr.metadata?.state === "new") {
            repr.metadata.state = "in_progress"
        }

        yield put(actions.fetchAccountOk(repr, relations, accountUri, noCache))
    } catch (e) {
        yield put(actions.fetchAccountError(e, accountUri, noCache))
        yield put(catchError(e))
    }
}

export function* handleFetchAccounts(action: ActionType<typeof actions.fetchAccounts>) {
    const { uri: initialUri, per_page, page, query, noCache } = action.payload

    try {
        const queryParams = {}
        if (page) {
            Object.assign(queryParams, {
                page: page.toString(),
            })
        }
        if (query) {
            Object.assign(queryParams, {
                query: query.toString(),
            })
        }
        if (per_page) {
            Object.assign(queryParams, {
                per_page: per_page.toString(),
            })
        }
        const uri = new UrlParse(initialUri, true)

        uri.set("query", {
            ...uri.query,
            ...queryParams,
        })

        const merchantApiRequest = new MerchantApiRequest<IAccountsData, IAccountsRelations>(
            uri.toString(),
            {
                noCache,
            }
        )
        const { relations, repr }: MerchantApiResponse<IAccountsData, IAccountsRelations> =
            yield call([merchantApiRequest, merchantApiRequest.get])

        yield put(
            actions.fetchAccountsOk(repr, relations, initialUri, per_page, page, query, noCache)
        )
    } catch (e) {
        if (e instanceof Problem) {
            yield put(actions.fetchAccountsError(e, initialUri, per_page, page, query, noCache))
        }
        yield put(catchError(e))
    }
}

export function* handleBatchFetchAccount(action: ActionType<typeof actions.batchFetchAccount>) {
    const { uris } = action.payload
    const accounts: IAccount[] = []

    try {
        for (const uri of uris) {
            const merchantApiRequest = new MerchantApiRequest(uri)
            const { repr, relations }: MerchantApiResponse<IAccountData, IAccountRelations> =
                yield call([merchantApiRequest, merchantApiRequest.get])

            if (!repr.metadata) {
                const merchantApiRequestNoCache = new MerchantApiRequest(uri, { noCache: true })
                const { repr, relations }: MerchantApiResponse<IAccountData, IAccountRelations> =
                    yield call([merchantApiRequestNoCache, merchantApiRequestNoCache.get])

                accounts.push({
                    ...repr,
                    relations,
                })
            } else {
                // @ts-ignore
                if (repr.metadata?.state === "new") {
                    repr.metadata.state = "in_progress"
                }

                accounts.push({
                    ...repr,
                    relations,
                })
            }
        }

        yield put(actions.batchFetchAccountOk(accounts, uris))
    } catch (e) {
        if (e instanceof Problem) {
            yield put(actions.batchFetchAccountError(e, uris))
        }
        yield put(catchError(e))
    }
}

export default function* accountRootSaga() {
    yield put(forkWatcher(getType(actions.fetchAccount), handleFetchAccount))
    yield put(forkWatcher(getType(actions.fetchAccounts), handleFetchAccounts))
    yield put(forkWatcher(getType(actions.batchFetchAccount), handleBatchFetchAccount))
}
