import React, { createContext, CSSProperties, useLayoutEffect, useMemo, useState } from "react"
import { useLocation } from "react-router-dom"

interface Context {
    style?: CSSProperties
    setStyle: (style: CSSProperties) => void
}

const Context = createContext<Context>({
    style: undefined,
    setStyle: () => {
        throw Error("setStyle not overridden")
    },
})

interface IProviderProps {
    children: React.ReactNode
}
const Provider: React.FC<IProviderProps> = (props) => {
    const { children } = props
    const location = useLocation()
    const [currentStyle, setCurrentStyle] = useState<CSSProperties | undefined>(undefined)

    const setStyle = (style: CSSProperties) => {
        setCurrentStyle(style)
    }

    useLayoutEffect(() => {
        setCurrentStyle(undefined)
    }, [location])

    const value = useMemo(
        () => ({
            style: currentStyle,
            setStyle,
        }),
        [currentStyle]
    )

    return <Context.Provider value={value}>{children}</Context.Provider>
}

export { Provider as ServiceMessagesProvider, Context as ServiceMessagesContext }
