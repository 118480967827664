import React from "react"
import { Route, RouteComponentProps, Switch } from "react-router-dom"
import { Account } from "./Redirect/Account"
import { Application } from "./Redirect/Application"
import { Home } from "./Redirect/Home"

export const RedirectRouter: React.FC<RouteComponentProps> = (props) => {
    const { match } = props

    return (
        <Switch>
            <Route path={`${match.path}/home/account/:id`} component={Home} />
            <Route path={`${match.path}/application/:id`} component={Application} />
            <Route path={`${match.path}/account/:id/:tabKey?`} component={Account} />
        </Switch>
    )
}
