import { LoadingScreen } from "@clearhaus/design-system"
import { LocalizationLoader } from "components/common"
import { useInitializeDefaultLanguage } from "components/localization/hooks"
import { localizationNamespaces } from "components/localization/localizationNamespaces"
import React from "react"

interface DefaultLanguageLocalizationLoaderProps {
    children: React.ReactNode
}

export const DefaultLanguageLocalizationLoader: React.FC<DefaultLanguageLocalizationLoaderProps> = (
    props
) => {
    const { children } = props
    const { isLoading } = useInitializeDefaultLanguage()

    if (isLoading) {
        return <LoadingScreen />
    }

    return <LocalizationLoader namespace={localizationNamespaces}>{children}</LocalizationLoader>
}
