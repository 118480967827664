import { makeStyles } from "@clearhaus/design-system/utils/styles/makeStyles"
import { ControlItem } from "pages/DashboardWelcome/ControlItem"
import React from "react"
import { Translate } from "react-localize-redux"
import { styles } from "./styles"

const texts = {
    accountTitle: <Translate id="common.dashboard_welcome.controls.account_title" />,
    accountDescription: <Translate id="common.dashboard_welcome.controls.account_description" />,
    navigationTitle: <Translate id="common.dashboard_welcome.controls.navigation_title" />,
    navigationDescription: (
        <Translate id="common.dashboard_welcome.controls.navigation_description" />
    ),
    userTitle: <Translate id="common.dashboard_welcome.controls.user_title" />,
    userDescription: <Translate id="common.dashboard_welcome.controls.user_description" />,
    feedbackTitle: <Translate id="common.dashboard_welcome.controls.feedback_title" />,
    feedbackDescription: <Translate id="common.dashboard_welcome.controls.feedback_description" />,
}

interface IControlsProps {
    activeIndex: number
    onClick: (index: number) => void
    progressPercentage: number
}

const useStyles = makeStyles(styles)
export const Controls: React.FC<IControlsProps> = (props) => {
    const { activeIndex, onClick, progressPercentage } = props
    const classes = useStyles()

    return (
        <div className={classes.controls}>
            <ControlItem
                progressPercentage={progressPercentage}
                index={1}
                activeIndex={activeIndex}
                onClick={onClick}
            >
                <ControlItem.Title>{texts.accountTitle}</ControlItem.Title>
                <ControlItem.Description>{texts.accountDescription}</ControlItem.Description>
            </ControlItem>
            <ControlItem
                progressPercentage={progressPercentage}
                index={2}
                activeIndex={activeIndex}
                onClick={onClick}
            >
                <ControlItem.Title>{texts.navigationTitle}</ControlItem.Title>
                <ControlItem.Description>{texts.navigationDescription}</ControlItem.Description>
            </ControlItem>
            <ControlItem
                progressPercentage={progressPercentage}
                index={3}
                activeIndex={activeIndex}
                onClick={onClick}
            >
                <ControlItem.Title>{texts.userTitle}</ControlItem.Title>
                <ControlItem.Description>{texts.userDescription}</ControlItem.Description>
            </ControlItem>
            <ControlItem
                progressPercentage={progressPercentage}
                index={4}
                activeIndex={activeIndex}
                onClick={onClick}
            >
                <ControlItem.Title>{texts.feedbackTitle}</ControlItem.Title>
                <ControlItem.Description>{texts.feedbackDescription}</ControlItem.Description>
            </ControlItem>
        </div>
    )
}
