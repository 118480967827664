import {
    BoxEdges,
    Button,
    Grid,
    HomeIcon,
    MenuIcon,
    MessageSquareIcon,
    Navigation,
    RepeatIcon,
    RotateCwIcon,
    Spacer,
} from "@clearhaus/design-system"
import { AccountSwitcher } from "components/AccountSwitcher/AccountSwitcher"
import { AccountSwitcherContext } from "components/AccountSwitcher/Provider/AccountSwitcherContext"
import { AccountDropdown, LanguageSwitcher, SupportDropdown } from "components/common"
import { NavigationBrandmark } from "components/common/NavigationBrandmark"
import { NavigationItem } from "components/common/NavigationItem"
import { MasterNavigationContext } from "components/MasterNavigation/MasterNavigation"
import React, { useContext, useEffect, useState } from "react"
import { Translate } from "react-localize-redux"
import { useHistory, useLocation } from "react-router"
import useCallbackRef from "utils/useCallbackRef"

let masterNavigationMounted = 0
let navigationErrorTimeout = 0

const icons = {
    home: <HomeIcon />,
    transactions: <RepeatIcon />,
    settlements: <RotateCwIcon />,
    disputes: <MessageSquareIcon />,
}

const texts = {
    home: <Translate id="navigation.home" />,
    transactions: <Translate id="navigation.transactions" />,
    settlements: <Translate id="navigation.settlements" />,
    disputes: <Translate id="navigation.disputes" />,
}

interface INavbarProps {
    showMainNavigation: boolean
    heightPostMount?: (height: number) => void
    onOpenSidebar: () => void
}

export const Navbar: React.FC<INavbarProps> = (props) => {
    const history = useHistory()
    const location = useLocation()
    const { relations } = useContext(AccountSwitcherContext)

    const [navigationHeight, setNavigationHeight] = useState(0)
    const [masterNavigationHeight, setMasterNavigationHeight] = useState(0)
    const { setHeight } = useContext(MasterNavigationContext)

    useEffect(() => {
        if (masterNavigationHeight > 0 && props.heightPostMount) {
            setHeight(masterNavigationHeight)
            props.heightPostMount(masterNavigationHeight)
        }
    }, [masterNavigationHeight])

    useEffect(() => {
        masterNavigationMounted++

        return () => {
            if (navigationErrorTimeout) {
                window.clearTimeout(navigationErrorTimeout)
                navigationErrorTimeout = 0
            }
            masterNavigationMounted--
        }
    }, [])

    useEffect(() => {
        if (navigationHeight > 0) {
            setMasterNavigationHeight(navigationHeight)
        }
    }, [navigationHeight])

    const handleHomeClick = useCallbackRef(() => {
        history.push("/")
    })

    const handleTransactionsClick = useCallbackRef(() => {
        history.push("/transactions")
    })

    const handleSettlementsClick = useCallbackRef(() => {
        history.push("/settlements")
    })

    const handleDisputesClick = useCallbackRef(() => {
        history.push("/disputes")
    })

    if (masterNavigationMounted > 1) {
        throw Error(`Only one <MasterNavigation /> is allowed`)
    }

    return (
        <Navigation fixedTop shadowAlwaysShown noPadding heightOnMount={setNavigationHeight}>
            {!props.showMainNavigation && (
                <>
                    <Grid item>
                        <Grid row>
                            <Navigation.Brand>
                                <NavigationBrandmark link="/" />
                            </Navigation.Brand>
                        </Grid>
                    </Grid>
                    <Grid item auto>
                        <Grid row alignItems="center" justify="center" noGutters>
                            <Navigation.Nav align="right">
                                <Grid row alignItems="center" justify="center" noGutters>
                                    <Grid item auto>
                                        <LanguageSwitcher />
                                    </Grid>
                                    <Grid item auto>
                                        <SupportDropdown />
                                    </Grid>
                                    <Grid item auto>
                                        <AccountDropdown />
                                    </Grid>
                                </Grid>
                            </Navigation.Nav>
                        </Grid>
                    </Grid>
                </>
            )}
            {props.showMainNavigation && (
                <>
                    <Grid item auto xs lg={0}>
                        <BoxEdges mr={12}>
                            <Button onClick={props.onOpenSidebar} round light>
                                <MenuIcon />
                            </Button>
                        </BoxEdges>
                    </Grid>
                    <Grid item xs={0} sm lg={3}>
                        <Grid row alignItems={"center"} noGutters>
                            <Grid item xs={0} xl="auto">
                                <Grid row noGutters>
                                    <Navigation.Brand>
                                        <NavigationBrandmark link="/" />
                                    </Navigation.Brand>
                                    <Grid item auto>
                                        <Spacer size={16} />
                                    </Grid>
                                </Grid>
                            </Grid>
                            {relations && (
                                <Navigation.Nav align="left">
                                    <Grid row alignItems={"center"} justify={"center"} noGutters>
                                        <Grid item xs={0} sm="auto">
                                            <BoxEdges my={8}>
                                                <AccountSwitcher />
                                            </BoxEdges>
                                        </Grid>
                                    </Grid>
                                </Navigation.Nav>
                            )}
                        </Grid>
                    </Grid>
                    {relations && (
                        <Grid item xs={0} lg={6}>
                            <Grid row justify="center" alignItems="center">
                                <Navigation.Nav align="left">
                                    <Grid container fluid>
                                        <Grid
                                            row
                                            noGutters
                                            alignItems="center"
                                            justify="center"
                                            wrap="nowrap"
                                        >
                                            <Grid item auto>
                                                <Grid item xs={12} lg={0}>
                                                    <NavigationItem
                                                        text={texts.home}
                                                        onClick={handleHomeClick}
                                                        active={location.pathname === "/"}
                                                    />
                                                </Grid>
                                                <Grid item xs={0} lg={12}>
                                                    <NavigationItem
                                                        icon={icons.home}
                                                        text={texts.home}
                                                        onClick={handleHomeClick}
                                                        active={location.pathname === "/"}
                                                    />
                                                </Grid>
                                            </Grid>
                                            {relations.transactions && (
                                                <Grid item auto>
                                                    <Grid item xs={12} lg={0}>
                                                        <NavigationItem
                                                            text={texts.transactions}
                                                            onClick={handleTransactionsClick}
                                                            active={location.pathname.startsWith(
                                                                "/transactions"
                                                            )}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={0} lg={12}>
                                                        <NavigationItem
                                                            icon={icons.transactions}
                                                            text={texts.transactions}
                                                            onClick={handleTransactionsClick}
                                                            active={location.pathname.startsWith(
                                                                "/transactions"
                                                            )}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            )}
                                            {relations.settlements && (
                                                <Grid item auto>
                                                    <Grid item xs={12} lg={0}>
                                                        <NavigationItem
                                                            text={texts.settlements}
                                                            onClick={handleSettlementsClick}
                                                            active={location.pathname.startsWith(
                                                                "/settlements"
                                                            )}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={0} lg={12}>
                                                        <NavigationItem
                                                            icon={icons.settlements}
                                                            text={texts.settlements}
                                                            onClick={handleSettlementsClick}
                                                            active={location.pathname.startsWith(
                                                                "/settlements"
                                                            )}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            )}
                                            {relations.disputes && (
                                                <Grid item auto>
                                                    <Grid item xs={12} lg={0}>
                                                        <NavigationItem
                                                            text={texts.disputes}
                                                            onClick={handleDisputesClick}
                                                            active={location.pathname.startsWith(
                                                                "/disputes"
                                                            )}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={0} lg={12}>
                                                        <NavigationItem
                                                            icon={icons.disputes}
                                                            text={texts.disputes}
                                                            onClick={handleDisputesClick}
                                                            active={location.pathname.startsWith(
                                                                "/disputes"
                                                            )}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </Grid>
                                </Navigation.Nav>
                            </Grid>
                        </Grid>
                    )}
                    <Grid item lg={3}>
                        <Grid row alignItems="center" justify="center" noGutters>
                            <Navigation.Nav align="right">
                                <Grid row alignItems="center" justify="center" noGutters>
                                    <Grid item auto>
                                        <LanguageSwitcher />
                                    </Grid>
                                    <Grid item auto>
                                        <SupportDropdown />
                                    </Grid>
                                    <Grid item auto>
                                        <AccountDropdown />
                                    </Grid>
                                </Grid>
                            </Navigation.Nav>
                        </Grid>
                    </Grid>
                </>
            )}
        </Navigation>
    )
}
