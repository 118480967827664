import { IServiceMessageType } from "components/ServiceMessages/Provider/reducer"
import { cancelAction, forkWatcher } from "modules/common/actions"
import { fetchMessages, fetchMessagesOk } from "modules/ratatoskr"
import { fetchMessagesError } from "modules/ratatoskr/sagas"
import { fetchIncidents, fetchIncidentsError, fetchIncidentsOk } from "modules/statuspage/sagas"
import { fork, put, race, take, takeEvery } from "redux-saga/effects"
import { ActionType, createCustomAction, getType } from "typesafe-actions"
import { ReducerRegistry } from "utils/ReducerRegistry"
import { SagaRegistry } from "utils/SagaRegistry"
import { providerReducer } from "./reducer"

const PROVIDER_FETCH = "@@serviceMessages/FETCH"
export const providerFetch = createCustomAction(PROVIDER_FETCH)
const PROVIDER_FETCH_OK = "@@serviceMessages/FETCH_OK"
export const providerFetchOk = createCustomAction(
    PROVIDER_FETCH_OK,
    (data: IServiceMessageType[]) => ({ payload: { data } })
)

function* handleProviderFetch() {
    const finalMessages: IServiceMessageType[] = []

    yield put(fetchMessages())
    const [messagesOk]: [
        ActionType<typeof fetchMessagesOk> | undefined,
        ActionType<typeof fetchMessagesError> | undefined
    ] = yield race([take(getType(fetchMessagesOk)), take(getType(fetchMessagesError))])

    yield put(fetchIncidents())
    const [incidentsOk]: [
        ActionType<typeof fetchIncidentsOk> | undefined,
        ActionType<typeof fetchIncidentsError> | undefined
    ] = yield race([take(getType(fetchIncidentsOk)), take(getType(fetchIncidentsError))])

    if (incidentsOk && incidentsOk.payload.data) {
        // First add incidents, as they should be higher priority
        if (incidentsOk.payload.data.incidents) {
            for (const incidentMessage of incidentsOk.payload.data.incidents) {
                finalMessages.push({
                    title: incidentMessage.name,
                    status: incidentMessage.status,
                    shortlink: incidentMessage.shortlink,
                })
            }
        }

        // Second add maintenance
        if (incidentsOk.payload.data.scheduled_maintenances) {
            const currentTime = new Date().getTime()
            for (const maintenanceMessage of incidentsOk.payload.data.scheduled_maintenances) {
                if (
                    maintenanceMessage.scheduled_for &&
                    maintenanceMessage.scheduled_until &&
                    new Date(maintenanceMessage.scheduled_for).getTime() > currentTime &&
                    new Date(maintenanceMessage.scheduled_until).getTime() > currentTime
                ) {
                    finalMessages.push({
                        title: maintenanceMessage.name,
                        status: maintenanceMessage.status,
                        scheduled_for: maintenanceMessage.scheduled_for,
                        scheduled_until: maintenanceMessage.scheduled_until,
                        shortlink: maintenanceMessage.shortlink,
                    })
                }
            }
        }
    }

    // Add support service messages last
    if (messagesOk && messagesOk.payload.data) {
        for (const message of messagesOk.payload.data.messages) {
            finalMessages.push({
                title: message.message,
                langauge: message.language,
            })
        }
    }

    yield put(providerFetchOk(finalMessages))
}

const INITIALIZE_PROVIDER = "@@serviceMessages/INITIALIZE"
export const initializeProvider = createCustomAction(INITIALIZE_PROVIDER)

const TEARDOWN_PROVIDER = "@@serviceMessages/TEARDOWN"
export const teardownProvider = createCustomAction(TEARDOWN_PROVIDER)

export function* handleInitializeProvider() {
    yield put(forkWatcher(getType(providerFetch), handleProviderFetch))
    yield put(providerFetch())
}

export function* handleTeardownProvider() {
    yield put(cancelAction(getType(providerFetch), false))
}

export function* watchProvider() {
    yield takeEvery(getType(initializeProvider), handleInitializeProvider)
    yield takeEvery(getType(teardownProvider), handleTeardownProvider)
}

function* providerRootSaga() {
    yield fork(watchProvider)
}

SagaRegistry.register(providerRootSaga)
ReducerRegistry.register({
    serviceMessages: providerReducer,
})
