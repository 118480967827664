import { Problem } from "ketting"
import { MerchantApiRequest, MerchantApiResponse } from "modules/common"
import { catchError, forkWatcher } from "modules/common/actions"
import { call, put } from "redux-saga/effects"
import { ActionType, getType } from "typesafe-actions"
import * as actions from "./actions"
import { IPeopleData, IPeopleRelations, IPerson, IPersonData, IPersonRelations } from "./types"

export function* handleFetchPeople(action: ActionType<typeof actions.fetchPeople>) {
    const { uri: peopleUri, noCache } = action.payload

    try {
        const merchantApiRequest = new MerchantApiRequest<IPeopleData, IPeopleRelations>(
            peopleUri,
            { noCache }
        )
        const { relations, repr }: MerchantApiResponse<IPeopleData, IPeopleRelations> = yield call([
            merchantApiRequest,
            merchantApiRequest.get,
        ])

        yield put(actions.fetchPeopleOk(repr, relations, peopleUri))
    } catch (e) {
        if (e instanceof Problem) {
            yield put(actions.fetchPeopleError(e, peopleUri))
        }
        yield put(catchError(e))
    }
}

export function* handleFetchPerson(action: ActionType<typeof actions.fetchPerson>) {
    const { uri: personUri } = action.payload

    try {
        const merchantApiRequest = new MerchantApiRequest<IPersonData, IPersonRelations>(personUri)
        const { relations, repr }: MerchantApiResponse<IPersonData, IPersonRelations> = yield call([
            merchantApiRequest,
            merchantApiRequest.get,
        ])

        yield put(actions.fetchPersonOk(repr, relations, personUri))
    } catch (e) {
        if (e instanceof Problem) {
            yield put(actions.fetchPersonError(e, personUri))
        }
        yield put(catchError(e))
    }
}

export function* handleBatchFetchPerson(action: ActionType<typeof actions.batchFetchPerson>) {
    const { uris: personUris } = action.payload
    const data: IPerson[] = []

    try {
        for (const personUri of personUris) {
            const merchantApiRequest = new MerchantApiRequest(personUri)
            const { repr, relations }: MerchantApiResponse<IPersonData, IPersonRelations> =
                yield call([merchantApiRequest, merchantApiRequest.get])
            data.push({
                ...repr,
                relations,
            })
        }

        yield put(actions.batchFetchPersonOk(data, personUris))
    } catch (e) {
        if (e instanceof Problem) {
            yield put(actions.batchFetchPersonError(e, personUris))
        }
        yield put(catchError(e))
    }
}

export function* handleCreatePerson(action: ActionType<typeof actions.createPerson>) {
    const { data: personData, uri: peopleUri } = action.payload
    try {
        const merchantApiRequest = new MerchantApiRequest(peopleUri, {
            contentType: "application/json",
        })
        const { repr, relations }: MerchantApiResponse<IPersonData, IPersonRelations> = yield call(
            [merchantApiRequest, merchantApiRequest.post],
            personData
        )
        yield put(actions.createPersonOk(repr, relations, personData, peopleUri))
    } catch (e) {
        yield put(catchError(e))
    }
}

export function* handleUpdatePerson(
    action: ActionType<typeof actions.updatePerson>
): Generator<any, any, any> {
    const { uri: personUri, data: personData } = action.payload

    try {
        const merchantApiRequest = new MerchantApiRequest(personUri)
        const { repr, relations }: MerchantApiResponse<IPersonData, IPersonRelations> = yield call(
            [merchantApiRequest, merchantApiRequest.patch],
            personData
        )

        return yield put(actions.updatePersonOk(repr, relations, personData, personUri))
    } catch (e) {
        if (e instanceof Problem) {
            yield put(actions.updatePersonError(e, personData, personUri))
        }
        yield put(catchError(e))
    }
}

export function* handleDeletePerson(action: ActionType<typeof actions.deletePerson>) {
    const { uri: personUri } = action.payload
    try {
        const merchantApiRequest = new MerchantApiRequest(personUri)
        yield call([merchantApiRequest, merchantApiRequest.delete])

        yield put(actions.deletePersonOk(personUri))
    } catch (e) {
        if (e instanceof Problem) {
            yield put(actions.deletePersonError(e, personUri))
        }
        yield put(catchError(e))
    }
}

export default function* peopleSaga() {
    yield put(forkWatcher(getType(actions.batchFetchPerson), handleBatchFetchPerson))
    yield put(forkWatcher(getType(actions.fetchPeople), handleFetchPeople))
    yield put(forkWatcher(getType(actions.fetchPerson), handleFetchPerson))
    yield put(forkWatcher(getType(actions.createPerson), handleCreatePerson))
    yield put(forkWatcher(getType(actions.updatePerson), handleUpdatePerson))
    yield put(forkWatcher(getType(actions.deletePerson), handleDeletePerson))
}
