import {
    BoxEdges,
    Button,
    ChevronDownIcon,
    defaults as defaultsOrg,
    Grid,
    Paragraph,
    Spacer,
    Tag,
    ThemeContext,
} from "@clearhaus/design-system"
import { makeStyles } from "@clearhaus/design-system/utils/styles/makeStyles"
import classNames from "classnames"
import { AccountSwitcherLinks } from "components/AccountSwitcher/AccountSwitcherLinks"
import { supportedCurrencies } from "modules/common/helpers"
import { Uri } from "modules/common/types"
import React, { useContext, useMemo } from "react"

const styles = (defaults: typeof defaultsOrg) => ({
    wrapper: {
        padding: `${defaults.spacing[4]}px ${defaults.spacing[12]}px`,
        backgroundColor: defaults.colors.primary[0],
        border: `1px solid ${defaults.colors.primary[200]}`,
        borderRadius: defaults.cornerRadius.large,
        width: 200,
        boxShadow: defaults.shadows.small.tight,

        "&:hover": {
            backgroundColor: defaults.colors.primary[100],
        },
    },

    pointer: {
        cursor: "pointer",
    },

    name: {
        fontSize: defaults.fontSizes[14],
    },

    fullWidth: {
        width: "auto",
    },
})

export type AccountSwitcherCurrencyType = typeof supportedCurrencies | "All"

interface IAccountSwitcherItem {
    chevron?: boolean
    fullWidth?: boolean
    wrapper?: boolean
    showLinks?: boolean
    currency?: AccountSwitcherCurrencyType
    name: string | JSX.Element
    descriptor?: string | JSX.Element
    mid?: string
    onClick?: () => void
    light?: boolean
    selfLink?: Uri
}

const useStyles = makeStyles(styles)
export const AccountSwitcherItem: React.FC<IAccountSwitcherItem> = React.memo((props) => {
    const {
        chevron,
        fullWidth,
        wrapper = true,
        showLinks = true,
        selfLink,
        currency,
        name,
        descriptor,
        mid,
        onClick,
        light,
    } = props

    const classes = useStyles()
    const { defaults } = useContext(ThemeContext)

    const wrapperClasses = useMemo(() => {
        return classNames({
            [classes.wrapper]: wrapper,
            [classes.fullWidth]: fullWidth,
        })
    }, [fullWidth])

    const containerClasses = useMemo(() => {
        return classNames({
            [classes.pointer]: onClick,
        })
    }, [onClick])

    return (
        <Grid row noGutters alignItems="center" wrap="nowrap" className={wrapperClasses}>
            <Grid item onClick={onClick} className={containerClasses}>
                <BoxEdges mb={4}>
                    <Grid row alignItems="center" noGutters>
                        <Grid item auto>
                            <BoxEdges mt={1}>
                                <Paragraph
                                    size="xs"
                                    weight="medium"
                                    noMargin
                                    color={light ? defaults.colors.neutrals[100] : undefined}
                                    className={classes.name}
                                    lineHeight="s"
                                >
                                    {name}
                                </Paragraph>
                            </BoxEdges>
                        </Grid>
                        {currency && (
                            <>
                                <Spacer size={8} />
                                <Grid item auto>
                                    <Tag xxsmall shade="light">
                                        {currency as string}
                                    </Tag>
                                </Grid>
                            </>
                        )}
                    </Grid>
                </BoxEdges>
                <Grid row>
                    <Grid item>
                        <Paragraph size={"xs"} lineHeight="s" light>
                            {mid && <>{mid} ·</>} {descriptor}
                        </Paragraph>
                    </Grid>
                </Grid>
            </Grid>
            {showLinks && (
                <Grid item auto>
                    <BoxEdges ml={24}>
                        <AccountSwitcherLinks selfLink={selfLink} />
                    </BoxEdges>
                </Grid>
            )}
            {chevron && (
                <Grid item auto onClick={onClick}>
                    <BoxEdges ml={16}>
                        <Button round size="small" tight light>
                            <ChevronDownIcon size={24} />
                        </Button>
                    </BoxEdges>
                </Grid>
            )}
        </Grid>
    )
})
