import {
    BoxEdges,
    Button,
    Heading,
    HelpCircleIcon,
    Paragraph,
    Separator,
    Spacer,
} from "@clearhaus/design-system"
import { Popover } from "@clearhaus/design-system"
import { makeStyles } from "@clearhaus/design-system/utils/styles/makeStyles"
import { NavigationItem } from "components/common/NavigationItem"
import { HELPDESK_URL } from "modules/common"
import React, { useCallback, useContext, useMemo, useState } from "react"
import { LocalizeContext, Translate } from "react-localize-redux"

interface ISupportDropdownProps {
    light?: boolean
}

const styles = () => ({
    link: {
        color: "inherit",
        textDecoration: "none",
        fontSize: "inherit",
    },
})

const SupportIcon = <HelpCircleIcon size={15} />

const useStyles = makeStyles(styles)
const SupportDropdown: React.FC<ISupportDropdownProps> = (props) => {
    const { translate } = useContext(LocalizeContext)
    const classes = useStyles()

    const texts = useMemo(
        () => ({
            phone: translate("navigation.support_dropdown.phone_number").toString(),
            email: translate("navigation.support_dropdown.email").toString(),
        }),
        []
    )
    const [showPopover, setShowPopover] = useState(false)

    const handleOnHideEnd = useCallback(() => {
        setShowPopover(false)
    }, [showPopover])

    const handleOnItemClick = useCallback(() => {
        setShowPopover(!showPopover)
    }, [showPopover])

    return (
        <Popover placement="bottom" show={showPopover}>
            <Popover.Reference>
                <NavigationItem
                    icon={SupportIcon}
                    onClick={handleOnItemClick}
                    chevron
                    light={props.light}
                />
            </Popover.Reference>
            <Popover.Content onHideEnd={handleOnHideEnd}>
                <BoxEdges minWidth={200} px={32} py={32} pb={24}>
                    <Heading level={4}>
                        <a className={classes.link} href={`tel:${texts.phone}`}>
                            <Translate id="navigation.support_dropdown.phone_number" />
                        </a>
                    </Heading>
                    <Spacer size={10} />
                    <Paragraph size="xs">
                        <Translate id="navigation.support_dropdown.opening_hours_monday_to_thursday" />
                    </Paragraph>
                    <Spacer size={8} />
                    <Paragraph size="xs">
                        <Translate id="navigation.support_dropdown.opening_hours_friday" />
                    </Paragraph>
                    <Spacer size={20} />
                    <Separator direction="horizontal" />
                    <Spacer size={20} />
                    <Heading level={4}>
                        <a className={classes.link} href={`mailto:${texts.email}`}>
                            <Translate id="navigation.support_dropdown.email" />
                        </a>
                    </Heading>
                    <Spacer size={10} />
                    <Paragraph size="xs">
                        <Translate id="navigation.support_dropdown.description" />
                    </Paragraph>
                    <Spacer size={20} />
                    <Separator direction="horizontal" />
                    <Spacer size={20} />
                    <Button href={HELPDESK_URL} target="blank" kind="plain">
                        <Translate id="navigation.support_dropdown.helpdesk_button" />
                    </Button>
                </BoxEdges>
            </Popover.Content>
        </Popover>
    )
}
export default SupportDropdown
export { SupportDropdown }
