import { deepEqual } from "fast-equals"
import { IStoreItemValue, RxStore } from "modules/common/RxStore"
import { Uri } from "modules/common/types"
import {
    batchFetchTransaction,
    fetchTransaction,
    fetchTransactions,
} from "modules/transactions/actions"
import {
    ITransactionData,
    ITransactionRelations,
    ITransactionsData,
    ITransactionsRelations,
} from "modules/transactions/types"
import { useEffect, useMemo, useState } from "react"
import { useDispatch } from "react-redux"
import { BehaviorSubject, combineLatest, Observable, of } from "rxjs"
import { debounceTime, distinctUntilChanged, map, shareReplay, switchMap } from "rxjs/operators"

export const useTransactions = (transactionsUri?: Uri, per_page?: number, query?: string) => {
    const dispatch = useDispatch()
    const [transactionsUriSubject] = useState(() => {
        return new BehaviorSubject(transactionsUri)
    })
    const [perPageSubject] = useState(() => {
        return new BehaviorSubject(per_page)
    })
    const [querySubject] = useState(() => {
        return new BehaviorSubject(query)
    })

    const transactions = useMemo(() => {
        return combineLatest([transactionsUriSubject, perPageSubject, querySubject]).pipe(
            debounceTime(100),
            map(([uri, per_page, query]) => {
                if (!uri) {
                    return of(null)
                }

                dispatch(fetchTransactions(uri, per_page, query))

                return RxStore.get<ITransactionsData, ITransactionsRelations>(uri, {
                    per_page,
                    query,
                })
            }),
            switchMap((o) => o),
            distinctUntilChanged<IStoreItemValue<ITransactionsData, ITransactionsRelations>>(
                deepEqual
            ),
            shareReplay(1)
        )
    }, [transactionsUriSubject, perPageSubject, querySubject])

    useEffect(() => {
        transactionsUriSubject.next(transactionsUri)
    }, [transactionsUri])

    useEffect(() => {
        perPageSubject.next(per_page)
    }, [per_page])

    useEffect(() => {
        querySubject.next(query)
    }, [query])

    return transactions
}

export const useBatchTransactions = (transactionUris?: Uri[]) => {
    const [transactionUrisSubject] = useState(() => {
        return new BehaviorSubject(transactionUris)
    })

    const dispatch = useDispatch()

    const transactions = useMemo(() => {
        return transactionUrisSubject.pipe(
            map((transactionUris: Uri[]) => {
                dispatch(batchFetchTransaction(transactionUris))

                return transactionUris
            }),
            map((transactionUris) =>
                transactionUris.map((transactionUri) =>
                    RxStore.get<ITransactionData, ITransactionRelations>(transactionUri)
                )
            ),
            map((transactionObs) => {
                if (transactionObs.length === 0) {
                    return of([])
                }

                return combineLatest([...transactionObs])
            }),
            switchMap((o) => o),
            debounceTime(50),
            distinctUntilChanged(deepEqual),
            shareReplay<Array<IStoreItemValue<ITransactionData, ITransactionRelations>>>(1)
        )
    }, [transactionUrisSubject])

    useEffect(() => {
        transactionUrisSubject.next(transactionUris)
    }, [transactionUris])

    return transactions as Observable<
        Array<IStoreItemValue<ITransactionData, ITransactionRelations>>
    >
}

export const useTransaction = (transactionUri?: Uri) => {
    const dispatch = useDispatch()

    const [uriSubject] = useState(() => {
        return new BehaviorSubject(transactionUri)
    })

    const transaction = useMemo(() => {
        return uriSubject.pipe(
            debounceTime(50),
            map((uri) => {
                if (!uri) {
                    return of(null)
                }

                dispatch(fetchTransaction(uri, true))

                return RxStore.get<ITransactionData, ITransactionRelations>(uri).pipe(
                    distinctUntilChanged<IStoreItemValue<ITransactionData, ITransactionRelations>>(
                        deepEqual
                    ),
                    shareReplay(1)
                )
            }),
            switchMap((o) => o),
            distinctUntilChanged<
                IStoreItemValue<ITransactionData, ITransactionRelations> | undefined
            >(deepEqual),
            shareReplay(1)
        )
    }, [transactionUri])

    useEffect(() => {
        uriSubject.next(transactionUri)
    }, [transactionUri])

    return transaction
}
