import { deepEqual } from "fast-equals"
import { fetchAccounts } from "modules/accounts/actions"
import { IAccountsData, IAccountsRelations } from "modules/accounts/types"
import { IStoreItemValue, RxStore } from "modules/common/RxStore"
import { Uri } from "modules/common/types"
import { useEffect, useMemo, useState } from "react"
import { useDispatch } from "react-redux"
import { BehaviorSubject, combineLatest, of } from "rxjs"
import { debounceTime, distinctUntilChanged, map, shareReplay, switchMap } from "rxjs/operators"

export const useAccounts = (
    accountsUri?: Uri,
    per_page?: number,
    page?: number,
    query?: string
) => {
    const [accountsUriSubject] = useState(() => {
        return new BehaviorSubject(accountsUri)
    })
    const [perPageSubject] = useState(() => {
        return new BehaviorSubject(per_page)
    })
    const [pageSubject] = useState(() => {
        return new BehaviorSubject(page)
    })
    const [querySubject] = useState(() => {
        return new BehaviorSubject(query)
    })

    const dispatch = useDispatch()

    const accounts = useMemo(() => {
        return combineLatest([accountsUriSubject, perPageSubject, pageSubject, querySubject]).pipe(
            debounceTime(50),
            map(([accountsUri, per_page, page, query]) => {
                if (!accountsUri) {
                    return of(undefined)
                }

                dispatch(fetchAccounts(accountsUri, per_page, page, query))

                return RxStore.get(accountsUri, { per_page, page, query })
            }),
            switchMap((o) => o),
            distinctUntilChanged<IStoreItemValue<IAccountsData, IAccountsRelations>>(deepEqual),
            shareReplay(1)
        )
    }, [accountsUriSubject, perPageSubject, pageSubject, querySubject])

    useEffect(() => {
        accountsUriSubject.next(accountsUri)
    }, [accountsUri])

    useEffect(() => {
        pageSubject.next(page)
    }, [page])

    useEffect(() => {
        perPageSubject.next(per_page)
    }, [per_page])

    useEffect(() => {
        querySubject.next(query)
    }, [query])

    return accounts
}
