import { Problem } from "ketting"
import { IAccount, IAccountsData, IAccountsRelations } from "modules/accounts/types"
import React from "react"

interface IAccountsContext {
    data: IAccount[]
    metadata: IAccountsData | null
    relations: IAccountsRelations | null
    isLoading: boolean
    loadMore: () => void
    error?: Problem
}

export const AccountsContext = React.createContext<IAccountsContext>({
    data: [],
    isLoading: false,
    loadMore: () => {
        throw Error(`No AccountsProvider`)
    },
    metadata: null,
    relations: null,
})
