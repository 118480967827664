import { Problem } from "ketting"
import { HttpError } from "ketting/dist/http/error"
import commonSagas from "modules/common/sagas"
import { createCustomAction, TypeConstant } from "typesafe-actions"
import { SagaRegistry } from "utils/SagaRegistry"
import { CANCEL_ACTION, CATCH_ERROR, FORK_WATCHER, WorkerPattern } from "./types"

export const catchError = createCustomAction(CATCH_ERROR, (error: Error | Problem | HttpError) => {
    return { payload: error }
})

export const cancelAction = createCustomAction(
    CANCEL_ACTION,
    (action: TypeConstant, reFork: boolean = true) => ({ payload: action, meta: { reFork } })
)
export const forkWatcher = createCustomAction(
    FORK_WATCHER,
    (action: TypeConstant, actionHandler: WorkerPattern<TypeConstant>) => ({
        meta: actionHandler,
        payload: action,
    })
)

SagaRegistry.register(commonSagas)
