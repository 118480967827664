import { WEBSITE_URL } from "modules/common"
import { useMemo } from "react"

export const useTermsUrl = (languageCode?: string) => {
    const url = useMemo(() => {
        switch (languageCode) {
            case "da":
                return `${WEBSITE_URL}/dk/terms/`
            case "de":
                return `${WEBSITE_URL}/de/terms/`
            default:
                return `${WEBSITE_URL}/terms/`
        }
    }, [languageCode])

    return url
}
