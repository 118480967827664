import { BoxEdges, Grid, Paragraph } from "@clearhaus/design-system"
import {
    IEmptyStateCommonProps,
    useEmptyStateContext,
} from "components/common/EmptyState/EmptyState"
import React from "react"

interface IDescription {
    color?: string
}

export const Description: React.FC<IEmptyStateCommonProps & IDescription> = (props) => {
    useEmptyStateContext()

    return (
        <BoxEdges mb={!props.noMargin ? 16 : 0}>
            <Grid row justify="center" alignItems="center">
                <Grid item auto>
                    <Paragraph noMargin align="center" color={props.color}>
                        <>{props.children}</>
                    </Paragraph>
                </Grid>
            </Grid>
        </BoxEdges>
    )
}
