import { Problem } from "ketting"
import { EntityIndex } from "modules/common/types"
import { Reducer } from "redux"
import { ActionType, getType } from "typesafe-actions"
import { createPersonOk, deletePerson, updatePersonOk } from "../../actions"
import { IPeopleData, IPeopleRelations, IPerson } from "../../types"
import {
    initializeProvider,
    providerDeleteError,
    providerFetch,
    providerFetchError,
    providerFetchOk,
    teardownProvider,
} from "./sagas"

export interface IProviderState {
    data: IPerson[] | null
    metadata: IPeopleData | null
    relations: IPeopleRelations | null
    isLoading: boolean
    error?: Problem
}

export interface IProvidersRootState {
    peopleProviders: EntityIndex<IProviderState>
}

type ActionTypes = ActionType<
    | typeof initializeProvider
    | typeof teardownProvider
    | typeof providerFetch
    | typeof providerFetchOk
    | typeof providerFetchError
    | typeof deletePerson
    | typeof providerDeleteError
    | typeof createPersonOk
    | typeof updatePersonOk
>

export const providersReducer: Reducer<EntityIndex<IProviderState>, ActionTypes> = (
    currentState,
    action
) => {
    let nextState
    if (!currentState) {
        currentState = {}
    }

    nextState = currentState

    switch (action.type) {
        case getType(initializeProvider): {
            const { uri } = action.payload
            nextState = {
                ...nextState,
                [uri]: {
                    data: null,
                    isLoading: false,
                    metadata: null,
                    relations: null,
                },
            }
            break
        }
        case getType(teardownProvider): {
            const { uri } = action.payload
            delete nextState[uri]
            break
        }
        case getType(providerFetch): {
            const { uri } = action.payload
            nextState = {
                ...nextState,
                [uri]: {
                    ...nextState[uri],
                    isLoading: true,
                },
            }
            break
        }
        case getType(providerFetchOk): {
            const { uri } = action.meta
            const { data, metadata, relations } = action.payload
            nextState = {
                ...nextState,
                [uri]: {
                    ...nextState[uri],
                    data,
                    isLoading: false,
                    metadata,
                    relations,
                },
            }
            break
        }
        case getType(createPersonOk): {
            const { data: personData, relations: personRelations } = action.payload
            const { uri: peopleUri } = action.meta

            const nextStateRelations = nextState[peopleUri].relations
            if (
                nextState[peopleUri] &&
                Array.isArray(nextState[peopleUri].data) &&
                nextState[peopleUri].metadata &&
                nextStateRelations
            ) {
                const nextStateRelationsPeople = Array.isArray(nextStateRelations.people)
                    ? (nextStateRelations.people = [
                          ...nextStateRelations.people,
                          personRelations.self,
                      ])
                    : nextStateRelations.people !== undefined
                    ? (nextStateRelations.people = [
                          nextStateRelations.people,
                          personRelations.self,
                      ])
                    : personRelations.self
                nextState[peopleUri] = {
                    ...nextState[peopleUri],
                    data: [
                        // @ts-ignore - Typescript does not understand the above isArray check
                        ...nextState[peopleUri].data,
                        { ...personData, relations: personRelations },
                    ],
                    // @ts-ignore - Typescript does not understand the above metadata check
                    metadata: {
                        ...nextState[peopleUri].metadata,
                        // @ts-ignore - Typescript does not understand the above metadata check
                        count: nextState[peopleUri].metadata.count + 1,
                    },
                    relations: {
                        ...nextStateRelations,
                        people: nextStateRelationsPeople,
                    },
                }
            }

            break
        }
        case getType(updatePersonOk): {
            const { data: personData, relations: personRelations } = action.payload
            const { uri: personUri } = action.meta

            for (const personProvider in nextState) {
                if (
                    nextState.hasOwnProperty(personProvider) &&
                    Array.isArray(nextState[personProvider].data)
                ) {
                    // @ts-ignore - Typescript does the understand the above isArray check
                    const personInData = nextState[personProvider].data.some(
                        (p) => p.relations.self === personUri
                    )
                    if (personInData) {
                        nextState[personProvider] = {
                            ...nextState[personProvider],
                            // @ts-ignore - Typescript does the understand the above isArray check
                            data: nextState[personProvider].data.map((p) => {
                                if (p.relations.self !== personUri) {
                                    return p
                                }

                                return {
                                    ...personData,
                                    relations: personRelations,
                                }
                            }),
                        }
                    }
                }
            }

            break
        }
        case getType(deletePerson): {
            const { uri: personUri } = action.payload

            for (const peopleProvider in nextState) {
                if (!nextState.hasOwnProperty(peopleProvider)) {
                    continue
                }

                const nextStateRelations = nextState[peopleProvider].relations
                const nextStateData = nextState[peopleProvider].data
                if (
                    nextStateData &&
                    nextStateRelations &&
                    ((Array.isArray(nextStateRelations.people) &&
                        nextStateRelations.people.some((p) => p === personUri)) ||
                        nextStateRelations.people === personUri)
                ) {
                    const relationsPeople = Array.isArray(nextStateRelations.people)
                        ? nextStateRelations.people.filter((p) => p !== personUri)
                        : undefined
                    nextState[peopleProvider] = {
                        ...nextState[peopleProvider],
                        data: nextStateData.filter((p) => {
                            if (p.relations.self !== personUri) {
                                return p
                            }
                            return null
                        }),
                        relations: {
                            ...nextStateRelations,
                            people:
                                Array.isArray(relationsPeople) && relationsPeople.length === 1
                                    ? relationsPeople[0]
                                    : relationsPeople,
                        },
                    }
                }
            }
            break
        }
        case getType(providerDeleteError):
        case getType(providerFetchError): {
            const { uri } = action.meta
            nextState = {
                ...nextState,
                [uri]: {
                    ...nextState[uri],
                    error: action.payload,
                    isLoading: false,
                },
            }
            break
        }
    }

    return nextState
}
