export const ACTION_PREFIX = "@@voyager/"
export const GET_SUPPORTED_COUNTRIES = "@@voyager/GET_SUPPORTED_COUNTRIES"
export const GET_SUPPORTED_COUNTRIES_OK = "@@voyager/GET_SUPPORTED_COUNTRIES_OK"
export const LOOKUP_COMPANY = "@@voyager/LOOKUP_COMPANY"
export const LOOKUP_COMPANY_OK = "@@voyager/LOOKUP_COMPANY_OK"
export const LOOKUP_COMPANY_ERROR = "@@voyager/LOOKUP_COMPANY_ERROR"
export const LOOKUP_COMPANY_NOT_FOUND = "@@voyager/LOOKUP_COMPANY_NOT_FOUND"

export type CompanyForm =
    | "Enkeltmandsvirksomhed"
    | "Interessentskab"
    | "Aktieselskab"
    | "Anpartsselskab"
    | "Forening"

export interface LegalEntityDto {
    address?: string
    city?: string
    name: string
    ownership_percentage?: number | null
    zip_code?: number | string
}

export interface LegalEntity extends LegalEntityDto {}

export interface OwnershipDto {
    beneficial: LegalEntityDto[]
    legal: LegalEntityDto[]
    ownedByDirector: boolean
    soleProprietorship: boolean
}

export interface Ownership extends OwnershipDto {
    beneficial: LegalEntity[]
    legal: LegalEntity[]
}

export interface CompanyDto {
    address: string
    city: string
    company_form: CompanyForm
    cvr: number
    director?: LegalEntityDto
    email: string
    name: string
    ownership: OwnershipDto
    ownership_distribution: LegalEntityDto[]
    phone: string
    zip_code: number | string
}

export interface LookedUpCompany extends CompanyDto {
    director?: LegalEntity
    ownership: Ownership
    ownership_distribution: LegalEntity[]
}

export interface CountriesDto {
    countries: string[]
}
