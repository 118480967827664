import { Button, SettingsIcon } from "@clearhaus/design-system"
import { LoginTooltip } from "apps/ConfirmSubscription/components"
import React from "react"
import { Translate } from "react-localize-redux"

const text = <Translate id={"subscription_confirmation.settings"} />
const Icon = <SettingsIcon />

export const SettingsButton: React.FC = () => {
    const handleOnClick = () => {
        if (!process.env.AUTH_REDIRECT_URI) {
            return
        }

        window.location.href = `${process.env.AUTH_REDIRECT_URI}/subscriptions`
    }

    return (
        <LoginTooltip>
            <Button onClick={handleOnClick} size="normal" color="primary" prefix={Icon} light>
                {text}
            </Button>
        </LoginTooltip>
    )
}
