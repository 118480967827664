import { put } from "@redux-saga/core/effects"
import { Problem, State } from "ketting"
import Resource from "ketting/dist/resource"
import { VOYAGER_API_ROOT } from "modules/common"
import { catchError, forkWatcher } from "modules/common/actions"
import { HALClient } from "modules/common/HALClient"
import { CompanyDto, CountriesDto, LookedUpCompany } from "modules/voyager/types"
import { ActionType, getType } from "typesafe-actions"
import * as actions from "./actions"

// @TODO: Fix error handling in sagas

export function* handleGetSupportedCountries() {
    try {
        const resource: Resource<CountriesDto> = yield HALClient.go(`${VOYAGER_API_ROOT}/countries`)
        const countriesDto: State<CountriesDto> = yield resource.get()

        yield put(actions.getSupportedCountriesOk(countriesDto.data.countries))
    } catch (e) {
        yield put(catchError(e))
    }
}

export function* handleLookupCompany(action: ActionType<typeof actions.lookupCompany>) {
    const regNo = action.payload
    const strippedRegNo = regNo?.replace(/\s+/g, "")
    const country = action.meta
    try {
        const resource: Resource<CompanyDto> = yield HALClient.go(
            `${VOYAGER_API_ROOT}/companies/${country}/${strippedRegNo}`
        )
        const companyDto: State<CompanyDto> = yield resource.get()
        const lookedUpCompany: LookedUpCompany = { ...companyDto.data } as LookedUpCompany

        if (country.toUpperCase() === "NO") {
            lookedUpCompany.company_form = lookedUpCompany.company_form
                ? (lookedUpCompany.company_form as any).beskrivelse
                : null
            lookedUpCompany.address = Array.isArray(lookedUpCompany.address)
                ? lookedUpCompany.address.join(" ")
                : lookedUpCompany.address
        }

        yield put(actions.lookupCompanyOk(lookedUpCompany, { country, registrationNumber: regNo }))
    } catch (e) {
        const problem = e as Problem
        if (problem.status === 404) {
            yield put(actions.lookupCompanyNotFound({ country, registrationNumber: regNo }))
        } else {
            yield put(
                actions.lookupCompanyError(problem, {
                    country,
                    registrationNumber: regNo,
                })
            )
        }
    }
}

export default function* voyagerSaga() {
    yield put(forkWatcher(getType(actions.lookupCompany), handleLookupCompany))
    yield put(forkWatcher(getType(actions.getSupportedCountries), handleGetSupportedCountries))
}
