export const MERCHANT_API_ROOT = process.env.MERCHANT_API_ROOT
export const VOYAGER_API_ROOT = process.env.VOYAGER_API_ROOT
export const RATATOSKR_API_ROOT = process.env.RATATOSKR_API_ROOT
export const MIDGARD_FEEDBACK_API_ROOT = process.env.MIDGARD_FEEDBACK_API_ROOT
export const TRACK_JS_TOKEN = process.env.TRACK_JS_TOKEN
export const CUTTER_API_ROOT = process.env.CUTTER_API_ROOT
export const DASHBOARD_URL = process.env.DASHBOARD_URL
export const STATUSPAGE_UNRESOLVED_URL = process.env.STATUSPAGE_UNRESOLVED_URL
export const HELPDESK_URL = process.env.HELPDESK_URL
export const WEBSITE_URL = process.env.WEBSITE_URL
export const DOWNLOADS_PATH = process.env.DOWNLOADS_PATH
export const PUBLIC_PATH = process.env.PUBLIC_PATH
    ? process.env.PUBLIC_PATH.slice(-1) !== "/"
        ? `${process.env.PUBLIC_PATH}/`
        : process.env.PUBLIC_PATH
    : "/"
export const GA_TRACKING_ID = process.env.GA_TRACKING_ID
