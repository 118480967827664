import { StateCache } from "ketting/dist/cache"
import { HalState } from "./halState"

export class HALCache implements StateCache {
    private cache: Map<string, HalState>

    public constructor() {
        this.cache = new Map()
    }

    public store(state: HalState, fromEmbedded?: string) {
        const embeddedContext = fromEmbedded ? fromEmbedded : state.uri

        const previousStateObject = this.get(state.uri)
        if (!!previousStateObject) {
            if (previousStateObject.fromEmbedded !== embeddedContext) {
                return
            }
        }

        this.cache.set(state.uri, state.clone())

        for (const embeddedState of state.getEmbedded() as HalState[]) {
            // Ignore self embeddeding
            if (embeddedState.uri === state.uri) {
                continue
            }

            const embeddedItemInCache = this.get(embeddedState.uri)
            if (!embeddedItemInCache || embeddedItemInCache.fromEmbedded === embeddedContext) {
                const clonedState = embeddedState.clone()
                clonedState.fromEmbedded = embeddedContext

                this.store(clonedState)
            }
        }
    }

    public get(uri: string): HalState | null {
        const state = this.cache.get(uri)
        if (!state) {
            return null
        }
        return state.clone()
    }

    public has(uri: string): boolean {
        return this.cache.has(uri)
    }

    public delete(uri: string): void {
        const stateInCache = this.cache.get(uri)
        if (stateInCache) {
            for (const embeddedItem of stateInCache.getEmbedded()) {
                const cachedEmbeddedItem = this.get(embeddedItem.uri)
                if (cachedEmbeddedItem?.links.defaultContext === uri) {
                    this.cache.delete(embeddedItem.uri)
                }
            }
        }

        this.cache.delete(uri)
    }

    public clear() {
        this.cache.clear()
    }
}
