import { Problem } from "ketting"
import applicationsSaga from "modules/applications/sagas"
import { RxMiddleware } from "modules/common/RxMiddleware"
import { Error, Uri } from "modules/common/types"
import { createCustomAction } from "typesafe-actions"
import { SagaRegistry } from "utils/SagaRegistry"
import * as actions from "./actions"
import {
    CREATE_APPLICATION,
    CREATE_APPLICATION_ERROR,
    CREATE_APPLICATION_OK,
    FETCH_APPLICATION,
    FETCH_APPLICATIONS,
    FETCH_APPLICATIONS_ERROR,
    FETCH_APPLICATIONS_OK,
    FETCH_APPLICATION_ERROR,
    FETCH_APPLICATION_OK,
    IApplicationData,
    IApplicationRelations,
    IApplicationsData,
    IApplicationsRelations,
    REFRESH_APPLICATION,
    REMOVE_STAMP_APPLICATION,
    REMOVE_STAMP_APPLICATION_ERROR,
    REMOVE_STAMP_APPLICATION_OK,
    STAMP_APPLICATION,
    STAMP_APPLICATION_ERROR,
    STAMP_APPLICATION_OK,
    UPDATE_APPLICATION,
    UPDATE_APPLICATION_ERROR,
    UPDATE_APPLICATION_OK,
} from "./types"

export const fetchApplications = createCustomAction(
    FETCH_APPLICATIONS,
    (uri: Uri, page?: number, per_page?: number, query?: string) => ({
        payload: { uri, page, per_page, query },
    })
)
export const fetchApplicationsOk = createCustomAction(
    FETCH_APPLICATIONS_OK,
    (
        data: IApplicationsData,
        relations: IApplicationsRelations,
        uri: Uri,
        page?: number,
        per_page?: number,
        query?: string
    ) => ({
        meta: { uri, page, per_page, query },
        payload: { data, relations },
    })
)
export const fetchApplicationsError = createCustomAction(
    FETCH_APPLICATIONS_ERROR,
    (error: Error, page?: number, per_page?: number, query?: string) => ({
        payload: error,
        meta: { page, per_page, query },
    })
)
export const fetchApplication = createCustomAction(
    FETCH_APPLICATION,
    (uri: Uri, noCache?: boolean) => {
        return {
            payload: { uri, noCache },
        }
    }
)
export const refreshApplication = createCustomAction(REFRESH_APPLICATION, (uri: Uri) => ({
    payload: { uri },
}))
export const fetchApplicationOk = createCustomAction(
    FETCH_APPLICATION_OK,
    (data: IApplicationData, relations: IApplicationRelations, uri: Uri, noCache?: boolean) => ({
        meta: { uri, noCache },
        payload: { data, relations },
    })
)
export const fetchApplicationError = createCustomAction(
    FETCH_APPLICATION_ERROR,
    (error: Problem, uri: Uri, noCache?: boolean) => ({ payload: error, meta: { uri, noCache } })
)
export const createApplication = createCustomAction(CREATE_APPLICATION, (application: object) => ({
    payload: application,
}))
export const createApplicationOk = createCustomAction(
    CREATE_APPLICATION_OK,
    (data: IApplicationData, relations: IApplicationRelations, application: object) => ({
        meta: application,
        payload: { data, relations },
    })
)
export const createApplicationError = createCustomAction(
    CREATE_APPLICATION_ERROR,
    (error: Error, application: object) => ({ payload: error, meta: application })
)
export const updateApplication = createCustomAction(
    UPDATE_APPLICATION,
    (data: Partial<IApplicationData>, uri: Uri) => ({
        payload: { data, uri },
    })
)
export const updateApplicationOk = createCustomAction(
    UPDATE_APPLICATION_OK,
    (data: IApplicationData, relations: IApplicationRelations, uri: Uri) => ({
        meta: { uri },
        payload: { data, relations },
    })
)
export const updateApplicationError = createCustomAction(
    UPDATE_APPLICATION_ERROR,
    (e: Problem, uri: Uri) => ({
        meta: { uri },
        payload: e,
    })
)

export const stampApplication = createCustomAction(
    STAMP_APPLICATION,
    (uri: Uri, applicationUri: Uri) => ({
        payload: { uri, applicationUri },
    })
)

export const stampApplicationOk = createCustomAction(
    STAMP_APPLICATION_OK,
    (uri: Uri, applicationUri: Uri) => ({
        meta: { uri, applicationUri },
    })
)

export const stampApplicationError = createCustomAction(
    STAMP_APPLICATION_ERROR,
    (e: Problem, uri: Uri, applicationUri: Uri) => ({
        payload: e,
        meta: { uri, applicationUri },
    })
)

export const removeStampApplication = createCustomAction(
    REMOVE_STAMP_APPLICATION,
    (uri: Uri, applicationUri: Uri) => ({
        payload: { uri, applicationUri },
    })
)
export const removeStampApplicationOk = createCustomAction(
    REMOVE_STAMP_APPLICATION_OK,
    (uri: Uri, applicationUri: Uri) => ({ meta: { uri, applicationUri } })
)

export const removeStampApplicationError = createCustomAction(
    REMOVE_STAMP_APPLICATION_ERROR,
    (e: Problem, uri: Uri, applicationUri: Uri) => ({
        meta: { uri, applicationUri },
        payload: e,
    })
)

RxMiddleware.addActions(actions)
SagaRegistry.register(applicationsSaga)
