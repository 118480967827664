import { rootSaga } from "modules"
import { TRACK_JS_TOKEN } from "modules/common"
import { RxMiddleware } from "modules/common/RxMiddleware"
import { TrackJSLogger } from "modules/trackjs"
import { applyMiddleware, combineReducers, compose, createStore } from "redux"
import createSagaMiddleware, { SagaMiddlewareOptions } from "redux-saga"
import { TrackJS } from "trackjs"
import { productionMode } from "utils/envHelpers"
import { ReducerRegistry } from "utils/ReducerRegistry"
import { SagaRegistry } from "utils/SagaRegistry"
import { authenticationMiddleware as authMiddleware } from "./modules/authentication/middleware"

const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
let sagaMiddlewareOptions: SagaMiddlewareOptions = {
    onError: (e) => {
        if (TRACK_JS_TOKEN) {
            TrackJS.track(e)
        } else {
            // tslint:disable-next-line: no-console
            console.error(e)
        }
    },
}
if (!productionMode) {
    // tslint:disable-next-line: no-var-requires
    const sagaMonitor = require("@redux-saga/simple-saga-monitor").default
    sagaMiddlewareOptions = {
        ...sagaMiddlewareOptions,
        sagaMonitor,
    }
}
const sagaMiddleware = createSagaMiddleware(sagaMiddlewareOptions)

const configureReducers = (reducers: object) =>
    combineReducers({
        ...reducers,
    })

export default function configureStore() {
    const store = createStore(
        configureReducers(ReducerRegistry.getReducers()),
        {},
        composeEnhancers(
            applyMiddleware(TrackJSLogger, authMiddleware, sagaMiddleware, RxMiddleware.middleware)
        )
    )

    ReducerRegistry.setChangeListener((reducers) => {
        store.replaceReducer(combineReducers(reducers))
    })

    SagaRegistry.setChangeListener((saga) => {
        sagaMiddleware.run(saga)
    })

    sagaMiddleware.run(rootSaga)

    if ((module as any).hot) {
        ;(module as any).hot.accept(() => {
            const nextRootReducer = combineReducers(ReducerRegistry.getReducers())
            store.replaceReducer(nextRootReducer)
        })
    }

    return { store }
}
