import { Button, Grid, Heading, Paragraph, Spacer, ThemeContext } from "@clearhaus/design-system"
import React, { useContext } from "react"
import { Translate } from "react-localize-redux"

const texts = {
    title: <Translate id="common.dashboard_welcome.intro.title" />,
    description: <Translate id="common.dashboard_welcome.intro.description" />,
    action: <Translate id="common.dashboard_welcome.intro.action" />,
}

interface IIntroProps {
    onClick: () => void
}

export const Intro: React.FC<IIntroProps> = (props) => {
    const { onClick } = props
    const { defaults } = useContext(ThemeContext)

    return (
        <>
            <Grid row justify="center">
                <Grid item auto>
                    <Heading level={2}>{texts.title}</Heading>
                </Grid>
            </Grid>
            <Spacer size={24} />
            <Grid row justify="center">
                <Grid item xs={12} sm={8} md={6} lg={5}>
                    <Paragraph align="center" color={defaults.colors.neutrals[700]}>
                        {texts.description}
                    </Paragraph>
                </Grid>
            </Grid>
            <Spacer size={24} />
            <Grid row justify="center">
                <Grid item auto>
                    <Button size="normal" onClick={onClick}>
                        {texts.action}
                    </Button>
                </Grid>
            </Grid>
        </>
    )
}
