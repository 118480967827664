import { FETCH_ROOT, FETCH_ROOT_ERROR, FETCH_ROOT_OK, IRootData } from "modules/root/types"
import { createCustomAction } from "typesafe-actions"

export const fetchRoot = createCustomAction(FETCH_ROOT)
export const fetchRootOk = createCustomAction(FETCH_ROOT_OK, (data: IRootData) => ({
    payload: { data },
}))
export const fetchRootError = createCustomAction(FETCH_ROOT_ERROR, (error: Error) => ({
    payload: error,
}))
