import { Problem } from "ketting"
import { MerchantApiRequest, MerchantApiResponse } from "modules/common"
import { catchError, forkWatcher } from "modules/common/actions"
import { ICompanyData, ICompanyRelations } from "modules/companies/types"
import { call, put } from "redux-saga/effects"
import { ActionType, getType } from "typesafe-actions"
import * as actions from "./actions"

export function* handleFetchCompany(action: ActionType<typeof actions.fetchCompany>) {
    const { uri } = action.payload

    try {
        const merchantApiRequest = new MerchantApiRequest(uri)
        const {
            repr,
            relations,
        }: MerchantApiResponse<ICompanyData, ICompanyRelations> = yield call([
            merchantApiRequest,
            merchantApiRequest.get,
        ])

        yield put(actions.fetchCompanyOk(repr, relations, uri))
    } catch (e) {
        if (e instanceof Problem) {
            yield put(actions.fetchCompanyError(e, uri))
        }
        yield put(catchError(e))
    }
}

export function* handleUpdateCompany(action: ActionType<typeof actions.updateCompany>) {
    const { data, uri } = action.payload

    try {
        const merchantApiRequest = new MerchantApiRequest(uri)
        const {
            repr,
            relations,
        }: MerchantApiResponse<ICompanyData, ICompanyRelations> = yield call(
            [merchantApiRequest, merchantApiRequest.patch],
            data
        )

        yield put(actions.updateCompanyOk(repr, relations, data, uri))
    } catch (e) {
        if (e instanceof Problem) {
            yield put(actions.updateCompanyError(e, uri))
        }
        yield put(catchError(e))
    }
}

export default function* companiesRootSaga() {
    yield put(forkWatcher(getType(actions.updateCompany), handleUpdateCompany))
    yield put(forkWatcher(getType(actions.fetchCompany), handleFetchCompany))
}
