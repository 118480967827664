import { BoxEdges, Grid } from "@clearhaus/design-system"
import {
    IEmptyStateCommonProps,
    useEmptyStateContext,
} from "components/common/EmptyState/EmptyState"
import React from "react"

interface IIconProps {
    icon: JSX.Element
}

export const Icon: React.FC<IIconProps & IEmptyStateCommonProps> = (props) => {
    useEmptyStateContext()
    const icon = React.cloneElement(props.icon, {
        size: 50,
    })

    return (
        <BoxEdges mb={!props.noMargin ? 12 : 0}>
            <Grid row alignItems="center" justify="center">
                <Grid item auto>
                    {icon}
                </Grid>
            </Grid>
        </BoxEdges>
    )
}
