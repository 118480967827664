import { defaults, Fonts, GlobalStyles, ThemeProvider } from "@clearhaus/design-system"
import { Page } from "apps/ConfirmSubscription/Page"
import {
    IConfirmSubscriptionData,
    useConfirmSubscription,
} from "apps/ConfirmSubscription/hooks/useConfirmSubscription"
import React, { createContext } from "react"
import { LocalizeProvider } from "react-localize-redux"

interface ConfirmSubscriptionContext {
    data: IConfirmSubscriptionData | null
    isLoading: boolean
    isError: boolean
}

const defaultContextValues: ConfirmSubscriptionContext = {
    data: null,
    isLoading: false,
    isError: false,
}

export const ConfirmSubscriptionContext =
    createContext<ConfirmSubscriptionContext>(defaultContextValues)

const ConfirmSubscription = () => {
    const values = useConfirmSubscription()

    return (
        <LocalizeProvider>
            <ThemeProvider defaults={defaults}>
                <GlobalStyles />
                <Fonts />
                <ConfirmSubscriptionContext.Provider value={values}>
                    <Page />
                </ConfirmSubscriptionContext.Provider>
            </ThemeProvider>
        </LocalizeProvider>
    )
}

export { ConfirmSubscription }
