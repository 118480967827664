import { BoxEdges, Grid, Paragraph } from "@clearhaus/design-system"
import {
    IEmptyStateCommonProps,
    useEmptyStateContext,
} from "components/common/EmptyState/EmptyState"
import React from "react"

interface ITitle {
    color?: string
}

export const Title: React.FC<IEmptyStateCommonProps & ITitle> = (props) => {
    useEmptyStateContext()

    return (
        <BoxEdges mb={!props.noMargin ? 16 : 0}>
            <Grid row justify="center" alignItems="center">
                <Grid item auto>
                    <Paragraph size="xxl" align="center" weight="medium" color={props.color}>
                        <>{props.children}</>
                    </Paragraph>
                </Grid>
            </Grid>
        </BoxEdges>
    )
}
