import {
    AlertTriangleColoredIcon,
    BoxEdges,
    Button,
    defaults as defaultsOrg,
    Fonts,
    GlobalStyles,
    Grid,
    NoticeBox,
    Paragraph,
    Spacer,
} from "@clearhaus/design-system"
import { LocalizationLoader } from "components/common"
import { EmptyState } from "components/common/EmptyState/EmptyState"
import { TRACK_JS_TOKEN } from "modules/common"
import React, { ErrorInfo, PropsWithChildren } from "react"
import { Translate } from "react-localize-redux"
import { TrackJS } from "trackjs"
import { devMode } from "utils/envHelpers"

const icon = <AlertTriangleColoredIcon backgroundColor={defaultsOrg.colors.accents.error[300]} />
const containerStyling = { minHeight: "100vh" }

const texts = {
    title: <Translate id="common.error.title" />,
    description: <Translate id="common.error.description" />,
    button: <Translate id="common.error.button" />,
}

interface ErrorBoundaryState {
    hasError: boolean
    error?: Error
    errorInfo?: ErrorInfo
}

interface ErrorBoundaryProps extends PropsWithChildren<{}> {}

const localizationNamespaces = ["common"]
class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
    public static getDerivedStateFromError() {
        return {
            hasError: true,
        }
    }

    constructor(props: ErrorBoundaryProps) {
        super(props)

        this.state = {
            hasError: false,
        }
    }

    public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        this.setState({
            error,
            errorInfo,
        })

        if (errorInfo && errorInfo.componentStack) {
            // tslint:disable-next-line: no-console
            console.log(errorInfo.componentStack)
        }

        if (TRACK_JS_TOKEN) {
            TrackJS.track(error)
        }
    }

    public handleOnClick() {
        window.location.href = "/"
    }

    public render() {
        if (this.state.hasError) {
            return (
                <>
                    <GlobalStyles />
                    <Fonts />
                    <LocalizationLoader namespace={localizationNamespaces}>
                        <Grid row alignItems="center" style={containerStyling}>
                            <Grid item>
                                <BoxEdges my={64}>
                                    <EmptyState>
                                        <EmptyState.Icon icon={icon} />
                                        <EmptyState.Title>{texts.title}</EmptyState.Title>
                                        <EmptyState.Description>
                                            {texts.description}
                                        </EmptyState.Description>
                                        <EmptyState.Actions>
                                            <Grid row alignItems="center" justify="center">
                                                <Grid item auto>
                                                    <Button onClick={this.handleOnClick}>
                                                        {texts.button}
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </EmptyState.Actions>
                                    </EmptyState>
                                    {devMode && (
                                        <Grid container containerSize="md">
                                            <Spacer size="medium" />
                                            <NoticeBox kind="accents-error">
                                                <NoticeBox.Content>
                                                    <Grid row>
                                                        <Grid item>
                                                            <Paragraph weight="medium">
                                                                <Translate id="common.error.heading_error" />
                                                            </Paragraph>
                                                            <pre>
                                                                {this.state.error?.stack
                                                                    ? this.state.error?.stack
                                                                    : this.state.error?.message}
                                                            </pre>
                                                            <Spacer size="small" />
                                                            {this.state.errorInfo && (
                                                                <>
                                                                    <Paragraph weight="medium">
                                                                        <Translate id="common.error.heading_component_stack" />
                                                                    </Paragraph>
                                                                    <pre>
                                                                        {
                                                                            this.state.errorInfo
                                                                                .componentStack
                                                                        }
                                                                    </pre>
                                                                </>
                                                            )}
                                                        </Grid>
                                                    </Grid>
                                                </NoticeBox.Content>
                                            </NoticeBox>
                                        </Grid>
                                    )}
                                </BoxEdges>
                            </Grid>
                        </Grid>
                    </LocalizationLoader>
                </>
            )
        }

        return this.props.children
    }
}

export { ErrorBoundary }
export default ErrorBoundary
