import { BoxEdges, Grid, PortalContainer } from "@clearhaus/design-system"
import { TermsAndPolicies } from "components/common/TermsAndPolicies/TermsAndPolicies"
import { TermsAndPoliciesProvider } from "components/common/TermsAndPolicies/TermsAndPoliciesProvider"
import {
    MasterNavigation,
    MasterNavigationProvider,
} from "components/MasterNavigation/MasterNavigation"
import { ServiceMessages } from "components/ServiceMessages"
import { ServiceMessagesProvider } from "components/ServiceMessages/ServiceMessagesProvider"
import { shallowEqual } from "fast-equals"
import React, { useMemo, useState } from "react"

const marginBottom = 32
const contentStyle = { marginTop: 48 }

export const PageLayout = React.memo((props: React.PropsWithChildren<{}>) => {
    const [topMargin, setTopMargin] = useState(0)
    const masterNavigationHeightPostMount = useMemo(
        () => (height: number) => {
            if (height > 0 && height !== topMargin) {
                setTopMargin(height)
            }
        },
        [topMargin]
    )

    const wrapperStyle = useMemo(
        () => ({
            display: "flex",
            flexDirection: "column" as "column",
            marginTop: topMargin,
            minHeight: `calc(100vh - ${topMargin}px - ${marginBottom}px)`,
        }),
        [topMargin]
    )

    return (
        <MasterNavigationProvider>
            <TermsAndPoliciesProvider>
                <ServiceMessagesProvider>
                    <BoxEdges mb={marginBottom}>
                        <PortalContainer>
                            <Grid container style={wrapperStyle}>
                                <Grid row noGutters>
                                    <Grid item noGutters>
                                        <MasterNavigation
                                            heightPostMount={masterNavigationHeightPostMount}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid row>
                                    <Grid item>
                                        <ServiceMessages />
                                    </Grid>
                                </Grid>
                                <Grid row grow={1} style={contentStyle}>
                                    <Grid item>{props.children}</Grid>
                                </Grid>
                                <Grid row>
                                    <Grid item>
                                        <Grid container fluid>
                                            <Grid row justify="center">
                                                <Grid item auto>
                                                    <BoxEdges mt={64}>
                                                        <TermsAndPolicies />
                                                    </BoxEdges>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </PortalContainer>
                    </BoxEdges>
                </ServiceMessagesProvider>
            </TermsAndPoliciesProvider>
        </MasterNavigationProvider>
    )
}, shallowEqual)
PageLayout.displayName = "PageLayout"
