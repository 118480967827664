import { RxStore } from "modules/common/RxStore"
import { Middleware } from "redux"
import { getType } from "typesafe-actions"
import { devMode } from "utils/envHelpers"

type ActionGroupType = "update" | "fetch" | "create" | "delete" | "batch"

interface IActionGroup {
    type: ActionGroupType
    actions: string[]
}

const ActionsToIgnore = ["fetchPdfContract", "fetchPdfContractOk", "fetchPdfContractError"]

class RxMiddleware {
    static instance: RxMiddleware = new RxMiddleware()

    private _actionGroups: IActionGroup[] = []

    public addActions(actionMethods: { [key: string]: any }) {
        const actions = Object.keys(actionMethods)
        for (const action of actions) {
            if (ActionsToIgnore.includes(action)) {
                continue
            }

            const group = actions.filter((a) => {
                const position = a.indexOf(action)
                if (position === 0) {
                    const nextChar = a.substring(action.length, action.length + 1)
                    if (nextChar === nextChar.toUpperCase()) {
                        return true
                    }
                }

                return false
            })
            if (group.length > 1) {
                const groupAsTypes: string[] = []
                for (const groupMethod of group) {
                    groupAsTypes.push(getType(actionMethods[groupMethod]))
                }
                const first = group[0]
                let actionType: ActionGroupType | null = null
                if (first.startsWith("fetch")) {
                    actionType = "fetch"
                }
                if (first.startsWith("update")) {
                    actionType = "update"
                }
                if (first.startsWith("create")) {
                    actionType = "create"
                }
                if (first.startsWith("delete")) {
                    actionType = "delete"
                }
                if (first.startsWith("batch")) {
                    actionType = "batch"
                }

                if (!actionType) {
                    if (devMode) {
                        // tslint:disable-next-line:no-console
                        console.warn(`Cannot understand '${first}'`)
                    }

                    continue
                }

                const actionGroup: IActionGroup = {
                    type: actionType,
                    actions: groupAsTypes,
                }
                this._actionGroups.push(actionGroup)
            }
        }
    }

    public get middleware(): Middleware {
        return () => {
            return (next) => {
                return (action) => {
                    const actionType = action.type
                    const findGroup = this._actionGroups
                        .filter((g) => g.actions.includes(actionType))
                        .pop()
                    if (findGroup) {
                        if (action.meta) {
                            switch (findGroup.type) {
                                case "batch":
                                    const payloadKeys = Object.keys(action.payload)
                                    if (
                                        payloadKeys.length === 1 &&
                                        Array.isArray(action.payload[payloadKeys[0]])
                                    ) {
                                        for (const payloadData of action.payload[payloadKeys[0]]) {
                                            if (
                                                payloadData.relations &&
                                                payloadData.relations.self
                                            ) {
                                                const { relations, ...data } = payloadData
                                                RxStore.set(payloadData.relations.self, undefined, {
                                                    relations,
                                                    data,
                                                })
                                            }
                                        }
                                    }
                                    break
                                default:
                                    const { uri, page, per_page, query } = action.meta
                                    RxStore.set(uri, { page, per_page, query }, action.payload)
                            }
                        } else {
                            switch (findGroup.type) {
                                case "batch":
                                    const { uris } = action.payload
                                    for (const uri of uris) {
                                        RxStore.set(uri)
                                    }
                                    break
                                default:
                                    const { uri, page, per_page, query } = action.payload
                                    if (uri) {
                                        RxStore.set(uri, { page, per_page, query })
                                    }
                            }
                        }
                    }

                    return next(action)
                }
            }
        }
    }
}

const instance = RxMiddleware.instance
export { instance as RxMiddleware }
