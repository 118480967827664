import { ISO4217 } from "modules/common/types"

interface ILocaleListItem {
    code: string
    currency: ISO4217
    name: string
    shortcode: string
}

const localeList: ILocaleListItem[] = [
    {
        code: "bg-BG",
        currency: "EUR",
        name: "Bulgaria",
        shortcode: "bg",
    },
    {
        code: "cs-CZ",
        currency: "EUR",
        name: "Czech Republic",
        shortcode: "cz",
    },
    {
        code: "da-DK",
        currency: "DKK",
        name: "Denmark",
        shortcode: "dk",
    },
    {
        code: "da-FO",
        currency: "DKK",
        name: "Faroe Islands",
        shortcode: "fo",
    },
    {
        code: "da-GL",
        currency: "DKK",
        name: "Greenland",
        shortcode: "gl",
    },
    {
        code: "de-AT",
        currency: "EUR",
        name: "Austria",
        shortcode: "at",
    },
    {
        code: "de-CH",
        currency: "EUR",
        name: "Switzerland",
        shortcode: "ch",
    },
    {
        code: "de-DE",
        currency: "EUR",
        name: "Germany",
        shortcode: "de",
    },
    {
        code: "de-LI",
        currency: "EUR",
        name: "Liechtenstein",
        shortcode: "li",
    },
    {
        code: "el-GR",
        currency: "EUR",
        name: "Greece",
        shortcode: "gr",
    },
    {
        code: "en-IE",
        currency: "EUR",
        name: "Ireland",
        shortcode: "ie",
    },
    {
        code: "en-UK",
        currency: "GBP",
        name: "United Kingdom",
        shortcode: "gb",
    },
    {
        code: "es-ES",
        currency: "EUR",
        name: "Spain",
        shortcode: "es",
    },
    {
        code: "et-EE",
        currency: "EUR",
        name: "Estonian",
        shortcode: "ee",
    },
    {
        code: "fi-FI",
        currency: "EUR",
        name: "Finland",
        shortcode: "fl",
    },
    {
        code: "fr-BE",
        currency: "EUR",
        name: "Belgium",
        shortcode: "be",
    },
    {
        code: "fr-FR",
        currency: "EUR",
        name: "France",
        shortcode: "fr",
    },
    {
        code: "fr-LU",
        currency: "EUR",
        name: "Luxembourg",
        shortcode: "lu",
    },
    {
        code: "hr-HR",
        currency: "EUR",
        name: "Croatia",
        shortcode: "hr",
    },
    {
        code: "hu-HU",
        currency: "EUR",
        name: "Hungary",
        shortcode: "hu",
    },
    {
        code: "is-IS",
        currency: "EUR",
        name: "Iceland",
        shortcode: "is",
    },
    {
        code: "it-IT",
        currency: "EUR",
        name: "Italy",
        shortcode: "it",
    },
    {
        code: "lt-LT",
        currency: "EUR",
        name: "Lithuania",
        shortcode: "lt",
    },
    {
        code: "lv-LV",
        currency: "EUR",
        name: "Latvia",
        shortcode: "lv",
    },
    {
        code: "mt-MT",
        currency: "EUR",
        name: "Malta",
        shortcode: "mt",
    },
    {
        code: "nl-NL",
        currency: "EUR",
        name: "Netherlands",
        shortcode: "nl",
    },
    {
        code: "no-NO",
        currency: "NOK",
        name: "Norway",
        shortcode: "no",
    },
    {
        code: "pl-PL",
        currency: "EUR",
        name: "Poland",
        shortcode: "pl",
    },
    {
        code: "pt-PT",
        currency: "EUR",
        name: "Portugal",
        shortcode: "pt",
    },
    {
        code: "ro-RO",
        currency: "EUR",
        name: "Romania",
        shortcode: "ro",
    },
    {
        code: "se-SE",
        currency: "SEK",
        name: "Sweden",
        shortcode: "se",
    },
    {
        code: "si-SI",
        currency: "EUR",
        name: "Slovenia",
        shortcode: "si",
    },
    {
        code: "sk-SK",
        currency: "EUR",
        name: "Slovakia",
        shortcode: "sk",
    },
]

const getLanguage = () => {
    return (
        navigator.language ||
        (navigator as any).browserLanguage ||
        (navigator.languages || ["en"])[0]
    )
}

export const getCurrencyBasedOnBrowserLanguage = () => {
    const browserLanguage = getLanguage()
    const currency = localeList
        .filter(
            (l) =>
                l.shortcode.toLowerCase() === browserLanguage.toLowerCase() ||
                l.code.toLowerCase().substring(0, 2) === browserLanguage.toLowerCase()
        )
        .map((c) => c.currency)
        .pop()

    return currency || "EUR"
}
