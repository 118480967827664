import React from "react"
import { Control } from "./Control"

interface IControlItemProps {
    children: React.ReactNode
    index: number
    activeIndex: number
    onClick: (index: number) => void
    progressPercentage: number
}

const ControlItem = (props: IControlItemProps) => {
    const { children, index, activeIndex, onClick, progressPercentage } = props

    return (
        <Control
            index={index}
            activeIndex={activeIndex}
            onClick={onClick}
            progressPercentage={progressPercentage}
        >
            {children}
        </Control>
    )
}

interface ITitleProps {
    children: React.ReactNode
}
const Title: React.FC<ITitleProps> = (props) => {
    const { children } = props

    return <Control.Title>{children}</Control.Title>
}

interface IDescriptionProps {
    children: React.ReactNode
}
const Description: React.FC<IDescriptionProps> = (props) => {
    const { children } = props
    return <Control.Description>{children}</Control.Description>
}

ControlItem.Title = Title
ControlItem.Description = Description

export { ControlItem }
