import Auth0 from "modules/authentication/Auth0"
import { IdTokenPayload } from "modules/authentication/types"
import React, { useContext, useEffect, useMemo, useState } from "react"
import { useHistory, useLocation } from "react-router"
import useCallbackRef from "utils/useCallbackRef"

interface IAuthContext {
    isAdmin: boolean
    sub?: string
    isLoading: boolean
    isError: boolean
    beforeLogout: (action: () => void) => void
    logout: () => void
}

export const AuthContext = React.createContext<IAuthContext>({
    isAdmin: false,
    isLoading: true,
    isError: false,
    sub: undefined,
    beforeLogout: () => {
        throw Error(`BeforeLogout action not initialized`)
    },
    logout: () => {
        throw Error(`AuthProvider not initialized`)
    },
})
export const useAuth = () => useContext(AuthContext)

let beforeLogoutActions: Array<() => void> = []
interface AuthProviderProps {
    onLoggedIn?: (idTokenDecodedPayload: IdTokenPayload) => Promise<boolean>
    children: React.ReactNode
}

export const AuthProvider: React.FC<AuthProviderProps> = (props) => {
    const { onLoggedIn } = props
    const [isLoading, setIsLoading] = useState(true)
    const [isError, setIsError] = useState(false)
    const [isAdmin, setIsAdmin] = useState(false)
    const [sub, setSub] = useState<string>()

    const history = useHistory()
    const location = useLocation()

    const addBeforeLogoutAction = useCallbackRef((action: () => void) => {
        beforeLogoutActions = [...beforeLogoutActions, action]
    })

    useEffect(() => {
        const initAuth0 = async () => {
            if (!Auth0.instance) {
                Auth0.instance = new Auth0()
            }

            if (location.hash.startsWith("#error=")) {
                setIsLoading(false)
                setIsError(true)
                return
            }

            if (location.hash.includes("access_token=")) {
                const appState = await Auth0.instance.handleAuthentication()
                if (appState.returnUrl) {
                    location.hash = ""
                    history.replace(appState.returnUrl)
                } else {
                    location.hash = ""
                }
            }

            let voucherCode = null

            // Support old Uri
            if (location.hash.startsWith("#/auth/register?code=")) {
                voucherCode = location.hash.replace("#/auth/register?code=", "")
            }

            // Voucher / partner
            if (location.pathname.startsWith("/partner/") || voucherCode) {
                voucherCode = voucherCode || location.pathname.replace("/partner/", "")

                // Store voucherCode in localStorage
                localStorage.setItem("voucherCode", voucherCode)

                // Redirect to frontpage
                history.replace("/?signup=true&fromVoucher=true")
            }

            if (!Auth0.instance.isAuthenticated()) {
                Auth0.instance.login()
            } else {
                if (onLoggedIn) {
                    onLoggedIn(Auth0.instance.getIdTokenPayload()).then(() => {
                        const roles = Auth0.instance.getRoles()
                        const sub = Auth0.instance.getSub()

                        setIsAdmin(roles.some((r) => r === "admin"))
                        setSub(sub)
                        setIsLoading(false)
                        setIsError(false)
                    })
                } else {
                    const roles = Auth0.instance.getRoles()
                    const sub = Auth0.instance.getSub()

                    setIsAdmin(roles.some((r) => r === "admin"))
                    setSub(sub)
                    setIsLoading(false)
                    setIsError(false)
                }
            }
        }
        initAuth0()
    }, [location])

    const authContextValue = useMemo(() => {
        if (!Auth0.instance) {
            return null
        }

        const logout = () => {
            beforeLogoutActions.map((action) => {
                action()
            })

            Auth0.instance.logout()
        }

        return {
            beforeLogout: addBeforeLogoutAction,
            isAdmin,
            sub,
            isLoading,
            isError,
            logout,
        }
    }, [isLoading, isError, isAdmin, sub, Auth0.instance])

    if (!Auth0.instance || !authContextValue) {
        return null
    }

    return (
        <AuthContext.Provider value={authContextValue}>
            {!isLoading && props.children}
        </AuthContext.Provider>
    )
}

export default AuthProvider
