import { BoxEdges, Popover, Separator, Spacer, UserCircleIcon } from "@clearhaus/design-system"
import { List } from "@clearhaus/design-system/List/List"
import { useAuth } from "components/authentication/AuthProvider"
import { Notice } from "components/common/AccountDropdown/Notice"
import { Profile } from "components/common/AccountDropdown/Profile"
import { WelcomePopover } from "components/common/AccountDropdown/WelcomePopover"
import { NavigationItem } from "components/common/NavigationItem"
import { FeedbackModal } from "components/feedback/FeedbackModal"
import { EditMeModal } from "components/me/EditMeModal"
import { DASHBOARD_URL } from "modules/common"
import { useUpdateUserAppSettings, useUserAppSettings } from "modules/user-app-settings/queries"
import { DashboardWelcome } from "pages/DashboardWelcome/DashboardWelcome"
import React, { useCallback, useEffect, useState } from "react"
import { Translate } from "react-localize-redux"
import { useHistory } from "react-router"

interface AccountDropdownProps {
    color?: "primary" | "secondary" | "tertiary"
    light?: boolean
}

const AccountDropdownIcon = <UserCircleIcon size={15} />

const AccountDropdown = (props: AccountDropdownProps) => {
    const { data: userAppSettings } = useUserAppSettings()
    const { mutate: updateUserAppSettings } = useUpdateUserAppSettings()
    const { logout } = useAuth()
    const history = useHistory()

    const [editMe, setEditMe] = useState(false)
    const [showPopover, setShowPopover] = useState(false)
    const [feedbackOpen, setFeedbackOpen] = useState(false)
    const [showDashboardWelcome, setShowDashboardWelcome] = useState(false)

    useEffect(() => {
        if (!userAppSettings?.welcome) {
            return
        }

        setShowDashboardWelcome(true)
    }, [userAppSettings])

    const handleEditMeClosed = useCallback(() => {
        setEditMe(false)
    }, [])

    const handleFeedbackClosed = useCallback(() => {
        setFeedbackOpen(false)
    }, [])

    const handleDashboardWelcomeClosed = useCallback(() => {
        setShowDashboardWelcome(false)

        if (!userAppSettings?.welcome) {
            return
        }

        updateUserAppSettings({ welcome: false })
    }, [userAppSettings])

    const handleEditMeClick = useCallback(() => {
        setShowPopover(false)
        setEditMe(true)
    }, [setEditMe])

    const logoutClick = useCallback(() => {
        logout()
    }, [logout])

    const handleYourApplicationsClick = useCallback(() => {
        setShowPopover(false)
        history.push(`/applications`)
    }, [])

    const handleYourSubscriptionsClick = useCallback(() => {
        setShowPopover(false)
        history.push(`/subscriptions`)
    }, [])

    const handleYourExportsClick = useCallback(() => {
        setShowPopover(false)
        history.push(`/exports`)
    }, [])

    const handleYourAccountsClick = useCallback(() => {
        setShowPopover(false)
        history.push(`/accounts`)
    }, [])

    const handleYourAnnouncementsClick = useCallback(() => {
        setShowPopover(false)
        history.push(`/announcements`)
    }, [])

    const handleDashboardClick = useCallback(async () => {
        if (!DASHBOARD_URL) {
            throw Error(`Missing environment variable 'DASHBOARD_URL'`)
        }

        if (userAppSettings?.midgard) {
            await updateUserAppSettings({ midgard: false })
        }

        window.location.href = DASHBOARD_URL
    }, [userAppSettings])

    const handleOnHideEnd = useCallback(() => {
        setShowPopover(false)
    }, [showPopover])

    const handleIntroClick = useCallback(() => {
        updateUserAppSettings({ welcome: true })
        setShowPopover(false)
        setShowDashboardWelcome(true)
    }, [])

    const handleFeedbackClick = useCallback(() => {
        setShowPopover(false)
        setFeedbackOpen(true)
    }, [])

    const handleOnItemClick = useCallback(() => {
        setShowPopover(!showPopover)
    }, [showPopover])

    return (
        <>
            <Popover placement="bottom" show={showPopover}>
                <Popover.Reference>
                    <WelcomePopover>
                        <NavigationItem
                            icon={AccountDropdownIcon}
                            chevron
                            light={props.light}
                            noPaddingRight
                            onClick={handleOnItemClick}
                        />
                    </WelcomePopover>
                </Popover.Reference>
                <Popover.Content onHideEnd={handleOnHideEnd}>
                    <BoxEdges minWidth={200} p={12}>
                        <Profile onEditClick={handleEditMeClick} />
                        <Separator direction="horizontal" my={6} />
                        <List>
                            <List.Item onClick={handleYourApplicationsClick}>
                                <BoxEdges px={8}>
                                    <Translate id="navigation.applications" />
                                </BoxEdges>
                            </List.Item>
                            <Separator direction="horizontal" my={6} />
                            <List.Item onClick={handleYourAccountsClick}>
                                <BoxEdges px={8}>
                                    <Translate id="navigation.accounts" />
                                </BoxEdges>
                            </List.Item>
                            <Separator direction="horizontal" my={6} />
                            <List.Item onClick={handleYourSubscriptionsClick}>
                                <BoxEdges px={8}>
                                    <Translate id="navigation.subscriptions" />
                                </BoxEdges>
                            </List.Item>
                            <Separator direction="horizontal" my={6} />
                            <List.Item onClick={handleYourExportsClick}>
                                <BoxEdges px={8}>
                                    <Translate id="navigation.exports" />
                                </BoxEdges>
                            </List.Item>
                            <Separator direction="horizontal" my={6} />
                            <List.Item onClick={handleYourAnnouncementsClick}>
                                <BoxEdges px={8}>
                                    <Translate id="navigation.announcements" />
                                </BoxEdges>
                            </List.Item>
                            <Separator direction="horizontal" my={6} />
                            <List.Item onClick={logoutClick}>
                                <BoxEdges px={8}>
                                    <Translate id="navigation.account_dropdown.log_out" />
                                </BoxEdges>
                            </List.Item>
                        </List>
                        {userAppSettings?.midgard && (
                            <>
                                <Spacer size={6} />
                                <Notice>
                                    <Notice.Item onClick={handleFeedbackClick}>
                                        <Translate id="navigation.account_dropdown.feedback" />
                                    </Notice.Item>
                                    <Notice.Separator />
                                    <Notice.Item onClick={handleDashboardClick}>
                                        <Translate id="navigation.account_dropdown.dashboard" />
                                    </Notice.Item>
                                    <Notice.Separator />
                                    <Notice.Item onClick={handleIntroClick}>
                                        <Translate id="navigation.account_dropdown.intro" />
                                    </Notice.Item>
                                </Notice>
                            </>
                        )}
                    </BoxEdges>
                </Popover.Content>
            </Popover>
            <EditMeModal open={editMe} onClosed={handleEditMeClosed} />
            <FeedbackModal open={feedbackOpen} onClosed={handleFeedbackClosed} />
            <DashboardWelcome open={showDashboardWelcome} onClosed={handleDashboardWelcomeClosed} />
        </>
    )
}
export { AccountDropdown }
