import "@clearhaus/design-system/public/styles/normalize.css"
import "core-js/stable"
import { GA_TRACKING_ID, PUBLIC_PATH } from "modules/common"
import { Insights } from "modules/insights/Insights"
import React from "react"
import ReactDOM from "react-dom"
import "react-hot-loader/patch"
import "regenerator-runtime/runtime"
import { devMode, envMode } from "utils/envHelpers"
import "whatwg-fetch"
import { ConfirmSubscription, Default } from "./apps"

if (devMode) {
    // tslint:disable-next-line:no-var-requires
    const whyDidYouRender = require("@welldone-software/why-did-you-render")
    whyDidYouRender(React, {
        trackAllPureComponents: false,
        // include: [/^Modal/],
    })
}

// If in 'testing' mode, save the PUBLIC_PATH to localStorage, so it can be used by `my-test.clrhs.dk` to do a proper redirect
if (envMode === "testing") {
    localStorage.setItem("PUBLIC_PATH", PUBLIC_PATH)
}

if (GA_TRACKING_ID) {
    Insights.instance = new Insights("GoogleAnalytics4", {
        debug: devMode,
        gId: GA_TRACKING_ID,
    })
}

const render = () => {
    // Check if we are on the subscriptions confirm page
    const isSubscriptionsConfirmUrl = window.location.pathname.includes("/subscriptions/confirm")

    // Determine which app to render
    const App = isSubscriptionsConfirmUrl ? ConfirmSubscription : Default

    // Render app to the DOM
    ReactDOM.render(<App />, document.querySelector("#root"))
}

render()
