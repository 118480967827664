import { BoxEdges, Button, Grid, Loader } from "@clearhaus/design-system"
import { MeContext } from "modules/me/Provider/context"
import { useCreateFeedback } from "modules/midgard-feedback/queries"
import React, { useCallback, useContext, useMemo } from "react"
import { LocalizeContext } from "react-localize-redux"

interface IActionsProps {
    input: string
    onCancel: () => void
    onSettle: () => void
}

export const Actions: React.FC<IActionsProps> = (props) => {
    const { input, onCancel, onSettle } = props

    const { data: me } = useContext(MeContext)
    const { translate } = useContext(LocalizeContext)
    const { mutate: createFeedback, isLoading } = useCreateFeedback()

    const texts = useMemo(
        () => ({
            cancel: translate("navigation.account_dropdown.feedback_form.cancel").toString(),
            send: translate("navigation.account_dropdown.feedback_form.send").toString(),
        }),
        []
    )

    const handleOnSend = useCallback(() => {
        if (input) {
            createFeedback(
                {
                    feedback: input,
                    email: me?.email ?? "unknown email",
                },
                {
                    onSettled: () => {
                        onSettle()
                    },
                }
            )
        }
    }, [input, me])

    return (
        <>
            <Grid row noGutters>
                <Grid item auto>
                    <Button color="secondary" light onClick={onCancel}>
                        {texts.cancel}
                    </Button>
                </Grid>
                <Grid item auto>
                    <BoxEdges pl={16}>
                        <Button
                            prefix={isLoading ? <Loader size={12} light /> : undefined}
                            onClick={handleOnSend}
                            disabled={isLoading}
                        >
                            {texts.send}
                        </Button>
                    </BoxEdges>
                </Grid>
            </Grid>
        </>
    )
}
