import { IApplication } from "modules/applications/types"
import { fetchCompany, fetchCompanyOk } from "modules/companies/actions"
import { deletePerson, fetchPeople, fetchPeopleOk } from "modules/people/actions"
import { fork, put, take, takeLatest } from "redux-saga/effects"
import { ActionType, createCustomAction, getType, isActionOf } from "typesafe-actions"

export const DELETE_PERSONS_FROM_APPLICATION = "@@flows/DELETE_PERSONS_FROM_APPLICATION"
export const DELETE_PERSONS_FROM_APPLICATION_OK = "@@flows/DELETE_PERSONS_FROM_APPLICATION_OK"

export const deletePersonsFromApplication = createCustomAction(
    DELETE_PERSONS_FROM_APPLICATION,
    (application: IApplication) => ({ payload: application })
)

export const deletePersonsFromApplicationOk = createCustomAction(
    DELETE_PERSONS_FROM_APPLICATION_OK,
    // tslint:disable-next-line: no-empty
    () => {}
)

function* handleDeletePersonsFromApplication(
    action: ActionType<typeof deletePersonsFromApplication>
) {
    const application = action.payload

    yield put(fetchCompany(application.relations.company))
    const { payload: company }: ActionType<typeof fetchCompanyOk> = yield take(
        (a: ActionType<typeof fetchCompanyOk>) => {
            return isActionOf(fetchCompanyOk, a) && a.meta.uri === application.relations.company
        }
    )

    yield put(fetchPeople(company.relations.people))
    const { payload: fetchPeopleOkPayload }: ActionType<typeof fetchPeopleOk> = yield take(
        (a: ActionType<typeof fetchPeopleOk>) => {
            return isActionOf(fetchPeopleOk, a) && a.meta.uri === company.relations.people
        }
    )
    const personUris = Array.isArray(fetchPeopleOkPayload.relations.people)
        ? fetchPeopleOkPayload.relations.people
        : []

    if (personUris.length > 0) {
        for (const personUri of personUris) {
            yield put(deletePerson(personUri))
        }
    }

    yield put(deletePersonsFromApplicationOk())
}

function* watchDeletePersonsFromApplication() {
    yield takeLatest(getType(deletePersonsFromApplication), handleDeletePersonsFromApplication)
}

export default function* deletePersonsFromApplicationSaga() {
    yield fork(watchDeletePersonsFromApplication)
}
