import {
    BoxEdges,
    ChevronRightIcon,
    defaults as orgDefaults,
    Grid,
    NoticeBox,
    Paragraph,
    Separator as DSSeparator,
    ThemeContext,
} from "@clearhaus/design-system"
import { makeStyles } from "@clearhaus/design-system/utils/styles/makeStyles"
import React, { useContext } from "react"

interface INoticeProps {
    children: React.ReactNode
}

const Notice = (props: INoticeProps) => {
    const { children } = props

    return (
        <NoticeBox noPadding kind="accents-notice">
            <NoticeBox.Content>
                <BoxEdges p={8}>{children}</BoxEdges>
            </NoticeBox.Content>
        </NoticeBox>
    )
}

const styles = () => ({
    item: {
        cursor: "pointer",
        borderRadius: orgDefaults.cornerRadius.normal,

        "&:hover": {
            backgroundColor: orgDefaults.colors.accents.notice[300],
        },
    },
})

interface IItemProps {
    onClick?: () => void
    children: React.ReactNode
}

const useStyles = makeStyles(styles)
const Item: React.FC<IItemProps> = (props) => {
    const { children, onClick } = props
    const { defaults } = useContext(ThemeContext)
    const classes = useStyles()

    return (
        <div onClick={onClick} className={classes.item}>
            <BoxEdges px={8} py={6}>
                <Grid row alignItems="center">
                    <Grid item>
                        <Paragraph color={defaults.colors.accents.notice[900]}>
                            {children}
                        </Paragraph>
                    </Grid>
                    <Grid item auto>
                        <ChevronRightIcon size={16} color={defaults.colors.accents.notice[900]} />
                    </Grid>
                </Grid>
            </BoxEdges>
        </div>
    )
}

const Separator: React.FC = () => {
    const { defaults } = useContext(ThemeContext)

    return (
        <DSSeparator
            direction="horizontal"
            my={6}
            customColor={defaults.colors.accents.notice[300]}
        />
    )
}

Notice.Item = Item
Notice.Separator = Separator

export { Notice }
