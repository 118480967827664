import { AxiosError } from "axios"
import type { Error, Link } from "modules/common/types"
import { base64url } from "rfc4648"

export function linksToRelationsObject<Relations = unknown>(links: Link[]) {
    const relations = {} as Relations

    for (const link of links) {
        // tslint:disable-next-line:prefer-const
        let { name, rel } = link
        rel = rel.split("ch:").pop()!

        let href = link.href
        if (link.templated) {
            href = href.replace(/\{.*\}/g, "")
        }

        if (name) {
            // @ts-ignore
            if (!relations[rel]) {
                Object.defineProperty(relations, rel, {
                    enumerable: true,
                    value: {},
                })
            }

            // @ts-ignore
            Object.defineProperty(relations[rel], name, {
                enumerable: true,
                value: href,
            })
        } else {
            // @ts-ignore
            if (relations[rel]) {
                // @ts-ignore
                if (!Array.isArray(relations[rel])) {
                    Object.defineProperty(relations, rel, {
                        enumerable: true,
                        // @ts-ignore
                        value: [relations[rel]],
                    })
                }
                // @ts-ignore
                relations[rel].push(href)
            } else {
                Object.defineProperty(relations, rel, {
                    enumerable: true,
                    value: href,
                    writable: true,
                })
            }
        }
    }

    return relations
}

export function nullifyEmptyKeys<T extends object>(obj: T): T {
    const result = {} as T
    const keys = Object.keys(obj)
    for (const key of keys) {
        Object.defineProperty(result, key, {
            configurable: true,
            enumerable: true,
            // @ts-ignore
            value: obj[key] ? obj[key] : null,
            writable: true,
        })
    }

    return result
}

export function removeEmptyKeys<T extends object>(obj: T): T {
    const result = {} as T
    const keys = Object.keys(obj)
    for (const key of keys) {
        // @ts-ignore
        if (!obj[key]) {
            continue
        }
        Object.defineProperty(result, key, {
            configurable: true,
            enumerable: true,
            // @ts-ignore
            value: obj[key],
            writable: true,
        })
    }

    return result
}

export function extractGuid(value: string) {
    const guidRegex = /([\w\d]{8}\-[\w\d]{4}\-[\w\d]{4}\-[\w\d]{4}\-[\w\d]{12})/gi
    const matches = []
    const maxDepth = 9
    let currentMatch
    // tslint:disable-next-line:no-conditional-assignment
    while ((currentMatch = guidRegex.exec(value)) !== null) {
        if (matches.length > maxDepth) {
            throw Error("Too many matches, be more specific or limit input value")
        }

        matches.push(currentMatch[0])
    }

    if (matches.length === 1) {
        return matches[0]
    }

    return matches
}

export function extractLastGuid(value: string) {
    const Guids = extractGuid(value)
    return Array.isArray(Guids) ? Guids.pop() : Guids
}

export function parseAxiosError(error?: AxiosError) {
    if (!error) {
        return {
            status: 500,
            title: "Unknown error",
        }
    }
    if (error.response) {
        return error.response.data
            ? (error.response.data as Error)
            : {
                  status: 500,
                  title: "Unknown error",
              }
    }

    return {
        status: 500,
        title: "Unknown error",
    }
}

export function encodeParameter(input: string) {
    return base64url.stringify(
        input.split("").map((c) => c.charCodeAt(0)),
        { pad: false }
    )
}
export function decodeParameter(input: string) {
    return (base64url.parse(input, { out: Array, loose: true }) as unknown as number[])
        .map((charNo) => String.fromCharCode(charNo))
        .join("")
}

export const supportedCountries = [
    { countryCode: "AD", language: "ca", name: "Andorra" },
    { countryCode: "AT", language: "de", name: "Austria", flag: "de" },
    { countryCode: "BE", language: ["nl", "fr"], name: "Belgium" },
    { countryCode: "BG", language: "bg", name: "Bulgaria" },
    { countryCode: "HR", language: "hr", name: "Croatia" },
    { countryCode: "CY", language: "el", name: "Cyprus" },
    { countryCode: "CZ", language: "cs", name: "Czech Republic" },
    { countryCode: "DK", language: "da", name: "Denmark" },
    { countryCode: "EE", language: "et", name: "Estonia" },
    { countryCode: "FO", language: "fo", name: "Faroe Island" },
    { countryCode: "FI", language: "fi", name: "Finland" },
    { countryCode: "FR", language: "fr", name: "France" },
    { countryCode: "DE", language: "de", name: "Germany" },
    { countryCode: "GI", language: "en", name: "Gibraltar" },
    { countryCode: "GR", language: "el", name: "Greece" },
    { countryCode: "GL", language: ["da", "kl"], name: "Greenland" },
    { countryCode: "HU", language: "hu", name: "Hungary" },
    { countryCode: "IS", language: "is", name: "Iceland" },
    { countryCode: "IE", language: ["en", "ga"], name: "Ireland" },
    { countryCode: "IT", language: ["ca", "de", "fur", "it"], name: "Italy" },
    { countryCode: "LV", language: "lv", name: "Latvia" },
    { countryCode: "LI", language: ["de", "gsw"], name: "Liechtenstein" },
    { countryCode: "LT", language: "lt", name: "Lithuania" },
    { countryCode: "LU", language: ["de", "fr", "lb", "pt"], name: "Luxenbourg" },
    { countryCode: "MT", language: ["en", "mt"], name: "Malta" },
    { countryCode: "MC", language: "fr", name: "Monaco" },
    { countryCode: "NL", language: ["en", "nl"], name: "Netherlands" },
    { countryCode: "NO", language: "no", name: "Norway" },
    { countryCode: "PL", language: "pl", name: "Poland" },
    { countryCode: "PT", language: "pt", name: "Portugal" },
    { countryCode: "RO", language: "ro", name: "Romania" },
    { countryCode: "SM", language: "it", name: "San Marino" },
    { countryCode: "SK", language: "sk", name: "Slovakia" },
    { countryCode: "SI", language: ["en", "si"], name: "Slovenia" },
    { countryCode: "ES", language: "es", name: "Spain" },
    { countryCode: "SE", language: "se", name: "Sweden" },
    {
        countryCode: "CH",
        language: ["de", "en", "fr", "gsw", "it", "pt", "rm", "wae"],
        name: "Switzerland",
    },
    { countryCode: "GB", language: "en", name: "United Kingdom" },
]

export const allCountries = [
    { countryCode: "ad", name: "Andorra" },
    { countryCode: "ae", name: "United Arab Emirates" },
    { countryCode: "af", name: "Afghanistan" },
    { countryCode: "ag", name: "Antigua and Barbuda" },
    { countryCode: "ai", name: "Anguilla" },
    { countryCode: "al", name: "Albania" },
    { countryCode: "am", name: "Armenia" },
    { countryCode: "ao", name: "Angola" },
    { countryCode: "aq", name: "Antarctica" },
    { countryCode: "ar", name: "Argentina" },
    { countryCode: "as", name: "American Samoa" },
    { countryCode: "at", name: "Austria" },
    { countryCode: "au", name: "Australia" },
    { countryCode: "aw", name: "Aruba" },
    { countryCode: "ax", name: "Aland Islands" },
    { countryCode: "az", name: "Azerbaijan" },
    { countryCode: "ba", name: "Bosnia and Herzegovina" },
    { countryCode: "bb", name: "Barbados" },
    { countryCode: "bd", name: "Bangladesh" },
    { countryCode: "be", name: "Belgium" },
    { countryCode: "bf", name: "Burkina Faso" },
    { countryCode: "bg", name: "Bulgaria" },
    { countryCode: "bh", name: "Bahrain" },
    { countryCode: "bi", name: "Burundi" },
    { countryCode: "bj", name: "Benin" },
    { countryCode: "bl", name: "Saint Barthelemy" },
    { countryCode: "bm", name: "Bermuda" },
    { countryCode: "bn", name: "Brunei" },
    { countryCode: "bo", name: "Bolivia" },
    { countryCode: "bq", name: "Bonaire, Saint Eustatius and Saba " },
    { countryCode: "br", name: "Brazil" },
    { countryCode: "bs", name: "Bahamas" },
    { countryCode: "bt", name: "Bhutan" },
    { countryCode: "bv", name: "Bouvet Island" },
    { countryCode: "bw", name: "Botswana" },
    { countryCode: "by", name: "Belarus" },
    { countryCode: "bz", name: "Belize" },
    { countryCode: "ca", name: "Canada" },
    { countryCode: "cc", name: "Cocos Islands" },
    { countryCode: "cd", name: "Democratic Republic of the Congo" },
    { countryCode: "cf", name: "Central African Republic" },
    { countryCode: "cg", name: "Republic of the Congo" },
    { countryCode: "ch", name: "Switzerland" },
    { countryCode: "ci", name: "Ivory Coast" },
    { countryCode: "ck", name: "Cook Islands" },
    { countryCode: "cl", name: "Chile" },
    { countryCode: "cm", name: "Cameroon" },
    { countryCode: "cn", name: "China" },
    { countryCode: "co", name: "Colombia" },
    { countryCode: "cr", name: "Costa Rica" },
    { countryCode: "cu", name: "Cuba" },
    { countryCode: "cv", name: "Cape Verde" },
    { countryCode: "cw", name: "Curacao" },
    { countryCode: "cx", name: "Christmas Island" },
    { countryCode: "cy", name: "Cyprus" },
    { countryCode: "cz", name: "Czech Republic" },
    { countryCode: "de", name: "Germany" },
    { countryCode: "dj", name: "Djibouti" },
    { countryCode: "dk", name: "Denmark" },
    { countryCode: "dm", name: "Dominica" },
    { countryCode: "do", name: "Dominican Republic" },
    { countryCode: "dz", name: "Algeria" },
    { countryCode: "ec", name: "Ecuador" },
    { countryCode: "ee", name: "Estonia" },
    { countryCode: "eg", name: "Egypt" },
    { countryCode: "eh", name: "Western Sahara" },
    { countryCode: "er", name: "Eritrea" },
    { countryCode: "es", name: "Spain" },
    { countryCode: "et", name: "Ethiopia" },
    { countryCode: "fi", name: "Finland" },
    { countryCode: "fj", name: "Fiji" },
    { countryCode: "fk", name: "Falkland Islands" },
    { countryCode: "fm", name: "Micronesia" },
    { countryCode: "fo", name: "Faroe Islands" },
    { countryCode: "fr", name: "France" },
    { countryCode: "ga", name: "Gabon" },
    { countryCode: "gb", name: "United Kingdom" },
    { countryCode: "gd", name: "Grenada" },
    { countryCode: "ge", name: "Georgia" },
    { countryCode: "gf", name: "French Guiana" },
    { countryCode: "gg", name: "Guernsey" },
    { countryCode: "gh", name: "Ghana" },
    { countryCode: "gi", name: "Gibraltar" },
    { countryCode: "gl", name: "Greenland" },
    { countryCode: "gm", name: "Gambia" },
    { countryCode: "gn", name: "Guinea" },
    { countryCode: "gp", name: "Guadeloupe" },
    { countryCode: "gq", name: "Equatorial Guinea" },
    { countryCode: "gr", name: "Greece" },
    { countryCode: "gs", name: "South Georgia and the South Sandwich Islands" },
    { countryCode: "gt", name: "Guatemala" },
    { countryCode: "gu", name: "Guam" },
    { countryCode: "gw", name: "Guinea-Bissau" },
    { countryCode: "gy", name: "Guyana" },
    { countryCode: "hk", name: "Hong Kong" },
    { countryCode: "hm", name: "Heard Island and McDonald Islands" },
    { countryCode: "hn", name: "Honduras" },
    { countryCode: "hr", name: "Croatia" },
    { countryCode: "ht", name: "Haiti" },
    { countryCode: "hu", name: "Hungary" },
    { countryCode: "id", name: "Indonesia" },
    { countryCode: "ie", name: "Ireland" },
    { countryCode: "il", name: "Israel" },
    { countryCode: "im", name: "Isle of Man" },
    { countryCode: "in", name: "India" },
    { countryCode: "io", name: "British Indian Ocean Territory" },
    { countryCode: "iq", name: "Iraq" },
    { countryCode: "ir", name: "Iran" },
    { countryCode: "is", name: "Iceland" },
    { countryCode: "it", name: "Italy" },
    { countryCode: "je", name: "Jersey" },
    { countryCode: "jm", name: "Jamaica" },
    { countryCode: "jo", name: "Jordan" },
    { countryCode: "jp", name: "Japan" },
    { countryCode: "ke", name: "Kenya" },
    { countryCode: "kg", name: "Kyrgyzstan" },
    { countryCode: "kh", name: "Cambodia" },
    { countryCode: "ki", name: "Kiribati" },
    { countryCode: "km", name: "Comoros" },
    { countryCode: "kn", name: "Saint Kitts and Nevis" },
    { countryCode: "kp", name: "North Korea" },
    { countryCode: "kr", name: "South Korea" },
    { countryCode: "kw", name: "Kuwait" },
    { countryCode: "ky", name: "Cayman Islands" },
    { countryCode: "kz", name: "Kazakhstan" },
    { countryCode: "la", name: "Laos" },
    { countryCode: "lb", name: "Lebanon" },
    { countryCode: "lc", name: "Saint Lucia" },
    { countryCode: "li", name: "Liechtenstein" },
    { countryCode: "lk", name: "Sri Lanka" },
    { countryCode: "lr", name: "Liberia" },
    { countryCode: "ls", name: "Lesotho" },
    { countryCode: "lt", name: "Lithuania" },
    { countryCode: "lu", name: "Luxembourg" },
    { countryCode: "lv", name: "Latvia" },
    { countryCode: "ly", name: "Libya" },
    { countryCode: "ma", name: "Morocco" },
    { countryCode: "mc", name: "Monaco" },
    { countryCode: "md", name: "Moldova" },
    { countryCode: "me", name: "Montenegro" },
    { countryCode: "mf", name: "Saint Martin" },
    { countryCode: "mg", name: "Madagascar" },
    { countryCode: "mh", name: "Marshall Islands" },
    { countryCode: "mk", name: "Macedonia" },
    { countryCode: "ml", name: "Mali" },
    { countryCode: "mm", name: "Myanmar" },
    { countryCode: "mn", name: "Mongolia" },
    { countryCode: "mo", name: "Macao" },
    { countryCode: "mp", name: "Northern Mariana Islands" },
    { countryCode: "mq", name: "Martinique" },
    { countryCode: "mr", name: "Mauritania" },
    { countryCode: "ms", name: "Montserrat" },
    { countryCode: "mt", name: "Malta" },
    { countryCode: "mu", name: "Mauritius" },
    { countryCode: "mv", name: "Maldives" },
    { countryCode: "mw", name: "Malawi" },
    { countryCode: "mx", name: "Mexico" },
    { countryCode: "my", name: "Malaysia" },
    { countryCode: "mz", name: "Mozambique" },
    { countryCode: "na", name: "Namibia" },
    { countryCode: "nc", name: "New Caledonia" },
    { countryCode: "ne", name: "Niger" },
    { countryCode: "nf", name: "Norfolk Island" },
    { countryCode: "ng", name: "Nigeria" },
    { countryCode: "ni", name: "Nicaragua" },
    { countryCode: "nl", name: "Netherlands" },
    { countryCode: "no", name: "Norway" },
    { countryCode: "np", name: "Nepal" },
    { countryCode: "nr", name: "Nauru" },
    { countryCode: "nu", name: "Niue" },
    { countryCode: "nz", name: "New Zealand" },
    { countryCode: "om", name: "Oman" },
    { countryCode: "pa", name: "Panama" },
    { countryCode: "pe", name: "Peru" },
    { countryCode: "pf", name: "French Polynesia" },
    { countryCode: "pg", name: "Papua New Guinea" },
    { countryCode: "ph", name: "Philippines" },
    { countryCode: "pk", name: "Pakistan" },
    { countryCode: "pl", name: "Poland" },
    { countryCode: "pm", name: "Saint Pierre and Miquelon" },
    { countryCode: "pn", name: "Pitcairn" },
    { countryCode: "pr", name: "Puerto Rico" },
    { countryCode: "ps", name: "Palestinian Territory" },
    { countryCode: "pt", name: "Portugal" },
    { countryCode: "pw", name: "Palau" },
    { countryCode: "py", name: "Paraguay" },
    { countryCode: "qa", name: "Qatar" },
    { countryCode: "re", name: "Reunion" },
    { countryCode: "ro", name: "Romania" },
    { countryCode: "rs", name: "Serbia" },
    { countryCode: "ru", name: "Russia" },
    { countryCode: "rw", name: "Rwanda" },
    { countryCode: "sa", name: "Saudi Arabia" },
    { countryCode: "sb", name: "Solomon Islands" },
    { countryCode: "sc", name: "Seychelles" },
    { countryCode: "sd", name: "Sudan" },
    { countryCode: "se", name: "Sweden" },
    { countryCode: "sg", name: "Singapore" },
    { countryCode: "sh", name: "Saint Helena" },
    { countryCode: "si", name: "Slovenia" },
    { countryCode: "sj", name: "Svalbard and Jan Mayen" },
    { countryCode: "sk", name: "Slovakia" },
    { countryCode: "sl", name: "Sierra Leone" },
    { countryCode: "sm", name: "San Marino" },
    { countryCode: "sn", name: "Senegal" },
    { countryCode: "so", name: "Somalia" },
    { countryCode: "sr", name: "Suriname" },
    { countryCode: "ss", name: "South Sudan" },
    { countryCode: "st", name: "Sao Tome and Principe" },
    { countryCode: "sv", name: "El Salvador" },
    { countryCode: "sx", name: "Sint Maarten" },
    { countryCode: "sy", name: "Syria" },
    { countryCode: "sz", name: "Swaziland" },
    { countryCode: "tc", name: "Turks and Caicos Islands" },
    { countryCode: "td", name: "Chad" },
    { countryCode: "tf", name: "French Southern Territories" },
    { countryCode: "tg", name: "Togo" },
    { countryCode: "th", name: "Thailand" },
    { countryCode: "tj", name: "Tajikistan" },
    { countryCode: "tk", name: "Tokelau" },
    { countryCode: "tl", name: "East Timor" },
    { countryCode: "tm", name: "Turkmenistan" },
    { countryCode: "tn", name: "Tunisia" },
    { countryCode: "to", name: "Tonga" },
    { countryCode: "tr", name: "Turkey" },
    { countryCode: "tt", name: "Trinidad and Tobago" },
    { countryCode: "tv", name: "Tuvalu" },
    { countryCode: "tw", name: "Taiwan" },
    { countryCode: "tz", name: "Tanzania" },
    { countryCode: "ua", name: "Ukraine" },
    { countryCode: "ug", name: "Uganda" },
    { countryCode: "um", name: "United States Minor Outlying Islands" },
    { countryCode: "us", name: "United States" },
    { countryCode: "uy", name: "Uruguay" },
    { countryCode: "uz", name: "Uzbekistan" },
    { countryCode: "va", name: "Vatican" },
    { countryCode: "vc", name: "Saint Vincent and the Grenadines" },
    { countryCode: "ve", name: "Venezuela" },
    { countryCode: "vg", name: "British Virgin Islands" },
    { countryCode: "vi", name: "U.S. Virgin Islands" },
    { countryCode: "vn", name: "Vietnam" },
    { countryCode: "vu", name: "Vanuatu" },
    { countryCode: "wf", name: "Wallis and Futuna" },
    { countryCode: "ws", name: "Samoa" },
    { countryCode: "xk", name: "Kosovo" },
    { countryCode: "ye", name: "Yemen" },
    { countryCode: "yt", name: "Mayotte" },
    { countryCode: "za", name: "South Africa" },
    { countryCode: "zm", name: "Zambia" },
    { countryCode: "zw", name: "Zimbabwe" },
]

export const allCurrencies = [
    "AED",
    "AFN",
    "ALL",
    "AMD",
    "ANG",
    "AOA",
    "ARS",
    "AUD",
    "AWG",
    "AZN",
    "BAM",
    "BBD",
    "BDT",
    "BGN",
    "BHD",
    "BIF",
    "BMD",
    "BND",
    "BOB",
    "BRL",
    "BSD",
    "BTN",
    "BWP",
    "BYN",
    "BZD",
    "CAD",
    "CDF",
    "CHF",
    "CLP",
    "CNH",
    "CNY",
    "COP",
    "CRC",
    "CUP",
    "CVE",
    "CZK",
    "DJF",
    "DKK",
    "DOP",
    "DZD",
    "EEK",
    "EGP",
    "ERN",
    "ETB",
    "EUR",
    "FJD",
    "FKP",
    "GBP",
    "GEL",
    "GHS",
    "GIP",
    "GMD",
    "GNF",
    "GTQ",
    "GYD",
    "HKD",
    "HNL",
    "HRK",
    "HTG",
    "HUF",
    "IDR",
    "ILS",
    "INR",
    "IQD",
    "IRR",
    "ISK",
    "JMD",
    "JOD",
    "JPY",
    "KES",
    "KGS",
    "KHR",
    "KMF",
    "KPW",
    "KRW",
    "KWD",
    "KYD",
    "KZT",
    "LAK",
    "LBP",
    "LKR",
    "LRD",
    "LSL",
    "LYD",
    "MAD",
    "MDL",
    "MGA",
    "MKD",
    "MMK",
    "MNT",
    "MOP",
    "MRO",
    "MRU",
    "MUR",
    "MVR",
    "MWK",
    "MXN",
    "MYR",
    "MZN",
    "NAD",
    "NGN",
    "NIO",
    "NOK",
    "NPR",
    "NZD",
    "OMR",
    "PAB",
    "PEN",
    "PGK",
    "PHP",
    "PKR",
    "PLN",
    "PYG",
    "QAR",
    "RON",
    "RSD",
    "RUB",
    "RWF",
    "SAR",
    "SBD",
    "SCR",
    "SDG",
    "SEK",
    "SGD",
    "SHP",
    "SLE",
    "SOS",
    "SRD",
    "SSP",
    "STD",
    "STN",
    "SVC",
    "SYP",
    "SZL",
    "THB",
    "TJS",
    "TMT",
    "TND",
    "TOP",
    "TRY",
    "TTD",
    "TWD",
    "TZS",
    "UAH",
    "UGX",
    "USD",
    "UYU",
    "UZS",
    "VEF",
    "VES",
    "VND",
    "VUV",
    "WST",
    "XAF",
    "XCD",
    "XOF",
    "XPF",
    "YER",
    "ZAR",
    "ZMK",
    "ZMW",
    "ZWG",
]

export const supportedCurrencies = [
    "DKK",
    "EUR",
    "SEK",
    "NOK",
    "GBP",
    "USD",
    "CZK",
    "HUF",
    "PLN",
    "RON",
    "CHF",
] as const

type ISupportedCurrency = (typeof supportedCurrencies)[number]

export interface ISupportedCurrencyCountry {
    country: string
    currency: ISupportedCurrency
}
export const supportedCurrenciesCountries: ISupportedCurrencyCountry[] = [
    {
        country: "DK",
        currency: "DKK",
    },
    {
        country: "SE",
        currency: "SEK",
    },
    {
        country: "NO",
        currency: "NOK",
    },
    {
        country: "GB",
        currency: "GBP",
    },
    {
        country: "US",
        currency: "USD",
    },
    {
        country: "CZ",
        currency: "CZK",
    },
    {
        country: "HU",
        currency: "HUF",
    },
    {
        country: "PL",
        currency: "PLN",
    },
    {
        country: "RO",
        currency: "RON",
    },
    {
        country: "CH",
        currency: "CHF",
    },
    {
        country: "EU",
        currency: "EUR",
    },
]
