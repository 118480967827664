import { shallowEqual } from "fast-equals"
import { AccountsContext } from "modules/accounts/Providers/Accounts/AccountsContext"
import {
    IAccountsProvidersRootState,
    IAccountsProviderState,
} from "modules/accounts/Providers/Accounts/reducer"
import {
    initializeProvider,
    providerFetchAccounts,
    providerLoadMore,
    teardownProvider,
} from "modules/accounts/Providers/Accounts/sagas"
import { EntityIndex, Uri } from "modules/common/types"
import React, { useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

interface IAccountsProviderProps {
    uri: Uri
    id?: string
    query?: string
    per_page?: number
    page?: number
    onLoading?: () => void
    onLoaded?: () => void
    children: React.ReactNode
}

const activeProviders: EntityIndex<number> = {}
export const AccountsProvider: React.FC<IAccountsProviderProps> = (props) => {
    const { uri, query, per_page = 50, page, onLoaded, onLoading, id } = props
    const [providerId] = useState(id ? id : uri)
    const [initialLoad, setInitialLoad] = useState(true)

    const { data, relations, error, isLoading, metadata } = useSelector<
        IAccountsProvidersRootState,
        IAccountsProviderState
    >((s) => {
        if (s.accountsProviders && s.accountsProviders[providerId]) {
            return s.accountsProviders[providerId]
        }

        return {} as IAccountsProviderState
    }, shallowEqual)

    const dispatch = useDispatch()

    useEffect(() => {
        if (!initialLoad) {
            dispatch(providerFetchAccounts(providerId, uri, per_page, page, query))
        }
    }, [providerId, uri, query, per_page, page])

    useEffect(() => {
        if (isLoading && onLoading) {
            onLoading()
        } else if (!isLoading && onLoaded) {
            onLoaded()
        }
    }, [isLoading])

    const accountsContextValue = useMemo(() => {
        function loadMore() {
            if (relations && relations.next) {
                dispatch(providerLoadMore(providerId, uri))
            }
        }

        return {
            data,
            error,
            isLoading,
            loadMore,
            metadata,
            relations,
        }
    }, [providerId, data, relations, error, isLoading])

    useEffect(() => {
        if (activeProviders[providerId]) {
            activeProviders[providerId]++
        } else {
            activeProviders[providerId] = 1
        }

        if (activeProviders[providerId] === 1) {
            dispatch(initializeProvider(providerId, uri, per_page, page, query))
        }

        setInitialLoad(false)

        return () => {
            activeProviders[providerId]--
            if (activeProviders[providerId] === 0) {
                dispatch(teardownProvider(providerId, uri))
            }
        }
    }, [])

    if (!accountsContextValue) {
        return null
    }

    return (
        <AccountsContext.Provider value={accountsContextValue}>
            {props.children}
        </AccountsContext.Provider>
    )
}
