import { ConsumerBase } from "modules/insights/Consumers/ConsumerBase"
import { GoogleAnalytics4 } from "modules/insights/Consumers/GoogleAnalytics4"
import { murmur3 } from "murmurhash-js"

interface GoogleAnalytics4Config {
    gId: string
    userId?: string
    debug?: boolean
}

interface SentEvent {
    sent_at: number
    event_hash: number
}

export class Insights {
    static instance: Insights | null = null

    private _consumer: ConsumerBase | null = null
    private _sentEvents: SentEvent[] = []
    private readonly _sentEventsGC: number = -1

    constructor(consumer: "GoogleAnalytics4", config: GoogleAnalytics4Config) {
        if (Insights.instance) {
            throw Error(`An instance is already created.`)
        }

        switch (consumer) {
            default:
                this._consumer = new GoogleAnalytics4(config.gId, config.userId, config.debug)
                break
        }

        this._sentEventsGC = window.setInterval(() => {
            if (!Insights.instance) {
                window.clearInterval(this._sentEventsGC)
                return
            }

            if (Insights.instance._sentEvents.length === 0) {
                return
            }

            const now = Date.now()
            Insights.instance._sentEvents = Insights.instance._sentEvents.filter(
                (se) => se.sent_at + 250 > now
            )
        }, 5000)
    }

    static setUserSession(userId: string, gateways: string | string[] | null) {
        if (!Insights.instance) {
            // tslint:disable-next-line:no-console
            console.warn(`No Insights instance created.`)
            return
        }

        if (!Insights.instance._consumer) {
            // tslint:disable-next-line:no-console
            console.warn(`No consumer created.`)
            return
        }

        Insights.instance._consumer.setUserSession(userId, gateways)
    }

    static logEvent<P extends object>(eventName: string, params: P) {
        if (!Insights.instance) {
            // tslint:disable-next-line:no-console
            console.warn(`No Insights instance created.`)
            return
        }

        if (!Insights.instance._consumer) {
            // tslint:disable-next-line:no-console
            console.warn(`No consumer created.`)
            return
        }

        const eventHash = murmur3(JSON.stringify({ eventName, ...params }))
        const now = Date.now()
        if (
            Insights.instance._sentEvents.find(
                (se) => se.event_hash === eventHash && se.sent_at + 250 > now
            )
        ) {
            // tslint:disable-next-line:no-console
            console.warn(`Event already sent`)
            return
        }

        Insights.instance._consumer.logEvent(eventName, params)
        Insights.instance._sentEvents.push({ event_hash: eventHash, sent_at: now })
    }
}
