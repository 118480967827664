import {
    BoxEdges,
    defaults as defaultsOrg,
    FileTextIcon,
    Grid,
    Paragraph,
    ThemeContext,
} from "@clearhaus/design-system"
import { makeStyles } from "@clearhaus/design-system/utils/styles/makeStyles"
import { TermsAndPoliciesContext } from "components/common/TermsAndPolicies/TermsAndPoliciesProvider"
import { useTermsUrl } from "hooks/useTermsUrl"
import React, { useContext } from "react"
import { LocalizeContext, Translate } from "react-localize-redux"

const styles = (defaults: typeof defaultsOrg) => ({
    link: {
        color: defaults.colors.neutrals[700],
        fontSize: defaults.fontSizes[13],
        lineHeight: defaults.lineHeights.m,
        textDecoration: "none",

        "&:active": {
            color: defaults.colors.neutrals[900],
        },
        "&:focus": {
            color: defaults.colors.neutrals[900],
            outline: 0,
        },
        "&:hover": {
            color: defaults.colors.neutrals[900],
        },
    },
})

const useStyles = makeStyles(styles)
export const TermsAndPolicies: React.FC = () => {
    const classes = useStyles()
    const { defaults } = useContext(ThemeContext)
    const { activeLanguage } = useContext(LocalizeContext)
    const url = useTermsUrl(activeLanguage.code)
    const { style } = useContext(TermsAndPoliciesContext)

    return (
        <Grid row alignItems="center" noGutters style={style}>
            <Grid item auto>
                <BoxEdges mr={6}>
                    <FileTextIcon size={14} color={defaults.colors.neutrals[700]} />
                </BoxEdges>
            </Grid>
            <Grid item>
                <Paragraph size="xs" light>
                    <Translate id="common.terms_and_policies.prefix" />{" "}
                    <a className={classes.link} href={url} target="_blank">
                        <Translate id="common.terms_and_policies.general_terms" />
                    </a>
                </Paragraph>
            </Grid>
        </Grid>
    )
}
