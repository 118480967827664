import { WebAuth } from "auth0-js"
import { Auth0State, IdTokenPayload } from "modules/authentication/types"
import { history, PUBLIC_PATH } from "modules/common"
import queryString from "query-string"
import { scopes } from "./scopes"

interface IAuth0Configuration {
    audience: string
    authorizationUri: string
    clientId: string
    redirectUri: string
}

const languageMap = {
    en: "en",
    dk: "da",
    de: "de",
    se: "sv",
    no: "nb",
    pl: "pl",
    fi: "fi",
}

export class Auth0 {
    public static instance: Auth0

    private _isLoggedIn = false
    private _accessToken: string | null = null
    private _idToken: string | null = null
    private _idTokenPayload: IdTokenPayload | null = null
    private _auth0: WebAuth | undefined
    private _config: IAuth0Configuration

    constructor() {
        this._config = {
            audience: process.env.MERCHANT_API_ROOT!,
            authorizationUri: process.env.AUTH_IDENTITY_ENDPOINT!,
            clientId: process.env.AUTH_CLIENT_ID!,
            redirectUri: process.env.AUTH_REDIRECT_URI!,
        }

        if (!this._config.audience) {
            throw new Error(`Missing 'MERCHANT_API_ROOT' environment variable`)
        }

        if (!this._config.authorizationUri) {
            throw new Error(`Missing 'AUTH_IDENTITY_ENDPOINT' environment variable`)
        }

        if (!this._config.clientId) {
            throw new Error(`Missing 'AUTH_CLIENT_ID' environment variable`)
        }

        if (!this._config.redirectUri) {
            throw new Error(`Missing 'AUTH_REDIRECT_URI' environment variable`)
        }

        this._auth0 = new WebAuth({
            audience: this._config.audience,
            clientID: this._config.clientId,
            domain: this._config.authorizationUri,
            redirectUri: this._config.redirectUri,
            responseType: "token id_token",
            scope: scopes.join(" "),
        })
    }

    public login() {
        const { signup, ...query } = queryString.parse(history.location.search)
        // Reconstruct the query without 'signup' parameter
        const stringifiedQuery = queryString.stringify(query)
        const returnPathname = history.location.pathname
            ? history.location.pathname.replace(PUBLIC_PATH, "/")
            : "/"

        const state: Auth0State = {
            returnUrl: `${returnPathname}${stringifiedQuery ? `?${stringifiedQuery}` : ""}`,
        }

        const options: any = {
            appState: btoa(JSON.stringify(state)),
            page: signup ? "signup" : "login",
        } // "as any" so it'll accept the 'page' property. Auth0 passes any 'unknown' properties as queries to auth

        const search = new URLSearchParams(history.location.search)

        if (search.get("lang")) {
            options.ui_locales = languageMap[search.get("lang") as keyof typeof languageMap]
            localStorage.setItem(
                "preferredLanguage",
                search.get("lang") as keyof typeof languageMap
            )
            options.lang = search.get("lang") // adding it, in case we need to revert back to auth pages. will be removed in production
        } else if (localStorage.getItem("preferredLanguage")) {
            options.ui_locales =
                languageMap[localStorage.getItem("preferredLanguage") as keyof typeof languageMap]
        }

        if (search.get("verified")) {
            options["ext-verified"] = search.get("verified")
        }

        if (this._auth0) {
            this._auth0.authorize({
                ...options,
            })
            // @TODO: Rethink UX design of auth.clrhs.dk, the above is an unsupported feature, that could disapear, if auth0 decides to only pass over 'allowed' properties
        }
    }

    public handleAuthentication() {
        return new Promise<Auth0State>((resolve, reject) => {
            if (this._auth0) {
                this._auth0.parseHash({ hash: history.location.hash }, (err, authResult) => {
                    if (authResult) {
                        if (
                            !authResult.idToken ||
                            !authResult.idTokenPayload ||
                            !authResult.appState ||
                            !authResult.accessToken
                        ) {
                            this.logout()
                            reject(
                                new Error(
                                    `Invalid authentication result: ${JSON.stringify(authResult)}`
                                )
                            )
                        } else {
                            this._accessToken = authResult.accessToken
                            this._idToken = authResult.idToken
                            this._idTokenPayload = authResult.idTokenPayload
                            this._isLoggedIn = true

                            const decodedAppState = JSON.parse(
                                atob(authResult.appState)
                            ) as Auth0State

                            resolve(decodedAppState)
                        }
                    } else {
                        this.logout()
                        reject(new Error(`Error handling authentication: ${JSON.stringify(err)}`))
                    }
                })
            } else {
                reject(new Error(`Auth0 not initialized`))
            }
        })
    }

    public logout() {
        if (this instanceof Auth0) {
            this._isLoggedIn = false
            this._idToken = null
            this._accessToken = null

            if (this._auth0) {
                this._auth0.logout({
                    returnTo: process.env.AUTH_REDIRECT_URI,
                })
                delete this._auth0
            }
        } else {
            Auth0.instance.logout()
        }
    }

    public getAccessToken() {
        if (!this._accessToken) {
            throw new Error(`No AccessToken available`)
        }

        return this._accessToken
    }

    public getIdToken() {
        if (!this._idToken) {
            throw new Error(`No IdToken available`)
        }

        return this._idToken
    }

    public getIdTokenPayload() {
        if (!this._idTokenPayload) {
            throw new Error(`No User logged in`)
        }

        return this._idTokenPayload
    }

    public getAppMetadata() {
        if (!this._idTokenPayload) {
            throw new Error(`No User logged in`)
        }

        const metadataClaim = this._idTokenPayload
            ? (Object.keys(this._idTokenPayload).find((key) =>
                  key.match(/app_metadata/)
              ) as "https://clearhaus.com/app_metadata")
            : null
        if (!metadataClaim) {
            throw new Error(`No metadata found in token`)
        }

        return this._idTokenPayload[metadataClaim]
    }

    public getRoles() {
        if (!this._idTokenPayload) {
            throw new Error(`No User logged in`)
        }

        const appMetadata = this.getAppMetadata()

        return appMetadata.roles
    }

    public getSub() {
        if (!this._idTokenPayload) {
            throw new Error(`No User logged in`)
        }

        const appMetadata = this.getAppMetadata()

        return appMetadata.sub
    }

    public isAuthenticated() {
        return this._isLoggedIn
    }
}

export default Auth0
