import { LoadingScreen } from "@clearhaus/design-system"
import { LocalizationLoader } from "components/common"
import { useInitializeSpecificLanguage } from "components/localization"
import React from "react"

interface SpecificLanguageLocalizationLoaderProps {
    children: React.ReactNode
    language: string
    namespaces: string[]
}

export const SpecificLanguageLocalizationLoader: React.FC<
    SpecificLanguageLocalizationLoaderProps
> = (props) => {
    const { children, language, namespaces } = props

    const { isLoading } = useInitializeSpecificLanguage(language)

    if (isLoading) {
        return <LoadingScreen />
    }

    return <LocalizationLoader namespace={namespaces}>{children}</LocalizationLoader>
}
