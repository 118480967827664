import { BoxEdges, Button, List, MoreIcon, Popover, Separator } from "@clearhaus/design-system"
import {
    AccountSwitcherActionsContext,
    AccountSwitcherContext,
} from "components/AccountSwitcher/Provider/AccountSwitcherContext"
import { encodeParameter } from "modules/common/helpers"
import { Uri } from "modules/common/types"
import React, { useCallback, useContext, useState } from "react"
import { Translate } from "react-localize-redux"
import { useHistory } from "react-router"

const texts = {
    generalInfo: <Translate id={"common.accountswitcher.additional_info.general_info"} />,
    contract: <Translate id={"common.accountswitcher.additional_info.contract"} />,
    members: <Translate id={"common.accountswitcher.additional_info.members"} />,
    transactionRules: <Translate id={"common.accountswitcher.additional_info.transaction_rules"} />,
    apiKeys: <Translate id={"common.accountswitcher.additional_info.api_keys"} />,
}

interface IAccountSwitcherLinksProps {
    selfLink?: Uri
}

export const AccountSwitcherLinks: React.FC<IAccountSwitcherLinksProps> = (props) => {
    const { relations } = useContext(AccountSwitcherContext)
    const { switchAccount } = useContext(AccountSwitcherActionsContext)
    const history = useHistory()

    const [showPopover, setshowPopover] = useState(false)

    const handleOnMoreClick = useCallback(
        (e: React.MouseEvent<HTMLElement>) => {
            e.stopPropagation()
            setshowPopover(!showPopover)
        },
        [showPopover]
    )

    const handleOnHideEnd = useCallback(() => {
        setshowPopover(false)
    }, [showPopover])

    const navigateToTab = useCallback((e: React.MouseEvent, tabName: string) => {
        e.stopPropagation()
        setshowPopover(false)

        if (props.selfLink) {
            switchAccount(props.selfLink)
            history.push(`/accounts/${encodeParameter(props.selfLink)}/${tabName}`)
            return
        }

        if (relations && "self" in relations) {
            history.push(`/accounts/${encodeParameter(relations.self)}/${tabName}`)
        }
    }, [])

    const handleOnGeneralClick = useCallback((e: React.MouseEvent) => {
        navigateToTab(e, "general-info")
    }, [])

    const handleContractClick = useCallback((e: React.MouseEvent) => {
        navigateToTab(e, "contract")
    }, [])

    const handleMembersClicked = useCallback((e: React.MouseEvent) => {
        navigateToTab(e, "members")
    }, [])

    const handleTransactionRulesClicked = useCallback((e: React.MouseEvent) => {
        navigateToTab(e, "transaction-rules")
    }, [])

    const handleApiKeysClicked = useCallback((e: React.MouseEvent) => {
        navigateToTab(e, "api-keys")
    }, [])

    return (
        <Popover placement="bottom" show={showPopover}>
            <Popover.Reference>
                <Button
                    onClick={handleOnMoreClick}
                    color="secondary"
                    round
                    size="small"
                    tight
                    light
                >
                    <MoreIcon size={24} />
                </Button>
            </Popover.Reference>
            <Popover.Content onHideEnd={handleOnHideEnd}>
                <BoxEdges minWidth={140} p={8}>
                    <List>
                        <List.Item onClick={handleOnGeneralClick}>
                            <BoxEdges px={8} py={4}>
                                {texts.generalInfo}
                            </BoxEdges>
                        </List.Item>
                        <Separator direction="horizontal" />
                        <List.Item onClick={handleContractClick}>
                            <BoxEdges px={8} py={4}>
                                {texts.contract}
                            </BoxEdges>
                        </List.Item>
                        <Separator direction="horizontal" />
                        <List.Item onClick={handleMembersClicked}>
                            <BoxEdges px={8} py={4}>
                                {texts.members}
                            </BoxEdges>
                        </List.Item>
                        <Separator direction="horizontal" />
                        <List.Item onClick={handleTransactionRulesClicked}>
                            <BoxEdges px={8} py={4}>
                                {texts.transactionRules}
                            </BoxEdges>
                        </List.Item>
                        <Separator direction="horizontal" />
                        <List.Item onClick={handleApiKeysClicked}>
                            <BoxEdges px={8} py={4}>
                                {texts.apiKeys}
                            </BoxEdges>
                        </List.Item>
                    </List>
                </BoxEdges>
            </Popover.Content>
        </Popover>
    )
}
