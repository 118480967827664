import { ActionMatchingPattern } from "@redux-saga/types"
import currencies from "currencies.json"
import { Link as KettingLink } from "ketting"

export const ACTION_PREFIX = "@@common/"
export const CATCH_ERROR = "@@common/CATCH_ERROR"
export const CANCEL_ACTION = "@@common/CANCEL_ACTION"
export const FORK_WATCHER = "@@common/FORK_WATCHER"

export type WorkerPattern<P extends string> = (action: ActionMatchingPattern<P>) => any

export interface Link extends KettingLink {
    name?: string
}

export interface LinksItem {
    href: string
}

export type TemplatedLinksItem = LinksItem & {
    templated: boolean
}

export interface ResponsePaginated {
    readonly count: number
    readonly page: number
    readonly per_page: number
}
export interface PaginationRelations {
    first?: Uri
    last?: Uri
    next?: Uri
    prev?: Uri
}

export interface SelfLink {
    self: LinksItem
}

export interface EntityIndex<T> {
    [Uri: string]: T
}

export interface Error {
    title: string
    status: number
    detail?: string
    errors?: ErrorErrors
}

interface ErrorErrors {
    [key: string]: string[]
}

export type ValuesOf<T extends any[]> = T[number]

export type Uri = string

export type Url = string

export type Guid = string

export type ISO4217 = keyof typeof currencies

export type ISO3166_1_alpha2 = string

export type ISO9362 = string

export type ISO13616_1 = string

export type ISO8601DateTime = string
// @ts-ignore - External lib also exports an ISO8601Date
export type ISO8601Date = string

export type ISO639_1 = string
