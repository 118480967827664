import { Avatar, BoxEdges, Button, Grid, Paragraph, Spacer } from "@clearhaus/design-system"
import { MeContext } from "modules/me/Provider/context"
import React, { useContext, useMemo } from "react"
import { LocalizeContext } from "react-localize-redux"

interface IProfileProps {
    onEditClick: () => void
}

export const Profile: React.FC<IProfileProps> = (props) => {
    const { onEditClick } = props
    const { data: me } = useContext(MeContext)
    const { translate } = useContext(LocalizeContext)

    const texts = useMemo(() => {
        return {
            edit: translate("navigation.account_dropdown.profile.edit").toString(),
        }
    }, [])

    if (!me) {
        return null
    }

    return (
        <BoxEdges px={8} pt={6} pb={16}>
            <Grid row alignItems="center" noGutters>
                <Grid item>
                    <Avatar name={me.name ? me.name : me.email} size={13} />
                </Grid>
                <Spacer size={12} />
                <Grid item>
                    <Grid row>
                        <Grid item>
                            <Paragraph lineHeight="s" weight="medium">
                                {me.name}
                            </Paragraph>
                        </Grid>
                    </Grid>
                    <Spacer size={3} />
                    <Grid row>
                        <Grid item>
                            <Paragraph lineHeight="s" size="s" light>
                                {me.email}
                            </Paragraph>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item auto>
                    <BoxEdges ml={128}>
                        <Button size="small" light onClick={onEditClick}>
                            {texts.edit}
                        </Button>
                    </BoxEdges>
                </Grid>
            </Grid>
        </BoxEdges>
    )
}
