import { Loader } from "@clearhaus/design-system"
import React, { useContext, useEffect, useRef, useState } from "react"
import { RouteComponentProps } from "react-router-dom"
import { Subscription } from "rxjs"
import { map, pluck } from "rxjs/operators"
import { useAccounts } from "../../modules/accounts/hooks/useAccounts"
import { useRoot } from "../../modules/root"
import NotFound from "../NotFound"
import {
    AccountSwitcherActionsContext,
    AccountSwitcherContext,
} from "components/AccountSwitcher/Provider/AccountSwitcherContext"
import { useAccountHelpers } from "hooks/useAccountHelpers"

interface IHomeRedirectMatch {
    id: string
}

export const Home: React.FC<RouteComponentProps<IHomeRedirectMatch>> = (props) => {
    const { match } = props

    const { isSwitching } = useContext(AccountSwitcherContext)
    const { switchAccount } = useContext(AccountSwitcherActionsContext)

    const [accountUri, setAccountUri] = useState<string | null>(null)

    const root = useRoot()
    const [notFound, setNotFound] = useState(() => {
        return !root.accounts
    })
    const accountsSub = useRef<Subscription>()
    const accounts = useAccounts(root.accounts, 1, 1, `id:${match.params.id}`)
    const { navigateTo } = useAccountHelpers()

    useEffect(() => {
        accountsSub.current = accounts
            .pipe(
                pluck("relations"),
                map((relations) => {
                    if (!relations) {
                        return null
                    }
                    if (!relations.accounts) {
                        return []
                    }

                    return Array.isArray(relations.accounts)
                        ? relations.accounts
                        : [relations.accounts]
                })
            )
            .subscribe((accountUris) => {
                if (accountUris) {
                    if (accountUris.length === 0) {
                        setNotFound(true)
                    } else {
                        const accountUri = accountUris[0]

                        setAccountUri(accountUri)
                    }
                }
            })

        return () => {
            if (accountsSub.current && !accountsSub.current.closed) {
                accountsSub.current.unsubscribe()
            }
        }
    }, [])

    useEffect(() => {
        if (isSwitching) {
            return
        }

        if (!accountUri) {
            return
        }

        switchAccount(accountUri)
        navigateTo(accountUri, true)
    }, [isSwitching, accountUri])

    return (
        <>
            {!notFound && <Loader size={32} />}
            {notFound && <NotFound />}
        </>
    )
}
