import { MIDGARD_FEEDBACK_API_ROOT } from "modules/common"
import { useMutation } from "react-query"
import { IFeedback, IFeedbackCreate } from "./types"

async function feedbackCreate(feedback: IFeedbackCreate): Promise<IFeedback> {
    if (!MIDGARD_FEEDBACK_API_ROOT) {
        throw Error(`Missing environment variable 'MIDGARD_FEEDBACK_API_ROOT'`)
    }

    const response = await fetch(MIDGARD_FEEDBACK_API_ROOT, {
        method: "POST",
        body: JSON.stringify(feedback),
        headers: {
            "Content-Type": "application/json",
        },
    })

    if (!response.ok) {
        throw new Error("Failed sending feedback to midgard-feedback server")
    }

    return response.json()
}

export const useCreateFeedback = () => {
    return useMutation((updates: IFeedbackCreate) => feedbackCreate(updates))
}
