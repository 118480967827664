import {
    Button,
    Card,
    Grid,
    Heading,
    Label,
    Loader,
    Paragraph,
    RoundedFlagIcon,
    Select,
    SelectOption,
    Separator,
    Spacer,
    ValidationInput,
} from "@clearhaus/design-system"
import { supportedCountries } from "modules/common/helpers"
import { MeContext } from "modules/me/Provider/context"
import React, { useCallback, useContext, useState } from "react"
import { LocalizeContext, Translate } from "react-localize-redux"
import { localizedYup } from "utils"
import { ValidationError } from "yup"

const UserProfileValidationSchema = localizedYup.object().shape({
    name: localizedYup.string().required(),
    phone: localizedYup.string().required(),
})

const getFlagPrefix = (languageCode: string) => {
    let flag = "international"
    if (languageCode === "en") {
        flag = "gb"
    } else {
        const countryCode = supportedCountries.find((country) => country.language === languageCode)
        if (countryCode) {
            if (countryCode.flag) {
                flag = countryCode.flag
            } else {
                flag = countryCode.countryCode
            }
        }
    }

    return <RoundedFlagIcon countryCode={flag} size={16} />
}

export const Welcome: React.FC = () => {
    const { setActiveLanguage, languages, activeLanguage, translate } = useContext(LocalizeContext)
    const { data: me, update, isLoading } = useContext(MeContext)

    const [name, setName] = useState(() => (me && me.name !== me.email ? me.name : ""))
    const [nameHasError, setNameHasError] = useState(false)
    const handleNameOnValid = useCallback(
        (value: string) => {
            if (nameHasError) {
                setNameHasError(false)
            }
            setName(value)
        },
        [nameHasError]
    )
    const handleNameInValid = useCallback(() => {
        if (!nameHasError) {
            setNameHasError(true)
        }
    }, [nameHasError])

    const [phone, setPhone] = useState(() => (me && me.phone ? me.phone : ""))
    const [phoneHasError, setPhoneHasError] = useState(false)
    const handlePhoneOnValid = useCallback(
        (value: string) => {
            if (phoneHasError) {
                setPhoneHasError(false)
            }
            setPhone(value)
        },
        [phoneHasError]
    )
    const handlePhoneInValid = useCallback(() => {
        if (!phoneHasError) {
            setPhoneHasError(true)
        }
    }, [phoneHasError])

    const handleLanguageChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const nextLanguageCode = event.target.value
            if (nextLanguageCode !== activeLanguage.code) {
                setActiveLanguage(nextLanguageCode)
            }
        },
        [activeLanguage]
    )

    const handleContinueClick = useCallback(() => {
        const meUpdate = {
            name,
            phone,
        }

        try {
            UserProfileValidationSchema.validateSync(meUpdate, { abortEarly: false })
            update(meUpdate)
        } catch (e) {
            if (e instanceof ValidationError) {
                const nameError = e.inner.filter((v) => v.path === "name")
                setNameHasError(nameError.length > 0)
                const phoneError = e.inner.filter((v) => v.path === "phone")
                setPhoneHasError(phoneError.length > 0)
            }
        }
    }, [name, phone, nameHasError, phoneHasError])

    return (
        <Grid container containerSize={"sm"}>
            <Grid row direction={"column"}>
                <Grid item>
                    <Card>
                        <Card.Content>
                            <Grid row direction={"column"}>
                                <Grid item auto>
                                    <Heading level={1}>
                                        <Translate id={"welcome.heading"} />
                                    </Heading>
                                    <Paragraph>
                                        <Translate id={"welcome.description"} />
                                    </Paragraph>
                                </Grid>
                                <Grid item auto>
                                    <Spacer size={"medium"} />
                                </Grid>
                                <Grid item auto>
                                    <Label>
                                        <Translate id="welcome.preferred_language" />
                                    </Label>
                                    <Select
                                        value={activeLanguage.code}
                                        onChange={handleLanguageChange}
                                    >
                                        {languages.map((lang) => (
                                            <SelectOption key={lang.code} value={lang.code}>
                                                <Grid
                                                    row
                                                    wrap={"nowrap"}
                                                    alignItems="center"
                                                    noGutters
                                                >
                                                    <Grid item auto>
                                                        {getFlagPrefix(lang.code)}
                                                    </Grid>
                                                    <Grid item auto>
                                                        <Spacer size={12} />
                                                    </Grid>
                                                    <Grid item>
                                                        <Translate
                                                            id={`common.languages.${lang.code}`}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </SelectOption>
                                        ))}
                                    </Select>
                                </Grid>
                                <Grid item auto>
                                    <Spacer size={"medium"} />
                                </Grid>
                                <Grid item auto>
                                    <Label>
                                        <Translate id="welcome.full_name_field.label" />
                                    </Label>
                                    <ValidationInput
                                        validationSchema={UserProfileValidationSchema}
                                        placeholder={translate(
                                            "welcome.full_name_field.placeholder"
                                        ).toString()}
                                        onInvalid={handleNameInValid}
                                        pathToValidation={"name"}
                                        onValid={handleNameOnValid}
                                        validationDelay={1200}
                                        hasError={nameHasError}
                                        defaultValue={name}
                                        instantValidationOnBlur
                                    />
                                </Grid>
                                <Grid item auto>
                                    <Spacer size={32} />
                                </Grid>
                                <Grid item auto>
                                    <Label>
                                        <Translate id="welcome.phone_field.label" />
                                    </Label>
                                    <ValidationInput
                                        validationSchema={UserProfileValidationSchema}
                                        placeholder={translate(
                                            "welcome.phone_field.placeholder"
                                        ).toString()}
                                        onInvalid={handlePhoneInValid}
                                        pathToValidation={"name"}
                                        onValid={handlePhoneOnValid}
                                        validationDelay={1200}
                                        hasError={phoneHasError}
                                        defaultValue={phone}
                                        instantValidationOnBlur
                                    />
                                </Grid>
                                <Grid item>
                                    <Spacer size={24} />
                                </Grid>
                                <Grid item>
                                    <Separator height={1} />
                                </Grid>
                                <Grid item>
                                    <Spacer size={16} />
                                </Grid>
                                <Grid item>
                                    <Grid row noGutters justify={"flex-end"}>
                                        <Grid item auto>
                                            <Button
                                                onClick={handleContinueClick}
                                                disabled={isLoading}
                                                prefix={
                                                    isLoading ? (
                                                        <Loader size={14} fillToParent noBackdrop />
                                                    ) : undefined
                                                }
                                            >
                                                {translate("welcome.continue") as string}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Card.Content>
                    </Card>
                </Grid>
            </Grid>
        </Grid>
    )
}
