import { Problem } from "ketting"
import { IApplicationData, IApplicationRelations } from "modules/applications/types"
import { Uri } from "modules/common/types"
import React from "react"

interface IApplicationContext {
    relations: IApplicationRelations | null
    data: IApplicationData | null
    isLoading: boolean
    isSubmitting: boolean
    error?: Problem
}

interface IContextActions {
    removeStamp: (stampUri: Uri) => void
    update: (data: Partial<IApplicationData>) => void
    submit: () => void
}

export const ApplicationContext = React.createContext<IApplicationContext>({
    data: null,
    isLoading: false,
    isSubmitting: false,
    relations: null,
})

export const ApplicationActionsContext = React.createContext<IContextActions>({
    removeStamp: () => {
        throw Error(`No 'ApplicationProvider' found`)
    },
    update: () => {
        throw Error(`No 'ApplicationProvider' found`)
    },
    submit: () => {
        throw Error(`No 'ApplicationProvider' found`)
    },
})
