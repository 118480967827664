import {
    IServiceMessagesProviderState,
    IServiceMessagesProviderStateRoot,
} from "components/ServiceMessages/Provider/reducer"
import { shallowEqual } from "fast-equals"
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { initializeProvider, teardownProvider } from "./sagas"
import { ServiceMessagesDataContext } from "./ServiceMessagesDataContext"

interface IServiceMessagesDataProviderProps {
    children: React.ReactNode
}

let activeProviders: number = 0
export const ServiceMessagesDataProvider: React.FC<IServiceMessagesDataProviderProps> = (props) => {
    const serviceMessages = useSelector<
        IServiceMessagesProviderStateRoot,
        IServiceMessagesProviderState
    >((s) => {
        return s.serviceMessages
    }, shallowEqual)

    const dispatch = useDispatch()

    useEffect(() => {
        if (activeProviders === 0) {
            dispatch(initializeProvider())
        }
        activeProviders++

        return () => {
            activeProviders--
            if (activeProviders === 0) {
                dispatch(teardownProvider())
            }
        }
    }, [])

    if (!serviceMessages) {
        return null
    }

    return (
        <ServiceMessagesDataContext.Provider value={serviceMessages}>
            {props.children}
        </ServiceMessagesDataContext.Provider>
    )
}
