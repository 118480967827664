import { Problem } from "ketting"
import { meRootSaga } from "modules/me/sagas"
import {
    GET_ME,
    GET_ME_ERROR,
    GET_ME_OK,
    Me,
    UpdateMe,
    UPDATE_ME,
    UPDATE_ME_OK,
} from "modules/me/types"
import { createCustomAction } from "typesafe-actions"
import { SagaRegistry } from "utils/SagaRegistry"

export const getMe = createCustomAction(GET_ME)
export const getMeOk = createCustomAction(GET_ME_OK, (data: Me) => ({ payload: { data } }))

export const updateMe = createCustomAction(UPDATE_ME, (data: UpdateMe) => ({ payload: { data } }))
export const updateMeOk = createCustomAction(UPDATE_ME_OK, (data: Me, updateData: UpdateMe) => ({
    payload: { data },
    meta: { data: updateData },
}))

export const getMeError = createCustomAction(GET_ME_ERROR, (e: Problem) => ({
    payload: e,
}))

SagaRegistry.register(meRootSaga)
