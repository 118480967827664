import { MerchantApiRequest, MERCHANT_API_ROOT } from "modules/common"
import { Uri } from "modules/common/types"
import {
    ISubscriptionData,
    ISubscriptionRelations,
    ISubscriptionRootData,
    ISubscriptionRootRelations,
} from "modules/subscription/types"

export async function fetchSubscriptionRoot(token: string) {
    if (!MERCHANT_API_ROOT) {
        throw new Error(`Cannot fetch merchant API root, as it's not defined.`)
    }

    try {
        const request = new MerchantApiRequest<ISubscriptionRootData, ISubscriptionRootRelations>(
            MERCHANT_API_ROOT,
            {
                token,
                noCache: true,
            }
        )
        const { repr, relations } = await request.get()

        return { repr, relations }
    } catch (error) {
        throw new Error(`Cannot fetch subscription root: ${error}`)
    }
}

export async function fetchSubscription(uri: Uri, token: string) {
    try {
        const request = new MerchantApiRequest<ISubscriptionData, ISubscriptionRelations>(uri, {
            token,
            noCache: true,
        })
        const { repr, relations } = await request.get()

        return { repr, relations }
    } catch (error) {
        throw new Error(`Cannot fetch subscription: ${error}`)
    }
}

export async function stampSubscription(uri: Uri, token: string) {
    try {
        const request = new MerchantApiRequest(uri, { token })
        await request.put({})
    } catch (error) {
        throw new Error(`Cannot stamp subscription: ${error}`)
    }
}
