import { Badge, BoxEdges, Grid, ThemeContext } from "@clearhaus/design-system"
import { ColorNames } from "@clearhaus/design-system/defaults"
import { makeStyles } from "@clearhaus/design-system/utils/styles/makeStyles"
import React, { useContext } from "react"
import { styles } from "../styles"

interface IIconProps {
    icon?: JSX.Element
    light?: boolean
    active?: boolean
    margin?: "none" | "s" | "l"
    badgeColor?: ColorNames
}

const useStyles = makeStyles(styles)
export const Icon: React.FC<IIconProps> = (props) => {
    const { icon, light, active, margin, badgeColor } = props

    const classes = useStyles()
    const { defaults } = useContext(ThemeContext)

    const Icon = icon
        ? React.cloneElement(icon, {
              color: active
                  ? defaults.colors.primary[500]
                  : light
                  ? defaults.colors.neutrals[100]
                  : defaults.colors.neutrals[800],
              size: icon.props.size ? icon.props.size : 14,
          })
        : null

    const mr = margin === "l" ? 16 : margin === "s" ? 8 : 0

    if (!Icon) {
        return null
    }

    return (
        <Grid item auto>
            <BoxEdges className={classes.icon} mr={mr}>
                {badgeColor ? (
                    <Badge color={badgeColor} overlap="circular">
                        {Icon}
                    </Badge>
                ) : (
                    <>{Icon}</>
                )}
            </BoxEdges>
        </Grid>
    )
}
