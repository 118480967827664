import Ketting, { bearerAuth } from "ketting"
import Resource from "ketting/dist/resource"
import { binaryStateFactory, htmlStateFactory } from "ketting/dist/state"
import Auth0 from "modules/authentication/Auth0"
import { MERCHANT_API_ROOT } from "modules/common"
import { Uri } from "modules/common/types"
import { HALCache } from "./ketting/HALCache"
import { factory as halStateFactory } from "./ketting/halState"

export class HALClient {
    public static resourceCache: Map<string, Resource>
    private static _token: string | undefined

    public static go(uri?: Uri): Resource {
        return HALClient.getKettingClient.go(uri)
    }

    public static getResource(uri: Uri): Resource | undefined {
        return HALClient.getKettingClient.resources.get(uri)
    }

    public static setToken(token: string) {
        this._token = token
    }

    private static instance: Ketting

    private static get getKettingClient(): Ketting {
        if (!HALClient.instance) {
            HALClient.instance = new Ketting(`${MERCHANT_API_ROOT}`)
            HALClient.instance.cache = new HALCache()
            HALClient.instance.use(
                bearerAuth(this._token ? this._token : Auth0.instance.getAccessToken())
            )
            HALClient.instance.fetcher.advertiseKetting = false
            HALClient.resourceCache = HALClient.instance.resources
            HALClient.setContentTypes()
        }

        return HALClient.instance
    }
    private static setContentTypes() {
        HALClient.instance.contentTypeMap = {
            "application/json": [halStateFactory, "1.0"],
            "application/hal+json": [halStateFactory, "0.8"],
            "text/html": [htmlStateFactory, "0.7"],
            "application/pdf": [binaryStateFactory, "0.5"],
        }
    }
}
