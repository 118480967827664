import { shallowEqual } from "fast-equals"
import { BehaviorSubject, Observable } from "rxjs"
import { shareReplay } from "rxjs/operators"
import URLParse from "url-parse"

export interface IStoreItemValue<D = object, R = object> {
    data: D
    relations: R
}

interface IStoreItem<D = object, R = object> {
    value: Observable<IStoreItemValue<D, R> | null>
    subject: BehaviorSubject<IStoreItemValue<D, R> | null>
    key: string
    metadata: object
}

class RxStore {
    static instance: RxStore = new RxStore()

    private _store: { [key: string]: IStoreItem } = {}

    public set<D extends object, R extends object>(
        key: string,
        metadata: object = {},
        data?: IStoreItemValue<D, R>
    ) {
        // Clean metadata
        Object.keys(metadata).forEach(
            (prop: keyof typeof metadata) => metadata[prop] === undefined && delete metadata[prop]
        )

        const storeKey = RxStore.createKey(key, metadata)
        // Update store metadata if found
        if (!this._store[storeKey]) {
            const newItem = {
                subject: new BehaviorSubject<any>(data ? data : null),
                key,
                metadata: {
                    ...metadata,
                },
            }
            Object.assign(this._store, {
                [storeKey]: {
                    ...newItem,
                    value: newItem.subject.pipe(shareReplay(1)),
                },
            })
        } else if (data) {
            this._store[storeKey].subject.next(data)
        }
    }

    public get<D = object, R = object>(key: string, metadata: object = {}) {
        // Clean metadata
        Object.keys(metadata).forEach(
            (prop: keyof typeof metadata) => metadata[prop] === undefined && delete metadata[prop]
        )

        const storeKey = RxStore.createKey(key, metadata)
        // If not found in store, create an empty one
        if (!this._store[storeKey]) {
            const newItem = {
                subject: new BehaviorSubject<any>(null),
                key,
                metadata: {
                    ...metadata,
                },
            }
            Object.assign(this._store, {
                [storeKey]: {
                    ...newItem,
                    value: newItem.subject.pipe(shareReplay(1)),
                },
            })

            return (this._store[storeKey].value as unknown) as Observable<IStoreItemValue<D, R>>
        } else {
            const storeItem = this._store[storeKey]
            const metadataLength = Object.keys(metadata).length
            if (metadataLength === 0) {
                return (storeItem.value as unknown) as Observable<IStoreItemValue<D, R>>
            } else if (shallowEqual(storeItem.metadata, metadata)) {
                return (storeItem.value as unknown) as Observable<IStoreItemValue<D, R>>
            }

            throw Error(`'${key}' with metadata '${JSON.stringify(metadata)}' not found in RxStore`)
        }
    }

    private static createKey(key: string, metadata: object = {}) {
        const metadataKeys = Object.keys(metadata).sort() as Array<keyof typeof metadata>
        const result = new URLParse(key, true)
        for (const mKey of metadataKeys) {
            result.set("query", {
                ...result.query,
                [mKey]: metadata[mKey],
            })
        }

        return result.toString()
    }
}

const instance = RxStore.instance
export { instance as RxStore }
