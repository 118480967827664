import { shallowEqual } from "fast-equals"
import { Problem } from "ketting"
import {
    IAccountProviderRootState,
    IAccountProviderState,
} from "modules/accounts/Providers/Account/reducer"
import {
    accountProviderFetch,
    accountProviderInitialize,
    accountProviderTeardown,
} from "modules/accounts/Providers/Account/sagas"
import { IAccountData, IAccountRelations } from "modules/accounts/types"
import { Uri } from "modules/common/types"
import React, { useEffect, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"

interface IAccountContext {
    relations?: IAccountRelations
    data: IAccountData | null
    isLoading: boolean
    error?: Problem
}
export const AccountContext = React.createContext<IAccountContext>({
    data: null,
    isLoading: false,
})

interface IAccountActionsContext {
    refresh: () => void
}

export const AccountActionsContext = React.createContext<IAccountActionsContext>({
    refresh: () => {
        throw Error(`Not overridden`)
    },
})

interface IAccountProviderProps {
    accountUri: Uri
    noCache?: boolean
    children: React.ReactNode
}

const activeAccountProviders: { [key: string]: number } = {}

export const AccountProvider: React.FC<IAccountProviderProps> = (props) => {
    const { accountUri, noCache } = props

    const accountProviderState = useSelector<
        IAccountProviderRootState,
        IAccountProviderState | null
    >((s) => {
        if (s.accountProvider && s.accountProvider[accountUri]) {
            return s.accountProvider[accountUri]
        }

        return null
    }, shallowEqual)
    const dispatch = useDispatch()

    const accountProviderContextValue = useMemo(() => {
        if (accountProviderState) {
            const { data, relations, error, isLoading } = accountProviderState
            return {
                data,
                error,
                isLoading,
                relations,
            }
        } else {
            return null
        }
    }, [accountProviderState])

    const accountActionsContextValue = useMemo(() => {
        function refresh() {
            dispatch(accountProviderFetch(accountUri, true))
        }

        return {
            refresh,
        }
    }, [accountUri])

    useEffect(() => {
        if (activeAccountProviders[accountUri]) {
            activeAccountProviders[accountUri]++
        } else {
            activeAccountProviders[accountUri] = 1
        }

        if (activeAccountProviders[accountUri] === 1) {
            dispatch(accountProviderInitialize(accountUri, noCache))
        }

        if (noCache && activeAccountProviders[accountUri] > 1) {
            dispatch(accountProviderFetch(accountUri, true))
        }

        return () => {
            activeAccountProviders[accountUri]--
            if (activeAccountProviders[accountUri] === 0) {
                dispatch(accountProviderTeardown(accountUri))
            }
        }
    }, [accountUri])

    if (accountProviderState === null || accountProviderContextValue === null) {
        return null
    }

    return (
        <AccountActionsContext.Provider value={accountActionsContextValue}>
            <AccountContext.Provider value={accountProviderContextValue}>
                {props.children}
            </AccountContext.Provider>
        </AccountActionsContext.Provider>
    )
}
