import { SettingsIcon } from "@clearhaus/design-system"
import { LoginTooltip } from "apps/ConfirmSubscription/components"
import { NavigationItem } from "components/common/NavigationItem"
import React from "react"
import { Translate } from "react-localize-redux"

const text = <Translate id={"subscription_confirmation.settings"} />
const Icon = <SettingsIcon />

export const SettingsNavigationItem: React.FC = () => {
    const handleOnClick = () => {
        if (!process.env.AUTH_REDIRECT_URI) {
            return
        }

        window.location.href = `${process.env.AUTH_REDIRECT_URI}/subscriptions`
    }

    return (
        <LoginTooltip>
            <NavigationItem text={text} onClick={handleOnClick} icon={Icon} />
        </LoginTooltip>
    )
}
