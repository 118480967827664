import { defaults as orgDefaults } from "@clearhaus/design-system"

export const styles = (defaults: typeof orgDefaults) => ({
    container: {
        cursor: "pointer",
        padding: `20px ${defaults.spacing[12]}px`,

        "&$paddingYSmall": {
            paddingTop: defaults.spacing[4],
            paddingBottom: defaults.spacing[4],
        },

        "&$paddingYLarge": {
            paddingTop: 20,
            paddingBottom: 20,
        },

        "&$noPaddingLeft": {
            paddingLeft: 0,
        },

        "&$noPaddingRight": {
            paddingRight: 0,
        },

        "&:hover": {
            "& $text": {
                color: defaults.colors.primary[500],
            },

            "& $chevron svg": {
                color: defaults.colors.primary[500],
            },

            "& $icon svg": {
                color: defaults.colors.primary[500],
            },
        },
    },

    active: {
        boxShadow: `inset 0 -3px 0 0 ${defaults.colors.primary[500]}`,

        "& $text": {
            color: defaults.colors.primary[500],
        },

        "&$vertical": {
            boxShadow: `inset 3px 0 0 0 ${defaults.colors.primary[500]}`,
        },

        "&$horizontal": {
            boxShadow: `inset 0 -3px 0 0 ${defaults.colors.primary[500]}`,
        },
    },

    text: {
        fontSize: defaults.fontSizes[14],
        color: defaults.colors.neutrals[800],
    },

    chevron: {},

    noPaddingLeft: {},

    noPaddingRight: {},

    vertical: {
        paddingLeft: defaults.spacing[16],
    },

    horizontal: {},

    paddingYSmall: {},

    paddingYLarge: {},

    icon: {},
})
