import { IAccountData, IAccountRelations } from "modules/accounts/types"
import { Uri } from "modules/common/types"
import { IRootData } from "modules/root"
import React from "react"

interface IAccountSwitcherContext {
    relations: IAccountRelations | IRootData | null
    data: IAccountData | null
    isLoading: boolean
    isSwitching: boolean
}

interface IAccountSwitcherActionsContext {
    switchAccount: (uri?: Uri) => void
}

export const AccountSwitcherContext = React.createContext<IAccountSwitcherContext>({
    relations: null,
    data: null,
    isLoading: false,
    isSwitching: false,
})

export const AccountSwitcherActionsContext = React.createContext<IAccountSwitcherActionsContext>({
    switchAccount: () => {
        throw Error(`No 'AccountSwitcherProvider' found`)
    },
})
