import {
    deletePersonsFromApplication,
    deletePersonsFromApplicationOk,
} from "flows/deletePersonsFromApplication"
import { updateApplication, updateApplicationOk } from "modules/applications/actions"
import { IApplication } from "modules/applications/types"
import { updateBankAccount, updateBankAccountOk } from "modules/bankAccounts/actions"
import { updateContact, updateContactOk } from "modules/contacts/actions"
import { getMe, getMeOk } from "modules/me/actions"
import { fork, put, take, takeLatest } from "redux-saga/effects"
import { ActionType, createCustomAction, getType } from "typesafe-actions"
import { getCurrencyBasedOnBrowserLanguage } from "utils/currencyHelpers"

export const PREFILL_APPLICATION_DATA = "@@flows/PREFILL_APPLICATION_DATA"
export const PREFILL_APPLICATION_DATA_OK = "@@flows/PREFILL_APPLICATION_DATA_OK"

export const prefillApplicationData = createCustomAction(
    PREFILL_APPLICATION_DATA,
    (application: IApplication) => ({ payload: application })
)
export const prefillApplicationDataOk = createCustomAction(PREFILL_APPLICATION_DATA_OK)

function* handleprefillApplicationData(action: ActionType<typeof prefillApplicationData>) {
    const application = action.payload

    yield put(deletePersonsFromApplication(application))
    yield take(getType(deletePersonsFromApplicationOk))

    yield put(getMe())
    const { payload: meOkPayload }: ActionType<typeof getMeOk> = yield take(getType(getMeOk))
    const { data: me } = meOkPayload
    const presetCurrency = getCurrencyBasedOnBrowserLanguage()

    yield put(
        updateApplication(
            {
                business_model: {
                    estimate_currency: presetCurrency,
                    estimated_average_transaction_amount: "1.0",
                },
                signer: {
                    name: me.name,
                    email: me.email,
                },
            },
            application.relations.self
        )
    )
    yield take(getType(updateApplicationOk))

    yield put(
        updateContact(
            {
                email: me.email,
                name: me.name,
                phone: me.phone,
            },
            application.relations.contact
        )
    )
    yield take(getType(updateContactOk))

    yield put(
        updateBankAccount(
            {
                currency: presetCurrency,
            },
            application.relations["bank-account"]
        )
    )
    yield take(getType(updateBankAccountOk))

    yield put(prefillApplicationDataOk())
}

function* watchPrefillApplicationData() {
    yield takeLatest(getType(prefillApplicationData), handleprefillApplicationData)
}

export default function* prefillApplicationDataSaga() {
    yield fork(watchPrefillApplicationData)
}
