import { Problem } from "ketting"
import {
    accountProviderFetch,
    accountProviderFetchError,
    accountProviderFetchOk,
    accountProviderInitialize,
    accountProviderTeardown,
} from "modules/accounts/Providers/Account/sagas"
import { IAccountData, IAccountRelations } from "modules/accounts/types"
import { EntityIndex } from "modules/common/types"
import { Reducer } from "redux"
import { ActionType, getType } from "typesafe-actions"

export interface IAccountProviderState {
    data: IAccountData | null
    relations?: IAccountRelations
    isLoading: boolean
    error?: Problem
}

export interface IAccountProviderRootState {
    accountProvider: EntityIndex<IAccountProviderState>
}

type ActionTypes = ActionType<
    | typeof accountProviderInitialize
    | typeof accountProviderTeardown
    | typeof accountProviderFetch
    | typeof accountProviderFetchOk
    | typeof accountProviderFetchError
>

export const accountProviderReducer: Reducer<EntityIndex<IAccountProviderState>, ActionTypes> = (
    currentState,
    action
) => {
    let nextState
    if (!currentState) {
        currentState = {}
    }

    nextState = currentState

    switch (action.type) {
        case getType(accountProviderInitialize): {
            const { accountUri } = action.payload
            nextState = {
                ...nextState,
                [accountUri]: {
                    data: null,
                    isLoading: false,
                },
            }
            break
        }
        case getType(accountProviderTeardown): {
            const { accountUri } = action.payload
            delete nextState[`${accountUri}`]
            break
        }
        case getType(accountProviderFetch): {
            const { accountUri } = action.payload
            nextState = {
                ...nextState,
                [accountUri]: {
                    ...nextState[accountUri],
                    isLoading: true,
                },
            }
            break
        }
        case getType(accountProviderFetchOk): {
            const { accountData, accountRelations } = action.payload
            const { accountUri } = action.meta
            nextState = {
                ...nextState,
                [accountUri]: {
                    data: accountData,
                    isLoading: false,
                    relations: accountRelations,
                },
            }
            break
        }
        case getType(accountProviderFetchError): {
            const { accountUri } = action.meta
            nextState = {
                ...nextState,
                [accountUri]: {
                    ...nextState[accountUri],
                    error: action.payload,
                    isLoading: false,
                },
            }
            break
        }
    }

    return nextState
}
