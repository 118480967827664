import { Grid } from "@clearhaus/design-system"
import { makeStyles } from "@clearhaus/design-system/utils/styles/makeStyles"
import React, { createContext, ReactNode } from "react"
import { Slide } from "./Slide"
import { styles } from "./styles"

interface IContext {
    activeIndex: number
}

const initial = { activeIndex: 1 }

export const SlidesContext = createContext<IContext>(initial)

interface ISlidesProps {
    children: ReactNode
    activeIndex: number
}

const useStyles = makeStyles(styles)
const Slides = (props: ISlidesProps) => {
    const { children, activeIndex } = props
    const value = { activeIndex }
    const classes = useStyles()

    return (
        <SlidesContext.Provider value={value}>
            <Grid row>
                <Grid item>
                    <div className={classes.slides}>{children}</div>
                </Grid>
            </Grid>
        </SlidesContext.Provider>
    )
}

Slides.Slide = Slide

export { Slides }
