import { Problem } from "ketting"
import { EntityIndex, ISO13616_1, ISO4217, ISO9362, Uri } from "modules/common/types"

export const ACTION_PREFIX = "@@bank_accounts"
export const FETCH_BANK_ACCOUNT = "@@bank_accounts/FETCH_BANK_ACCOUNT"
export const FETCH_BANK_ACCOUNT_OK = "@@bank_accounts/FETCH_BANK_ACCOUNT_OK"
export const FETCH_BANK_ACCOUNT_ERROR = "@@bank_accounts/FETCH_BANK_ACCOUNT_ERROR"
export const UPDATE_BANK_ACCOUNT = "@@bank_accounts/UPDATE_BANK_ACCOUNT"
export const UPDATE_BANK_ACCOUNT_OK = "@@bank_accounts/UPDATE_BANK_ACCOUNT_OK"
export const UPDATE_BANK_ACCOUNT_ERROR = "@@bank_accounts/UPDATE_BANK_ACCOUNT_ERROR"

export interface IBankAccountData {
    bank?: string
    currency?: ISO4217
    iban?: ISO13616_1
    swift_code?: ISO9362
}

export interface IBankAccount extends IBankAccountData {
    relations: IBankAccountRelations
}

export interface IBankAccountRelations {
    application: Uri
    comments: Uri
    self: Uri
}

export interface BankAccountsState {
    atUri?: EntityIndex<BankAccount>
    errors?: Problem[] | null
}

export interface BankAccount extends IBankAccount {}
