import { LoadingScreen } from "@clearhaus/design-system"
import { LocalizationLoader } from "components/common"
import { useInitializeLanguage } from "components/localization/hooks"
import { localizationNamespaces } from "components/localization/localizationNamespaces"
import React from "react"

interface LanguageLocalizationLoaderProps {
    children: React.ReactNode
}

export const LanguageLocalizationLoader: React.FC<LanguageLocalizationLoaderProps> = (props) => {
    const { children } = props
    const { isLoading } = useInitializeLanguage()

    if (isLoading) {
        return <LoadingScreen />
    }

    return <LocalizationLoader namespace={localizationNamespaces}>{children}</LocalizationLoader>
}
