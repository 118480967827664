import { useHistory } from "react-router"
import { encodeParameter } from "../modules/common/helpers"
import { Uri } from "../modules/common/types"
import useCallbackRef from "../utils/useCallbackRef"

export const useAccountHelpers = () => {
    const history = useHistory()

    const navigateToContract = useCallbackRef((accountUri: Uri, replace?: boolean) => {
        const encodedUri = encodeParameter(accountUri)
        const accountContractPath = `/accounts/${encodedUri}/contract`

        if (replace) {
            history.replace(accountContractPath)
        } else {
            history.push(accountContractPath)
        }
    })

    const navigateTo = useCallbackRef((accountUri: Uri, replace?: boolean) => {
        const encodedUri = encodeParameter(accountUri)
        const accountPath = `/accounts/${encodedUri}`

        if (replace) {
            history.replace(accountPath)
        } else {
            history.push(accountPath)
        }
    })

    return {
        navigateToContract,
        navigateTo,
    }
}
