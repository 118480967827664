import { call, put, race, take } from "@redux-saga/core/effects"
import heic2any from "heic2any"
import { Problem } from "ketting"
import { MerchantApiRequest, MerchantApiResponse } from "modules/common"
import { catchError, forkWatcher } from "modules/common/actions"
import { createUploadFileChannel } from "modules/files/channels"
import { getMimetype } from "modules/files/helpers"
import {
    IFile,
    IFileData,
    IFileDownloadData,
    IFileRelations,
    IFilesData,
    IFilesRelations,
} from "modules/files/types"
import { FileUploadPreparing } from "modules/insights/Events/FileUploadPreparing"
import { EventChannel } from "redux-saga"
import { ActionType, getType, isActionOf } from "typesafe-actions"
import {
    batchFetchFile,
    batchFetchFileOk,
    convertHeicFile,
    convertHeicFileOk,
    createFile,
    createFileError,
    createFileOk,
    deleteFile,
    deleteFileOk,
    downloadFile,
    downloadFileFailed,
    downloadFileOk,
    fetchFile,
    fetchFileError,
    fetchFileOk,
    fetchFiles,
    fetchFilesOk,
    newFileFlow,
    prepareFile,
    prepareFileOk,
    replaceFile,
    replaceFileError,
    replaceFileOk,
    uploadFile,
    uploadFileFailed,
    uploadFileOk,
    uploadFileProgress,
} from "./actions"

export function* handleFetchFiles(action: ActionType<typeof fetchFiles>) {
    const { uri: filesUri } = action.payload
    try {
        const merchantApiRequest = new MerchantApiRequest(filesUri, {
            noCache: true,
        })
        const { repr, relations }: MerchantApiResponse<IFilesData, IFilesRelations> = yield call([
            merchantApiRequest,
            merchantApiRequest.get,
        ])

        yield put(fetchFilesOk(repr, relations, filesUri))
    } catch (e) {
        yield put(catchError(e))
    }
}

export function* handleCreateFile(action: ActionType<typeof createFile>) {
    const { uri: filesUri, data } = action.payload

    try {
        const merchantApiRequest = new MerchantApiRequest(filesUri, {
            contentType: "application/json",
            noCache: true,
        })
        const { repr, relations }: MerchantApiResponse<IFileData, IFileRelations> = yield call(
            [merchantApiRequest, merchantApiRequest.post],
            data
        )

        yield put(createFileOk(repr, relations, data, filesUri))
    } catch (e) {
        if (e instanceof Problem) {
            yield put(createFileError(e, data, filesUri))
        }
        yield put(catchError(e))
    }
}

export function* handleFetchFile(action: ActionType<typeof fetchFile>) {
    const { uri } = action.payload
    try {
        const merchantApiRequest = new MerchantApiRequest(uri)
        const { relations, repr }: MerchantApiResponse<IFileData, IFileRelations> = yield call([
            merchantApiRequest,
            merchantApiRequest.get,
        ])
        yield put(fetchFileOk(repr, relations, uri))
    } catch (e) {
        if (e instanceof Problem) {
            yield put(fetchFileError(e, uri))
        }
        yield put(catchError(e))
    }
}

export function* handleDeleteFile(action: ActionType<typeof deleteFile>) {
    const { uri: fileUri } = action.payload

    try {
        const merchantApiRequest = new MerchantApiRequest(fileUri)
        yield call([merchantApiRequest, merchantApiRequest.delete])

        yield put(deleteFileOk(fileUri))
    } catch (e) {
        yield put(catchError(e))
    }
}

export function* handleUploadFile(action: ActionType<typeof uploadFile>) {
    const { file, uri: fileUri } = action.payload

    try {
        const merchantApiRequest = new MerchantApiRequest(fileUri)
        const { relations }: MerchantApiResponse<IFileData, IFileRelations> = yield call([
            merchantApiRequest,
            merchantApiRequest.get,
        ])
        const channel: EventChannel<{
            progress: number
            error: string
            sucess: boolean
            timeout: boolean
            // @ts-ignore
        }> = yield call(createUploadFileChannel, relations.data.upload, file)
        while (true) {
            const { progress = 0, error, success } = yield take(channel)
            if (error) {
                yield put(uploadFileFailed(error, fileUri, file))
                yield put(catchError(error))
                break
            }

            if (success) {
                yield put(uploadFileOk(fileUri, file))
                break
            }

            yield put(uploadFileProgress(progress, fileUri))
        }
    } catch (e) {
        yield put(uploadFileFailed(e, fileUri, file))
        yield put(catchError(e))
    }
}

// @ts-ignore
export function* handleNewFileFlow(action: ActionType<typeof newFileFlow>) {
    const { uri: filesUri, label } = action.payload
    let file = action.payload.file
    const fileName = file.name
    const size = file.size
    let contentType: string | false = yield getMimetype(file)
    if (!contentType && file.type) {
        contentType = file.type
    }

    // Check if we need to convert it
    if (
        contentType &&
        (contentType.toLowerCase() === "image/heic" || contentType.toLowerCase() === "image/heif")
    ) {
        // iOS / OS X default format, convert it to jpeg
        yield put(convertHeicFile(file))
        const { payload: convertPayload }: ActionType<typeof convertHeicFileOk> = yield take(
            (a: ActionType<typeof convertHeicFileOk>) => {
                return isActionOf(convertHeicFileOk, a) && a.meta.file === file
            }
        )

        file = convertPayload.convertedFile
    }

    const newFileData = {
        label,
        name: fileName,
        size,
        content_type: contentType as string,
    }

    yield put(createFile(newFileData, filesUri))
    const fileCreated: ActionType<typeof createFileOk> = yield take(
        (a: ActionType<typeof createFileOk>) => {
            return (
                isActionOf(createFileOk, a) &&
                a.payload.data.size === size &&
                a.payload.data.name === fileName
            )
        }
    )

    const [, error] = yield race([
        call(handleUploadFile, uploadFile(fileCreated.meta.uri, file)),
        take(getType(uploadFileFailed)),
    ])

    if (error) {
        yield put(deleteFile(fileCreated.meta.uri))
        yield put(catchError(error))
    } else {
        yield take(getType(uploadFileOk))
    }
}

// @ts-ignore
export function* handleReplaceFile(action: ActionType<typeof replaceFile>) {
    const { file, uri: fileUri } = action.payload

    try {
        const merchantApiRequest = new MerchantApiRequest<IFileData>(fileUri)
        let contentType: string | false = yield getMimetype(file)
        if (!contentType && file.type) {
            contentType = file.type
        }
        yield merchantApiRequest.patch({
            content_type: contentType,
            name: file.name,
            size: file.size,
        })

        yield put(fetchFile(fileUri))
        const fileToReplaceAction: ActionType<typeof fetchFileOk> = yield take(
            (a: ActionType<typeof fetchFileOk>) => {
                return isActionOf(fetchFileOk, a) && a.meta.uri === fileUri
            }
        )

        yield put(uploadFile(fileToReplaceAction.meta.uri, file))
        const [success, error] = yield race([
            take((a: ActionType<typeof uploadFileOk>) => {
                return isActionOf(uploadFileOk, a) && a.meta.uri === fileUri
            }),
            take((a: ActionType<typeof uploadFileFailed>) => {
                return isActionOf(uploadFileFailed, a) && a.meta.uri === fileUri
            }),
        ])

        if (error) {
            yield put(replaceFileError(error, file, fileUri))
            yield put(catchError(error))
            yield put(deleteFile(fileToReplaceAction.meta.uri))
        } else if (success) {
            yield put(replaceFileOk(file, fileUri))
        }
    } catch (e) {
        yield put(catchError(e))
    }
}

export function* handleDownloadFile(action: ActionType<typeof downloadFile>) {
    const { uri: fileUri } = action.payload

    try {
        yield put(fetchFile(fileUri))
        const { payload: fileData }: ActionType<typeof fetchFileOk> = yield take(
            (a: ActionType<typeof fetchFileOk>) => {
                return isActionOf(fetchFileOk, a) && a.meta.uri === fileUri
            }
        )

        const downloadUri = fileData.relations.data.download
        if (!downloadUri) {
            // noinspection ExceptionCaughtLocallyJS
            throw Error(`No download uri found`)
        }

        // Get real download link
        const downloadMerchantApiRequest = new MerchantApiRequest<IFileDownloadData>(downloadUri, {
            noCache: true,
        })
        const { repr: downloadDto }: MerchantApiResponse<IFileDownloadData> =
            yield downloadMerchantApiRequest.get()

        // Just a plain request, no authentication needed.
        const rawFileRequest = new Request(downloadDto.download)
        const rawFileResponse: Response = yield fetch(rawFileRequest)
        const blob: Blob = yield rawFileResponse.blob()
        const objectURL = URL.createObjectURL(blob)

        yield put(downloadFileOk(objectURL, fileData.data, fileUri))
    } catch (e) {
        yield put(downloadFileFailed(e, fileUri))
        yield put(catchError(e))
    }
}

export function* handleBatchFetchFile(action: ActionType<typeof batchFetchFile>) {
    const { uris } = action.payload
    const files: IFile[] = []

    try {
        for (const uri of uris) {
            const merchantApiRequest = new MerchantApiRequest(uri)
            const { repr, relations }: MerchantApiResponse<IFileData, IFileRelations> = yield call([
                merchantApiRequest,
                merchantApiRequest.get,
            ])

            files.push({
                ...repr,
                relations,
            })
        }

        yield put(batchFetchFileOk(files, uris))
    } catch (e) {
        yield put(catchError(e))
    }
}

export function* handleConvertHeicFile(action: ActionType<typeof convertHeicFile>) {
    const { file } = action.payload

    const jpegBlob: Blob = yield heic2any({
        blob: file,
        quality: 95,
    })
    const newFileName = file.name.toLowerCase().replace(/\.heic|\.heif/gi, ".jpg")
    const convertedFile = new File([jpegBlob], newFileName, {
        type: "image/jpeg",
    })

    yield put(convertHeicFileOk(convertedFile, file))
}

export function* handlePrepareFile(action: ActionType<typeof prepareFile>) {
    const { file, createData } = action.payload
    let resultingFile: File

    // Determine file type
    const fileType: string | false = yield getMimetype(file)
    if (
        fileType &&
        (fileType.toLowerCase() === "image/heic" || fileType.toLowerCase() === "image/heif")
    ) {
        FileUploadPreparing.log({
            label: createData.label || "unknown",
            size: file.size,
            file_type: fileType,
            conversion: true,
        })
        // iOS / OS X default format, convert it to jpeg
        yield put(convertHeicFile(file))
        const { payload: convertPayload }: ActionType<typeof convertHeicFileOk> = yield take(
            (a: ActionType<typeof convertHeicFileOk>) => {
                return isActionOf(convertHeicFileOk, a) && a.meta.file === file
            }
        )

        resultingFile = convertPayload.convertedFile
    } else {
        FileUploadPreparing.log({
            label: createData.label || "unknown",
            size: file.size,
            file_type: file.type,
            conversion: false,
        })
        resultingFile = file
    }

    yield put(prepareFileOk(resultingFile, file, createData))
}

export default function* filesRootSaga() {
    yield put(forkWatcher(getType(newFileFlow), handleNewFileFlow))
    yield put(forkWatcher(getType(replaceFile), handleReplaceFile))
    yield put(forkWatcher(getType(createFile), handleCreateFile))
    yield put(forkWatcher(getType(uploadFile), handleUploadFile))
    yield put(forkWatcher(getType(fetchFile), handleFetchFile))
    yield put(forkWatcher(getType(fetchFiles), handleFetchFiles))
    yield put(forkWatcher(getType(deleteFile), handleDeleteFile))
    yield put(forkWatcher(getType(downloadFile), handleDownloadFile))
    yield put(forkWatcher(getType(batchFetchFile), handleBatchFetchFile))
    yield put(forkWatcher(getType(prepareFile), handlePrepareFile))
    yield put(forkWatcher(getType(convertHeicFile), handleConvertHeicFile))
}
