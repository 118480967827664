import { BoxEdges, Loader, Popover, PortalContainer } from "@clearhaus/design-system"
import { AccountSwitcherContent } from "components/AccountSwitcher/AccountSwitcherContent"
import {
    AccountSwitcherCurrencyType,
    AccountSwitcherItem,
} from "components/AccountSwitcher/AccountSwitcherItem"
import { AccountSwitcherModal } from "components/AccountSwitcher/AccountSwitcherModal"
import {
    AccountSwitcherActionsContext,
    AccountSwitcherContext,
} from "components/AccountSwitcher/Provider/AccountSwitcherContext"
import { AuthContext } from "components/authentication/AuthProvider"
import { AccountsContext } from "modules/accounts/Providers/Accounts/AccountsContext"
import { AccountsProvider } from "modules/accounts/Providers/Accounts/AccountsProvider"
import { useRoot } from "modules/root"
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { Translate } from "react-localize-redux"
import { truncate } from "utils/truncate"

interface IAccountSwitcher {
    light?: boolean
}

export const AccountSwitcher: React.FC<IAccountSwitcher> = (props) => {
    const { accounts } = useRoot()

    if (!accounts) {
        return null
    }

    return (
        <AccountsProvider uri={accounts} id="accountSwitcher" per_page={5}>
            <AccountSwitcherInner {...props} />
        </AccountsProvider>
    )
}

const allAccountsText = <Translate id={"common.accountswitcher.all_accounts"} />
const allAccountsDescriptionText = (
    <Translate id={"common.accountswitcher.all_accounts_description"} />
)

const AccountSwitcherInner: React.FC<IAccountSwitcher> = (props) => {
    const { light } = props

    const { data: selectedAccount, isSwitching } = useContext(AccountSwitcherContext)
    const { data: accounts, isLoading, metadata } = useContext(AccountsContext)
    const { beforeLogout } = useContext(AuthContext)
    const { switchAccount } = useContext(AccountSwitcherActionsContext)

    const [modalOpen, setModalOpen] = useState(false)
    const [popoverOpen, setPopoverOpen] = useState(false)

    useEffect(() => {
        beforeLogout(() => switchAccount())
    }, [])

    const selectedAccountTruncated = useMemo(() => {
        if (metadata?.count === 1) {
            return {
                name: accounts[0]?.name ? truncate(accounts[0].name, 10) : "-",
                descriptor: accounts[0]?.descriptor ? truncate(accounts[0].descriptor, 11) : "-",
            }
        }

        if (!selectedAccount) {
            return { name: "", descriptor: "" }
        }

        return {
            name: selectedAccount?.name ? truncate(selectedAccount.name, 10) : "-",
            descriptor: selectedAccount?.descriptor
                ? truncate(selectedAccount.descriptor, 11)
                : "-",
        }
    }, [selectedAccount, metadata, accounts])

    const handleOnClick = useCallback(() => {
        setModalOpen(true)
    }, [])

    const handleOnClosed = useCallback(() => {
        setModalOpen(false)
    }, [])

    const handleOnItemClick = useCallback(() => {
        setPopoverOpen(!popoverOpen)
    }, [popoverOpen])

    const handleOnHideEnd = useCallback(() => {
        setPopoverOpen(false)
    }, [popoverOpen])

    useEffect(() => {
        setPopoverOpen(false)
    }, [isSwitching])

    if (isLoading || !accounts || !metadata) {
        return <Loader size={14} />
    }

    return (
        <>
            {metadata.count === 1 && (
                <AccountSwitcherItem
                    currency={accounts[0].currency as AccountSwitcherCurrencyType}
                    name={selectedAccountTruncated.name}
                    descriptor={selectedAccountTruncated.descriptor}
                    mid={accounts[0].merchant_id}
                    light={light}
                    selfLink={accounts[0].relations.self}
                />
            )}
            {metadata.count <= 5 && metadata.count > 1 && (
                <Popover placement="bottom" show={popoverOpen}>
                    <Popover.Reference>
                        {!selectedAccount && (
                            <AccountSwitcherItem
                                name={allAccountsText}
                                descriptor={allAccountsDescriptionText}
                                chevron
                                light={light}
                                showLinks={false}
                                onClick={handleOnItemClick}
                            />
                        )}
                        {selectedAccount && (
                            <AccountSwitcherItem
                                currency={selectedAccount.currency as AccountSwitcherCurrencyType}
                                name={selectedAccountTruncated.name}
                                descriptor={selectedAccountTruncated.descriptor}
                                mid={selectedAccount.merchant_id}
                                chevron
                                onClick={handleOnItemClick}
                                light={light}
                            />
                        )}
                    </Popover.Reference>
                    <Popover.Content onHideEnd={handleOnHideEnd}>
                        <PortalContainer>
                            <BoxEdges minWidth={350}>
                                <AccountSwitcherContent />
                            </BoxEdges>
                        </PortalContainer>
                    </Popover.Content>
                </Popover>
            )}
            {metadata.count > 5 && (
                <>
                    {!selectedAccount && (
                        <AccountSwitcherItem
                            name={allAccountsText}
                            descriptor={allAccountsDescriptionText}
                            onClick={handleOnClick}
                            light={light}
                            chevron
                            showLinks={false}
                        />
                    )}
                    {selectedAccount && (
                        <AccountSwitcherItem
                            currency={selectedAccount.currency as AccountSwitcherCurrencyType}
                            name={selectedAccountTruncated.name}
                            descriptor={selectedAccountTruncated.descriptor}
                            onClick={handleOnClick}
                            mid={selectedAccount.merchant_id}
                            light={light}
                            chevron
                        />
                    )}
                    <AccountSwitcherModal open={modalOpen} onClosed={handleOnClosed} />
                </>
            )}
        </>
    )
}
