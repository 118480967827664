import { EventBase } from "modules/insights/Events/EventBase"

interface ApplicationCreatedParams {
    instant: boolean
    partner?: string
}

export class ApplicationCreated {
    static log<P extends ApplicationCreatedParams>(params: P) {
        EventBase.log("application_created", params)
    }
}
