import { ContextDataFeedback } from "components/ContextDataFeedback"
import { Problem } from "ketting"
import { AccountContext, AccountProvider } from "modules/accounts/Providers/Account/AccountProvider"
import { IAccountData, IAccountRelations } from "modules/accounts/types"
import { Uri } from "modules/common/types"
import { IRootData, useRoot } from "modules/root"
import React, { useEffect, useMemo, useState } from "react"
import { useHistory } from "react-router"
import { AccountSwitcherActionsContext, AccountSwitcherContext } from "./AccountSwitcherContext"

const LOCALSTORAGEKEY = "selectedAccountUri"

const noRedirectList = ["transactions", "settlements", "disputes"]

interface IAccountSwitcherProviderProps {
    children: React.ReactNode
}
export const AccountSwitcherProvider: React.FC<IAccountSwitcherProviderProps> = (props) => {
    const history = useHistory()
    const [accountData, setAccountData] = useState<IAccountData | null>(null)
    const [relations, setRelations] = useState<IAccountRelations | IRootData | null>(null)
    const [isSwitching, setIsSwitching] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState<Problem | undefined>()
    const [currentAccountUri, setCurrentAccountUri] = useState<Uri | null | undefined>(() => {
        // Get from localStorage
        return localStorage.getItem(LOCALSTORAGEKEY)
    })
    const [initialLoad, setInitialLoad] = useState(true)

    const root = useRoot()

    useEffect(() => {
        if (currentAccountUri === undefined) {
            setAccountData(null)
        }
    }, [currentAccountUri])

    useEffect(() => {
        if (
            isSwitching &&
            currentAccountUri &&
            relations &&
            (relations as IAccountRelations).self === currentAccountUri
        ) {
            setIsSwitching(false)
            return
        }

        // Persist to localStorage
        if (currentAccountUri) {
            localStorage.setItem(LOCALSTORAGEKEY, currentAccountUri)
        } else {
            localStorage.removeItem(LOCALSTORAGEKEY)
        }

        if (currentAccountUri && !root.isLoading && !accountData && !isSwitching && !initialLoad) {
            // Assumes no access
            setCurrentAccountUri(null)
        }

        if (!currentAccountUri && !root.isLoading) {
            setRelations(root)

            if (accountData) {
                setAccountData(null)
            }

            if (isSwitching) {
                setIsSwitching(false)
            }
        }
    }, [currentAccountUri, root.isLoading, isSwitching, accountData])

    useEffect(() => {
        if (error) {
            setCurrentAccountUri(null)
        }
    }, [error])

    useEffect(() => {
        if (!isSwitching || !currentAccountUri) {
            return
        }

        const { pathname } = history.location
        const pathParts = pathname.split("/", 3)

        const shouldRedirectToOverview = noRedirectList.some((i) => {
            return pathParts[1] === i && pathParts[2]
        })

        if (shouldRedirectToOverview) {
            history.push(`/${pathParts[1]}`)
        }
    }, [isSwitching])

    const contextValue = useMemo(() => {
        return {
            data: accountData,
            relations,
            isSwitching,
            isLoading,
        }
    }, [accountData, relations, isSwitching, isLoading])

    const actionsContextValue = useMemo(() => {
        function switchAccount(uri?: Uri) {
            setIsSwitching(true)
            setCurrentAccountUri(uri)
        }

        return {
            switchAccount,
        }
    }, [])

    useEffect(() => {
        setInitialLoad(false)
    }, [])

    return (
        <AccountSwitcherActionsContext.Provider value={actionsContextValue}>
            {currentAccountUri && (
                <AccountProvider accountUri={currentAccountUri} noCache>
                    <ContextDataFeedback context={AccountContext} onDataChange={setAccountData} />
                    <ContextDataFeedback
                        context={AccountContext}
                        onDataChange={setRelations}
                        dataProperty={"relations"}
                    />
                    <ContextDataFeedback
                        context={AccountContext}
                        onDataChange={setIsLoading}
                        dataProperty={"isLoading"}
                    />
                    <ContextDataFeedback
                        context={AccountContext}
                        onDataChange={setError}
                        dataProperty={"error"}
                    />
                </AccountProvider>
            )}
            <AccountSwitcherContext.Provider value={contextValue}>
                {props.children}
            </AccountSwitcherContext.Provider>
        </AccountSwitcherActionsContext.Provider>
    )
}
