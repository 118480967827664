import {
    AutofillCompanyData,
    AutofillCompanySupportedCountries,
} from "components/application/AutofillCompany/AutofillCompany"
import * as actions from "flows/autofillCompany"
import { Problem } from "ketting"
import { IAppState } from "modules"
import { getSupportedCountries } from "modules/voyager/actions"
import { useDispatch, useSelector } from "react-redux"
import { Dispatch } from "redux"

interface PropsFromState {
    companyAutofillSupportedCountries: AutofillCompanySupportedCountries
    companyAutofillData: AutofillCompanyData
    companyAutofillDataError?: Problem | string | undefined
    companyAutofillDataIsLoading?: boolean
}

const mapStateToHook = ({ pages }: IAppState): PropsFromState => {
    const {
        applications: {
            companyAutofillData,
            companyAutofillDataError,
            companyAutofillDataIsLoading,
            companyAutofillSupportedCountries,
        },
    } = pages

    return {
        companyAutofillData,
        companyAutofillDataError,
        companyAutofillDataIsLoading,
        companyAutofillSupportedCountries,
    }
}

const mapDispatchToHook = (dispatch: Dispatch) => ({
    autofillCompany: (registrationNumber: string, country: string) =>
        dispatch(actions.autofillCompany(registrationNumber, country)),
    getSupportedCountries: () => dispatch(getSupportedCountries()),
    autofillCompanyReset: () => dispatch(actions.autofillCompanyReset()),
})

export const useAutofillCompany = (): [PropsFromState, ReturnType<typeof mapDispatchToHook>] => {
    const dispatch = useDispatch()
    const autofillActions = mapDispatchToHook(dispatch)
    const autofillState = useSelector(mapStateToHook)

    return [autofillState, autofillActions]
}
