import { BoxEdges, Modal, Spacer } from "@clearhaus/design-system"
import React from "react"
import { Controls } from "./Controls"
import { Intro } from "./Intro"
import { Slideshow } from "./Slideshow"
import { useSlideshow } from "./useSlideshow"

interface IDashboardWelcomeProps {
    open: boolean
    onClosed: () => void
}

export const DashboardWelcome: React.FC<IDashboardWelcomeProps> = (props) => {
    const { open, onClosed } = props

    const slideshow = useSlideshow()

    const closeModal = () => {
        onClosed()
    }

    const onControlsClick = (index: number) => {
        slideshow.setIsPlaying(false)
        slideshow.setProgressPercentage(100)
        slideshow.setActiveIndex(index)
    }

    return (
        <Modal open={open} onClosed={closeModal} fullscreen>
            <Modal.Content noPadding>
                <BoxEdges mt={64} mx={64} mb={24}>
                    <Intro onClick={closeModal} />
                    <Spacer size={64} />
                    <Controls
                        progressPercentage={slideshow.progressPercentage}
                        activeIndex={slideshow.activeIndex}
                        onClick={onControlsClick}
                    />
                    <Spacer size={48} />
                    <Slideshow activeIndex={slideshow.activeIndex} />
                </BoxEdges>
            </Modal.Content>
        </Modal>
    )
}
