import { ConsumerBase } from "modules/insights/Consumers/ConsumerBase"
import { devMode } from "utils/envHelpers"

const gtagBaseUrl = "https://www.googletagmanager.com/gtag/js"

export class GoogleAnalytics4 extends ConsumerBase {
    constructor(gId: string, userId?: string, debug = false) {
        // Gtag already added?
        if (document.head.querySelector(`script[src^="${gtagBaseUrl}"]`)) {
            throw Error(`Gtag already added.`)
        }

        // Gtag element
        const gtag = document.createElement("script")
        gtag.setAttribute("async", "true")
        const gtagSrc = new URL(gtagBaseUrl)
        gtagSrc.searchParams.append("id", gId)
        gtag.setAttribute("src", gtagSrc.href)
        document.head.appendChild(gtag)

        // Gtag setup element
        const gtagSetup = document.createElement("script")
        gtagSetup.innerHTML = `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
        `
        if (userId) {
            gtagSetup.innerHTML += `
                gtag('config', '${gId}', {
                    'user_id': ${userId},
                    'debug_mode': ${debug}
                });
            `
        } else {
            gtagSetup.innerHTML += `
                gtag('config', '${gId}', {
                    'debug_mode': ${debug}
                });
            `
        }
        document.head.appendChild(gtagSetup)

        super()
    }

    setUserSession(userId: string, gateways: string | string[] | null): void {
        const userProperties = {
            clrhs_user_id: userId,
        }

        if (gateways) {
            const normalizedGateways = Array.isArray(gateways)
                ? gateways.sort().join(",")
                : gateways

            Object.assign(userProperties, {
                gateways: normalizedGateways,
            })
        }

        window.gtag("set", "user_properties", userProperties)
    }

    logEvent(eventName: string, params: object): void {
        if (!window.gtag && devMode) {
            // tslint:disable-next-line:no-console
            console.warn(`Google Analytics is not available, blocked by client?`)
            return
        }

        if (!window.gtag) {
            return
        }

        window.gtag("event", eventName, params)
    }
}
