import { shallowEqual } from "fast-equals"
import React, { useContext, useEffect, useState } from "react"

interface IContextDataFeedbackProps {
    context: React.Context<any>
    onDataChange: (data: any) => void
    dataProperty?: string
}

export const ContextDataFeedback: React.FC<IContextDataFeedbackProps> = (props) => {
    const { context, onDataChange, dataProperty = "data" } = props

    const contextData = useContext(context)

    if (!contextData.hasOwnProperty(dataProperty)) {
        throw Error(`Provided context does not have a '${dataProperty}' property!`)
    }

    const [prevData, setPrevData] = useState()

    useEffect(() => {
        if (!shallowEqual(contextData[dataProperty], prevData)) {
            onDataChange(contextData[dataProperty])
            setPrevData(contextData[dataProperty])
        }
    }, [contextData[dataProperty], prevData])

    return null
}
