import React, { useEffect, useRef } from "react"
import { useHistory } from "react-router-dom"

const ScrollToTop: React.FC = () => {
    const history = useHistory()
    const prevPath = useRef("/")

    useEffect(() => {
        const unlisten = history.listen((location) => {
            if (location.pathname !== prevPath.current) {
                window.scrollTo(0, 0)
                prevPath.current = location.pathname
            }
        })
        return () => {
            unlisten()
        }
    }, [])

    return null
}

export default ScrollToTop
