import { Button, Grid, UserColoredIcon } from "@clearhaus/design-system"
import { AuthContext } from "components/authentication/AuthProvider"
import { EmptyState } from "components/common/EmptyState/EmptyState"
import React, { useContext } from "react"
import { Translate } from "react-localize-redux"

const styles = { height: "100vh" }

const texts = {
    title: <Translate id="common.incomplete_profile.title" />,
    description: <Translate id="common.incomplete_profile.description" />,
    logout_button: <Translate id="common.incomplete_profile.logout_button" />,
}

export const IncompleteUserProfile: React.FC = () => {
    const { logout } = useContext(AuthContext)

    const handleOnClick = () => {
        logout()
    }

    return (
        <Grid container fluid>
            <Grid row noGutters justify="center" alignContent="center" style={styles}>
                <Grid item auto>
                    <EmptyState>
                        <EmptyState.Icon icon={<UserColoredIcon />} />
                        <EmptyState.Title>{texts.title}</EmptyState.Title>
                        <EmptyState.Description>{texts.description}</EmptyState.Description>
                        <EmptyState.Actions>
                            <Grid row alignItems="center" justify="center">
                                <Grid item auto>
                                    <Button onClick={handleOnClick}>{texts.logout_button}</Button>
                                </Grid>
                            </Grid>
                        </EmptyState.Actions>
                    </EmptyState>
                </Grid>
            </Grid>
        </Grid>
    )
}
