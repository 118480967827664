import { IAccountsProvidersRootState } from "modules/accounts/Providers/Accounts/reducer"

export const getNextUri = (id: string) => (state: IAccountsProvidersRootState) => {
    const accountProviderState = state.accountsProviders ? state.accountsProviders[id] : null
    if (accountProviderState && accountProviderState.relations) {
        return accountProviderState.relations.next
    }

    return accountProviderState
}
