import { put } from "@redux-saga/core/effects"
import { Problem } from "ketting"
import { MerchantApiRequest, MerchantApiResponse } from "modules/common"
import { catchError, forkWatcher } from "modules/common/actions"
import {
    batchFetchRecord,
    batchFetchRecordError,
    batchFetchRecordOk,
    fetchRecord,
    fetchRecordError,
    fetchRecordOk,
    fetchRecords,
    fetchRecordsError,
    fetchRecordsOk,
} from "modules/records/actions"
import {
    IRecord,
    IRecordData,
    IRecordRelations,
    IRecordsData,
    IRecordsRelations,
} from "modules/records/types"
import { call } from "redux-saga/effects"
import { ActionType, getType } from "typesafe-actions"
import UrlParse from "url-parse"

export function* handleFetchRecords(action: ActionType<typeof fetchRecords>) {
    const { page, per_page, query, uri: recordsUri } = action.payload

    try {
        const queryParams = {}
        if (page) {
            Object.assign(queryParams, {
                page: page.toString(),
            })
        }
        if (per_page) {
            Object.assign(queryParams, {
                per_page: per_page.toString(),
            })
        }
        if (query) {
            Object.assign(queryParams, {
                query,
            })
        }
        const uri = new UrlParse(recordsUri, true)
        uri.set("query", {
            ...uri.query,
            ...queryParams,
        })

        const merchantApiRequest = new MerchantApiRequest<IRecordsData, IRecordData>(
            uri.toString(),
            {
                noCache: true, // Never cache records, as it contains data from many resources, which makes it difficult to invalidate
            }
        )
        const {
            relations,
            repr,
        }: {
            relations: IRecordsRelations
            repr: IRecordsData
        } = yield call([merchantApiRequest, merchantApiRequest.get])

        yield put(fetchRecordsOk(repr, relations, recordsUri, page, per_page, query))
    } catch (e) {
        if (e instanceof Problem) {
            yield put(fetchRecordsError(e, recordsUri, page, per_page, query))
            yield put(catchError(e))
        } else {
            throw e
        }
    }
}

export function* handleFetchRecord(action: ActionType<typeof fetchRecord>) {
    const { uri: recordUri, noCache } = action.payload

    try {
        const merchantApiRequest = new MerchantApiRequest(recordUri, { noCache })
        const { repr, relations }: MerchantApiResponse<IRecordData, IRecordRelations> = yield call([
            merchantApiRequest,
            merchantApiRequest.get,
        ])

        // Map admin only state "new" to "in_progress"
        if (repr.states?.accounts?.new) {
            repr.states.accounts.in_progress = repr.states.accounts.new
            delete repr.states.accounts.new
        }

        yield put(fetchRecordOk(repr, relations, recordUri, noCache))
    } catch (e) {
        if (e instanceof Problem) {
            yield put(fetchRecordError(e, recordUri, noCache))
        } else {
            throw e
        }
    }
}

export function* handleBatchFetchRecord(action: ActionType<typeof batchFetchRecord>) {
    const { uris: recordUris } = action.payload
    const records: IRecord[] = []

    try {
        for (const recordUri of recordUris) {
            const merchantApiRequest = new MerchantApiRequest(recordUri)
            const { repr, relations }: MerchantApiResponse<IRecordData, IRecordRelations> =
                yield call([merchantApiRequest, merchantApiRequest.get])

            // Map admin only state "new" to "in_progress"
            if (repr.states?.accounts?.new) {
                repr.states.accounts.in_progress = repr.states.accounts.new
                delete repr.states.accounts.new
            }

            records.push({
                ...repr,
                relations,
            })
        }

        yield put(batchFetchRecordOk(records, recordUris))
    } catch (e) {
        if (e instanceof Problem) {
            yield put(batchFetchRecordError(e, recordUris))
        } else {
            throw e
        }
    }
}

export function* recordsRootSaga() {
    yield put(forkWatcher(getType(fetchRecords), handleFetchRecords))
    yield put(forkWatcher(getType(fetchRecord), handleFetchRecord))
    yield put(forkWatcher(getType(batchFetchRecord), handleBatchFetchRecord))
}
