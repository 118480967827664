import { MerchantApiRequest } from "modules/common"
import { Me, UpdateMeDto } from "modules/me/types"
import { useMutation, useQuery, useQueryClient } from "react-query"

const ME_KEY = "me"

const fetchMe = () => {
    const merchantApiRequest = new MerchantApiRequest<Me>("me")
    return merchantApiRequest.get()
}

export function useMe() {
    return useQuery([ME_KEY], fetchMe, {
        select: (data) => data.repr,
    })
}

const updateMe = (update: UpdateMeDto) => {
    const merchantApiRequest = new MerchantApiRequest<Me>("me")
    return merchantApiRequest.post(update, true)
}

export function useUpdateMe() {
    const queryClient = useQueryClient()

    return useMutation((update: UpdateMeDto) => updateMe(update), {
        onSettled: () => {
            queryClient.invalidateQueries([ME_KEY])
        },
    })
}
