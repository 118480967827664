import { pagesApplicationReducer } from "pages/Applications/reducers"
import { PagesApplicationState } from "pages/Applications/types"
import { Reducer } from "redux"

interface PagesState {
    applications: PagesApplicationState
}

const initialState: PagesState = {
    applications: {
        bankAccountAutofill: {
            error: false,
            fetched: false,
            isLoading: false,
        },
        companyAutofillData: undefined,
        companyAutofillDataError: undefined,
        companyAutofillDataIsLoading: false,
        companyAutofillSupportedCountries: undefined,
        isLoading: false,
        selectedApplication: "",
    },
}

export const pagesRootReducer: Reducer<PagesState, any> = (state = initialState, action) => {
    return {
        ...state,
        applications: pagesApplicationReducer(state.applications, action),
    }
}
