import { Problem } from "ketting"
import { RxMiddleware } from "modules/common/RxMiddleware"
import { Uri } from "modules/common/types"
import { recordsRootSaga } from "modules/records/sagas"
import {
    BATCH_FETCH_RECORD,
    BATCH_FETCH_RECORD_ERROR,
    BATCH_FETCH_RECORD_OK,
    FETCH_RECORD,
    FETCH_RECORDS,
    FETCH_RECORDS_ERROR,
    FETCH_RECORDS_OK,
    FETCH_RECORD_ERROR,
    FETCH_RECORD_OK,
    IRecord,
    IRecordData,
    IRecordRelations,
    IRecordsData,
    IRecordsRelations,
} from "modules/records/types"
import { createCustomAction } from "typesafe-actions"
import { SagaRegistry } from "utils/SagaRegistry"
import * as actions from "./actions"

export const fetchRecords = createCustomAction(
    FETCH_RECORDS,
    (uri: Uri, page?: number, per_page?: number, query?: string) => ({
        payload: { uri, page, per_page, query },
    })
)
export const fetchRecordsOk = createCustomAction(
    FETCH_RECORDS_OK,
    (
        data: IRecordsData,
        relations: IRecordsRelations,
        uri: Uri,
        page?: number,
        per_page?: number,
        query?: string
    ) => ({
        meta: { uri, page, per_page, query },
        payload: { data, relations },
    })
)
export const fetchRecordsError = createCustomAction(
    FETCH_RECORDS_ERROR,
    (error: Problem, uri: Uri, page?: number, per_page?: number, query?: string) => ({
        meta: { uri, page, per_page, query },
        payload: error,
    })
)

export const fetchRecord = createCustomAction(FETCH_RECORD, (uri: Uri, noCache?: boolean) => ({
    payload: { uri, noCache },
}))
export const fetchRecordOk = createCustomAction(
    FETCH_RECORD_OK,
    (data: IRecordData, relations: IRecordRelations, uri: Uri, noCache?: boolean) => ({
        meta: { uri, noCache },
        payload: { data, relations },
    })
)
export const fetchRecordError = createCustomAction(
    FETCH_RECORD_ERROR,
    (error: Problem, uri: Uri, noCache?: boolean) => ({
        meta: { uri, noCache },
        payload: error,
    })
)

export const batchFetchRecord = createCustomAction(BATCH_FETCH_RECORD, (uris: Uri[]) => ({
    payload: { uris },
}))
export const batchFetchRecordOk = createCustomAction(
    BATCH_FETCH_RECORD_OK,
    (data: IRecord[], uris: Uri[]) => ({
        meta: { uris },
        payload: { data },
    })
)
export const batchFetchRecordError = createCustomAction(
    BATCH_FETCH_RECORD_ERROR,
    (e: Problem, uris: Uri[]) => ({
        meta: { uris },
        payload: e,
    })
)

RxMiddleware.addActions(actions)
SagaRegistry.register(recordsRootSaga)
