import { shallowEqual } from "fast-equals"
import { Problem } from "ketting"
import { getMe, getMeError, getMeOk, updateMe, updateMeOk } from "modules/me/actions"
import { Me } from "modules/me/types"
import { Reducer } from "redux"
import { ActionType, getType } from "typesafe-actions"

type ActionTypes = ActionType<
    typeof updateMe | typeof updateMeOk | typeof getMe | typeof getMeOk | typeof getMeError
>

export interface IMeProviderState {
    data?: Me
    isLoading?: boolean
    error?: Problem
}

export interface IMeProviderRootState {
    meProvider: IMeProviderState
}

export const meProviderReducer: Reducer<IMeProviderState, ActionTypes> = (currentState, action) => {
    let nextState
    if (!currentState) {
        currentState = {}
    }

    nextState = { ...currentState }

    switch (action.type) {
        case getType(getMe): {
            nextState = {
                ...nextState,
                isLoading: true,
            }
            break
        }
        case getType(getMeOk): {
            const { data } = action.payload
            nextState = {
                ...nextState,
                isLoading: false,
                data,
            }
            break
        }
        case getType(getMeError): {
            nextState = {
                ...nextState,
                error: action.payload,
                isLoading: false,
            }
            break
        }
        case getType(updateMe): {
            nextState = {
                ...nextState,
                isLoading: true,
            }
            break
        }
        case getType(updateMeOk): {
            const { data } = action.payload
            nextState = {
                ...nextState,
                isLoading: false,
            }
            const oldMeEqual = shallowEqual(data, nextState.data)
            if (!oldMeEqual) {
                nextState = {
                    ...nextState,
                    data,
                }
            }
        }
    }

    return nextState
}
