import { all, fork, put, race, take, takeEvery } from "@redux-saga/core/effects"

import { IAutofillCompanyInformation } from "components/application/AutofillCompany/AutofillCompany"
import { Problem } from "ketting"
import { removeEmptyKeys } from "modules/common/helpers"
import {
    lookupCompany,
    lookupCompanyError,
    lookupCompanyNotFound,
    lookupCompanyOk,
} from "modules/voyager/actions"
import { ActionType, createCustomAction, getType, isActionOf } from "typesafe-actions"

export const AUTOFILL_COMPANY = "@@flows/AUTOFILL_COMPANY"
export const AUTOFILL_COMPANY_OK = "@@flows/AUTOFILL_COMPANY_OK"
export const AUTOFILL_COMPANY_ERROR = "@@flows/AUTOFILL_COMPANY_ERROR"
export const AUTOFILL_COMPANY_RESET = "@@flows/AUTOFILL_COMPANY_RESET"

export const autofillCompany = createCustomAction(
    AUTOFILL_COMPANY,
    (registrationNumber: string, country: string) => ({
        meta: country,
        payload: registrationNumber,
    })
)
export const autofillCompanyOk = createCustomAction(
    AUTOFILL_COMPANY_OK,
    (
        companyInformation: IAutofillCompanyInformation | null,
        lookupMeta: { country: string; registrationNumber: string }
    ) => ({ payload: companyInformation, meta: lookupMeta })
)
export const autofillCompanyError = createCustomAction(
    AUTOFILL_COMPANY_ERROR,
    (problem: Problem | string, lookupMeta: { country: string; registrationNumber: string }) => ({
        meta: lookupMeta,
        payload: problem,
    })
)

export const autofillCompanyReset = createCustomAction(AUTOFILL_COMPANY_RESET, () => ({}))

function* handleAutofillCompany(action: ActionType<typeof autofillCompany>) {
    const regNo = action.payload
    const countryCode = action.meta

    yield put(lookupCompany(regNo, countryCode))

    // Creates a race condition, first one to appear get's the candy
    const [companyOk, companyNotFound, companyError]: [
        ActionType<typeof lookupCompanyOk> | undefined,
        ActionType<typeof lookupCompanyNotFound> | undefined,
        ActionType<typeof lookupCompanyError> | undefined
    ] = yield race([
        take((a: ActionType<typeof lookupCompanyOk>) => {
            return (
                isActionOf(lookupCompanyOk, a) &&
                a.meta.registrationNumber === regNo &&
                a.meta.country === countryCode
            )
        }),
        take((a: ActionType<typeof lookupCompanyNotFound>) => {
            return (
                isActionOf(lookupCompanyNotFound, a) &&
                a.payload.registrationNumber === regNo &&
                a.payload.country === countryCode
            )
        }),
        take((a: ActionType<typeof lookupCompanyError>) => {
            return (
                isActionOf(lookupCompanyError, a) &&
                a.meta.registrationNumber === regNo &&
                a.meta.country === countryCode
            )
        }),
    ])

    if (companyError) {
        yield put(
            autofillCompanyError(companyError.payload, {
                country: companyError.meta.country,
                registrationNumber: companyError.meta.registrationNumber,
            })
        )
        return
    }

    let companyInformation: IAutofillCompanyInformation | null
    if (companyNotFound) {
        companyInformation = null
    } else if (companyOk) {
        const {
            email,
            name,
            address,
            city,
            zip_code,
            phone,
            company_form: form,
            director,
            ownership_distribution: ownership,
        } = companyOk.payload
        const zipcode = zip_code.toString()
        companyInformation = {
            address,
            city,
            director,
            email,
            form,
            name,
            ownership,
            phone,
            zipcode,
        }
    } else {
        yield put(
            autofillCompanyError(`Unknown error`, {
                country: countryCode,
                registrationNumber: regNo,
            })
        )
        return
    }

    yield put(
        autofillCompanyOk(companyInformation ? removeEmptyKeys(companyInformation) : null, {
            country: countryCode,
            registrationNumber: regNo,
        })
    )
}

function* watchAutofillCompany() {
    yield takeEvery(getType(autofillCompany), handleAutofillCompany)
    yield takeEvery(getType(autofillCompanyReset), () => autofillCompanyReset())
}

export function* autofillCompanySaga() {
    yield all([fork(watchAutofillCompany)])
}
