import React from "react"
import { IServiceMessageType } from "./reducer"

interface IServiceMessagesContext {
    messages: IServiceMessageType[]
}

export const ServiceMessagesDataContext = React.createContext<IServiceMessagesContext>({
    messages: [],
})
