import { Loader } from "@clearhaus/design-system"
import React, { useEffect, useRef, useState } from "react"
import { RouteComponentProps } from "react-router-dom"
import { Subscription } from "rxjs"
import { map, pluck } from "rxjs/operators"
import { useApplicationHelpers } from "../../hooks/useApplicationHelpers"
import { useApplications } from "../../modules/applications/hooks"
import { Uri } from "../../modules/common/types"
import { useRoot } from "../../modules/root"
import NotFound from "../NotFound"

interface IApplicationRedirectMatch {
    id: string
}

export const Application: React.FC<RouteComponentProps<IApplicationRedirectMatch>> = (props) => {
    const { match } = props
    const root = useRoot()
    const applicationsSub = useRef<Subscription>()
    const [notFound, setNotFound] = useState(false)
    const { navigateTo } = useApplicationHelpers()

    const applications = useApplications(
        root.applications,
        undefined,
        undefined,
        `id:${match.params.id}`
    )

    useEffect(() => {
        applicationsSub.current = applications
            .pipe(
                pluck("relations"),
                map((relations) => {
                    if (!relations) {
                        return null
                    }
                    if (!relations.applications) {
                        return []
                    }

                    return Array.isArray(relations.applications)
                        ? relations.applications
                        : [relations.applications]
                })
            )
            .subscribe((appUris: Uri[]) => {
                if (appUris) {
                    if (appUris.length === 0) {
                        setNotFound(true)
                    } else {
                        const applicationUri = appUris[0]
                        navigateTo(applicationUri, true)
                    }
                }
            })

        return () => {
            if (applicationsSub.current && !applicationsSub.current.closed) {
                applicationsSub.current.unsubscribe()
            }
        }
    }, [])

    return (
        <>
            {!notFound && <Loader size={32} />}
            {notFound && <NotFound />}
        </>
    )
}
