import {
    BoxEdges,
    Card,
    Grid,
    Paragraph,
    ProgressBar,
    Spacer,
    ThemeContext,
} from "@clearhaus/design-system"
import { ColorNames, ColorWeights } from "@clearhaus/design-system/defaults"
import { makeStyles } from "@clearhaus/design-system/utils/styles/makeStyles"
import React, { useContext, useMemo } from "react"
import { styles } from "./styles"

const states = {
    active: {
        frameKind: "primary" as ColorNames,
    },
    inactive: {
        flatten: true,
        kind: "neutrals" as ColorNames,
        shade: 300 as ColorWeights,
    },
}

interface IControlProps {
    children?: React.ReactNode
    index: number
    activeIndex: number
    onClick: (index: number) => void
    progressPercentage: number
}

const useStyles = makeStyles(styles)
const Control = (props: IControlProps) => {
    const { children, index, activeIndex, onClick, progressPercentage } = props
    const classes = useStyles()

    const isActive = useMemo(() => {
        return index === activeIndex ? true : false
    }, [index, activeIndex])

    const cardProps = useMemo(() => {
        return isActive ? states.active : states.inactive
    }, [isActive])

    const handleOnClick = () => {
        onClick(index)
    }

    return (
        <div onClick={handleOnClick} className={classes.control}>
            <Grid
                row
                direction="column"
                justify="center"
                alignItems="center"
                className={classes.height100}
            >
                <Grid item>
                    <Card {...cardProps} frame className={classes.height100}>
                        <Card.Content noSpacing>
                            <BoxEdges px={16} py={10}>
                                {children}
                            </BoxEdges>
                        </Card.Content>
                    </Card>
                </Grid>
                <Grid item auto>
                    <Spacer size={16} />
                </Grid>
                <Grid item auto>
                    {isActive && (
                        <ProgressBar
                            size="tiny"
                            value={progressPercentage}
                            className={classes.progressVisible}
                        />
                    )}
                    {!isActive && (
                        <ProgressBar size="tiny" value={0} className={classes.progressInvisible} />
                    )}
                </Grid>
            </Grid>
        </div>
    )
}

interface ITitleProps {
    children: React.ReactNode
}
const Title: React.FC<ITitleProps> = (props) => {
    return (
        <>
            <Paragraph align="center" weight="medium" noMargin>
                {props.children}
            </Paragraph>
            <Spacer size={4} />
        </>
    )
}

interface IDescriptionProps {
    children: React.ReactNode
}
const Description: React.FC<IDescriptionProps> = (props) => {
    const { defaults } = useContext(ThemeContext)

    return (
        <Paragraph align="center" size="m" color={defaults.colors.neutrals[700]}>
            {props.children}
        </Paragraph>
    )
}

Control.Title = Title
Control.Description = Description

export { Control }
