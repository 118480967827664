import { fromBlob } from "file-type/browser"

export const getMimetype = (file: File) =>
    new Promise<string | false>((resolve, reject) => {
        const fileReader = new FileReader()
        fileReader.onloadend = (e) => {
            try {
                // Convert ArrayBuffer into Uint8Array
                // @ts-ignore
                const convertedArrayBuffer = new Uint8Array(e.target.result)

                // Convert Uint8Array into Blob
                const blob = new Blob([convertedArrayBuffer])

                fromBlob(blob).then((fileType) => {
                    if (fileType) {
                        resolve(fileType.mime)
                    } else {
                        resolve(false)
                    }
                })
            } catch (e) {
                reject(e)
            }
        }

        let partOfFileToRead = file as Blob
        if (file.size > 100 * 1024) {
            partOfFileToRead = partOfFileToRead.slice(0, 100 * 1024)
        }
        fileReader.readAsArrayBuffer(partOfFileToRead)
    })
