import {
    BoxEdges,
    Card,
    CrossIcon,
    defaults,
    Input,
    LoadingScreen,
    Modal,
    PortalContainer,
    SearchIcon,
    Spacer,
} from "@clearhaus/design-system"
import useCallbackRef from "@clearhaus/design-system/utils/useCallbackRef"
import { AccountSwitcherContent } from "components/AccountSwitcher/AccountSwitcherContent"
import { AccountSwitcherContext } from "components/AccountSwitcher/Provider/AccountSwitcherContext"
import { AccountsProvider } from "modules/accounts/Providers/Accounts/AccountsProvider"
import { useRoot } from "modules/root"
import React, { useContext, useEffect, useMemo, useRef, useState } from "react"
import { LocalizeContext, Translate } from "react-localize-redux"
import { Subject, Subscription } from "rxjs"

interface IAccountSwitcherModal {
    open: boolean
    onClosed: () => void
}

const modalContentListStyle = {
    maxHeight: "75vh",
    overflow: "auto",
    paddingLeft: defaults.spacing[12],
    paddingRight: defaults.spacing[12],
}

const searchIcon = <SearchIcon />

export const AccountSwitcherModal: React.FC<IAccountSwitcherModal> = (props) => {
    const { open, onClosed } = props
    const [query, setQuery] = useState("")
    const queryTimeoutRef = useRef(0)
    const [accountsQuery, setAccountsQuery] = useState("")
    const [switching, setSwitching] = useState<boolean | null>(null)
    const [modalOpen, setModalOpen] = useState(open)
    const { accounts: accountsRoot } = useRoot()
    const { isSwitching } = useContext(AccountSwitcherContext)
    const inputRef = useRef<HTMLInputElement>(null)
    const { translate } = useContext(LocalizeContext)

    const [activeIndex, setActiveIndex] = useState(-1)
    const [switchToActiveAccountByIndex] = useState(() => new Subject<number>())

    const [accountCount, setAccountCount] = useState(0)
    const [accountCountSubject] = useState(() => new Subject<number>())
    const accountCountSubjectSub = useRef<Subscription>()
    useEffect(() => {
        if (accountCountSubjectSub.current && !accountCountSubjectSub.current.closed) {
            accountCountSubjectSub.current.unsubscribe()
        }

        accountCountSubjectSub.current = accountCountSubject.subscribe((c) => {
            setAccountCount(c)
        })
    }, [activeIndex])

    useEffect(() => {
        return () => {
            if (accountCountSubjectSub.current && !accountCountSubjectSub.current.closed) {
                accountCountSubjectSub.current.unsubscribe()
            }
        }
    }, [])

    useEffect(() => {
        if (!modalOpen && open) {
            setModalOpen(open)
        } else if (modalOpen && !open) {
            setModalOpen(open)
        }
    }, [open])

    const handleOnKeyDown = useCallbackRef((e: React.KeyboardEvent) => {
        const preventKeys = ["ArrowDown", "ArrowUp", "Enter"]
        const pressedKey = e.key
        if (preventKeys.includes(pressedKey)) {
            e.preventDefault()
        } else {
            return
        }

        const nextIndex =
            pressedKey === "ArrowDown"
                ? activeIndex + 1
                : pressedKey === "ArrowUp"
                ? activeIndex !== -1
                    ? activeIndex - 1
                    : -1
                : null

        switch (pressedKey) {
            case "ArrowDown":
                if (nextIndex !== null && nextIndex <= accountCount + 1) {
                    setActiveIndex(nextIndex)
                }
                break
            case "ArrowUp":
                if (nextIndex !== null && nextIndex <= accountCount) {
                    setActiveIndex(nextIndex)
                }
                break
            case "Enter":
                switchToActiveAccountByIndex.next(activeIndex)
                break
        }
    })

    const handleOnQueryChange = useCallbackRef((e: React.ChangeEvent<HTMLInputElement>) => {
        setQuery(e.target.value)
        if (activeIndex !== -1) {
            setActiveIndex(-1)
        }
    })

    const handleOnOpend = useCallbackRef(() => {
        if (!inputRef.current) {
            return
        }

        inputRef.current.focus()
    })

    useEffect(() => {
        if (switching === false) {
            setModalOpen(false)
        }
    }, [switching])

    useEffect(() => {
        if (isSwitching) {
            setSwitching(true)
        } else if (!isSwitching && switching) {
            setSwitching(false)
        }
    }, [isSwitching, switching])

    useEffect(() => {
        if (queryTimeoutRef.current > 0) {
            window.clearTimeout(queryTimeoutRef.current)
        }

        queryTimeoutRef.current = window.setTimeout(() => {
            setAccountsQuery(query)
        }, 500)
    }, [query])

    const clearButton = useMemo(() => {
        if (!query) {
            return <></>
        }

        return <CrossIcon onClick={() => setQuery("")} size={14} />
    }, [query])

    return (
        <Modal open={modalOpen} onClosed={onClosed} onOpened={handleOnOpend} size={"md"}>
            <Modal.Header>
                <Translate id={"common.accountswitcher.search_header"} />
            </Modal.Header>
            <PortalContainer>
                <Modal.Content>
                    <LoadingScreen hidden={!switching} />
                    <Input
                        prefix={searchIcon}
                        suffix={clearButton}
                        placeholder={translate(
                            "common.accountswitcher.search_placeholder"
                        ).toString()}
                        value={query}
                        onChange={handleOnQueryChange}
                        inputRef={inputRef}
                        onKeyDown={handleOnKeyDown}
                    />
                    <Spacer size={16} />
                    <Card frame style={modalContentListStyle}>
                        <Card.Content noSpacing>
                            <BoxEdges p={12}>
                                {accountsRoot && (
                                    <AccountsProvider
                                        uri={accountsRoot}
                                        per_page={10}
                                        query={accountsQuery}
                                    >
                                        <AccountSwitcherContent
                                            allowMore
                                            activeIndex={activeIndex}
                                            switchToActiveAccountByIndex={
                                                switchToActiveAccountByIndex
                                            }
                                            accountCountSubject={accountCountSubject}
                                        />
                                    </AccountsProvider>
                                )}
                            </BoxEdges>
                        </Card.Content>
                    </Card>
                </Modal.Content>
            </PortalContainer>
        </Modal>
    )
}
