import { shallowEqual } from "fast-equals"
import { getMe, updateMe } from "modules/me/actions"
import { MeContext } from "modules/me/Provider/context"
import { IMeProviderRootState, IMeProviderState } from "modules/me/Provider/reducer"
import { UpdateMeDto } from "modules/me/types"
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

interface IMeProviderProps {
    children: React.ReactNode
}
export const MeProvider: React.FC<IMeProviderProps> = (props) => {
    const {
        data,
        isLoading = true,
        error,
    } = useSelector<IMeProviderRootState, IMeProviderState>((s) => {
        if (s.meProvider) {
            return s.meProvider
        }
        return {} as IMeProviderState
    }, shallowEqual)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getMe())
    }, [])

    const update = (me: UpdateMeDto) => {
        dispatch(updateMe(me))
    }

    return (
        <MeContext.Provider
            value={{
                isLoading,
                data,
                error,
                update,
            }}
        >
            {props.children}
        </MeContext.Provider>
    )
}
