import { IbanInfo, LOOKUP_IBAN, LOOKUP_IBAN_ERROR, LOOKUP_IBAN_OK } from "modules/iban/types"
import { createCustomAction } from "typesafe-actions"

export const lookupIban = createCustomAction(LOOKUP_IBAN, (iban: string) => ({ payload: { iban } }))
export const lookupIbanOk = createCustomAction(LOOKUP_IBAN_OK, (data: IbanInfo, iban: string) => ({
    payload: { data },
    meta: { iban },
}))
export const lookupIbanError = createCustomAction(
    LOOKUP_IBAN_ERROR,
    (error: Error, iban: string) => ({ payload: error, meta: { iban } })
)
