import { Problem } from "ketting"
import { catchError, forkWatcher } from "modules/common/actions"
import { call, put } from "redux-saga/effects"
import { ActionType, getType } from "typesafe-actions"
import { MerchantApiRequest, MerchantApiResponse } from "../common"
import {
    batchFetchWebsite,
    batchFetchWebsiteError,
    batchFetchWebsiteOk,
    createWebsite,
    createWebsiteError,
    createWebsiteOk,
    deleteWebsite,
    deleteWebsiteError,
    deleteWebsiteOk,
    fetchWebsite,
    fetchWebsiteError,
    fetchWebsiteOk,
    fetchWebsites,
    fetchWebsitesError,
    fetchWebsitesOk,
    updateWebsite,
    updateWebsiteError,
    updateWebsiteOk,
} from "./actions"
import {
    IWebsite,
    IWebsiteData,
    IWebsiteRelations,
    IWebsitesData,
    IWebsitesRelations,
} from "./types"

export function* handleFetchWebsites(action: ActionType<typeof fetchWebsites>) {
    const { uri, noCache } = action.payload

    try {
        const merchantApiRequest = new MerchantApiRequest(uri, { noCache })
        const { repr, relations }: MerchantApiResponse<IWebsitesData, IWebsitesRelations> =
            yield call([merchantApiRequest, merchantApiRequest.get])

        yield put(fetchWebsitesOk(repr, relations, uri, noCache))
    } catch (e) {
        if (e instanceof Problem) {
            yield put(fetchWebsitesError(e, uri, noCache))
        }
        yield put(catchError(e))
    }
}

export function* handleFetchWebsite(action: ActionType<typeof fetchWebsite>) {
    const { uri } = action.payload

    try {
        const merchantApiRequest = new MerchantApiRequest(uri)
        const { repr, relations }: MerchantApiResponse<IWebsiteData, IWebsiteRelations> =
            yield call([merchantApiRequest, merchantApiRequest.get])

        yield put(fetchWebsiteOk(repr, relations, uri))
    } catch (e) {
        if (e instanceof Problem) {
            yield put(fetchWebsiteError(e, uri))
        }
        yield put(catchError(e))
    }
}

export function* handleCreateWebsite(action: ActionType<typeof createWebsite>) {
    const { uri, data } = action.payload

    try {
        const merchantApiRequest = new MerchantApiRequest(uri, { contentType: "application/json" })
        const { repr, relations }: MerchantApiResponse<IWebsiteData, IWebsiteRelations> =
            yield call([merchantApiRequest, merchantApiRequest.post], data)

        yield put(createWebsiteOk(repr, relations, data, uri))
    } catch (e) {
        if (e instanceof Problem) {
            yield put(createWebsiteError(e, data, uri))
        }
        yield put(catchError(e))
    }
}

export function* handleDeleteWebsite(action: ActionType<typeof deleteWebsite>) {
    const { uri } = action.payload

    try {
        const merchantApiRequest = new MerchantApiRequest(uri)
        yield call([merchantApiRequest, merchantApiRequest.delete])

        yield put(deleteWebsiteOk(uri))
    } catch (e) {
        if (e instanceof Problem) {
            yield put(deleteWebsiteError(e, uri))
        }
        yield put(catchError(e))
    }
}
export function* handleUpdateWebsite(action: ActionType<typeof updateWebsite>) {
    const { uri, data } = action.payload

    try {
        const merchantApiRequest = new MerchantApiRequest(uri, { contentType: "application/json" })
        const { repr, relations }: MerchantApiResponse<IWebsiteData, IWebsiteRelations> =
            yield call([merchantApiRequest, merchantApiRequest.patch], data)

        yield put(updateWebsiteOk(repr, relations, data, uri))
    } catch (e) {
        if (e instanceof Problem) {
            yield put(updateWebsiteError(e, data, uri))
        }
        yield put(catchError(e))
    }
}

export function* handleBatchFetchWebsite(action: ActionType<typeof batchFetchWebsite>) {
    const { uris } = action.payload
    const data: IWebsite[] = []

    try {
        for (const uri of uris) {
            const merchantApiRequest = new MerchantApiRequest(uri)
            const { repr, relations }: MerchantApiResponse<IWebsiteData, IWebsiteRelations> =
                yield call([merchantApiRequest, merchantApiRequest.get])

            data.push({
                ...repr,
                relations,
            })
        }

        yield put(batchFetchWebsiteOk(data, uris))
    } catch (e) {
        if (e instanceof Problem) {
            yield put(batchFetchWebsiteError(e, uris))
        }
        yield put(catchError(e))
    }
}

export default function* websitesSaga() {
    yield put(forkWatcher(getType(fetchWebsites), handleFetchWebsites))
    yield put(forkWatcher(getType(fetchWebsite), handleFetchWebsite))
    yield put(forkWatcher(getType(createWebsite), handleCreateWebsite))
    yield put(forkWatcher(getType(deleteWebsite), handleDeleteWebsite))
    yield put(forkWatcher(getType(updateWebsite), handleUpdateWebsite))
    yield put(forkWatcher(getType(batchFetchWebsite), handleBatchFetchWebsite))
}
