export const ACTION_PREFIX = "@@iban/"
export const LOOKUP_IBAN = "@@iban/LOOKUP_IBAN"
export const LOOKUP_IBAN_OK = "@@iban/LOOKUP_IBAN_OK"
export const LOOKUP_IBAN_ERROR = "@@iban/LOOKUP_IBAN_ERROR"

export interface IbanInfo {
    bank?: string
    bic?: string
    error?: string
}
