import { ColorNames } from "@clearhaus/design-system/defaults"
import { Grid } from "@clearhaus/design-system/Grid"
import { makeStyles } from "@clearhaus/design-system/utils/styles/makeStyles"
import classNames from "classnames"
import { Chevron, Content, Icon } from "components/common/NavigationItem/components"
import React from "react"
import { styles } from "./styles"

interface INavigationItemProps {
    icon?: JSX.Element
    text?: string | JSX.Element
    chevron?: boolean
    light?: boolean
    onClick?: (e: React.MouseEvent<HTMLElement>) => void
    active?: boolean
    direction?: "horizontal" | "vertical"
    noPaddingLeft?: boolean
    noPaddingRight?: boolean
    py?: "s" | "l"
    badgeColor?: ColorNames
}

const useStyles = makeStyles(styles)
const NavigationItem: React.FC<INavigationItemProps> = (props) => {
    const {
        icon,
        text,
        chevron,
        light,
        onClick,
        active,
        direction,
        noPaddingLeft,
        noPaddingRight,
        py,
        badgeColor,
    } = props

    const classes = useStyles()
    const containerClasses = classNames(classes.container, {
        [classes.active]: active,
        [classes.noPaddingLeft]: noPaddingLeft,
        [classes.noPaddingRight]: noPaddingRight,
        [classes.horizontal]: direction === "horizontal",
        [classes.vertical]: direction === "vertical",
        [classes.paddingYLarge]: py === "l",
        [classes.paddingYSmall]: py === "s",
    })

    const iconMargin = text ? "l" : chevron ? "s" : "none"
    const chevronMargin = text ? "s" : "none"

    return (
        <Grid row alignItems="center" onClick={onClick} className={containerClasses} noGutters>
            <Icon
                active={active}
                icon={icon}
                light={light}
                margin={iconMargin}
                badgeColor={badgeColor}
            />
            <Content light={light} text={text} />
            <Chevron show={chevron} light={light} margin={chevronMargin} />
        </Grid>
    )
}

export { NavigationItem }
