import { Grid } from "@clearhaus/design-system"
import { Slides } from "pages/DashboardWelcome/Slides"
import React from "react"

interface ISlideshowProps {
    activeIndex: number
}

export const Slideshow: React.FC<ISlideshowProps> = (props) => {
    const { activeIndex } = props

    return (
        <Grid row justify="center">
            <Grid item xs={12} lg={9}>
                <Slides activeIndex={activeIndex}>
                    <Slides.Slide index={1} src="/images/dashboard-welcome/account.svg" />
                    <Slides.Slide index={2} src="/images/dashboard-welcome/navigation.svg" />
                    <Slides.Slide index={3} src="/images/dashboard-welcome/user.svg" />
                    <Slides.Slide index={4} src="/images/dashboard-welcome/feedback.svg" />
                </Slides>
            </Grid>
        </Grid>
    )
}
