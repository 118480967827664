import { shallowEqual } from "fast-equals"
import { Problem } from "ketting"
import { cancelAction, forkWatcher } from "modules/common/actions"
import { EntityIndex, Uri } from "modules/common/types"
import { fork, put, race, take, takeEvery } from "redux-saga/effects"
import { ActionType, createCustomAction, getType, isActionOf } from "typesafe-actions"
import { ReducerRegistry } from "utils/ReducerRegistry"
import { SagaRegistry } from "utils/SagaRegistry"
import {
    batchFetchPerson,
    batchFetchPersonOk,
    deletePerson,
    deletePersonError,
    deletePersonOk,
    fetchPeople,
    fetchPeopleError,
    fetchPeopleOk,
} from "../../actions"
import { IPeopleData, IPeopleRelations, IPerson } from "../../types"
import { providersReducer } from "./reducer"

const PROVIDER_FETCH = "@@people/PROVIDERS/PEOPLE/FETCH"
const PROVIDER_FETCH_OK = "@@people/PROVIDERS/PEOPLE/FETCH_OK"
const PROVIDER_FETCH_ERROR = "@@people/PROVIDERS/PEOPLE/FETCH_ERROR"
export const providerFetch = createCustomAction(PROVIDER_FETCH, (uri: Uri) => ({
    payload: { uri },
}))
export const providerFetchOk = createCustomAction(
    PROVIDER_FETCH_OK,
    (data: IPerson[], metadata: IPeopleData, relations: IPeopleRelations, uri: Uri) => ({
        meta: { uri },
        payload: {
            data,
            metadata,
            relations,
        },
    })
)
export const providerFetchError = createCustomAction(
    PROVIDER_FETCH_ERROR,
    (e: Problem, uri: Uri) => ({
        meta: { uri },
        payload: e,
    })
)

export function* handleProviderFetch(action: ActionType<typeof providerFetch>) {
    const { uri } = action.payload

    yield put(fetchPeople(uri))
    const [success, error]: [
        ActionType<typeof fetchPeopleOk> | undefined,
        ActionType<typeof fetchPeopleError> | undefined
    ] = yield race([
        take((a: ActionType<typeof fetchPeopleOk>) => {
            return isActionOf(fetchPeopleOk, a) && a.meta.uri === uri
        }),
        take((a: ActionType<typeof fetchPeopleError>) => {
            return isActionOf(fetchPeopleError, a) && a.meta.uri === uri
        }),
    ])

    if (success) {
        const { data: peopleData, relations: peopleRelations } = success.payload
        if (peopleRelations.people) {
            const personUris = Array.isArray(peopleRelations.people)
                ? peopleRelations.people
                : [peopleRelations.people]
            yield put(batchFetchPerson(personUris))
            const { payload: batchOkPayload }: ActionType<typeof batchFetchPersonOk> = yield take(
                (a: ActionType<typeof batchFetchPersonOk>) => {
                    return (
                        isActionOf(batchFetchPersonOk, a) && shallowEqual(a.meta.uris, personUris)
                    )
                }
            )
            yield put(providerFetchOk(batchOkPayload.data, peopleData, peopleRelations, uri))
        } else {
            yield put(providerFetchOk([], peopleData, peopleRelations, uri))
        }
    } else if (error) {
        yield put(providerFetchError(error.payload, uri))
    }
}

const PROVIDER_DELETE = "@@people/PROVIDERS/DELETE"
const PROVIDER_DELETE_OK = "@@people/PROVIDERS/DELETE_OK"
const PROVIDER_DELETE_ERROR = "@@people/PROVIDERS/DELETE_ERROR"
export const providerDelete = createCustomAction(PROVIDER_DELETE, (personUri: Uri, uri: Uri) => ({
    payload: { personUri, uri },
}))
export const providerDeleteOk = createCustomAction(
    PROVIDER_DELETE_OK,
    (personUri: Uri, uri: Uri) => ({
        meta: { personUri, uri },
    })
)
export const providerDeleteError = createCustomAction(
    PROVIDER_DELETE_ERROR,
    (e: Problem, personUri: Uri, uri: Uri) => ({
        meta: { personUri, uri },
        payload: e,
    })
)
export function* handleProviderDelete(action: ActionType<typeof providerDelete>) {
    const { uri, personUri } = action.payload

    yield put(deletePerson(personUri))
    const [success, error]: [
        ActionType<typeof deletePersonOk> | undefined,
        ActionType<typeof deletePersonError> | undefined
    ] = yield race([
        take((a: ActionType<typeof deletePersonOk>) => {
            return isActionOf(deletePersonOk, a) && a.meta.uri === personUri
        }),
        take((a: ActionType<typeof deletePersonError>) => {
            return isActionOf(deletePersonError, a) && a.meta.uri === personUri
        }),
    ])

    if (success) {
        yield put(providerDeleteOk(personUri, uri))
        yield put(fetchPeople(uri, true))
    } else if (error) {
        yield put(providerDeleteError(error.payload, personUri, uri))
    }
}

const PROVIDER = "@@people/PROVIDERS/PEOPLE"
const INITIALIZE_PROVIDER = "@@people/PROVIDERS/PEOPLE/INITIALIZE"
const TEARDOWN_PROVIDER = "@@people/PROVIDERS/PEOPLE/TEARDOWN"
export const initializeProvider = createCustomAction(INITIALIZE_PROVIDER, (uri: Uri) => ({
    payload: { uri },
}))
export const teardownProvider = createCustomAction(TEARDOWN_PROVIDER, (uri: Uri) => ({
    payload: { uri },
}))

const providerContructor = (uri: Uri) => createCustomAction(`${PROVIDER}/${uri}`)

const providerSagaCache: EntityIndex<GeneratorFunction> = {}
export const handleProvider = (uri: Uri) => {
    if (!providerSagaCache[`${uri}`]) {
        providerSagaCache[`${uri}`] = function* () {
            yield takeEvery((a: ActionType<typeof providerFetch>) => {
                return isActionOf(providerFetch, a) && a.payload.uri === uri
            }, handleProviderFetch)
            yield takeEvery((a: ActionType<typeof providerDelete>) => {
                return isActionOf(providerDelete, a) && a.payload.uri === uri
            }, handleProviderDelete)
        } as GeneratorFunction
    }

    return providerSagaCache[`${uri}`]
}

export function* handleInitializeProvider(action: ActionType<typeof initializeProvider>) {
    const { uri } = action.payload

    yield put(forkWatcher(getType(providerContructor(uri)), handleProvider(uri)))
    yield put(providerContructor(uri)())
    yield put(providerFetch(uri))
}

export function* handleTeardownProvider(action: ActionType<typeof teardownProvider>) {
    const { uri } = action.payload

    yield put(cancelAction(getType(providerContructor(uri)), false))
}

export function* watchProviderSaga() {
    yield takeEvery(getType(initializeProvider), handleInitializeProvider)
    yield takeEvery(getType(teardownProvider), handleTeardownProvider)
}

function* providerRootSaga() {
    yield fork(watchProviderSaga)
}

SagaRegistry.register(providerRootSaga)
ReducerRegistry.register({
    peopleProviders: providersReducer,
})
