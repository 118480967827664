import { EntityIndex, Uri } from "modules/common/types"
import { IPersonData, IPersonRelations } from "modules/people/types"
import { Reducer } from "redux"
import { ActionType, getType } from "typesafe-actions"
import {
    initializeEmptyProvider,
    initializePersonProvider,
    personProviderCreatePerson,
    personProviderCreatePersonOk,
    personProviderDeletePerson,
    personProviderDeletePersonOk,
    personProviderFetchPerson,
    personProviderFetchPersonOk,
    personProviderUpdatePersonOk,
    teardownEmptyProvider,
    teardownPersonProvider,
} from "./sagas"

type ActionTypes = ActionType<
    | typeof initializeEmptyProvider
    | typeof teardownEmptyProvider
    | typeof initializePersonProvider
    | typeof teardownPersonProvider
    | typeof personProviderCreatePerson
    | typeof personProviderCreatePersonOk
    | typeof personProviderDeletePerson
    | typeof personProviderDeletePersonOk
    | typeof personProviderFetchPerson
    | typeof personProviderFetchPersonOk
    | typeof personProviderUpdatePersonOk
>

export interface IPersonProviderState {
    data?: IPersonData
    personUri?: Uri
    relations?: IPersonRelations
    isLoading?: boolean
}

export interface IPersonProviderRootState {
    personProviders: EntityIndex<IPersonProviderState>
}

export const personProviderReducer: Reducer<EntityIndex<IPersonProviderState>, ActionTypes> = (
    currentState,
    action
) => {
    let nextState
    if (!currentState) {
        currentState = {}
    }

    nextState = { ...currentState }

    switch (action.type) {
        case getType(initializeEmptyProvider): {
            const { id } = action.payload
            nextState = {
                ...nextState,
                [id]: {
                    isLoading: false,
                },
            }
            break
        }
        case getType(teardownEmptyProvider): {
            const { id } = action.payload
            delete nextState[id]
            break
        }
        case getType(initializePersonProvider): {
            const { personUri } = action.payload
            nextState = {
                ...nextState,
                [personUri]: {
                    isLoading: true,
                },
            }
            break
        }
        case getType(teardownPersonProvider): {
            const { personUri } = action.payload
            delete nextState[personUri]
            break
        }
        case getType(personProviderCreatePerson): {
            const { providerId } = action.payload
            nextState = {
                ...nextState,
                [providerId]: {
                    ...nextState[providerId],
                    isLoading: true,
                },
            }
            break
        }
        case getType(personProviderCreatePersonOk): {
            const { relations } = action.payload
            const { providerId } = action.meta
            nextState = {
                ...nextState,
                [providerId]: {
                    ...nextState[providerId],
                    isLoading: false,
                    personUri: relations.self,
                },
            }
            break
        }
        case getType(personProviderFetchPerson):
        case getType(personProviderDeletePerson): {
            const { personUri } = action.payload
            nextState = {
                ...nextState,
                [personUri]: {
                    ...nextState[personUri],
                    isLoading: true,
                },
            }
            break
        }
        case getType(personProviderFetchPersonOk): {
            const { personUri } = action.meta
            const { data, relations } = action.payload
            nextState = {
                ...nextState,
                [personUri]: {
                    isLoading: false,
                    data,
                    relations,
                },
            }
            break
        }
        case getType(personProviderUpdatePersonOk): {
            const { personUri } = action.meta
            const { data, relations } = action.payload

            nextState = {
                ...nextState,
                [personUri]: {
                    ...nextState[personUri],
                    data,
                    relations,
                },
            }
            break
        }
        case getType(personProviderDeletePersonOk): {
            const { personUri } = action.meta
            nextState = {
                ...nextState,
                [personUri]: {
                    isLoading: false,
                },
            }
            break
        }
        default: {
            nextState = currentState
        }
    }

    return nextState
}
