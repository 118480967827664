import { WorkerPattern } from "modules/common/types"
import { Task } from "redux-saga"
import { TypeConstant } from "typesafe-actions"

interface ISaga {
    task?: Task
    action: TypeConstant
    actionHandler: WorkerPattern<TypeConstant>
}

class CancelableSagaRegistry {
    public static instance: CancelableSagaRegistry = new CancelableSagaRegistry()

    private _sagas: ISaga[] = []

    public registerSaga(saga: ISaga) {
        if (
            !this._sagas.some(
                (s) => s.action === saga.action && s.actionHandler === saga.actionHandler
            )
        ) {
            this._sagas.push(saga)
        }
    }

    public deregisterSaga(saga: ISaga) {
        const index = this._sagas.findIndex((s) => s === saga)
        if (index !== -1) {
            this._sagas.splice(index, 1)
        }
    }

    public getSagasByAction(action: TypeConstant) {
        return this._sagas.filter((s) => s.action === action)
    }
}

const instance = CancelableSagaRegistry.instance
export default instance
export { instance as CancelableSagaRegistry }
