import { makeStyles } from "@clearhaus/design-system/utils/styles/makeStyles"
import { motion } from "framer-motion"
import React, { useContext, useMemo } from "react"
import { SlidesContext } from "./Slides"
import { styles } from "./styles"

const hidden = { opacity: 0 }
const visible = { opacity: 1 }

const variants = {
    visible,
    hidden,
}

const transition = { duration: 0.35 }

export const getSlideTransition = (animate: "hidden" | "visible") => ({
    variants,
    animate,
    transition,
})

interface ISlideProps {
    src: string
    index: number
}

const useStyles = makeStyles(styles)
export const Slide: React.FC<ISlideProps> = (props) => {
    const { src, index } = props
    const classes = useStyles()
    const { activeIndex } = useContext(SlidesContext)

    const slideTransition = useMemo(() => {
        return getSlideTransition(index === activeIndex ? "visible" : "hidden")
    }, [index, activeIndex])

    return (
        <motion.div {...slideTransition} className={classes.slide}>
            <img src={src} width="100%" />
        </motion.div>
    )
}
