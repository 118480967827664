import { Modal, Textarea } from "@clearhaus/design-system"
import { Actions } from "components/feedback/Actions"
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { LocalizeContext } from "react-localize-redux"

interface IFeedbackModalProps {
    open: boolean
    onClosed: () => void
}

export const FeedbackModal: React.FC<IFeedbackModalProps> = (props) => {
    const { open = false, onClosed } = props

    const { translate } = useContext(LocalizeContext)
    const texts = useMemo(
        () => ({
            title: translate("navigation.account_dropdown.feedback_form.title").toString(),
            placeholder: translate(
                "navigation.account_dropdown.feedback_form.placeholder"
            ).toString(),
        }),
        []
    )

    const [internalOpen, setInternalOpen] = useState(open)
    const [input, setInput] = useState("")

    useEffect(() => {
        if (open !== internalOpen) {
            setInternalOpen(open)
        }
    }, [open, internalOpen])

    const closeModal = useCallback(() => {
        setInternalOpen(false)
        onClosed()
    }, [])

    const handleInputChange = useCallback((_: React.ChangeEvent, value: string) => {
        setInput(value)
    }, [])

    return (
        <Modal size="md" open={open} onClosed={closeModal}>
            <Modal.Header>{texts.title}</Modal.Header>
            <Modal.Content>
                <Textarea onChange={handleInputChange} autoFocus placeholder={texts.placeholder} />
            </Modal.Content>
            <Modal.Footer>
                <Modal.Actions>
                    <Actions input={input} onCancel={closeModal} onSettle={closeModal} />
                </Modal.Actions>
            </Modal.Footer>
        </Modal>
    )
}
