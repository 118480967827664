import React, { useEffect } from "react"
import { useHistory } from "react-router"

export let history: ReturnType<typeof useHistory>

interface IHistorySetterProps {
    children: React.ReactNode
}
export const HistorySetter: React.FC<IHistorySetterProps> = (props) => {
    const routerHistory = useHistory()

    useEffect(() => {
        if (!history) {
            history = routerHistory
        }
    }, [])

    return <>{props.children}</>
}
