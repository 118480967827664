import { fork, put, takeEvery } from "@redux-saga/core/effects"
import { MerchantApiRequest, MERCHANT_API_ROOT } from "modules/common"
import { fetchRoot, fetchRootError, fetchRootOk } from "modules/root/actions"
import { IRootData } from "modules/root/types"
import { getType } from "typesafe-actions"

export function* handleFetchRoot() {
    const rootUri = MERCHANT_API_ROOT

    try {
        if (!rootUri) {
            throw new Error(`Missing 'rootUri'`)
        }
        const merchantApiRequest = new MerchantApiRequest(rootUri)
        const {
            relations,
        }: {
            relations: IRootData
        } = yield merchantApiRequest.get()

        yield put(fetchRootOk(relations))
    } catch (e) {
        if (e instanceof Error) {
            yield put(fetchRootError(e))
        }
        throw e
    }
}

export function* watchRoot() {
    yield takeEvery(getType(fetchRoot), handleFetchRoot)
}

export default function* rootRootSaga() {
    yield fork(watchRoot)
}
