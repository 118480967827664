import { Problem } from "ketting"
import { HttpError } from "ketting/dist/http/error"
import { Auth0 } from "modules/authentication/Auth0"
import { TRACK_JS_TOKEN } from "modules/common"
import React from "react"
import { QueryClient, QueryClientProvider, setLogger } from "react-query"
import { ReactQueryDevtools } from "react-query/devtools"
import { TrackJS } from "trackjs"

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: 1,
        },
    },
})

setLogger({
    log: (message: string) => {
        // tslint:disable-next-line:no-console
        console.log(message)
    },
    warn: (message: string) => {
        // tslint:disable-next-line:no-console
        console.warn(message)
    },
    error: (error: Problem | HttpError | Error) => {
        if ((error instanceof Problem || error instanceof HttpError) && error.status === 401) {
            Auth0.instance.login()
        } else {
            if (TRACK_JS_TOKEN) {
                TrackJS.track(error)
            } else {
                // tslint:disable-next-line:no-console
                console.error(error)
            }
        }
    },
})

interface IReactQueryClientProviderProps {
    children: React.ReactNode
    showDevTools?: boolean
}

export const ReactQueryClientProvider: React.FC<IReactQueryClientProviderProps> = (props) => {
    const { children, showDevTools = false } = props

    return (
        <QueryClientProvider client={queryClient}>
            {children}
            {showDevTools && <ReactQueryDevtools initialIsOpen={false} />}
        </QueryClientProvider>
    )
}
