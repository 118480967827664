type Saga = () => Generator
type EmitCB = (saga: Saga) => void
class SagaRegistry {
    public static instance: SagaRegistry = new SagaRegistry()

    private _sagas: Saga[] = []
    private _emitChange: null | EmitCB = null

    public getSagas() {
        return { ...this._sagas }
    }

    public setChangeListener(listener: EmitCB) {
        if (this._emitChange != null) {
            throw new Error("Can only set the listener for a SagaRegistry once.")
        }
        this._emitChange = listener

        if (this._sagas.length > 0) {
            this._sagas.forEach((s) => {
                if (this._emitChange) {
                    this._emitChange(s)
                }
            })
        }
    }

    public register(saga: Saga) {
        const sagaIndex = this._sagas.findIndex((s) => s === saga)
        if (sagaIndex !== -1) {
            return
        }

        this._sagas.push(saga)

        if (this._emitChange !== null) {
            this._emitChange(saga)
        }
    }
}

const instance = SagaRegistry.instance
export { instance as SagaRegistry }
