import {
    batchFetchPersonOk,
    fetchPersonOk,
    updatePerson,
    updatePersonOk,
} from "modules/people/actions"
import { providerFetchOk } from "modules/people/Providers/People/sagas"
import {
    personProviderFetchPersonOk,
    personProviderUpdatePerson,
    personProviderUpdatePersonOk,
} from "modules/people/Providers/PersonProvider/sagas"
import { TrackJSErrorPayload } from "trackjs"
import { ActionType, getType } from "typesafe-actions"

// Use performance API if it's available
export const timer =
    typeof performance !== "undefined" &&
    performance !== null &&
    typeof performance.now === "function"
        ? performance
        : Date

type ActionTypes = ActionType<
    | typeof fetchPersonOk
    | typeof updatePersonOk
    | typeof updatePerson
    | typeof personProviderUpdatePerson
    | typeof personProviderUpdatePersonOk
    | typeof personProviderFetchPersonOk
    | typeof batchFetchPersonOk
    | typeof providerFetchOk
>

export const removeSensitiveDataFromAction = (action: ActionTypes): ActionTypes => {
    const clonedAction: ActionTypes = JSON.parse(JSON.stringify(action))
    switch (clonedAction.type) {
        case getType(fetchPersonOk):
        case getType(updatePerson):
        case getType(updatePersonOk): {
            clonedAction.payload.data.social_security_number = clonedAction.payload.data
                .social_security_number
                ? "REDACTED"
                : clonedAction.payload.data.social_security_number
            break
        }
        case getType(personProviderUpdatePerson):
        case getType(personProviderUpdatePersonOk):
        case getType(personProviderFetchPersonOk): {
            clonedAction.payload.data.social_security_number = clonedAction.payload.data
                .social_security_number
                ? "REDACTED"
                : clonedAction.payload.data.social_security_number
            break
        }
        case getType(providerFetchOk):
        case getType(batchFetchPersonOk):
            clonedAction.payload.data = clonedAction.payload.data.map((p) => {
                p.social_security_number = p.social_security_number ? "REDACTED" : undefined

                return p
            })
            break
    }
    return clonedAction
}

export const redactTokens = (payload: TrackJSErrorPayload) => {
    // id_token
    const idRegex = /id_token=([\w\d\\.\-]+)/gi
    const idRedactStr = "id_token=REDACTED"
    // access_token
    const accessRegex = /access_token=([\w\d\\.\-]+)/gi
    const accessRedactStr = "access_token=REDACTED"

    // @ts-ignore
    if (payload.environment.originalUrl) {
        // @ts-ignore
        payload.environment.originalUrl = (payload.environment.originalUrl as string).replace(
            idRegex,
            idRedactStr
        )
        // @ts-ignore
        payload.environment.originalUrl = (payload.environment.originalUrl as string).replace(
            accessRegex,
            accessRedactStr
        )
    }

    if (payload.nav) {
        const nextNav = JSON.parse(JSON.stringify(payload.nav)) as typeof payload.nav
        for (const nav of nextNav) {
            nav.from = nav.from.replace(idRegex, idRedactStr)
            nav.from = nav.from.replace(accessRegex, accessRedactStr)
        }

        payload.nav = nextNav
    }

    if (payload.url) {
        payload.url = payload.url.replace(idRegex, idRedactStr)
        payload.url = payload.url.replace(accessRegex, accessRedactStr)
    }

    return true
}
