import { Uri } from "modules/common/types"
import { buffers, END, eventChannel } from "redux-saga"

type emitterCb = (input: {} | END) => void

const lastProgressEmit: { [key: string]: number } = {}
const onProgress = (emitter: emitterCb, _: XMLHttpRequest, startTime: number, uploadUri: Uri) => (
    e: ProgressEvent
) => {
    const currentTime = Math.floor(Date.now() / 1000)

    // Upload link is only valid for 120 seconds, but we limit it to 116 to have some wickle room
    if (currentTime - startTime >= 116) {
        const error = new Error()
        error.name = "Timeout"
        emitter({
            error,
            timeout: true,
        })
        emitter(END)
    }

    const currentEmitTime = Math.floor(Date.now())
    // Only allow progress to be emitted once every 250ms
    if (!lastProgressEmit[uploadUri] || currentEmitTime - lastProgressEmit[uploadUri] >= 250) {
        const progress = (e.loaded / e.total) * 100
        emitter({
            progress,
        })
        lastProgressEmit[uploadUri] = currentEmitTime
    }
}

const onFailure = (emitter: emitterCb, xhr: XMLHttpRequest) => () => {
    const error = new Error()
    error.name = xhr.statusText
    error.message = xhr.responseText ? xhr.responseText : xhr.response
    emitter({ error })
    emitter(END)
}

const onAbort = (emitter: emitterCb) => () => {
    emitter({ abort: true })
    emitter(END)
}

export interface IFIleProgressChannel {
    progress?: number
    success?: boolean
    abort?: boolean
    error?: any
    timeout?: Error
}

export function createUploadFileChannel(uploadUri: Uri, rawFile: File) {
    return eventChannel<IFIleProgressChannel>((emitter) => {
        const startTime = Math.floor(Date.now() / 1000)

        if ("ActiveXObject" in window) {
            fetch(uploadUri, {
                body: rawFile,
                method: "PUT",
            })
                .then((res) => {
                    if (res.status === 200) {
                        emitter({ success: true })
                        emitter(END)
                    } else {
                        const error = new Error()
                        error.name = res.statusText
                        emitter({ error })
                        emitter(END)
                    }
                })
                .catch((e) => {
                    emitter({ error: e })
                    emitter(END)
                })

            return () => {
                // Just something for IE11 🙄🤷‍♂️
            }
        } else {
            const xhr = new XMLHttpRequest()

            const onProgressHandler = onProgress(emitter, xhr, startTime, uploadUri)
            const onFailureHandler = onFailure(emitter, xhr)
            const onAbortHandler = onAbort(emitter)

            if (xhr.upload) {
                xhr.upload.addEventListener("progress", onProgressHandler)
                xhr.upload.addEventListener("error", onFailureHandler)
                xhr.upload.addEventListener("abort", onAbortHandler)
            }

            xhr.onreadystatechange = () => {
                const { readyState, status } = xhr
                if (readyState === 4) {
                    if (status === 200) {
                        emitter({ success: true })
                        emitter(END)
                    } else {
                        onFailureHandler()
                    }
                }
            }

            xhr.open("PUT", uploadUri, true)
            xhr.send(rawFile)

            return () => {
                if (xhr.upload) {
                    xhr.upload.removeEventListener("progress", onProgressHandler)
                    xhr.upload.removeEventListener("error", onFailureHandler)
                    xhr.upload.removeEventListener("abort", onAbortHandler)
                }
                xhr.onreadystatechange = null
                xhr.abort()
            }
        }
    }, buffers.sliding(2))
}
