import { Button, Brandmark as DSBrandmark } from "@clearhaus/design-system"
import React from "react"

const Icon = <DSBrandmark size={24} />

export const Brandmark: React.FC = () => {
    const handleOnClick = () => {
        if (!process.env.AUTH_REDIRECT_URI) {
            return
        }

        window.location.href = process.env.AUTH_REDIRECT_URI
    }

    return (
        <Button color="secondary" kind="plain" onClick={handleOnClick} size="large" prefix={Icon} />
    )
}
