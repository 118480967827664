import { Problem } from "ketting"
import { RxMiddleware } from "modules/common/RxMiddleware"
import { Uri } from "modules/common/types"
import companiesRootSaga from "modules/companies/sagas"
import { createCustomAction } from "typesafe-actions"
import { SagaRegistry } from "utils/SagaRegistry"
import * as actions from "./actions"
import {
    FETCH_COMPANY,
    FETCH_COMPANY_ERROR,
    FETCH_COMPANY_OK,
    ICompanyData,
    ICompanyRelations,
    UPDATE_COMPANY,
    UPDATE_COMPANY_ERROR,
    UPDATE_COMPANY_OK,
} from "./types"

export const fetchCompany = createCustomAction(FETCH_COMPANY, (uri: Uri) => ({
    payload: { uri },
}))
export const fetchCompanyOk = createCustomAction(
    FETCH_COMPANY_OK,
    (data: ICompanyData, relations: ICompanyRelations, uri: Uri) => ({
        payload: { data, relations },
        meta: { uri },
    })
)
export const fetchCompanyError = createCustomAction(
    FETCH_COMPANY_ERROR,
    (error: Problem, uri: Uri) => ({ payload: error, meta: { uri } })
)
export const updateCompany = createCustomAction(UPDATE_COMPANY, (data: ICompanyData, uri: Uri) => ({
    payload: { data, uri },
}))
export const updateCompanyOk = createCustomAction(
    UPDATE_COMPANY_OK,
    (data: ICompanyData, relations: ICompanyRelations, updateData: ICompanyData, uri: Uri) => ({
        payload: { data, relations },
        meta: { uri, data: updateData },
    })
)

export const updateCompanyError = createCustomAction(
    UPDATE_COMPANY_ERROR,
    (error: Problem, uri: Uri) => ({
        meta: { uri },
        payload: error,
    })
)

RxMiddleware.addActions(actions)
SagaRegistry.register(companiesRootSaga)
