import { Problem } from "ketting"
import {
    initializeProvider,
    providerFetchAccounts,
    providerFetchAccountsError,
    providerFetchAccountsOk,
    providerLoadMore,
    providerLoadMoreError,
    providerLoadMoreOk,
    teardownProvider,
} from "modules/accounts/Providers/Accounts/sagas"
import { IAccount, IAccountsData, IAccountsRelations } from "modules/accounts/types"
import { EntityIndex } from "modules/common/types"
import { Reducer } from "redux"
import { ActionType, getType } from "typesafe-actions"

export interface IAccountsProviderState {
    data: IAccount[]
    metadata: IAccountsData | null
    relations: IAccountsRelations | null
    isLoading: boolean
    error?: Problem
}

export interface IAccountsProvidersRootState {
    accountsProviders: EntityIndex<IAccountsProviderState>
}

type ActionTypes = ActionType<
    | typeof initializeProvider
    | typeof teardownProvider
    | typeof providerFetchAccounts
    | typeof providerFetchAccountsOk
    | typeof providerFetchAccountsError
    | typeof providerLoadMore
    | typeof providerLoadMoreOk
    | typeof providerLoadMoreError
>

export const accountsProvidersReducer: Reducer<EntityIndex<IAccountsProviderState>, ActionTypes> = (
    currentState,
    action
) => {
    let nextState
    if (!currentState) {
        currentState = {}
    }

    nextState = currentState

    switch (action.type) {
        case getType(initializeProvider): {
            const { id } = action.payload
            nextState = {
                ...nextState,
                [id]: {
                    data: [],
                    isLoading: false,
                    metadata: null,
                    relations: null,
                },
            }
            break
        }
        case getType(teardownProvider): {
            const { id } = action.payload
            delete nextState[id]
            break
        }
        case getType(providerLoadMore):
        case getType(providerFetchAccounts): {
            const { id } = action.payload
            nextState = {
                ...nextState,
                [id]: {
                    ...nextState[id],
                    isLoading: true,
                },
            }
            break
        }
        case getType(providerFetchAccountsOk): {
            const { id } = action.meta
            const { data, metadata, relations } = action.payload
            nextState = {
                ...nextState,
                [id]: {
                    ...nextState[id],
                    data,
                    isLoading: false,
                    metadata,
                    relations,
                },
            }
            break
        }
        case getType(providerLoadMoreError):
        case getType(providerFetchAccountsError): {
            const { id } = action.meta
            nextState = {
                ...nextState,
                [id]: {
                    ...nextState[id],
                    error: action.payload,
                    isLoading: false,
                },
            }
            break
        }
        case getType(providerLoadMoreOk): {
            const { id } = action.meta
            const { data, metadata, relations } = action.payload
            nextState = {
                ...nextState,
                [id]: {
                    ...nextState[id],
                    data: [...nextState[id].data, ...data],
                    isLoading: false,
                    metadata,
                    relations,
                },
            }
            break
        }
    }

    return nextState
}
