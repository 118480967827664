import { fork, put, take, takeEvery } from "@redux-saga/core/effects"
import { getMe, getMeOk, updateMe } from "modules/me/actions"
import { setActiveLanguage } from "react-localize-redux"
import { ActionType, getType } from "typesafe-actions"
import languageCodeToLocale from "utils/languageCodeToLocale"
import { SagaRegistry } from "utils/SagaRegistry"

function* handleSetActiveLanguage(action: ActionType<typeof setActiveLanguage>) {
    const locale = languageCodeToLocale(action.payload.languageCode)
    yield put(getMe())
    const { payload: meOkPayload }: ActionType<typeof getMeOk> = yield take(getType(getMeOk))
    const { type, email, ...updateMeDto } = meOkPayload.data

    yield put(updateMe({ ...updateMeDto, locale }))
}

function* watchSetActiveLanguage() {
    yield takeEvery("@@localize/SET_ACTIVE_LANGUAGE", handleSetActiveLanguage)
}

function* updateLocaleOnSetActivelanguageRootSaga() {
    yield fork(watchSetActiveLanguage)
}

SagaRegistry.register(updateLocaleOnSetActivelanguageRootSaga)
