import * as localizedYup from "yup"

localizedYup.setLocale({
    mixed: {
        required: `common.yup.mixed.required`,
    },
    string: {
        email: `common.yup.string.email`,
        max: 'common.yup.string.max|{"max":${max}}',
        min: 'common.yup.string.min|{"min":${min}}',
    },
})

export { localizedYup }
export default localizedYup
