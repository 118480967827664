import { Auth0 } from "modules/authentication/Auth0"
import { useUpdateMe } from "modules/me/queries"
import { IUpdateUserAppSettings } from "modules/user-app-settings/types"
import { useQuery, useQueryClient } from "react-query"

const USER_APP_SETTINGS_KEY = "user_app_settings"

const fetchUserAppSettings = () => {
    return Auth0.instance.getAppMetadata().status
}

export function useUserAppSettings() {
    return useQuery([USER_APP_SETTINGS_KEY], fetchUserAppSettings, {
        select: (data) => {
            try {
                return data ? JSON.parse(window.atob(data)) : null
            } catch {
                return null
            }
        },
        refetchOnWindowFocus: false,
    })
}

export function useUpdateUserAppSettings() {
    const queryClient = useQueryClient()

    const { data } = useUserAppSettings()
    const { mutate: _, mutateAsync: updateMeAsync, isLoading, isError } = useUpdateMe()

    const mutate = async (update: IUpdateUserAppSettings) => {
        try {
            const updated = {
                ...data,
                ...update,
            }

            // Encode updated status
            const status = window.btoa(JSON.stringify(updated))

            // update me endpoint using "updateMeAsync", which returns a promise
            await updateMeAsync({ status })

            // Set query data manually, which makes the current user settings info available in "useUserSettings" hook
            queryClient.setQueryData([USER_APP_SETTINGS_KEY], status)
        } catch (e) {
            // tslint:disable-next-line:no-console
            console.error(e)
        }
    }

    return { mutate, isLoading, isError }
}
