import { BoxEdges, Grid } from "@clearhaus/design-system"
import {
    IEmptyStateCommonProps,
    useEmptyStateContext,
} from "components/common/EmptyState/EmptyState"
import React from "react"

export const Actions: React.FC<IEmptyStateCommonProps> = (props) => {
    useEmptyStateContext()

    return (
        <BoxEdges mt={32} mb={!props.noMargin ? 16 : 0}>
            <Grid row justify="center" alignItems="center">
                <Grid item auto>
                    {props.children}
                </Grid>
            </Grid>
        </BoxEdges>
    )
}
