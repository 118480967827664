import { ConfirmSubscriptionContext } from "apps/ConfirmSubscription"
import { Confirmed, Enabled, Error } from "apps/ConfirmSubscription/components"
import React, { useContext } from "react"

export const Message: React.FC = () => {
    const { data, isError } = useContext(ConfirmSubscriptionContext)

    if (isError) {
        return <Error />
    }

    if (data?.isEnabled) {
        return <Enabled />
    }

    return <Confirmed />
}
