import { shallowEqual } from "fast-equals"
import { Problem } from "ketting"
import {
    batchFetchAccount,
    batchFetchAccountOk,
    fetchAccounts,
    fetchAccountsError,
    fetchAccountsOk,
} from "modules/accounts/actions"
import { accountsProvidersReducer } from "modules/accounts/Providers/Accounts/reducer"
import { getNextUri } from "modules/accounts/Providers/Accounts/selectors"
import { IAccount, IAccountsData, IAccountsRelations } from "modules/accounts/types"
import { cancelAction, forkWatcher } from "modules/common/actions"
import { EntityIndex, Uri } from "modules/common/types"
import { fork, put, race, select, take, takeEvery } from "redux-saga/effects"
import { ActionType, createCustomAction, getType, isActionOf } from "typesafe-actions"
import { ReducerRegistry } from "utils/ReducerRegistry"
import { SagaRegistry } from "utils/SagaRegistry"

const PROVIDER_FETCH_ACCOUNTS = "@@accounts/PROVIDERS/ACCOUNTS/FETCH"
const PROVIDER_FETCH_ACCOUNTS_OK = "@@accounts/PROVIDERS/ACCOUNTS/FETCH_OK"
const PROVIDER_FETCH_ACCOUNTS_ERROR = "@@accounts/PROVIDERS/ACCOUNTS/FETCH_ERROR"
export const providerFetchAccounts = createCustomAction(
    PROVIDER_FETCH_ACCOUNTS,
    (
        id: string,
        uri: Uri,
        per_page?: number,
        page?: number,
        query?: string,
        noCache?: boolean
    ) => ({
        payload: { uri, per_page, page, query, id, noCache },
    })
)
export const providerFetchAccountsOk = createCustomAction(
    PROVIDER_FETCH_ACCOUNTS_OK,
    (
        metadata: IAccountsData,
        relations: IAccountsRelations,
        data: IAccount[],
        id: string,
        uri: Uri,
        per_page?: number,
        page?: number,
        query?: string,
        noCache?: boolean
    ) => ({
        meta: { uri, per_page, page, query, id, noCache },
        payload: {
            data,
            metadata,
            relations,
        },
    })
)
export const providerFetchAccountsError = createCustomAction(
    PROVIDER_FETCH_ACCOUNTS_ERROR,
    (
        e: Problem,
        id: string,
        uri: Uri,
        per_page?: number,
        page?: number,
        query?: string,
        noCache?: boolean
    ) => ({
        meta: { uri, per_page, page, query, id, noCache },
        payload: e,
    })
)

export function* handleProviderFetchAccounts(action: ActionType<typeof providerFetchAccounts>) {
    const { uri, per_page, page, query, id, noCache } = action.payload
    const { id: _, ...fetchAccountsMeta } = action.payload

    yield put(fetchAccounts(uri, per_page, page, query, noCache))
    const [success, error]: [
        ActionType<typeof fetchAccountsOk> | undefined,
        ActionType<typeof fetchAccountsError> | undefined
    ] = yield race([
        take((a: ActionType<typeof fetchAccountsOk>) => {
            return isActionOf(fetchAccountsOk, a) && shallowEqual(a.meta, fetchAccountsMeta)
        }),
        take((a: ActionType<typeof fetchAccountsError>) => {
            return isActionOf(fetchAccountsError, a) && shallowEqual(a.meta, fetchAccountsMeta)
        }),
    ])

    if (success) {
        const { data, relations } = success.payload
        const uris = relations.accounts
            ? Array.isArray(relations.accounts)
                ? relations.accounts
                : [relations.accounts]
            : []
        if (uris) {
            yield put(batchFetchAccount(uris))
            const { payload: batchOkPayload }: ActionType<typeof batchFetchAccountOk> = yield take(
                (a: ActionType<typeof batchFetchAccountOk>) => {
                    return isActionOf(batchFetchAccountOk, a) && shallowEqual(a.meta.uris, uris)
                }
            )

            yield put(
                providerFetchAccountsOk(
                    data,
                    relations,
                    batchOkPayload.accounts,
                    id,
                    uri,
                    per_page,
                    page,
                    query,
                    noCache
                )
            )
        } else {
            yield put(
                providerFetchAccountsOk(
                    data,
                    relations,
                    [],
                    id,
                    uri,
                    per_page,
                    page,
                    query,
                    noCache
                )
            )
        }
    } else if (error) {
        yield put(
            providerFetchAccountsError(error.payload, id, uri, per_page, page, query, noCache)
        )
    }
}

const LOAD_MORE = "@@accounts/PROVIDERS/ACCOUNTS/LOAD_MORE"
const LOAD_MORE_OK = "@@accounts/PROVIDERS/ACCOUNTS/LOAD_MORE_OK"
const LOAD_MORE_ERROR = "@@accounts/PROVIDERS/ACCOUNTS/LOAD_MORE_ERROR"
export const providerLoadMore = createCustomAction(LOAD_MORE, (id: string, uri: Uri) => ({
    payload: { uri, id },
}))
export const providerLoadMoreOk = createCustomAction(
    LOAD_MORE_OK,
    (
        metadata: IAccountsData,
        relations: IAccountsRelations,
        data: IAccount[],
        id: string,
        uri: Uri
    ) => ({
        meta: { uri, id },
        payload: { data, metadata, relations },
    })
)
export const providerLoadMoreError = createCustomAction(
    LOAD_MORE_ERROR,
    (e: Problem, id: string, uri: Uri) => ({
        meta: { uri, id },
        payload: e,
    })
)

export function* handleProviderLoadMore(action: ActionType<typeof providerLoadMore>) {
    const { uri, id } = action.payload

    const nextUri: Uri | undefined | null = yield select(getNextUri(id))

    if (nextUri) {
        yield put(fetchAccounts(nextUri))
        const [success, error]: [
            ActionType<typeof fetchAccountsOk> | undefined,
            ActionType<typeof fetchAccountsError> | undefined
        ] = yield race([
            take((a: ActionType<typeof fetchAccountsOk>) => {
                return isActionOf(fetchAccountsOk, a) && a.meta.uri === nextUri
            }),
            take((a: ActionType<typeof fetchAccountsError>) => {
                return isActionOf(fetchAccountsError, a) && a.meta.uri === nextUri
            }),
        ])

        if (success) {
            const { relations, data } = success.payload
            const uris = relations.accounts
                ? Array.isArray(relations.accounts)
                    ? relations.accounts
                    : [relations.accounts]
                : []
            if (uris) {
                yield put(batchFetchAccount(uris))
                const {
                    payload: batchOkPayload,
                }: ActionType<typeof batchFetchAccountOk> = yield take(
                    (a: ActionType<typeof batchFetchAccountOk>) => {
                        return isActionOf(batchFetchAccountOk, a) && shallowEqual(a.meta.uris, uris)
                    }
                )

                yield put(providerLoadMoreOk(data, relations, batchOkPayload.accounts, id, uri))
            } else {
                yield put(providerLoadMoreOk(data, relations, [], id, uri))
            }
        } else if (error) {
            yield put(providerLoadMoreError(error.payload, id, uri))
        }
    }
}

const PROVIDER = "@@accounts/PROVIDERS/ACCOUNTS"
const INITIALIZE_PROVIDER = "@@accounts/PROVIDERS/ACCOUNTS/INITIALIZE"
const TEARDOWN_PROVIDER = "@@accounts/PROVIDERS/ACCOUNTS/TEARDOWN"
export const initializeProvider = createCustomAction(
    INITIALIZE_PROVIDER,
    (id: string, uri: Uri, per_page?: number, page?: number, query?: string) => ({
        payload: { uri, per_page, query, page, id },
    })
)
export const teardownProvider = createCustomAction(TEARDOWN_PROVIDER, (id: string, uri: Uri) => ({
    payload: { uri, id },
}))

const providerContructor = (id: string) => createCustomAction(`${PROVIDER}/${id}`)

const providerSagaCache: EntityIndex<GeneratorFunction> = {}
export const handleProvider = (id: string) => {
    if (!providerSagaCache[`${id}`]) {
        providerSagaCache[`${id}`] = function* () {
            yield takeEvery((a: ActionType<typeof providerFetchAccounts>) => {
                return isActionOf(providerFetchAccounts, a) && a.payload.id === id
            }, handleProviderFetchAccounts)
            yield takeEvery((a: ActionType<typeof providerLoadMore>) => {
                return isActionOf(providerLoadMore, a) && a.payload.id === id
            }, handleProviderLoadMore)
        } as GeneratorFunction
    }

    return providerSagaCache[`${id}`]
}

export function* handleInitializeProvider(action: ActionType<typeof initializeProvider>) {
    const { uri, per_page, page, query, id } = action.payload

    yield put(forkWatcher(getType(providerContructor(id)), handleProvider(id)))
    yield put(providerContructor(id)())
    yield put(providerFetchAccounts(id, uri, per_page, page, query))
}

export function* handleTeardownProvider(action: ActionType<typeof teardownProvider>) {
    const { id } = action.payload

    yield put(cancelAction(getType(providerContructor(id)), false))
}

export function* watchProviderSaga() {
    yield takeEvery(getType(initializeProvider), handleInitializeProvider)
    yield takeEvery(getType(teardownProvider), handleTeardownProvider)
}

function* providerRootSaga() {
    yield fork(watchProviderSaga)
}

SagaRegistry.register(providerRootSaga)
ReducerRegistry.register({
    accountsProviders: accountsProvidersReducer,
})
