import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { fetchRoot } from "./actions"
import { IRootRootState, IRootState } from "./reducer"

export default () => {
    const dispatch = useDispatch()
    const rootState = useSelector<IRootRootState, IRootState>((s) => s.root)

    useEffect(() => {
        if (!rootState.applications) {
            dispatch(fetchRoot())
        }
    }, [])

    return rootState
}
