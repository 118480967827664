import { Grid } from "@clearhaus/design-system"
import { Actions, Description, Icon, Title } from "components/common/EmptyState/compounds"
import React, { createContext, useContext, useMemo } from "react"

export interface IEmptyStateCommonProps {
    noMargin?: boolean
    children?: React.ReactNode
}

const EmptyStateContext = createContext<{} | null>(null)

export const useEmptyStateContext = () => {
    const context = useContext(EmptyStateContext)

    if (!context) {
        throw new Error(
            "EmptyState compound component cannot be rendered outside the EmptyState component"
        )
    }

    return context
}

const containerStyle = { display: "flex", height: "100%" }
const EmptyState = (props: { children?: React.ReactNode }) => {
    const contextValue = useMemo(() => ({}), [])

    return (
        <EmptyStateContext.Provider value={contextValue}>
            <Grid container containerSize="sm" style={containerStyle}>
                <Grid row grow={1} alignItems="center" justify="center">
                    <Grid item auto>
                        {props.children}
                    </Grid>
                </Grid>
            </Grid>
        </EmptyStateContext.Provider>
    )
}

EmptyState.Title = Title
EmptyState.Description = Description
EmptyState.Actions = Actions
EmptyState.Icon = Icon

export { EmptyState }
