import { Problem } from "ketting"
import accountRootSaga from "modules/accounts/sagas"
import {
    IAccount,
    IAccountData,
    IAccountRelations,
    IAccountsData,
    IAccountsRelations,
} from "modules/accounts/types"
import { RxMiddleware } from "modules/common/RxMiddleware"
import { Uri } from "modules/common/types"
import { createCustomAction } from "typesafe-actions"
import { SagaRegistry } from "utils/SagaRegistry"
import * as actions from "./actions"

const FETCH_ACCOUNT = "@@accounts/FETCH_ACCOUNT"
const FETCH_ACCOUNT_OK = "@@accounts/FETCH_ACCOUNT_OK"
const FETCH_ACCOUNT_ERROR = "@@accounts/FETCH_ACCOUNT_ERROR"
export const fetchAccount = createCustomAction(FETCH_ACCOUNT, (uri: Uri, noCache?: boolean) => ({
    payload: { uri, noCache },
}))
export const fetchAccountOk = createCustomAction(
    FETCH_ACCOUNT_OK,
    (data: IAccountData, relations: IAccountRelations, uri: Uri, noCache?: boolean) => ({
        payload: { data, relations },
        meta: { uri, noCache },
    })
)
export const fetchAccountError = createCustomAction(
    FETCH_ACCOUNT_ERROR,
    (e: Problem, uri: Uri, noCache?: boolean) => ({
        payload: e,
        meta: { uri, noCache },
    })
)

const FETCH_ACCOUNTS = "@@accounts/FETCH_ACCOUNTS"
const FETCH_ACCOUNTS_OK = "@@accounts/FETCH_ACCOUNTS_OK"
const FETCH_ACCOUNTS_ERROR = "@@accounts/FETCH_ACCOUNTS_ERROR"
export const fetchAccounts = createCustomAction(
    FETCH_ACCOUNTS,
    (uri: Uri, per_page?: number, page?: number, query?: string, noCache?: boolean) => ({
        payload: { uri, per_page, page, query, noCache },
    })
)
export const fetchAccountsOk = createCustomAction(
    FETCH_ACCOUNTS_OK,
    (
        data: IAccountsData,
        relations: IAccountsRelations,
        uri: Uri,
        per_page?: number,
        page?: number,
        query?: string,
        noCache?: boolean
    ) => ({
        meta: { uri, per_page, page, query, noCache },
        payload: { data, relations },
    })
)
export const fetchAccountsError = createCustomAction(
    FETCH_ACCOUNTS_ERROR,
    (
        e: Problem,
        uri: Uri,
        per_page?: number,
        page?: number,
        query?: string,
        noCache?: boolean
    ) => ({
        meta: { uri, per_page, page, query, noCache },
        payload: e,
    })
)

const BATCH_FETCH_ACCOUNT = "@@accounts/BATCH_FETCH_ACCOUNT"
const BATCH_FETCH_ACCOUNT_OK = "@@accounts/BATCH_FETCH_ACCOUNT_OK"
const BATCH_FETCH_ACCOUNT_ERROR = "@@accounts/BATCH_FETCH_ACCOUNT_ERROR"
export const batchFetchAccount = createCustomAction(BATCH_FETCH_ACCOUNT, (uris: Uri[]) => ({
    payload: { uris },
}))
export const batchFetchAccountOk = createCustomAction(
    BATCH_FETCH_ACCOUNT_OK,
    (accounts: IAccount[], uris: Uri[]) => ({
        meta: { uris },
        payload: { accounts },
    })
)
export const batchFetchAccountError = createCustomAction(
    BATCH_FETCH_ACCOUNT_ERROR,
    (e: Problem, uris: Uri[]) => ({
        meta: { uris },
        payload: e,
    })
)

RxMiddleware.addActions(actions)
SagaRegistry.register(accountRootSaga)
