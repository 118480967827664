import { LocalizationLoader } from "components/common"
import { ContextDataFeedback } from "components/ContextDataFeedback"
import { Navbar } from "components/MasterNavigation/components/Navbar"
import { Sidebar } from "components/MasterNavigation/components/Sidebar"
import { useRoot } from "modules/root"
import React, { useCallback, useMemo, useState } from "react"
import { AccountsContext } from "../../modules/accounts/Providers/Accounts/AccountsContext"
import { AccountsProvider } from "../../modules/accounts/Providers/Accounts/AccountsProvider"
import useCallbackRef from "../../utils/useCallbackRef"

type NavigationColors = "light" | "dark"
interface IMasterNavigationContext {
    currentColor: NavigationColors
    setColor: (color: NavigationColors) => void
    currentHeight: number
    setHeight: (height: number) => void
}
export const MasterNavigationContext = React.createContext<IMasterNavigationContext>({
    currentColor: "light",
    setColor: () => {
        throw Error(`Not overridden`)
    },
    currentHeight: 0,
    setHeight: () => {
        throw Error(`Not overridden`)
    },
})

interface IMasterNavigationProviderProps {
    children: React.ReactNode
}
export const MasterNavigationProvider: React.FC<IMasterNavigationProviderProps> = (props) => {
    const [currentColor, setCurrentColor] = useState<NavigationColors>("light")
    const [currentHeight, setCurrentHeight] = useState<number>(0)
    function setColor(color: NavigationColors) {
        setCurrentColor(color)
    }

    function setHeight(height: number) {
        setCurrentHeight(height)
    }

    const masterNavigationContextValue = useMemo(
        () => ({
            currentColor,
            setColor,
            currentHeight,
            setHeight,
        }),
        [currentColor, currentHeight]
    )

    return (
        <MasterNavigationContext.Provider value={masterNavigationContextValue}>
            {props.children}
        </MasterNavigationContext.Provider>
    )
}

interface IMasterNavigationProps {
    heightPostMount?: (height: number) => void
}

const localizationNamespaces = ["navigation"]
export function MasterNavigation(props: IMasterNavigationProps) {
    const { accounts } = useRoot()
    const [showMainNavigation, setShowMainNavigation] = useState(false)
    const [sidebarOpen, setSidebarOpen] = useState(false)

    const openSidebar = useCallback(() => {
        setSidebarOpen(true)
    }, [sidebarOpen])

    const closeSidebar = useCallback(() => {
        setSidebarOpen(false)
    }, [sidebarOpen])

    const onAccountsDataChange = useCallbackRef((accounts) => {
        if (accounts.length !== 0) {
            setShowMainNavigation(true)
        }
    })

    return (
        <>
            {accounts && (
                <AccountsProvider uri={accounts} per_page={1} id="navigationProvider">
                    <ContextDataFeedback
                        context={AccountsContext}
                        dataProperty="data"
                        onDataChange={onAccountsDataChange}
                    />
                </AccountsProvider>
            )}
            <LocalizationLoader namespace={localizationNamespaces}>
                {showMainNavigation && <Sidebar open={sidebarOpen} closeSidebar={closeSidebar} />}
                <Navbar
                    onOpenSidebar={openSidebar}
                    heightPostMount={props.heightPostMount}
                    showMainNavigation={showMainNavigation}
                />
            </LocalizationLoader>
        </>
    )
}
