import { defaults, ThemeProvider } from "@clearhaus/design-system"
import AuthProvider from "components/authentication/AuthProvider"
import { HistorySetter } from "components/common/HistorySetter"
import { Main } from "main"
import { PUBLIC_PATH } from "modules/common"
import { ReactQueryClientProvider } from "modules/common/react-query/ReactQueryClientProvider"
import React from "react"
import { AppContainer } from "react-hot-loader"
import "react-hot-loader/patch"
import { LocalizeProvider } from "react-localize-redux"
import { Provider } from "react-redux"
import { Provider as ReduxHooksProvider } from "react-redux-hooks"
import { BrowserRouter } from "react-router-dom"
import { devMode } from "utils/envHelpers"
import { handleOnLoggedIn } from "utils/handleOnLoggedIn"
import configureStore from "../../bootstrapStore"
import ErrorBoundary from "../../components/common/ErrorBoundary"

const ShouldAddHMR = (props: any) => {
    if (devMode) {
        return <AppContainer>{props.children}</AppContainer>
    } else {
        return props.children
    }
}

const { store } = configureStore()

export const Default = () => {
    return (
        <ShouldAddHMR>
            <ReactQueryClientProvider>
                <Provider store={store}>
                    <ReduxHooksProvider store={store}>
                        <LocalizeProvider store={store}>
                            <ThemeProvider defaults={defaults}>
                                <ErrorBoundary>
                                    <BrowserRouter basename={PUBLIC_PATH}>
                                        <HistorySetter>
                                            <AuthProvider onLoggedIn={handleOnLoggedIn}>
                                                <Main />
                                            </AuthProvider>
                                        </HistorySetter>
                                    </BrowserRouter>
                                </ErrorBoundary>
                            </ThemeProvider>
                        </LocalizeProvider>
                    </ReduxHooksProvider>
                </Provider>
            </ReactQueryClientProvider>
        </ShouldAddHMR>
    )
}
