import { Button, Loader } from "@clearhaus/design-system"
import React from "react"

interface ILoadMoreButtonProps {
    isLoading?: boolean
    onClick: () => void
    children: React.ReactNode
}

export const LoadMoreButton: React.FC<ILoadMoreButtonProps> = (props) => {
    return (
        <Button
            fullWidth
            onClick={props.onClick}
            prefix={props.isLoading ? <Loader size={14} fillToParent noBackdrop /> : undefined}
            disabled={props.isLoading}
            kind={"squared"}
            color={"primary"}
            light
        >
            {props.children}
        </Button>
    )
}
