import { Problem } from "ketting"
import {
    GET_SUPPORTED_COUNTRIES,
    GET_SUPPORTED_COUNTRIES_OK,
    LookedUpCompany,
    LOOKUP_COMPANY,
    LOOKUP_COMPANY_ERROR,
    LOOKUP_COMPANY_NOT_FOUND,
    LOOKUP_COMPANY_OK,
} from "modules/voyager/types"
import { createCustomAction } from "typesafe-actions"

export const getSupportedCountries = createCustomAction(GET_SUPPORTED_COUNTRIES)
export const getSupportedCountriesOk = createCustomAction(
    GET_SUPPORTED_COUNTRIES_OK,
    (countries: string[]) => ({ payload: countries })
)

export const lookupCompany = createCustomAction(
    LOOKUP_COMPANY,
    (registrationNumber: string, country: string) => ({
        meta: country,
        payload: registrationNumber,
    })
)
export const lookupCompanyOk = createCustomAction(
    LOOKUP_COMPANY_OK,
    (
        lookedUpCompany: LookedUpCompany,
        lookupMeta: { country: string; registrationNumber: string }
    ) => ({ payload: lookedUpCompany, meta: lookupMeta })
)
export const lookupCompanyNotFound = createCustomAction(
    LOOKUP_COMPANY_NOT_FOUND,
    (lookupMeta: { country: string; registrationNumber: string }) => ({ payload: lookupMeta })
)
export const lookupCompanyError = createCustomAction(
    LOOKUP_COMPANY_ERROR,
    (problem: Problem, lookupMeta: { country: string; registrationNumber: string }) => ({
        meta: lookupMeta,
        payload: problem,
    })
)
