import { BoxEdges, Grid, NoticeBox, Spacer, ThemeContext } from "@clearhaus/design-system"
import { ColorNames } from "@clearhaus/design-system/defaults"
import { ContextDataFeedback } from "components/ContextDataFeedback"
import { DateTime } from "components/DateTime"
import { IServiceMessageType } from "components/ServiceMessages/Provider/reducer"
import { ServiceMessagesDataContext } from "components/ServiceMessages/Provider/ServiceMessagesDataContext"
import { ServiceMessagesDataProvider } from "components/ServiceMessages/Provider/ServiceMessagesDataProvider"
import { ServiceMessagesContext } from "components/ServiceMessages/ServiceMessagesProvider"
import { MeContext } from "modules/me/Provider/context"
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { Translate } from "react-localize-redux"

export const ServiceMessages: React.FC = () => {
    const { defaults } = useContext(ThemeContext)
    const { data: me } = useContext(MeContext)
    const { style } = useContext(ServiceMessagesContext)
    const [serviceMessages, setServiceMessages] = useState<IServiceMessageType[]>([])
    const [serviceMessageCount, setServiceMessageCount] = useState(0)
    const [currentLanguage, setCurrentLanguage] = useState("en")

    const linkStyle = useMemo(
        () => ({
            color: defaults.colors.neutrals[900],
            fontWeight: defaults.fontWeights.normal,
        }),
        []
    )

    const titleStyle = useMemo(
        () => ({
            fontWeight: defaults.fontWeights.medium,
        }),
        []
    )

    const filteredMessages = useMemo(() => {
        return serviceMessages.filter((m) => {
            // Include incidents and maintainance messages
            if (!m.langauge) {
                return true
            }

            // Only include support messages for current language
            return m.langauge.toLowerCase() === currentLanguage.toLowerCase()
        })
    }, [serviceMessages, currentLanguage])

    useEffect(() => {
        setServiceMessageCount(filteredMessages.length)
    }, [filteredMessages])

    useEffect(() => {
        if (me && me.locale) {
            const nextLanguage = me.locale.slice(0, 2)
            if (nextLanguage !== currentLanguage) {
                setCurrentLanguage(nextLanguage)
            }
        }
    }, [me, currentLanguage])

    const handleDismissedNotice = useCallback(() => {
        const nextServiceMessageCount = serviceMessageCount - 1
        if (nextServiceMessageCount < 1) {
            setServiceMessageCount(0)
        } else {
            setServiceMessageCount(nextServiceMessageCount)
        }
    }, [serviceMessageCount])

    if (!me) {
        return null
    }

    return (
        <>
            <ServiceMessagesDataProvider>
                <ContextDataFeedback
                    context={ServiceMessagesDataContext}
                    onDataChange={setServiceMessages}
                    dataProperty={"messages"}
                />
            </ServiceMessagesDataProvider>
            {serviceMessages.length > 0 && serviceMessageCount > 0 && (
                <BoxEdges pt={24} mr={8} ml={8} style={style}>
                    {filteredMessages.map((m, index) => {
                        if (!m.langauge) {
                            let noticeKind: ColorNames = "primary"

                            if (m.status !== "resolved" && m.status !== "scheduled") {
                                noticeKind = "accents-error"
                            }

                            return (
                                <React.Fragment key={`service-message-${index}`}>
                                    <Grid row>
                                        <Grid item>
                                            <NoticeBox
                                                dismissable
                                                onDismissed={handleDismissedNotice}
                                                kind={noticeKind}
                                                tight
                                            >
                                                <NoticeBox.Content>
                                                    {m.scheduled_for && m.scheduled_until && (
                                                        <>
                                                            <span style={titleStyle}>
                                                                {m.title}
                                                            </span>
                                                            {". "}
                                                            <Translate
                                                                id={
                                                                    "common.service_messages.take_place_between"
                                                                }
                                                            />{" "}
                                                            <DateTime
                                                                size={16}
                                                                fontFamily="regular"
                                                            >
                                                                {m.scheduled_for}
                                                            </DateTime>{" "}
                                                            <Translate
                                                                id={"common.service_messages.and"}
                                                            />{" "}
                                                            <DateTime
                                                                size={16}
                                                                fontFamily="regular"
                                                            >
                                                                {m.scheduled_until}
                                                            </DateTime>
                                                        </>
                                                    )}
                                                    {!m.scheduled_for && <>{m.title}</>}
                                                    {m.shortlink && (
                                                        <>
                                                            {". "}
                                                            <a
                                                                href={m.shortlink}
                                                                style={linkStyle}
                                                                target="_blank"
                                                            >
                                                                <Translate
                                                                    id={
                                                                        "common.service_messages.see_details"
                                                                    }
                                                                />
                                                            </a>
                                                        </>
                                                    )}
                                                </NoticeBox.Content>
                                            </NoticeBox>
                                        </Grid>
                                    </Grid>
                                    {serviceMessageCount !== index + 1 && (
                                        <Grid row>
                                            <Grid item>
                                                <Spacer size={16} />
                                            </Grid>
                                        </Grid>
                                    )}
                                </React.Fragment>
                            )
                        } else if (m.langauge.toLowerCase() === currentLanguage.toLowerCase()) {
                            return (
                                <React.Fragment key={`service-message-${index}`}>
                                    <Grid row>
                                        <Grid item>
                                            <NoticeBox
                                                dismissable
                                                onDismissed={handleDismissedNotice}
                                                kind={"primary"}
                                                tight
                                            >
                                                <NoticeBox.Content>{m.title}</NoticeBox.Content>
                                            </NoticeBox>
                                        </Grid>
                                    </Grid>
                                    {serviceMessageCount !== index + 1 && (
                                        <Grid row>
                                            <Grid item>
                                                <Spacer size={16} />
                                            </Grid>
                                        </Grid>
                                    )}
                                </React.Fragment>
                            )
                        } else {
                            return null
                        }
                    })}
                </BoxEdges>
            )}
        </>
    )
}
