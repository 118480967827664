import { Problem } from "ketting"
import { meProviderReducer } from "modules/me/Provider/reducer"
import { Me, UpdateMeDto } from "modules/me/types"
import React from "react"
import { ReducerRegistry } from "utils/ReducerRegistry"

interface IMeContext {
    data?: Me
    isLoading: boolean
    error?: Problem
    update: (me: UpdateMeDto) => void
}

ReducerRegistry.register({
    meProvider: meProviderReducer,
})

// tslint:disable-next-line: no-empty
export const MeContext = React.createContext<IMeContext>({ isLoading: true, update: () => {} })
