import { LoadingScreen } from "@clearhaus/design-system"
import "flows/updateLocaleOnSetActivelanguage"
import { useShowWelcome } from "hooks/useShowWelcome"
import { Welcome } from "pages/Welcome"
import React from "react"
import { Route, Switch } from "react-router-dom"
import { RedirectRouter } from "./pages/RedirectRouter"

const Applications = React.lazy(() => import("./pages/ApplicationsRouter"))
const Home = React.lazy(() => import("./pages/Home"))
const Transactions = React.lazy(() => import("./pages/TransactionsRouter"))
const NotFound = React.lazy(() => import("./pages/NotFound"))
const Settlements = React.lazy(() => import("./pages/SettlementsRouter"))
const Subscriptions = React.lazy(() => import("./pages/SubscriptionsRouter"))
const Exports = React.lazy(() => import("./pages/ExportsRouter"))
const Accounts = React.lazy(() => import("./pages/AccountsRouter"))
const Disputes = React.lazy(() => import("./pages/DisputesRouter"))
const Announcements = React.lazy(() => import("./pages/AnnouncementsRouter"))

const fallbackComponent = <LoadingScreen />

const Router: React.FC = () => {
    const showWelcome = useShowWelcome()

    return (
        <>
            {showWelcome && <Welcome />}
            {!showWelcome && (
                <React.Suspense fallback={fallbackComponent}>
                    <Switch>
                        <Route exact={true} path="/" component={Home} />
                        <Route path="/transactions" component={Transactions} />
                        <Route path="/settlements" component={Settlements} />
                        <Route path="/applications" component={Applications} />
                        <Route path="/subscriptions" component={Subscriptions} />
                        <Route path="/exports" component={Exports} />
                        <Route path="/accounts" component={Accounts} />
                        <Route path="/disputes" component={Disputes} />
                        <Route path="/announcements" component={Announcements} />
                        <Route path="/redirect" component={RedirectRouter} />
                        <Route component={NotFound} />
                    </Switch>
                </React.Suspense>
            )}
        </>
    )
}

export default Router
