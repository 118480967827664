import {
    EntityIndex,
    ISO8601DateTime,
    PaginationRelations,
    ResponsePaginated,
    Uri,
} from "modules/common/types"

export const ACTION_PREFIX = "@@files/"

// Action flows
export const NEW_FILE_FLOW = "@@files/NEW_FILE_FLOW"

export interface IFilesData extends ResponsePaginated {}

export interface IFilesRelations extends PaginationRelations {
    self: Uri
    files: Uri | Uri[]
}

export type FileScope = "public" | "private"

export interface IFileData {
    label: string
    content_type: string
    name: string
    size: number
    created_at: ISO8601DateTime
    scope?: FileScope
}

export interface IFileProgress {
    uploadProgress?: number
    downloadProgress?: number
    downloadObjectUrl?: Uri
}

export interface IFile extends IFileData {
    converting?: string
    isDeleting?: boolean

    relations: IFileRelations
}

export interface ICreateFileDto {
    label?: string
    content_type: string
    name?: string
    size?: number
}

export interface ICreateFile extends ICreateFileDto {
    file: File
    scope?: FileScope
}

export interface IFileDownloadData {
    download: string
}

export interface FileData {
    label: string
    content_type: string
    name: string
    size: number
    download: Uri
    upload?: Uri
    progress?: number

    relations: IFileRelations
}

export interface IFileRelations {
    application?: Uri
    comments?: Uri
    company?: Uri
    person?: Uri
    website?: Uri
    data: {
        upload?: Uri
        download?: Uri
    }
    self: Uri
}

export interface FilesMetadata {
    count: number
    page: number
    per_page: number
    next?: Uri
    prev?: Uri
    last?: Uri
    first?: Uri
}

export interface CollectionData {
    files?: EntityIndex<FileData>
    meta?: FilesMetadata
    isFetching?: boolean
}

export interface FilesState {
    atUri: EntityIndex<CollectionData>
    isLoading: boolean
}
