import { defaults as defaultsOrg, SelectableText } from "@clearhaus/design-system"
import { makeStyles } from "@clearhaus/design-system/utils/styles/makeStyles"
import classNames from "classnames"
import { ISO8601DateTime } from "modules/common/types"
import { MeContext } from "modules/me/Provider/context"
import React, { useContext, useEffect, useMemo, useState } from "react"
import { devMode } from "utils/envHelpers"

interface IDateTimeProps {
    children: ISO8601DateTime | Date
    month?: "numeric" | "2-digit" | "long" | "short" | "narrow"
    day?: "numeric" | "2-digit"
    year?: "numeric" | "2-digit"
    hour?: "numeric" | "2-digit"
    minute?: "numeric" | "2-digit"
    second?: "numeric" | "2-digit"
    size?: keyof typeof defaultsOrg.fontSizes
    fontFamily?: "mono" | "regular"
    weight?: "normal" | "medium" | "semibold"
}

function returnDate(input: string | Date) {
    if (input instanceof window.Date) {
        return input
    } else {
        try {
            const realDate = new window.Date(input)
            if (isNaN(realDate.getTime())) {
                return null
            }
            return realDate
        } catch (e) {
            if (devMode) {
                // tslint:disable-next-line: no-console
                console.warn(`Cannot format '${input}', not a valid ISO8601 Date`)
            }
            return null
        }
    }
}

const styles = (defaults: typeof defaultsOrg) => ({
    mono: {
        fontFamily: defaults.fontFamilies.mono,
    },
    regular: {
        fontFamily: defaults.fontFamilies.primary,
    },
    semibold: {
        fontWeight: defaults.fontWeights.semibold,
    },
    medium: {
        fontWeight: defaults.fontWeights.medium,
    },
    normal: {
        fontWeight: defaults.fontWeights.normal,
    },
})

const useStyles = makeStyles(styles)
export const DateTime: React.FC<IDateTimeProps> = (props) => {
    const {
        children,
        day = "2-digit",
        month = "short",
        year = "numeric",
        hour = "numeric",
        minute = "2-digit",
        second,
        size = 13,
        fontFamily = "mono",
        weight = "normal",
    } = props
    const { data: me } = useContext(MeContext)
    const classes = useStyles()
    const [internalDate, setInternalDate] = useState<Date | null>(() => returnDate(children))
    const [locale, setLocale] = useState(() => {
        if (!me || !me.locale) {
            return "en-gb"
        }

        return me.locale.toLowerCase().replace("_", "-")
    })

    const [processedDateTime, setProcessedDateTime] = useState<string | null>(() => {
        if (!internalDate) {
            return null
        }

        return new Intl.DateTimeFormat(locale, { day, month, year, hour, minute, second }).format(
            internalDate
        )
    })

    useEffect(() => {
        if (me && me.locale) {
            const nextLocale = me.locale.toLowerCase().replace("_", "-")

            if (locale !== nextLocale) {
                setLocale(nextLocale)
            }
        }
    }, [me])

    useEffect(() => {
        const nextInternalDate = returnDate(children)

        if (nextInternalDate !== internalDate) {
            setInternalDate(nextInternalDate)
        }
    }, [children])

    useEffect(() => {
        if (!internalDate) {
            return
        }

        const nextProcessedDate = new Intl.DateTimeFormat(locale, {
            day,
            month,
            year,
            hour,
            minute,
            second,
        }).format(internalDate)

        if (nextProcessedDate !== processedDateTime) {
            setProcessedDateTime(nextProcessedDate)
        }
    }, [internalDate, locale])

    if (!processedDateTime) {
        return null
    }

    const spanStyle = useMemo(() => {
        return {
            fontSize: size,
        }
    }, [size])

    const spanClasses = useMemo(() => {
        return classNames({
            [classes.mono]: fontFamily === "mono",
            [classes.regular]: fontFamily === "regular",
            [classes.normal]: weight === "normal",
            [classes.medium]: weight === "medium",
            [classes.semibold]: weight === "semibold",
        })
    }, [classes])

    return (
        <SelectableText>
            <span title={internalDate?.toISOString()} className={spanClasses} style={spanStyle}>
                {processedDateTime}
            </span>
        </SelectableText>
    )
}
