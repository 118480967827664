import { Grid, Paragraph, ThemeContext } from "@clearhaus/design-system"
import { makeStyles } from "@clearhaus/design-system/utils/styles/makeStyles"
import React, { useContext } from "react"
import { styles } from "../styles"

interface IContentProps {
    text?: JSX.Element | string
    light?: boolean
}

const useStyles = makeStyles(styles)
export const Content: React.FC<IContentProps> = (props) => {
    const { light, text } = props

    const classes = useStyles()
    const { defaults } = useContext(ThemeContext)

    const color = light ? defaults.colors.neutrals[100] : undefined

    if (!text) {
        return null
    }

    return (
        <Grid item auto>
            <Paragraph className={classes.text} weight="medium" color={color}>
                {text}
            </Paragraph>
        </Grid>
    )
}
