import { EventBase } from "modules/insights/Events/EventBase"

interface FileUploadPreparingParams {
    label: string // MAPI label
    conversion: boolean
    file_type: string
    size: number
}

export class FileUploadPreparing {
    static log<P extends FileUploadPreparingParams>(params: P) {
        EventBase.log("file_upload_preparing", params)
    }
}
