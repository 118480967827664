import axios, { AxiosRequestConfig } from "axios"
import Auth0 from "modules/authentication/Auth0"

export default function setupAxiosInterceptor() {
    if (!process.env.MERCHANT_API_ROOT) {
        throw new Error(`MERCHANT_API_ROOT environment variable is required!`)
    }

    const MERCHANT_API_ROOT = process.env.MERCHANT_API_ROOT!
    axios.interceptors.request.use((requestConfig: AxiosRequestConfig) => {
        if (
            requestConfig.headers &&
            requestConfig.url &&
            requestConfig.url.indexOf(MERCHANT_API_ROOT) === 0
        ) {
            requestConfig.headers.Authorization = `Bearer ${Auth0.instance.getIdToken()}`
        }

        return requestConfig
    })
    axios.interceptors.response.use(
        (response) => response,
        (error) => {
            if (error.response.status === 401) {
                Auth0.instance.login()
            }
            return Promise.reject(error)
        }
    )
    ;(window as any).__AXIOS_INTERCEPTOR_SETUP__ = true
}
