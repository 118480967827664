import {
    ISO3166_1_alpha2,
    ISO4217,
    ISO8601DateTime,
    PaginationRelations,
    Uri,
} from "modules/common/types"

export const FETCH_TRANSACTIONS = "@@transactions/FETCH_TRANSACTIONS"
export const FETCH_TRANSACTIONS_OK = "@@transactions/FETCH_TRANSACTIONS_OK"
export const FETCH_TRANSACTIONS_ERROR = "@@transactions/FETCH_TRANSACTIONS_ERROR"
export const FETCH_TRANSACTION = "@@transactions/FETCH_TRANSACTION"
export const FETCH_TRANSACTION_OK = "@@transactions/FETCH_TRANSACTION_OK"
export const FETCH_TRANSACTION_ERROR = "@@transactions/FETCH_TRANSACTION_ERROR"
export const BATCH_FETCH_TRANSACTION = "@@transactions/BATCH_FETCH_TRANSACTION"
export const BATCH_FETCH_TRANSACTION_OK = "@@transactions/BATCH_FETCH_TRANSACTION_OK"
export const BATCH_FETCH_TRANSACTION_ERROR = "@@transactions/BATCH_FETCH_TRANSACTION_ERROR"

export interface ITransactionsMetadata {
    count?: number
    page?: number
    per_page: number
}

export interface ITransactions extends ITransactionsMetadata {
    relations: ITransactionsRelations
}

export interface ITransactionsData {
    readonly count?: number
    readonly page?: number
    readonly per_page: number
}

export interface ITransactionsRelations extends PaginationRelations {
    self: Uri
    transactions?: Uri[] | Uri
}

type TransactionType = "authorization" | "capture" | "refund" | "credit" | "debit" | "void"
export type ThreedSecureType = "false" | "attempt" | "full"
export type ThreedSecureStatus = "Y" | "N" | "U" | "A" | "C" | "D" | "R" | "I"
interface ThreedSecure {
    status: ThreedSecureStatus
    version: string
}
export type RegionType = "inter" | "intra" | "domestic"
export type PaymentMethodType =
    | "card"
    | "applepay"
    | "googlepay"
    | "mobilepayonline"
    | "token"
    | "tokenized_card"
    | "samsungpay"
type TransactionStatusCode =
    | 20000
    | 40110
    | 40111
    | 40120
    | 40130
    | 40135
    | 40140
    | 40150
    | 40190
    | 40200
    | 40300
    | 40310
    | 40400
    | 40410
    | 40411
    | 40412
    | 40413
    | 40414
    | 40415
    | 50000

export type TransactionSchemeType = "visa" | "mastercard"
export type TransactionSegmentType = "consumer" | "business"
export type TransactionCardType = "debit" | "credit" | "unknown"

export interface ITransactionStatus {
    code: TransactionStatusCode
    message?: string
}

export interface ITransactionCard {
    type: TransactionCardType
    scheme: TransactionSchemeType
    country?: ISO3166_1_alpha2
    last4: string
    bin: string
    expire_year: string
    expire_month: string
    segment?: TransactionSegmentType
}

export interface ITransactionFraud {
    type: string
    date: ISO8601DateTime
}

type IFeeDetailType = "3dsecure" | "authorization" | "capture" | "refund" | "credit" | "debit" | "interchange" | "scheme" | "series" | "late_capture"

interface IFeeDetail {
    type: IFeeDetailType
    amount: number
}

export interface ITransactionSettlement {
    amount_gross: number
    amount_net: number
    currency: ISO4217
    date: ISO8601DateTime
    fees: number
    fee_details: IFeeDetail[]
}

export interface ITransactionData {
    id: string
    rrn?: string
    arn?: string
    type: TransactionType
    processed_at: ISO8601DateTime
    amount: number
    currency: ISO4217
    text_on_statement: string
    reference: string
    threed_secure: ThreedSecureType
    "3dsecure"?: ThreedSecure
    recurring: boolean
    region: RegionType
    payment_method: PaymentMethodType
    status: ITransactionStatus
    card: ITransactionCard
    fraud?: ITransactionFraud
    settlement?: ITransactionSettlement
}

export interface ITransactionRelations {
    self: Uri
    account: Uri
    settlement?: Uri
    trail?: Uri | Uri[]
}

export interface ITransaction extends ITransactionData {
    relations: ITransactionRelations
}
