import { fork, put, race, take, takeLatest } from "@redux-saga/core/effects"
import { history } from "components/common/HistorySetter"
import { prefillApplicationData, prefillApplicationDataOk } from "flows/prefillApplicationData"
import { createApplication, createApplicationOk } from "modules/applications/actions"
import { encodeParameter } from "modules/common/helpers"
import { ApplicationCreated } from "modules/insights/Events/ApplicationCreated"
import { getVoucher, getVoucherError, getVoucherOk } from "modules/voucher"
import {
    deleteWebsite,
    deleteWebsiteOk,
    fetchWebsites,
    fetchWebsitesOk,
} from "modules/websites/actions"
import { ActionType, createCustomAction, getType, isActionOf } from "typesafe-actions"

export const NEW_APPLICAITON = "@@flows/NEW_APPLICATION"

export const newApplication = createCustomAction(NEW_APPLICAITON, (voucherCode?: string) => ({
    payload: voucherCode,
}))

function* handleNewApplication(action: ActionType<typeof newApplication>) {
    const voucherCode = action.payload

    const applicationObject = {
        ...(voucherCode && { voucher_code: voucherCode }),
    }

    yield put(createApplication(applicationObject))

    const { payload: createApplicationOkPayload }: ActionType<typeof createApplicationOk> =
        yield take(getType(createApplicationOk))

    const application = {
        ...createApplicationOkPayload.data,
        relations: createApplicationOkPayload.relations,
    }

    yield put(prefillApplicationData(application))
    yield take(getType(prefillApplicationDataOk))

    // Remove website
    yield put(fetchWebsites(application.relations.websites))
    const { payload: fetchWebsitesOkPayload }: ActionType<typeof fetchWebsitesOk> = yield take(
        (a: ActionType<typeof fetchWebsitesOk>) => {
            return isActionOf(fetchWebsitesOk, a) && a.meta.uri === application.relations.websites
        }
    )
    if (fetchWebsitesOkPayload.relations.websites) {
        const uris = Array.isArray(fetchWebsitesOkPayload.relations.websites)
            ? fetchWebsitesOkPayload.relations.websites
            : [fetchWebsitesOkPayload.relations.websites]
        for (const uri of uris) {
            yield put(deleteWebsite(uri))
            yield take((a: ActionType<typeof deleteWebsiteOk>) => {
                return isActionOf(deleteWebsiteOk, a) && a.meta.uri === uri
            })
        }
    }

    const applicationCreatedLog: Parameters<typeof ApplicationCreated.log>[0] = {
        instant: false,
        partner: undefined,
    }

    if (voucherCode) {
        yield put(getVoucher(voucherCode))
        const [success, _error]: [
            ActionType<typeof getVoucherOk> | undefined,
            ActionType<typeof getVoucherError> | undefined
        ] = yield race([
            take((a: ActionType<typeof getVoucherOk>) => {
                return isActionOf(getVoucherOk, a) && a.meta && a.meta.voucherCode === voucherCode
            }),
            take((a: ActionType<typeof getVoucherError>) => {
                return (
                    isActionOf(getVoucherError, a) && a.meta && a.meta.voucherCode === voucherCode
                )
            }),
        ])

        if (success) {
            applicationCreatedLog.instant = !!success.payload.data.instant
            applicationCreatedLog.partner = success.payload.data.gateway_name
        }
    }

    ApplicationCreated.log(applicationCreatedLog)

    history.push(`/applications/${encodeParameter(application.relations.self)}/form`)
}

function* watchNewApplication() {
    yield takeLatest(getType(newApplication), handleNewApplication)
}

export default function* newApplicationSaga() {
    yield fork(watchNewApplication)
}
