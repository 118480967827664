import { fork, put, take, takeLatest } from "@redux-saga/core/effects"
import { history } from "components/common/HistorySetter"
import { encodeParameter } from "modules/common/helpers"
import { Uri } from "modules/common/types"
import { fetchCompany, fetchCompanyOk } from "modules/companies/actions"
import { createPerson, createPersonOk, fetchPeople, fetchPeopleOk } from "modules/people/actions"
import { ActionType, createCustomAction, getType, isActionOf } from "typesafe-actions"

export const NEW_PERSON_IN_COMPANY = "@@flows/NEW_PERSON_IN_COMPANY"

export const newPersonInCompany = createCustomAction(NEW_PERSON_IN_COMPANY, (companyUri: Uri) => ({
    payload: companyUri,
}))

function* handleNewPersonInCompany(action: ActionType<typeof newPersonInCompany>) {
    const companyUri = action.payload

    yield put(fetchCompany(companyUri))
    const { payload: company }: ActionType<typeof fetchCompanyOk> = yield take(
        (a: ActionType<typeof fetchCompanyOk>) => {
            return isActionOf(fetchCompanyOk, a) && a.meta.uri === companyUri
        }
    )

    const personObject = {
        ...(company.data.country && { country: company.data.country }),
    }
    yield put(createPerson(personObject, company.relations.people))
    const { payload: person }: ActionType<typeof createPersonOk> = yield take(
        getType(createPersonOk)
    )

    yield put(fetchPeople(company.relations.people))
    yield take((a: ActionType<typeof fetchPeopleOk>) => {
        return isActionOf(fetchPeopleOk, a) && a.meta.uri === company.relations.people
    })

    history.push(
        `/applications/${encodeParameter(company.relations.application)}/person/${encodeParameter(
            person.relations.self
        )}?noInitialValidation`
    )
}

function* watchNewPersonInCompany() {
    yield takeLatest(getType(newPersonInCompany), handleNewPersonInCompany)
}

export default function* newPersonInCompanySaga() {
    yield fork(watchNewPersonInCompany)
}
