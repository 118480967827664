import { all, fork, put, race, take, takeEvery, takeLatest } from "@redux-saga/core/effects"
import ibanJs from "iban"
import { updateBankAccount } from "modules/bankAccounts/actions"
import { IBankAccount } from "modules/bankAccounts/types"
import { catchError } from "modules/common/actions"
import { IbanInfo, lookupIban, lookupIbanError, lookupIbanOk } from "modules/iban"
import { ActionType, createCustomAction, getType, isActionOf } from "typesafe-actions"

export const AUTOFILL_BANK_ACCOUNT = "@@flows/AUTOFILL_BANK_ACCOUNT"
export const AUTOFILL_BANK_ACCOUNT_OK = "@@flows/AUTOFILL_BANK_ACCOUNT_OK"
export const AUTOFILL_BANK_ACCOUNT_ERROR = "@@flows/AUTOFILL_BANK_ACCOUNT_ERROR"

export const autofillBankAccount = createCustomAction(AUTOFILL_BANK_ACCOUNT, (iban: string) => ({
    payload: iban,
}))
export const autofillBankAccountOk = createCustomAction(
    AUTOFILL_BANK_ACCOUNT_OK,
    (ibanInfo: IbanInfo, iban: string) => ({ payload: ibanInfo, meta: iban })
)
export const autofillBankAccountError = createCustomAction(
    AUTOFILL_BANK_ACCOUNT_ERROR,
    (error: any, iban: string) => ({ payload: error, meta: iban })
)

function* handleAutofillBankAccount(action: ActionType<typeof autofillBankAccount>) {
    const iban = action.payload

    if (!ibanJs.isValid(iban)) {
        yield put(autofillBankAccountError(`Invalid IBAN`, iban))
        return
    }

    yield put(lookupIban(iban))

    const [ibanOk, ibanError]: [
        ActionType<typeof lookupIbanOk>,
        ActionType<typeof lookupIbanError>
    ] = yield race([
        take((a: ActionType<typeof lookupIbanOk>) => {
            return isActionOf(lookupIbanOk, a) && a.meta.iban === iban
        }),
        take((a: ActionType<typeof lookupIbanError>) => {
            return isActionOf(lookupIbanError, a) && a.meta.iban === iban
        }),
    ])

    if (ibanError) {
        yield put(autofillBankAccountError(ibanError.payload, ibanError.meta.iban))
    } else {
        const ibanInfo = ibanOk.payload

        if (!ibanInfo.data.bank || !ibanInfo.data.bic) {
            yield put(autofillBankAccountError("Invalid bank or swift", iban))
            return
        }

        yield put(autofillBankAccountOk(ibanInfo.data, iban))
    }
}

function* handleUpdateBankAccount(action: ActionType<typeof updateBankAccount>) {
    const { uri: bankAccountUri } = action.meta
    const { data: bankAccountData } = action.payload

    try {
        // If there is no iban in the payload do not trigger an autofill
        if (!bankAccountData.iban) {
            return
        }

        const iban = bankAccountData.iban.replace(/\s+/g, "")

        yield put(autofillBankAccount(iban))

        const [autofillOk, autofillError]: [
            ActionType<typeof autofillBankAccountOk>,
            ActionType<typeof lookupIbanError>
        ] = yield race([
            take((a: ActionType<typeof autofillBankAccountOk>) => {
                return isActionOf(autofillBankAccountOk, a) && a.meta === iban
            }),
            take((a: ActionType<typeof autofillBankAccountError>) => {
                return isActionOf(autofillBankAccountError, a) && a.meta === iban
            }),
        ])

        if (autofillError) {
            return
        }

        const ibanInfo = autofillOk.payload
        const bankAccount: Partial<IBankAccount> = {
            bank: ibanInfo.bank,
            swift_code: ibanInfo.bic,
        }

        yield put(updateBankAccount(bankAccount, bankAccountUri))
    } catch (e) {
        yield put(catchError(e))
    }
}

function* watchHandleAutofillBankAccount() {
    // We only want the latest bankAccount update not every
    yield takeLatest(getType(updateBankAccount), handleUpdateBankAccount)
    yield takeEvery(AUTOFILL_BANK_ACCOUNT, handleAutofillBankAccount)
}

export default function* autofillBankAccountSaga() {
    yield all([fork(watchHandleAutofillBankAccount)])
}
