import { Problem } from "ketting"
import { RxMiddleware } from "modules/common/RxMiddleware"
import { Uri } from "modules/common/types"
import filesRootSaga from "modules/files/sagas"
import {
    ICreateFileDto,
    IFile,
    IFileData,
    IFileRelations,
    IFilesData,
    IFilesRelations,
    NEW_FILE_FLOW,
} from "modules/files/types"
import { createCustomAction } from "typesafe-actions"
import { SagaRegistry } from "utils/SagaRegistry"
import * as actions from "./actions"

const FETCH_FILES = "@@files/FETCH_FILES"
const FETCH_FILES_OK = "@@files/FETCH_FILES_OK"
const FETCH_FILES_ERROR = "@@files/FETCH_FILES_ERROR"

export const fetchFiles = createCustomAction(FETCH_FILES, (uri: Uri) => ({
    payload: { uri },
}))
export const fetchFilesOk = createCustomAction(
    FETCH_FILES_OK,
    (data: IFilesData, relations: IFilesRelations, uri: Uri) => ({
        meta: { uri },
        payload: { data, relations },
    })
)
export const fetchFilesError = createCustomAction(FETCH_FILES_ERROR, (e: Problem, uri: Uri) => ({
    meta: { uri },
    payload: e,
}))

const FETCH_FILE = "@@files/FETCH_FILE"
const FETCH_FILE_OK = "@@files/FETCH_FILE_OK"
const FETCH_FILE_ERROR = "@@files/FETCH_FILE_ERROR"
export const fetchFile = createCustomAction(FETCH_FILE, (uri: Uri) => ({
    payload: { uri },
}))
export const fetchFileOk = createCustomAction(
    FETCH_FILE_OK,
    (data: IFileData, relations: IFileRelations, uri: Uri) => ({
        meta: { uri },
        payload: { data, relations },
    })
)
export const fetchFileError = createCustomAction(FETCH_FILE_ERROR, (e: Problem, uri: Uri) => ({
    meta: { uri },
    payload: e,
}))

const CREATE_FILE = "@@files/CREATE_FILE"
const CREATE_FILE_OK = "@@files/CREATE_FILE_OK"
const CREATE_FILE_ERROR = "@@files/CREATE_FILE_ERROR"
export const createFile = createCustomAction(CREATE_FILE, (data: ICreateFileDto, uri: Uri) => ({
    payload: { data, uri },
}))
export const createFileOk = createCustomAction(
    CREATE_FILE_OK,
    (data: IFileData, relations: IFileRelations, createFileData: ICreateFileDto, uri: Uri) => ({
        meta: { data: createFileData, uri },
        payload: { data, relations },
    })
)
export const createFileError = createCustomAction(
    CREATE_FILE_ERROR,
    (e: Problem, data: ICreateFileDto, uri: Uri) => ({
        meta: { data, uri },
        payload: e,
    })
)

const UPLOAD_FILE = "@@files/UPLOAD_FILE"
const UPLOAD_FILE_PROGRESS = "@@files/UPLOAD_FILE_PROGRESS"
const UPLOAD_FILE_OK = "@@files/UPLOAD_FILE_OK"
const UPLOAD_FILE_ERROR = "@@files/UPLOAD_FILE_ERROR"
export const uploadFile = createCustomAction(UPLOAD_FILE, (uri: Uri, file: File) => ({
    payload: { file, uri },
}))
export const uploadFileOk = createCustomAction(UPLOAD_FILE_OK, (uri: Uri, file: File) => ({
    meta: { uri, file },
}))
export const uploadFileProgress = createCustomAction(
    UPLOAD_FILE_PROGRESS,
    (progress: number, uri: Uri) => ({
        meta: { uri },
        payload: { progress },
    })
)
export const uploadFileFailed = createCustomAction(
    UPLOAD_FILE_ERROR,
    (error: Problem, uri: Uri, file: File) => ({
        meta: { uri, file },
        payload: error,
    })
)

const DOWNLOAD_FILE = "@@files/DOWNLOAD_FILE"
const DOWNLOAD_FILE_OK = "@@files/DOWNLOAD_FILE_OK"
const DOWNLOAD_FILE_FAILED = "@@files/DOWNLOAD_FILE_FAILED"

export const downloadFile = createCustomAction(DOWNLOAD_FILE, (uri: Uri) => ({
    payload: { uri },
}))
export const downloadFileOk = createCustomAction(
    DOWNLOAD_FILE_OK,
    (objectUrl: Uri, data: IFileData, uri: Uri) => ({
        meta: {
            uri,
        },
        payload: {
            data,
            objectUrl,
        },
    })
)
export const downloadFileFailed = createCustomAction(
    DOWNLOAD_FILE_FAILED,
    (e: Problem, uri: Uri) => ({ payload: e, meta: { uri } })
)

const DELETE_FILE = "@@files/DELETE_FILE"
const DELETE_FILE_OK = "@@files/DELETE_FILE_OK"
export const deleteFile = createCustomAction(DELETE_FILE, (uri: Uri) => ({
    payload: { uri },
}))
export const deleteFileOk = createCustomAction(DELETE_FILE_OK, (uri: Uri) => ({
    meta: { uri },
}))

export const newFileFlow = createCustomAction(
    NEW_FILE_FLOW,
    (uri: Uri, label: string, file: File) => ({
        payload: { uri, label, file },
    })
)

const REPLACE_FILE = "@@files/REPLACE_FILE"
const REPLACE_FILE_OK = "@@files/REPLACE_FILE_OK"
const REPLACE_FILE_ERROR = "@@files/REPLACE_FILE_ERROR"
export const replaceFile = createCustomAction(REPLACE_FILE, (file: File, uri: Uri) => ({
    payload: { file, uri },
}))

export const replaceFileOk = createCustomAction(REPLACE_FILE_OK, (file: File, uri: Uri) => ({
    meta: { file, uri },
}))
export const replaceFileError = createCustomAction(
    REPLACE_FILE_ERROR,
    (e: Problem, file: File, uri: Uri) => ({ payload: e, meta: { file, uri } })
)

const BATCH_FETCH_FILE = "@@files/BATCH_FETCH_FILE"
const BATCH_FETCH_FILE_OK = "@@files/BATCH_FETCH_FILE_OK"
const BATCH_FETCH_FILE_ERROR = "@@files/BATCH_FETCH_FILE_ERROR"
export const batchFetchFile = createCustomAction(BATCH_FETCH_FILE, (uris: Uri[]) => ({
    payload: { uris },
}))
export const batchFetchFileOk = createCustomAction(
    BATCH_FETCH_FILE_OK,
    (data: IFile[], uris: Uri[]) => ({
        meta: { uris },
        payload: { data },
    })
)
export const batchFetchFileError = createCustomAction(
    BATCH_FETCH_FILE_ERROR,
    (e: Problem, uris: Uri[]) => ({ meta: { uris }, payload: e })
)

const CONVERTING_HEIC_FILE = "@@files/CONVERTING_HEIC_FILE"
const CONVERTING_HEIC_FILE_OK = "@@files/CONVERTING_HEIC_FILE_OK"
export const convertHeicFile = createCustomAction(CONVERTING_HEIC_FILE, (file: File) => ({
    payload: { file },
}))
export const convertHeicFileOk = createCustomAction(
    CONVERTING_HEIC_FILE_OK,
    (convertedFile: File, file: File) => ({
        meta: { file },
        payload: { convertedFile },
    })
)

const PREPARE_FILE = "@@files/PREPARE_FILE"
const PREPARE_FILE_OK = "@@files/PREPARE_FILE_OK"
export const prepareFile = createCustomAction(
    PREPARE_FILE,
    (file: File, createData: ICreateFileDto) => ({ payload: { file, createData } })
)
export const prepareFileOk = createCustomAction(
    PREPARE_FILE_OK,
    (file: File, prepareFile: File, createData: ICreateFileDto) => ({
        payload: { file },
        meta: { file: prepareFile, createData },
    })
)

RxMiddleware.addActions(actions)
SagaRegistry.register(filesRootSaga)
