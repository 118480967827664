import { ApplicationContext } from "modules/applications/Providers/Application/ApplicationContext"
import { encodeParameter } from "modules/common/helpers"
import { useContext } from "react"
import { useHistory } from "react-router"
import { Uri } from "../modules/common/types"
import useCallbackRef from "../utils/useCallbackRef"

export const useApplicationHelpers = () => {
    const history = useHistory()
    const { relations } = useContext(ApplicationContext)

    const navigateToForm = useCallbackRef((replace?: boolean) => {
        if (relations && relations.self) {
            const encodedUri = encodeParameter(relations.self)
            const formPath = `/applications/${encodedUri}/form`
            if (replace) {
                history.replace(formPath)
            } else {
                history.push(formPath)
            }
        }
    })

    const navigateTo = useCallbackRef((applicationUri: Uri, replace?: boolean) => {
        const encodedUri = encodeParameter(applicationUri)
        const applicationPath = `/applications/${encodedUri}`
        if (replace) {
            history.replace(applicationPath)
        } else {
            history.push(applicationPath)
        }
    })

    return {
        navigateToForm,
        navigateTo,
    }
}
