import { Uri } from "modules/common/types"

export const ACTION_PREFIX = "@@root/"
export const FETCH_ROOT = "@@root/FETCH_ROOT"
export const FETCH_ROOT_OK = "@@root/FETCH_ROOT_OK"
export const FETCH_ROOT_ERROR = "@@root/FETCH_ROOT_ERROR"

export interface IRootData {
    applications?: Uri
    records?: Uri
    accounts?: Uri
    settlements?: Uri
    transactions?: Uri
    disputes?: Uri
    jobs?: Uri
}

export interface IRoot {}

export interface IRootRelations extends IRootData {}
