import { BoxEdges, Paragraph, Tooltip } from "@clearhaus/design-system"
import React from "react"
import { Translate } from "react-localize-redux"

const text = <Translate id={"subscription_confirmation.login_tooltip"} />

interface LoginTooltipProps {
    children: React.ReactNode
}

export const LoginTooltip: React.FC<LoginTooltipProps> = (props) => {
    const { children } = props

    return (
        <Tooltip
            text={
                <BoxEdges my={6}>
                    <Paragraph align="center" width={200} size="m">
                        {text}
                    </Paragraph>
                </BoxEdges>
            }
            underline="none"
        >
            {children}
        </Tooltip>
    )
}
