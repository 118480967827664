import { fetchRoot, fetchRootError, fetchRootOk } from "modules/root/actions"
import { IRootData } from "modules/root/types"
import { Reducer } from "redux"
import { ActionType, getType } from "typesafe-actions"

export interface IRootState extends IRootData {
    isLoading: boolean
}

export interface IRootRootState {
    root: IRootState
}

type ActionTypes = ActionType<typeof fetchRoot | typeof fetchRootOk | typeof fetchRootError>

export const rootReducer: Reducer<IRootState, ActionTypes> = (currentState, action) => {
    let nextState
    if (!currentState) {
        currentState = { isLoading: false }
    }

    switch (action.type) {
        case getType(fetchRoot): {
            nextState = {
                ...currentState,
                isLoading: true,
            }
            break
        }
        case getType(fetchRootOk): {
            // Deconstruct to ensure we only expose what has been defined in the interface
            const {
                accounts,
                applications,
                disputes,
                records,
                settlements,
                transactions,
                jobs,
            } = action.payload.data
            nextState = {
                ...currentState,
                accounts,
                applications,
                disputes,
                isLoading: false,
                jobs,
                records,
                settlements,
                transactions,
            }
            break
        }
        case getType(fetchRootError): {
            nextState = {
                ...currentState,
                isLoading: false,
            }
            break
        }
        default: {
            nextState = currentState
            break
        }
    }

    return nextState
}
