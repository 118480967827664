import { defaults as defaultsOrg } from "@clearhaus/design-system"

const progressWidth = 50
export const styles = (defaults: typeof defaultsOrg) => {
    const { sm, md } = defaults.grid.breakpoints

    return {
        controls: {
            display: "grid",
            gridColumnGap: defaults.spacing[32],
            gridRowGap: defaults.spacing[16],

            [`@media (min-width: ${sm}px)`]: {
                gridTemplateColumns: "repeat(2, 1fr)",
            },

            [`@media (min-width: ${md}px)`]: {
                gridTemplateColumns: "repeat(4, 1fr)",
            },
        },

        slides: {
            display: "grid",
            gridTemplateColumns: "1fr",
            gridTemplateRows: "1fr",
            gridColumnGap: 0,
            gridRowGap: 0,
        },

        slide: { gridArea: "1 / 1 / 2 / 2" },

        control: {
            height: "100%",
            cursor: "pointer",
        },

        height100: {
            height: "100%",
        },
        progressVisible: {
            width: progressWidth,
        },
        progressInvisible: {
            width: progressWidth,
            opacity: 0,
        },
    }
}
