import { Fonts, GlobalStyles, LoadingScreen } from "@clearhaus/design-system"
import { AuthContext } from "components/authentication/AuthProvider"
import {
    DefaultLanguageLocalizationLoader,
    LanguageLocalizationLoader,
} from "components/localization"
import { MeProvider } from "modules/me/Provider/MeProvider"
import { IncompleteUserProfile } from "pages/IncompleteUserProfile"
import React, { useContext } from "react"
import { devMode, envMode } from "utils/envHelpers"
import { AccountSwitcherProvider } from "./components/AccountSwitcher/Provider/AccountSwitcherProvider"
import { PageLayout } from "./components/PageLayout"
import { BrowserNotSupported } from "./pages/BrowserNotSupported"
import { isIE } from "./utils/browserCheck"
import ScrollToTop from "./utils/ScrollToTop"

import "flows/updateLocaleOnSetActivelanguage"
import Router from "./router"

const fallbackComponent = <LoadingScreen />
const MockDataProvider =
    devMode || envMode === "testing"
        ? React.lazy(() => import("modules/mock/MockDataProvider"))
        : (props: { children: React.ReactNode }) => <>{props.children}</>

export const Main: React.FC = () => {
    return (
        <>
            <ScrollToTop />
            <GlobalStyles />
            <Fonts />
            <Content />
        </>
    )
}

const Content: React.FC = () => {
    const { isLoading: isAuthLoading, isError: isAuthError, sub: authSub } = useContext(AuthContext)
    const isInternetExplorer = isIE()

    if (isAuthLoading) {
        return <LoadingScreen />
    }

    if (isAuthError || !authSub) {
        return (
            <DefaultLanguageLocalizationLoader>
                <IncompleteUserProfile />
            </DefaultLanguageLocalizationLoader>
        )
    }

    if (isInternetExplorer) {
        return (
            <DefaultLanguageLocalizationLoader>
                <BrowserNotSupported />
            </DefaultLanguageLocalizationLoader>
        )
    }

    return (
        <MeProvider>
            <WithMe />
        </MeProvider>
    )
}

const WithMe: React.FC = () => {
    return (
        <LanguageLocalizationLoader>
            <React.Suspense fallback={fallbackComponent}>
                <AccountSwitcherProvider>
                    <MockDataProvider>
                        <PageLayout>
                            <Router />
                        </PageLayout>
                    </MockDataProvider>
                </AccountSwitcherProvider>
            </React.Suspense>
        </LanguageLocalizationLoader>
    )
}
