import { Problem } from "ketting"
import { RxMiddleware } from "modules/common/RxMiddleware"
import { Uri } from "modules/common/types"
import peopleSaga from "modules/people/sagas"
import {
    IPeopleData,
    IPeopleRelations,
    IPerson,
    IPersonData,
    IPersonRelations,
} from "modules/people/types"
import { createCustomAction } from "typesafe-actions"
import { SagaRegistry } from "utils/SagaRegistry"
import * as actions from "./actions"

const FETCH_PEOPLE = "@@people/FETCH_PEOPLE"
const FETCH_PEOPLE_OK = "@@people/FETCH_PEOPLE_OK"
const FETCH_PEOPLE_ERROR = "@@people/FETCH_PEOPLE_ERROR"
export const fetchPeople = createCustomAction(FETCH_PEOPLE, (uri: Uri, noCache?: boolean) => ({
    payload: { uri, noCache },
}))
export const fetchPeopleOk = createCustomAction(
    FETCH_PEOPLE_OK,
    (data: IPeopleData, relations: IPeopleRelations, uri: Uri, noCache?: boolean) => ({
        payload: { data, relations },
        meta: { uri, noCache },
    })
)
export const fetchPeopleError = createCustomAction(
    FETCH_PEOPLE_ERROR,
    (e: Problem, uri: Uri, noCache?: boolean) => ({
        payload: e,
        meta: { uri, noCache },
    })
)

const FETCH_PERSON = "@@people/FETCH_PERSON"
const FETCH_PERSON_OK = "@@people/FETCH_PERSON_OK"
const FETCH_PERSON_ERROR = "@@people/FETCH_PERSON_ERROR"
export const fetchPerson = createCustomAction(FETCH_PERSON, (uri: Uri) => ({
    payload: { uri },
}))
export const fetchPersonOk = createCustomAction(
    FETCH_PERSON_OK,
    (data: IPersonData, relations: IPersonRelations, uri: Uri) => ({
        meta: { uri },
        payload: { data, relations },
    })
)
export const fetchPersonError = createCustomAction(FETCH_PERSON_ERROR, (e: Problem, uri: Uri) => ({
    payload: e,
    meta: { uri },
}))

const CREATE_PERSON = "@@people/CREATE_PERSON"
const CREATE_PERSON_OK = "@@people/CREATE_PERSON_OK"
export const createPerson = createCustomAction(CREATE_PERSON, (data: IPersonData, uri: Uri) => ({
    payload: { data, uri },
}))
export const createPersonOk = createCustomAction(
    CREATE_PERSON_OK,
    (data: IPersonData, relations: IPersonRelations, createData: IPersonData, uri: Uri) => ({
        payload: { data, relations },
        meta: { uri, data: createData },
    })
)

const UPDATE_PERSON = "@@people/UPDATE_PERSON"
const UPDATE_PERSON_OK = "@@people/UPDATE_PERSON_OK"
const UPDATE_PERSON_ERROR = "@@people/UPDATE_PERSON_ERROR"
export const updatePerson = createCustomAction(UPDATE_PERSON, (data: IPersonData, uri: Uri) => ({
    payload: { data, uri },
}))
export const updatePersonOk = createCustomAction(
    UPDATE_PERSON_OK,
    (data: IPersonData, relations: IPersonRelations, updateData: IPersonData, uri: Uri) => ({
        payload: { data, relations },
        meta: { uri, data: updateData },
    })
)
export const updatePersonError = createCustomAction(
    UPDATE_PERSON_ERROR,
    (error: Problem, data: IPersonData, uri: Uri) => ({
        payload: error,
        meta: { uri, data },
    })
)

const DELETE_PERSON = "@@people/DELETE_PERSON"
const DELETE_PERSON_OK = "@@people/DELETE_PERSON_OK"
const DELETE_PERSON_ERROR = "@@people/DELETE_PERSON_ERROR"
export const deletePerson = createCustomAction(DELETE_PERSON, (uri: Uri) => ({
    payload: { uri },
}))
export const deletePersonOk = createCustomAction(DELETE_PERSON_OK, (uri: Uri) => ({
    meta: { uri },
}))
export const deletePersonError = createCustomAction(
    DELETE_PERSON_ERROR,
    (e: Problem, uri: Uri) => ({ payload: e, meta: { uri } })
)

const BATCH_FETCH_PERSON = "@@people/BATCH_FETCH"
const BATCH_FETCH_PERSON_OK = "@@people/BATCH_FETCH_OK"
const BATCH_FETCH_PERSON_ERROR = "@@people/BATCH_FETCH_ERROR"
export const batchFetchPerson = createCustomAction(BATCH_FETCH_PERSON, (uris: Uri[]) => ({
    payload: { uris },
}))
export const batchFetchPersonOk = createCustomAction(
    BATCH_FETCH_PERSON_OK,
    (data: IPerson[], uris: Uri[]) => ({ meta: { uris }, payload: { data } })
)
export const batchFetchPersonError = createCustomAction(
    BATCH_FETCH_PERSON_ERROR,
    (error: Problem, uris: Uri[]) => ({ payload: error, meta: { uris } })
)

RxMiddleware.addActions(actions)
SagaRegistry.register(peopleSaga)
