import { RATATOSKR_API_ROOT } from "modules/common"
import { catchError } from "modules/common/actions"
import { IAnnouncements, IMessages } from "modules/ratatoskr/types"
import { call, fork, put, takeEvery } from "redux-saga/effects"
import { ActionType, createCustomAction, getType } from "typesafe-actions"
import { SagaRegistry } from "utils/SagaRegistry"

const FETCH_MESSAGES = "@@ratatoskr/FETCH_MESSAGES"
export const fetchMessages = createCustomAction(FETCH_MESSAGES)
const FETCH_MESSAGES_OK = "@@ratatoskr/FETCH_MESSAGES_OK"
export const fetchMessagesOk = createCustomAction(FETCH_MESSAGES_OK, (data: IMessages) => ({
    payload: { data },
}))
const FETCH_MESSAGES_ERROR = "@@ratatoskr/FETCH_MESSAGES_ERROR"
export const fetchMessagesError = createCustomAction(FETCH_MESSAGES_ERROR)

export function* handleFetchMessages() {
    try {
        if (!RATATOSKR_API_ROOT) {
            throw Error(`Missing environment variable 'RATATOSKR_API_ROOT'`)
        }

        const apiResponse: Response = yield call(fetch, `${RATATOSKR_API_ROOT}/messages`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
            mode: "cors",
        })
        const responseResult: IMessages = yield call([apiResponse, apiResponse.json])

        yield put(fetchMessagesOk(responseResult))
    } catch (e) {
        yield put(fetchMessagesError())
        yield put(catchError(e))
    }
}

const FETCH_ANNOUNCEMENTS = "@@ratatoskr/FETCH_ANNOUNCEMENTS"
export const fetchAnnouncements = createCustomAction(
    FETCH_ANNOUNCEMENTS,
    (email: string, language?: string) => ({
        payload: { email, language },
    })
)
const FETCH_ANNOUNCEMENTS_OK = "@@ratatoskr/FETCH_ANNOUNCEMENTS_OK"
export const fetchAnnouncementsOk = createCustomAction(
    FETCH_ANNOUNCEMENTS_OK,
    (data: IAnnouncements) => ({
        payload: { data },
    })
)
const FETCH_ANNOUNCEMENTS_ERROR = "@@ratatoskr/FETCH_ANNOUNCEMENTS_ERROR"
export const fetchAnnouncementsError = createCustomAction(FETCH_ANNOUNCEMENTS_ERROR)

export function* handleFetchAnnouncements(action: ActionType<typeof fetchAnnouncements>) {
    const { email, language } = action.payload

    try {
        if (!RATATOSKR_API_ROOT) {
            throw Error("Missing environment variable 'RATATOSKR_API_ROOT'")
        }

        if (!email) {
            throw Error("Missing email when trying to fetch announcements")
        }

        const apiResponse: Response = yield call(
            fetch,
            `${RATATOSKR_API_ROOT}/announcements/${email}`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    ...(language && { "Accept-Language": language }),
                },
                mode: "cors",
            }
        )
        const responseResult: IAnnouncements = yield call([apiResponse, apiResponse.json])

        // Temporary fix - filter null from announcements
        const finalResponse = { announcements: responseResult.announcements.filter(Boolean) }

        yield put(fetchAnnouncementsOk(finalResponse))
    } catch (e) {
        yield put(fetchAnnouncementsError())
        yield put(catchError(e))
    }
}

export function* watchRatatoskr() {
    yield takeEvery(getType(fetchMessages), handleFetchMessages)
    yield takeEvery(getType(fetchAnnouncements), handleFetchAnnouncements)
}

function* rootRatatoskrSaga() {
    yield fork(watchRatatoskr)
}

SagaRegistry.register(rootRatatoskrSaga)
