import { fetchSubscription, fetchSubscriptionRoot, stampSubscription } from "modules/subscription"
import { useEffect, useState } from "react"

export interface IConfirmSubscriptionData {
    name?: string
    email?: string
    isEnabled?: boolean
}

export const useConfirmSubscription = () => {
    const [data, setData] = useState<IConfirmSubscriptionData | null>(null)
    const [isLoading, setIsLoading] = useState(true)
    const [isError, setIsError] = useState(false)

    useEffect(() => {
        async function fetchRessources() {
            setData(null)
            setIsError(false)
            setIsLoading(true)

            try {
                const { href } = window.location
                const token = href.substring(href.lastIndexOf("/") + 1).split("?")[0]

                const { relations: rootRelations } = await fetchSubscriptionRoot(token)

                if (!rootRelations?.subscription) {
                    throw new Error("Subscription link on root not found")
                }

                const { repr, relations } = await fetchSubscription(
                    rootRelations.subscription,
                    token
                )

                if (!ignore) {
                    const name = repr?.["ch:record"]?.name
                    const email = repr?.endpoint
                    const isEnabled = repr?.enabled

                    setData({ name, email, isEnabled })
                }

                if (relations?.stamps?.enabled) {
                    stampSubscription(relations.stamps.enabled, token)
                }
            } catch (error) {
                setIsError(true)
            }

            setIsLoading(false)
        }

        let ignore = false

        fetchRessources()

        return () => {
            ignore = true
        }
    }, [])

    return { data, isLoading, isError }
}
