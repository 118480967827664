import { AlertTriangleColoredIcon, ThemeContext } from "@clearhaus/design-system"
import { EmptyState } from "components/common/EmptyState/EmptyState"
import React, { useContext } from "react"
import { Translate } from "react-localize-redux"

const texts = {
    title: <Translate id={"subscription_confirmation.error_title"} />,
    description: <Translate id={"subscription_confirmation.error_description"} />,
}

export const Error: React.FC = () => {
    const { defaults } = useContext(ThemeContext)

    return (
        <EmptyState>
            <EmptyState.Icon
                icon={
                    <AlertTriangleColoredIcon
                        backgroundColor={defaults.colors.accents.error[300]}
                    />
                }
            />
            <EmptyState.Title>{texts.title}</EmptyState.Title>
            <EmptyState.Description>{texts.description}</EmptyState.Description>
        </EmptyState>
    )
}
