import { fork, put, race, take, takeEvery } from "@redux-saga/core/effects"
import { shallowEqual } from "fast-equals"
import { Uri } from "modules/common/types"
import {
    batchFetchRecord,
    batchFetchRecordOk,
    fetchRecords,
    fetchRecordsOk,
} from "modules/records/actions"
import { IRecord } from "modules/records/types"
import { getVoucher, getVoucherError, getVoucherOk } from "modules/voucher"
import { ActionType, createCustomAction, getType, isActionOf } from "typesafe-actions"

export const VIEW_HOME_OVERVIEW = "@@flows/VIEW_HOME_OVERVIEW"
export const VIEW_HOME_OVERVIEW_OK = "@@flows/VIEW_HOME_OVERVIEW_OK"

export const viewHomeOverview = createCustomAction(
    VIEW_HOME_OVERVIEW,
    (recordsUri: Uri, page?: number, per_page?: number, query?: string) => ({
        payload: { recordsUri, page, per_page, query },
    })
)
export const viewHomeOverviewOk = createCustomAction(
    VIEW_HOME_OVERVIEW_OK,
    (applications: IRecord[], count: number, page?: number, per_page?: number, query?: string) => ({
        meta: { count, page, per_page, query },
        payload: applications,
    })
)

export interface IHomeOverviewApplication extends IRecord {}
interface IFetchAllRecordsReturn {
    result: IHomeOverviewApplication[]
    count: number
}

function* fetchAllRecords(action: ActionType<typeof viewHomeOverview>) {
    const { recordsUri, page, per_page, query } = action.payload
    yield put(fetchRecords(recordsUri, page, per_page, query))
    const recordsOk: ActionType<typeof fetchRecordsOk> = yield take(
        (a: ActionType<typeof fetchRecordsOk>) => {
            return isActionOf(fetchRecordsOk, a)
        }
    )
    let result: IHomeOverviewApplication[] = []

    if (recordsOk.payload.relations.records) {
        const recordUris = Array.isArray(recordsOk.payload.relations.records)
            ? recordsOk.payload.relations.records
            : [recordsOk.payload.relations.records]
        yield put(batchFetchRecord(recordUris))
        const { payload: batchOk }: ActionType<typeof batchFetchRecordOk> = yield take(
            (a: ActionType<typeof batchFetchRecordOk>) => {
                return isActionOf(batchFetchRecordOk, a) && shallowEqual(a.meta.uris, recordUris)
            }
        )
        result = batchOk.data
    }

    return { result, count: recordsOk.payload.data.count } as IFetchAllRecordsReturn
}

function* handleViewHomeOverview(action: ActionType<typeof viewHomeOverview>) {
    let result: IHomeOverviewApplication[] = []
    let count: number = 0

    const records: IFetchAllRecordsReturn = yield fetchAllRecords(action)
    result = records.result
    count = records.count

    const voucherCode = count === 1 ? result[0].voucher_code : localStorage.getItem("voucherCode")

    if (voucherCode) {
        yield put(getVoucher(voucherCode))

        yield race([
            take((a: ActionType<typeof getVoucherOk>) => {
                return isActionOf(getVoucherOk, a) && a.meta.voucherCode === voucherCode
            }),
            take((a: ActionType<typeof getVoucherError>) => {
                return isActionOf(getVoucherError, a) && a.meta.voucherCode === voucherCode
            }),
        ])
    }

    const { page, per_page } = action.payload
    yield put(viewHomeOverviewOk(result, count, page, per_page))
}

function* watchViewHomeOverview() {
    yield takeEvery(getType(viewHomeOverview), handleViewHomeOverview)
}

export default function* viewHomeOverviewSaga() {
    yield fork(watchViewHomeOverview)
}
