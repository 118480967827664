import { BoxEdges, Button, Paragraph, Popover, Spacer } from "@clearhaus/design-system"
import { useUserAppSettings } from "modules/user-app-settings/queries"
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { LocalizeContext } from "react-localize-redux"

const contentStyles = {
    maxWidth: 250,
}

interface IWelcomePopoverProps {
    children: React.ReactNode
}
export const WelcomePopover: React.FC<IWelcomePopoverProps> = (props) => {
    const [show, setShow] = useState(false)
    const { translate } = useContext(LocalizeContext)
    const { data: userAppSettings } = useUserAppSettings()

    const texts = useMemo(
        () => ({
            description: translate(
                "navigation.account_dropdown.welcome_popover.description"
            ).toString(),
            button: translate("navigation.account_dropdown.welcome_popover.button").toString(),
        }),
        []
    )

    useEffect(() => {
        if (userAppSettings?.welcome) {
            setShow(true)
        }
    }, [userAppSettings])

    const hide = useCallback(() => {
        setShow(false)
    }, [])

    const handleOnClick = useCallback(() => {
        hide()
    }, [])

    const handleOnHideEnd = useCallback(() => {
        hide()
    }, [])

    return (
        <Popover placement="bottom" show={show}>
            <Popover.Reference>{props.children}</Popover.Reference>
            <Popover.Content dark onHideEnd={handleOnHideEnd}>
                <BoxEdges p={24} style={contentStyles}>
                    <Paragraph>{texts.description}</Paragraph>
                    <Spacer size={12} />
                    <Button kind="plain" color="tertiary" onClick={handleOnClick}>
                        {texts.button}
                    </Button>
                </BoxEdges>
            </Popover.Content>
        </Popover>
    )
}
