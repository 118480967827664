import { updateApplication } from "modules/applications/actions"
import { updateCompanyOk } from "modules/companies/actions"
import { all, fork, put, takeEvery } from "redux-saga/effects"
import { ActionType, getType } from "typesafe-actions"

export function* handleUpdateTradingName(action: ActionType<typeof updateCompanyOk>) {
    const company = action.payload
    if (company.data.name) {
        yield put(
            updateApplication(
                {
                    business_model: {
                        trading_name: company.data.name,
                    },
                },
                company.relations.application
            )
        )
    }
}

export function* watchUpdateTradingName() {
    yield takeEvery(getType(updateCompanyOk), handleUpdateTradingName)
}

export function* updateTradingNameSaga() {
    yield all([fork(watchUpdateTradingName)])
}
