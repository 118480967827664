import { BoxEdges, List, Separator, Spacer } from "@clearhaus/design-system"
import {
    AccountSwitcherCurrencyType,
    AccountSwitcherItem,
} from "components/AccountSwitcher/AccountSwitcherItem"
import { AccountSwitcherActionsContext } from "components/AccountSwitcher/Provider/AccountSwitcherContext"
import { LoadMoreButton } from "components/common/tables/LoadMoreButton"
import { AccountsContext } from "modules/accounts/Providers/Accounts/AccountsContext"
import React, { useContext, useEffect, useRef, useState } from "react"
import { LocalizeContext, Translate } from "react-localize-redux"
import { Subject, Subscription } from "rxjs"

interface IAccountSwitcherContent {
    allowMore?: boolean
    activeIndex?: number
    switchToActiveAccountByIndex?: Subject<number>
    accountCountSubject?: Subject<number>
}

const allAccountsText = <Translate id={"common.accountswitcher.all_accounts"} />
const allAccountsDescriptionText = (
    <Translate id={"common.accountswitcher.all_accounts_description_modal"} />
)

export const AccountSwitcherContent: React.FC<IAccountSwitcherContent> = (props) => {
    const { allowMore = false, accountCountSubject } = props
    const { data, isLoading, relations, loadMore } = useContext(AccountsContext)
    const { switchAccount } = useContext(AccountSwitcherActionsContext)
    const { translate } = useContext(LocalizeContext)
    const [activeIndex, setActiveIndex] = useState(() => {
        return props.activeIndex !== undefined ? props.activeIndex : -1
    })
    const setActiveAccountIndexSub = useRef<Subscription>()
    const dataRefs = useRef<Array<React.RefObject<HTMLLIElement>>>([])

    useEffect(() => {
        if (props.switchToActiveAccountByIndex) {
            if (setActiveAccountIndexSub.current && !setActiveAccountIndexSub.current.closed) {
                setActiveAccountIndexSub.current.unsubscribe()
            }

            setActiveAccountIndexSub.current = props.switchToActiveAccountByIndex.subscribe((i) => {
                const actualIndex = i - 1
                if (actualIndex === -1) {
                    switchAccount()
                } else {
                    if (data[actualIndex]) {
                        switchAccount(data[actualIndex].relations.self)
                    }
                }
            })
        }

        if (data) {
            if (accountCountSubject) {
                accountCountSubject.next(data.length)
            }

            for (let i = 0; i < data.length; i++) {
                dataRefs.current[i] = React.createRef<HTMLLIElement>()
            }
        }
    }, [data])

    useEffect(() => {
        if (props.activeIndex !== undefined && activeIndex !== props.activeIndex) {
            if (props.activeIndex > data.length) {
                loadMore()
            }
            setActiveIndex(props.activeIndex)
        } else if (props.activeIndex === undefined && activeIndex !== -1) {
            setActiveIndex(-1)
        }
    }, [props.activeIndex])

    useEffect(() => {
        const elementRef = dataRefs.current[activeIndex]
        if (elementRef && elementRef.current) {
            elementRef.current.scrollIntoView({ block: "end", inline: "nearest" })
        }
    }, [activeIndex])

    useEffect(() => {
        return () => {
            if (setActiveAccountIndexSub.current && !setActiveAccountIndexSub.current.closed) {
                setActiveAccountIndexSub.current.unsubscribe()
            }
        }
    }, [])

    if (!data || !relations) {
        return null
    }

    return (
        <List>
            <List.Item onClick={() => switchAccount()} active={activeIndex === 0}>
                <List.Item.Content>
                    <BoxEdges px={16}>
                        <AccountSwitcherItem
                            name={allAccountsText}
                            descriptor={allAccountsDescriptionText}
                            showLinks={false}
                            wrapper={false}
                            fullWidth
                        />
                    </BoxEdges>
                </List.Item.Content>
            </List.Item>
            <Separator />
            {data.map((a, i) => {
                const isLast = i === data.length - 1
                return (
                    <React.Fragment key={a.relations.self}>
                        <List.Item
                            onClick={() => switchAccount(a.relations.self)}
                            active={activeIndex === i + 1}
                            ref={dataRefs.current[i]}
                        >
                            <List.Item.Content>
                                <BoxEdges px={16}>
                                    <AccountSwitcherItem
                                        currency={a.currency as AccountSwitcherCurrencyType}
                                        name={a.name}
                                        descriptor={a.descriptor}
                                        mid={a.merchant_id}
                                        selfLink={a.relations.self}
                                        key={a.relations.self}
                                        wrapper={false}
                                        fullWidth
                                    />
                                </BoxEdges>
                            </List.Item.Content>
                        </List.Item>
                        {!isLast && <Separator />}
                    </React.Fragment>
                )
            })}
            {allowMore && relations.next && (
                <>
                    <Spacer size={12} />
                    <LoadMoreButton onClick={loadMore} isLoading={isLoading}>
                        {translate("common.accountswitcher.load_more").toString()}
                    </LoadMoreButton>
                </>
            )}
        </List>
    )
}
