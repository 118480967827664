import { fork, put, take, takeLatest } from "@redux-saga/core/effects"
import { IAutofillCompanyInformation } from "components/application/AutofillCompany/AutofillCompany"
import { shallowEqual } from "fast-equals"
import { catchError } from "modules/common/actions"
import { Uri } from "modules/common/types"
import { fetchCompany, fetchCompanyOk } from "modules/companies/actions"
import {
    batchFetchPerson,
    batchFetchPersonOk,
    createPerson,
    createPersonOk,
    fetchPeople,
    fetchPeopleOk,
} from "modules/people/actions"
import { IPersonData } from "modules/people/types"
import { ActionType, createCustomAction, getType, isActionOf } from "typesafe-actions"
import { objectChecksum } from "utils"

export const AUTOPOPULATE_OWNERSHIP_STRUCTURE = "@@flows/AUTOPOPULATE_OWNERSHIP_STRUCTURE"
export const AUTOPOPULATE_OWNERSHIP_STRUCTURE_OK = "@@flows/AUTOPOPULATE_OWNERSHIP_STRUCTURE_OK"

export const autopopulateOwnershipStructure = createCustomAction(
    AUTOPOPULATE_OWNERSHIP_STRUCTURE,
    (lookedUpCompany: IAutofillCompanyInformation, companyUri: Uri) => ({
        meta: companyUri,
        payload: lookedUpCompany,
    })
)
export const autopopulateOwnershipStructureOk = createCustomAction(
    AUTOPOPULATE_OWNERSHIP_STRUCTURE_OK,
    (companyUri: Uri) => ({ payload: companyUri })
)

function* handleAutopopulateOwnershipStructure(
    action: ActionType<typeof autopopulateOwnershipStructure>
) {
    const companyInformation = action.payload
    const companyUri = action.meta
    try {
        // If no director or ownership data available, bail fast
        if (!companyInformation.director && companyInformation.ownership.length === 0) {
            yield put(autopopulateOwnershipStructureOk(companyUri))
            return
        }

        yield put(fetchCompany(companyUri))
        const { payload: company }: ActionType<typeof fetchCompanyOk> = yield take(
            (a: ActionType<typeof fetchCompanyOk>) => {
                return isActionOf(fetchCompanyOk, a) && a.meta.uri === companyUri
            }
        )
        const peopleUri = company.relations.people

        yield put(fetchPeople(company.relations.people))
        const { payload: fetchPeopleOkPayload }: ActionType<typeof fetchPeopleOk> = yield take(
            (a: ActionType<typeof fetchPeopleOk>) => {
                return isActionOf(fetchPeopleOk, a) && a.meta.uri === company.relations.people
            }
        )
        const personUris = fetchPeopleOkPayload.relations.people
            ? Array.isArray(fetchPeopleOkPayload.relations.people)
                ? fetchPeopleOkPayload.relations.people
                : [fetchPeopleOkPayload.relations.people]
            : []
        yield put(batchFetchPerson(personUris))
        const { payload: batchOkPayload }: ActionType<typeof batchFetchPersonOk> = yield take(
            (a: ActionType<typeof batchFetchPersonOk>) => {
                return isActionOf(batchFetchPersonOk, a) && shallowEqual(a.meta.uris, personUris)
            }
        )
        const people = batchOkPayload.data

        // If there is already people added to this company, bail
        if (people.length !== 0) {
            yield put(autopopulateOwnershipStructureOk(companyUri))
            return
        }

        const peopleToCreate: IPersonData[] = []
        const peopleChecksums: number[] = []
        // First add the director, if there
        if (companyInformation.director) {
            const checksum = objectChecksum(companyInformation.director)
            const {
                address: address_line_1,
                zip_code: zipcodeInt,
                ownership_percentage,
                ...leftovers
            } = companyInformation.director
            const zipcode = zipcodeInt ? zipcodeInt.toString() : undefined
            peopleChecksums.push(checksum)
            peopleToCreate.push({
                ...leftovers,
                address_line_1,
                role_director: true,
                zipcode,
            })
        }

        if (companyInformation.ownership.length > 0) {
            companyInformation.ownership.forEach((p) => {
                const personChecksum = objectChecksum(p, ["ownership_percentage"])
                const checksumIndex = peopleChecksums.indexOf(personChecksum)
                // If checksum match found, add role_owner to that person
                if (checksumIndex !== -1) {
                    peopleToCreate[checksumIndex].role_owner = true
                } else {
                    const {
                        address: address_line_1,
                        zip_code: zipcodeInt,
                        ownership_percentage,
                        ...leftovers
                    } = p
                    const zipcode = zipcodeInt ? zipcodeInt.toString() : undefined

                    peopleChecksums.push(personChecksum)
                    peopleToCreate.push({
                        ...leftovers,
                        address_line_1,
                        role_owner: true,
                        zipcode,
                    })
                }
            })
        }

        for (const person of peopleToCreate) {
            yield put(createPerson(person, peopleUri))
            yield take((a: ActionType<typeof createPersonOk>) => {
                return (
                    isActionOf(createPersonOk, a) &&
                    a.payload.data.name === person.name &&
                    a.payload.data.address_line_1 === person.address_line_1 &&
                    a.payload.data.city === person.city
                )
            })
        }

        // Trigger fetchPeople so the form gets updated
        yield put(fetchPeople(peopleUri))

        yield put(autopopulateOwnershipStructureOk(companyUri))
    } catch (e) {
        yield put(catchError(e))
    }
}

function* watchAutopopulateOwnershipStructure() {
    yield takeLatest(getType(autopopulateOwnershipStructure), handleAutopopulateOwnershipStructure)
}

export default function* autopopulateOwnershipStructureSaga() {
    yield fork(watchAutopopulateOwnershipStructure)
}
