import { Problem } from "ketting"
import bankAccountsRootSaga from "modules/bankAccounts/sagas"
import { RxMiddleware } from "modules/common/RxMiddleware"
import { createCustomAction } from "typesafe-actions"
import { SagaRegistry } from "utils/SagaRegistry"

import { Uri } from "../common/types"
import * as actions from "./actions"
import {
    FETCH_BANK_ACCOUNT,
    FETCH_BANK_ACCOUNT_ERROR,
    FETCH_BANK_ACCOUNT_OK,
    IBankAccountData,
    IBankAccountRelations,
    UPDATE_BANK_ACCOUNT,
    UPDATE_BANK_ACCOUNT_ERROR,
    UPDATE_BANK_ACCOUNT_OK,
} from "./types"

export const fetchBankAccount = createCustomAction(FETCH_BANK_ACCOUNT, (uri: Uri) => ({
    payload: { uri },
}))
export const fetchBankAccountOk = createCustomAction(
    FETCH_BANK_ACCOUNT_OK,
    (data: IBankAccountData, relations: IBankAccountRelations, uri: Uri) => ({
        meta: { uri },
        payload: { data, relations },
    })
)

export const fetchBankAccountError = createCustomAction(
    FETCH_BANK_ACCOUNT_ERROR,
    (error: Problem, uri: Uri) => ({
        payload: error,
        meta: { uri },
    })
)

export const updateBankAccount = createCustomAction(
    UPDATE_BANK_ACCOUNT,
    (data: Partial<IBankAccountData>, uri: Uri) => ({
        meta: { uri },
        payload: { data },
    })
)

export const updateBankAccountOk = createCustomAction(
    UPDATE_BANK_ACCOUNT_OK,
    (data: IBankAccountData, relations: IBankAccountRelations, uri: Uri) => ({
        meta: { uri },
        payload: { data, relations },
    })
)

export const updateBankAccountError = createCustomAction(
    UPDATE_BANK_ACCOUNT_ERROR,
    (error: Problem, uri: Uri) => ({
        meta: { uri },
        payload: error,
    })
)

RxMiddleware.addActions(actions)
SagaRegistry.register(bankAccountsRootSaga)
