import { BoxEdges } from "@clearhaus/design-system/BoxEdges"
import { Brandmark } from "@clearhaus/design-system/Brandmark"
import { Button } from "@clearhaus/design-system/Button"
import defaults from "@clearhaus/design-system/defaults"
import { makeStyles } from "@clearhaus/design-system/utils/styles/makeStyles"
import React, { useCallback } from "react"
import { useHistory } from "react-router"

const styles = {
    background: {
        alignItems: "center",
        background: defaults.gradients.standard,
        display: "flex",
        justifyContent: "center",
    },
    brandmark: {
        // Makes Clearhaus logo look centered
        marginLeft: -1,
    },
}

interface INavigationBrandmarkProps {
    link?: string
    color?: string
    size?: number
    cornerRadius?: "large" | "normal" | "rounded"
}

const useStyles = makeStyles(styles)
const NavigationBrandmark: React.FC<INavigationBrandmarkProps> = (props) => {
    const classes = useStyles()
    const { color = defaults.colors.neutrals[100], size = 38, cornerRadius = "rounded" } = props

    const history = useHistory()

    const handleClick = useCallback(() => {
        if (props.link) {
            history.push(props.link)
        }
    }, [props.link])

    return (
        <Button
            color="secondary"
            kind="plain"
            onClick={handleClick}
            size="large"
            prefix={
                <BoxEdges
                    cornerRadius={defaults.cornerRadius[cornerRadius]}
                    className={classes.background}
                    style={{ width: size, height: size }}
                >
                    <div className={classes.brandmark}>
                        <Brandmark size={size * 0.55} color={color} />
                    </div>
                </BoxEdges>
            }
        />
    )
}

export { NavigationBrandmark }
