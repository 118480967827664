import {
    IApplicationsData,
    IApplicationsEmbedded,
    IApplicationsRelations,
} from "modules/applications/types"
import { IdTokenPayload } from "modules/authentication/types"
import { CUTTER_API_ROOT, MERCHANT_API_ROOT } from "modules/common"
import {
    MerchantApiResponseQuery,
    MerchantApiResponseQueryReturn,
} from "modules/common/MerchantApiResponseQuery"
import { IGatewaysData, IGatewaysEmbedded, IGatewaysRelations } from "modules/gateways/types"
import { Insights } from "modules/insights/Insights"
import { IRootRelations } from "modules/root/types"
import { IVoucherData } from "modules/voucher"
import { Observable, of, switchMap, zip } from "rxjs"
import { filter, map, take } from "rxjs/operators"

export const handleOnLoggedIn = (idTokenDecodedPayload: IdTokenPayload) => {
    return new Promise<boolean>((resolve, _reject) => {
        const voucherCode = localStorage.getItem("voucherCode")
        const onLoggedInObs = of(voucherCode).pipe(
            switchMap((voucherCode) => {
                if (voucherCode) {
                    return MerchantApiResponseQuery.get<IVoucherData>(
                        `${CUTTER_API_ROOT}/vouchers/${voucherCode}`
                    ).pipe(
                        filter((resp) => resp.status === "success"),
                        map((resp) => {
                            if (!resp.data) {
                                throw Error(`Not valid response from Cutter`)
                            }

                            return resp.data.gateway_name
                        }),
                        take(1)
                    )
                } else {
                    return MerchantApiResponseQuery.get<object, IRootRelations>(
                        MERCHANT_API_ROOT!
                    ).pipe(
                        filter((mapiRootResponse) => mapiRootResponse.status === "success"),
                        switchMap((mapiRoot) => {
                            if (!mapiRoot.relations?.applications) {
                                throw Error(`Not valid response from MAPI root`)
                            }

                            return MerchantApiResponseQuery.get<
                                IApplicationsData,
                                IApplicationsRelations,
                                IApplicationsEmbedded
                            >(mapiRoot.relations.applications)
                        }),
                        filter((applicationsResponse) => applicationsResponse.status === "success"),
                        switchMap((applicationsResponse) => {
                            if (
                                !applicationsResponse.embedded ||
                                !applicationsResponse.embedded["ch:applications"]
                            ) {
                                return of([])
                            }

                            const applications = Array.isArray(
                                applicationsResponse.embedded["ch:applications"]
                            )
                                ? applicationsResponse.embedded["ch:applications"]
                                : [applicationsResponse.embedded["ch:applications"]]
                            const processedVoucherCodes: string[] = []
                            const furtherProcessingNeeded = []

                            // Go through each application, process voucherCode, schedule for further processing if either missing or fails
                            for (const application of applications) {
                                if (!application.metadata.voucher_code) {
                                    furtherProcessingNeeded.push(application)
                                    continue
                                }

                                if (
                                    processedVoucherCodes.indexOf(
                                        application.metadata.voucher_code
                                    ) === -1
                                ) {
                                    furtherProcessingNeeded.push(application)
                                    processedVoucherCodes.push(application.metadata.voucher_code)
                                }
                            }

                            const gatewaysObs: Array<
                                Observable<
                                    MerchantApiResponseQueryReturn<
                                        IGatewaysData,
                                        IGatewaysRelations,
                                        IGatewaysEmbedded
                                    >
                                >
                            > = []
                            // Go through each furtherProcessingNeeded
                            for (const application of furtherProcessingNeeded) {
                                gatewaysObs.push(
                                    MerchantApiResponseQuery.get<
                                        IGatewaysData,
                                        IGatewaysRelations,
                                        IGatewaysEmbedded
                                    >(application.relations.gateways).pipe(
                                        filter(
                                            (gatewayReponse) => gatewayReponse.status === "success"
                                        ),
                                        take(1)
                                    )
                                )
                            }

                            return zip(gatewaysObs)
                        }),
                        map((gatewayResponses) => {
                            const gateways: string[] = []
                            for (const gatewayResponse of gatewayResponses) {
                                if (gatewayResponse.embedded?.["ch:gateways"]) {
                                    const selectedGateway = Array.isArray(
                                        gatewayResponse.embedded?.["ch:gateways"]
                                    )
                                        ? gatewayResponse.embedded?.["ch:gateways"][0]
                                        : gatewayResponse.embedded?.["ch:gateways"]
                                    if (gateways.indexOf(selectedGateway.name) === -1) {
                                        gateways.push(selectedGateway.name)
                                    }
                                }
                            }

                            return gateways.sort()
                        }),
                        take(1)
                    )
                }
            }),
            take(1)
        )

        onLoggedInObs.subscribe((gateways) => {
            Insights.setUserSession(idTokenDecodedPayload.sub, gateways)
            resolve(true)
        })
    })
}
