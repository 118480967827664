import { AuthContext } from "components/authentication/AuthProvider"
import { MeContext } from "modules/me/Provider/context"
import { useRoot } from "modules/root"
import { useContext, useEffect, useState } from "react"
import { renderToStaticMarkup } from "react-dom/server"
import { LocalizeContext } from "react-localize-redux"

const ACTIVATED_LANGUAGES = process.env.ACTIVATED_LANGUAGES
    ? process.env.ACTIVATED_LANGUAGES!.split(",")
    : false
if (!ACTIVATED_LANGUAGES) {
    throw new Error(`ACTIVATED_LANGUAGES environment variable must have a value`)
}
const DEFAULT_LANGUAGE = process.env.DEFAULT_LANGUAGE
if (!DEFAULT_LANGUAGE) {
    throw new Error(`DEFAULT_LANGUAGE environment variable must have a value`)
}

if (ACTIVATED_LANGUAGES.indexOf(DEFAULT_LANGUAGE) === -1) {
    throw new Error(
        `DEFAULT_LANGUAGE '${DEFAULT_LANGUAGE}' is not in ACTIVATED_LANGUAGES ('${ACTIVATED_LANGUAGES}')`
    )
}

export const useInitializeSpecificLanguage = (language: string) => {
    const [isInitialLoad, setIsInitialLoad] = useState(true)
    const { initialize: initializeLocalization } = useContext(LocalizeContext)

    useEffect(() => {
        if (isInitialLoad) {
            let currentLanguage = language
            if (!ACTIVATED_LANGUAGES.some((l) => l === language)) {
                currentLanguage = DEFAULT_LANGUAGE
            }
            initializeLocalization({
                languages: ACTIVATED_LANGUAGES,
                options: {
                    defaultLanguage: currentLanguage,
                    renderToStaticMarkup,
                },
            })
            setIsInitialLoad(false)
        }
    }, [isInitialLoad])

    return { isLoading: isInitialLoad }
}

export const useInitializeLanguage = () => {
    const [isInitialLoad, setIsInitialLoad] = useState(true)
    const { isLoading: meIsLoading, data: me } = useContext(MeContext)
    const { initialize: initializeLocalization } = useContext(LocalizeContext)
    const { isLoading: authenticationIsLoading } = useContext(AuthContext)
    const { isLoading: rootIsLoading } = useRoot()

    useEffect(() => {
        if (
            meIsLoading === false &&
            rootIsLoading === false &&
            authenticationIsLoading === false &&
            isInitialLoad
        ) {
            const langFromMe = me && me.locale ? me.locale.substring(0, 2) : undefined
            let currentLanguage = langFromMe

            if (!ACTIVATED_LANGUAGES.some((l) => l === langFromMe)) {
                currentLanguage = DEFAULT_LANGUAGE
            }
            initializeLocalization({
                languages: ACTIVATED_LANGUAGES,
                options: {
                    defaultLanguage: currentLanguage,
                    renderToStaticMarkup,
                },
            })
            setIsInitialLoad(false)
        }
    }, [meIsLoading, rootIsLoading, isInitialLoad, authenticationIsLoading])

    return { isLoading: isInitialLoad }
}

export const useInitializeDefaultLanguage = () => {
    const [isInitialLoad, setIsInitialLoad] = useState(true)
    const localization = useContext(LocalizeContext)
    const { isLoading: isAuthLoading } = useContext(AuthContext)
    const { isLoading: isRootLoading } = useRoot()

    useEffect(() => {
        if (isRootLoading === false && isAuthLoading === false && isInitialLoad) {
            localization.initialize({
                languages: ACTIVATED_LANGUAGES,
                options: {
                    defaultLanguage: DEFAULT_LANGUAGE,
                    renderToStaticMarkup,
                },
            })
            setIsInitialLoad(false)
        }
    }, [isRootLoading, isAuthLoading, isInitialLoad])

    return { isLoading: isInitialLoad }
}
