/*
 * Browser checks to se what the current browser is
 * based on: https://github.com/arasatasaygin/is.js
 */

const userAgent = ((navigator && navigator.userAgent) || "").toLowerCase()

// is current browser internet explorer?
export const isIE = (): boolean => {
    const match = userAgent.match(/(?:msie |trident.+?; rv:)(\d+)/)
    return match !== null
}
