import { Loader, UserIcon } from "@clearhaus/design-system"
import { Button } from "@clearhaus/design-system/Button"
import { Input } from "@clearhaus/design-system/FormElement"
import { Label } from "@clearhaus/design-system/Label"
import { LoadingScreen } from "@clearhaus/design-system/LoadingScreen/LoadingScreen"
import { Modal } from "@clearhaus/design-system/Modal"
import { Spacer } from "@clearhaus/design-system/Spacer"
import useEventListener from "@use-it/event-listener"
import { useMe, useUpdateMe } from "modules/me/queries"
import { Me } from "modules/me/types"
import React, { useContext, useEffect, useState } from "react"
import { LocalizeContext, Translate } from "react-localize-redux"

interface EditMeModalProps {
    open: boolean
    onClosed: () => void
}

const userIcon = <UserIcon />

export const EditMeModal: React.FC<EditMeModalProps> = (props) => {
    const { translate } = useContext(LocalizeContext)
    const { data } = useMe()
    const { mutate: updateMe, isLoading } = useUpdateMe()
    const [open, setOpen] = useState(props.open)
    const [fullName, setFullName] = useState(
        data ? (data.name !== data.email ? data.name : "") : ""
    )

    const [phone, setPhone] = useState(data ? data.phone : "")
    const [email, setEmail] = useState(data ? data.name : "")

    const handleKeyPress = (e: KeyboardEvent) => {
        if (open && e.key === "Enter") {
            handleSave()
        }
    }

    useEventListener("keypress", handleKeyPress)

    useEffect(() => {
        if (open && !isLoading) {
            setOpen(false)
        }
    }, [isLoading])

    useEffect(() => {
        if (props.open !== open) {
            setOpen(props.open)
        }
    }, [props.open])

    useEffect(() => {
        if (data !== undefined) {
            if (data.name !== fullName && fullName === "" && data.name !== data.email) {
                setFullName(data.name)
            }

            if (data.phone !== phone && phone === "") {
                setPhone(data.phone)
            }

            if (data.email !== email) {
                setEmail(data.email)
            }
        }
    }, [data])

    const handleSave = () => {
        const { email, type, ...oldMe } = data as Me
        updateMe({
            ...oldMe,
            name: fullName,
            phone,
        })
    }

    return (
        <Modal open={open} size="md" onClosed={props.onClosed}>
            <Modal.Header prefix={userIcon}>
                <Translate id="common.profile.heading" />
            </Modal.Header>
            <Modal.Content>
                <LoadingScreen hidden={!!data} />
                <Label>
                    <Translate id="common.profile.fields.email_label" />
                </Label>
                <Input defaultValue={email} disabled />
                <Spacer size={"small"} />
                <Label>
                    <Translate id="common.profile.fields.full_name_label" />
                </Label>
                <Input defaultValue={fullName} onChange={(_, v) => setFullName(v)} />
                <Spacer size={"small"} />
                <Label>
                    <Translate id="common.profile.fields.phone_label" />
                </Label>
                <Input defaultValue={phone} onChange={(_, v) => setPhone(v)} />
                <Spacer size={32} />
                <Button
                    fullWidth
                    kind={"squared"}
                    disabled={!phone || !fullName || isLoading}
                    onClick={handleSave}
                    prefix={isLoading ? <Loader size={14} fillToParent /> : undefined}
                >
                    {translate("common.profile.update_profile_button") as string}
                </Button>
            </Modal.Content>
        </Modal>
    )
}
