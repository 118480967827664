import { STATUSPAGE_UNRESOLVED_URL } from "modules/common"
import { catchError } from "modules/common/actions"
import { IStatusPage } from "modules/statuspage/types"
import { call, fork, put, takeEvery } from "redux-saga/effects"
import { createCustomAction, getType } from "typesafe-actions"
import { SagaRegistry } from "utils/SagaRegistry"

const FETCH_INCIDENTS = "@@statuspage/FETCH_INCIDENTS"
export const fetchIncidents = createCustomAction(FETCH_INCIDENTS)
const FETCH_INCIDENTS_OK = "@@statuspage/FETCH_INCIDENTS_OK"
export const fetchIncidentsOk = createCustomAction(FETCH_INCIDENTS_OK, (data: IStatusPage) => ({
    payload: { data },
}))
const FETCH_INCIDENTS_ERROR = "@@statuspage/FETCH_INCIDENTS_ERROR"
export const fetchIncidentsError = createCustomAction(FETCH_INCIDENTS_ERROR)

export function* handleFetchIncidents() {
    try {
        if (!STATUSPAGE_UNRESOLVED_URL) {
            throw Error(`Missing environment variable 'STATUSPAGE_UNRESOLVED_URL'`)
        }

        const apiResponse: Response = yield call(fetch, STATUSPAGE_UNRESOLVED_URL, {
            method: "GET",
            headers: { "Content-Type": "application/json" },
            mode: "cors",
        })
        const responseResult: IStatusPage = yield call([apiResponse, apiResponse.json])

        yield put(fetchIncidentsOk(responseResult))
    } catch (e) {
        yield put(fetchIncidentsError())
        yield put(catchError(e))
    }
}

export function* watchStatusPage() {
    yield takeEvery(getType(fetchIncidents), handleFetchIncidents)
}

function* statusPageRootSaga() {
    yield fork(watchStatusPage)
}

SagaRegistry.register(statusPageRootSaga)
