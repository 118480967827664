import { LoadingScreen } from "@clearhaus/design-system/LoadingScreen"
import React, { useContext, useEffect, useState } from "react"
import { LocalizeContext, SingleLanguageTranslation } from "react-localize-redux"

interface ILocalizationLoaderProps {
    namespace: string | string[]
    children: React.ReactNode
}

export const LocalizationLoader: React.FC<ILocalizationLoaderProps> = (props) => {
    const localizationContext = useContext(LocalizeContext)
    const [loadingTranslations, setLoadingTranslations] = useState(true)
    const [activeLanguage, setActiveLanguage] = useState<string | null>(null)
    const { children, namespace } = props

    const namespacesToLoad = !Array.isArray(namespace) ? [namespace] : namespace

    useEffect(() => {
        const { code: nextActiveLanguage } = localizationContext.activeLanguage
        if (activeLanguage !== nextActiveLanguage) {
            setLoadingTranslations(true)

            const translationsImportPromises: Array<Promise<SingleLanguageTranslation>> = []
            namespacesToLoad.forEach((n) => {
                translationsImportPromises.push(
                    import(`../../localizations/${nextActiveLanguage}/${n}.json`)
                )
            })
            Promise.all(translationsImportPromises).then((translations) => {
                translations.forEach((translation) => {
                    localizationContext.addTranslationForLanguage(translation, nextActiveLanguage)
                })
                setLoadingTranslations(false)
                setActiveLanguage(nextActiveLanguage)
            })
        }
    }, [localizationContext.activeLanguage])

    if (!localizationContext.activeLanguage || !localizationContext.languages) {
        throw new Error(`No 'react-localize-redux' context found, did you forget to initialize?`)
    }

    return (
        <>
            {loadingTranslations && <LoadingScreen />}
            {!loadingTranslations && children}
        </>
    )
}
