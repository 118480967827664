import { MerchantApiRequest } from "modules/common"
import { catchError, forkWatcher } from "modules/common/actions"
import { put } from "redux-saga/effects"
import { ActionType, getType } from "typesafe-actions"
import {
    fetchBankAccount,
    fetchBankAccountOk,
    updateBankAccount,
    updateBankAccountOk,
} from "./actions"
import { IBankAccountData, IBankAccountRelations } from "./types"

export function* handleFetchBankAccount(action: ActionType<typeof fetchBankAccount>) {
    try {
        const merchantApiRequest = new MerchantApiRequest(action.payload.uri)

        const {
            relations,
            repr,
        }: {
            relations: IBankAccountRelations
            repr: IBankAccountData
        } = yield merchantApiRequest.get()

        yield put(fetchBankAccountOk(repr, relations, relations.self))
    } catch (e) {
        yield put(catchError(e))
    }
}

export function* handleUpdateBankAccount(action: ActionType<typeof updateBankAccount>) {
    try {
        const { data: bankAccountData } = action.payload
        // Strip whitespace and uppercase
        if (bankAccountData.iban) {
            bankAccountData.iban = bankAccountData.iban.replace(/\s+/g, "")
            bankAccountData.iban = bankAccountData.iban.toUpperCase()
        }

        const merchantApiRequest = new MerchantApiRequest(action.meta.uri, {
            noCache: true,
        })
        yield merchantApiRequest.patch(bankAccountData)

        const {
            relations,
            repr,
        }: {
            relations: IBankAccountRelations
            repr: IBankAccountData
        } = yield merchantApiRequest.get()

        yield put(updateBankAccountOk(repr, relations, relations.self))
    } catch (e) {
        yield put(catchError(e))
    }
}

export default function* bankAccountsRootSaga() {
    yield put(forkWatcher(getType(fetchBankAccount), handleFetchBankAccount))
    yield put(forkWatcher(getType(updateBankAccount), handleUpdateBankAccount))
}
