import {
    autofillBankAccount,
    autofillBankAccountError,
    autofillBankAccountOk,
} from "flows/autofillBankAccount"
import {
    autofillCompany,
    autofillCompanyError,
    autofillCompanyOk,
    autofillCompanyReset,
} from "flows/autofillCompany"
import { viewHomeOverview, viewHomeOverviewOk } from "flows/viewHomeOverview"
import { fetchApplicationOk, updateApplicationOk } from "modules/applications/actions"
import { fetchBankAccountOk, updateBankAccountOk } from "modules/bankAccounts/actions"
import { fetchCompanyOk, updateCompanyOk } from "modules/companies/actions"
import { fetchContactOk, updateContactOk } from "modules/contacts/actions"
import { fetchPdfContract, fetchPdfContractOk } from "modules/contract/actions"
import { deleteFileOk, fetchFileOk } from "modules/files/actions"
import { fetchGatewaysOk, selectGatewayOk } from "modules/gateways/actions"
import { updateMeOk } from "modules/me/actions"
import {
    createPersonOk,
    deletePerson,
    fetchPeopleOk,
    fetchPersonOk,
    updatePersonOk,
} from "modules/people/actions"
import { getVoucherError, getVoucherOk } from "modules/voucher"
import { getSupportedCountriesOk } from "modules/voyager/actions"
import {
    deleteWebsiteOk,
    fetchWebsiteOk,
    fetchWebsitesOk,
    updateWebsiteOk,
} from "modules/websites/actions"
import { PagesApplicationState } from "pages/Applications/types"
import { Reducer } from "redux"
import { ActionType, getType } from "typesafe-actions"

const initialState: PagesApplicationState = {
    bankAccountAutofill: {
        error: false,
        fetched: false,
        isLoading: false,
    },
    companyAutofillData: undefined,
    companyAutofillDataError: undefined,
    companyAutofillDataIsLoading: false,
    companyAutofillSupportedCountries: undefined,
    isLoading: false,
    selectedApplication: "",
}

type ApplicationActions = ActionType<
    | typeof viewHomeOverview
    | typeof viewHomeOverviewOk
    | typeof fetchPdfContract
    | typeof fetchPdfContractOk
    | typeof updateMeOk
    | typeof getSupportedCountriesOk
    | typeof autofillCompany
    | typeof autofillCompanyOk
    | typeof autofillCompanyError
    | typeof deleteFileOk
    | typeof fetchFileOk
    | typeof deletePerson
    | typeof fetchPersonOk
    | typeof createPersonOk
    | typeof updatePersonOk
    | typeof fetchPeopleOk
    | typeof fetchApplicationOk
    | typeof updateApplicationOk
    | typeof fetchCompanyOk
    | typeof updateCompanyOk
    | typeof fetchWebsitesOk
    | typeof fetchWebsiteOk
    | typeof updateWebsiteOk
    | typeof deleteWebsiteOk
    | typeof fetchBankAccountOk
    | typeof updateBankAccountOk
    | typeof fetchGatewaysOk
    | typeof fetchContactOk
    | typeof updateContactOk
    | typeof getVoucherOk
    | typeof getVoucherError
    | typeof autofillBankAccount
    | typeof autofillBankAccountOk
    | typeof autofillBankAccountError
    | typeof autofillCompanyReset
    | typeof selectGatewayOk
>

export const pagesApplicationReducer: Reducer<PagesApplicationState, ApplicationActions> = (
    state = initialState,
    action
) => {
    switch (action.type) {
        case getType(viewHomeOverview): {
            return {
                ...state,
                selectedApplication: "",
            }
        }
        case getType(getSupportedCountriesOk): {
            const countriesUppercase = action.payload.map((country: string) =>
                country.toUpperCase()
            )
            return {
                ...state,
                companyAutofillSupportedCountries: countriesUppercase,
            }
        }
        case getType(autofillCompany): {
            return { ...state, companyAutofillDataIsLoading: true }
        }
        case getType(autofillCompanyOk): {
            let companyInformation
            if (!action.payload) {
                companyInformation = null
            } else {
                companyInformation = { ...action.payload }
            }
            return {
                ...state,
                companyAutofillData: companyInformation,
                companyAutofillDataError: undefined,
                companyAutofillDataIsLoading: false,
            }
        }
        case getType(autofillCompanyError): {
            let error
            if (typeof action.payload === "string") {
                error = action.payload
            } else {
                error = { ...action.payload }
            }
            return {
                ...state,
                companyAutofillDataError: error,
                companyAutofillDataIsLoading: false,
            }
        }
        case getType(autofillCompanyReset): {
            return {
                ...state,
                companyAutofillData: undefined,
                companyAutofillDataError: undefined,
                companyAutofillDataIsLoading: false,
                companyAutofillSupportedCountries: undefined,
            }
        }
        case getType(autofillBankAccount): {
            return {
                ...state,
                bankAccountAutofill: {
                    error: false,
                    fetched: false,
                    isLoading: true,
                },
            }
        }
        case getType(autofillBankAccountOk): {
            return {
                ...state,
                bankAccountAutofill: {
                    error: false,
                    fetched: true,
                    isLoading: false,
                },
            }
        }
        case getType(autofillBankAccountError): {
            return {
                ...state,
                bankAccountAutofill: {
                    error: true,
                    fetched: false,
                    isLoading: false,
                },
            }
        }
        default:
            return state
    }
}
