import { fork, put, race, take, takeEvery } from "@redux-saga/core/effects"
import { catchError } from "modules/common/actions"
import { Uri } from "modules/common/types"
import { downloadFile, downloadFileFailed, downloadFileOk } from "modules/files/actions"
import { ActionType, createCustomAction, getType, isActionOf } from "typesafe-actions"

const DOWNLOAD_FILE = "@@flows/DOWNLOAD_FILE"

export const downloadFileWithPromise = createCustomAction(
    DOWNLOAD_FILE,
    (
        fileUri: Uri,
        // tslint:disable-next-line:ban-types
        promise: { resolve: Function; reject: Function }
    ) => ({ payload: fileUri, meta: { promise } })
)

function* handleDownloadFilePromise(action: ActionType<typeof downloadFileWithPromise>) {
    const { resolve: resolvePromise, reject: rejectPromise } = action.meta.promise
    try {
        const fileUri = action.payload

        yield put(downloadFile(fileUri))
        const [downloadOk, downloadFailed]: [
            ActionType<typeof downloadFileOk>,
            ActionType<typeof downloadFileFailed>
        ] = yield race([
            take((a: ActionType<typeof downloadFileOk>) => {
                return isActionOf(downloadFileOk, a) && a.meta.uri === fileUri
            }),
            take((a: ActionType<typeof downloadFileFailed>) => {
                return isActionOf(downloadFileFailed, a) && a.meta.uri === fileUri
            }),
        ])

        if (downloadFailed) {
            rejectPromise()
            return
        }

        resolvePromise([downloadOk.payload.objectUrl, downloadOk.payload.data.content_type])
    } catch (e) {
        yield put(catchError(e))
        rejectPromise(e)
        return
    }
}

function* watchHandleDownloadFilePromise() {
    yield takeEvery(getType(downloadFileWithPromise), handleDownloadFilePromise)
}

export default function* downloadFilePromiseSaga() {
    yield fork(watchHandleDownloadFilePromise)
}
