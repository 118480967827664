import {
    BellIcon,
    BoxEdges,
    BrandText,
    Button,
    CopyIcon,
    CrossIcon,
    FileTextIcon,
    Grid,
    HomeIcon,
    MessageSquareIcon,
    Paragraph,
    RadioIcon,
    RepeatIcon,
    RotateCwIcon,
    Separator,
    Sidebar as DSSidebar,
    Spacer,
    UploadIcon,
} from "@clearhaus/design-system"
import { AccountSwitcher } from "components/AccountSwitcher/AccountSwitcher"
import { NavigationBrandmark } from "components/common/NavigationBrandmark"
import { NavigationItem } from "components/common/NavigationItem"
import React, { useEffect, useRef } from "react"
import { Translate } from "react-localize-redux"
import { useHistory, useLocation } from "react-router"
import useCallbackRef from "utils/useCallbackRef"

const icons = {
    home: <HomeIcon />,
    transactions: <RepeatIcon />,
    settlements: <RotateCwIcon />,
    disputes: <MessageSquareIcon />,
    applications: <FileTextIcon />,
    accounts: <CopyIcon />,
    subscriptions: <BellIcon />,
    exports: <UploadIcon />,
    announcements: <RadioIcon />,
}

const texts = {
    home: <Translate id="navigation.home" />,
    transactions: <Translate id="navigation.transactions" />,
    settlements: <Translate id="navigation.settlements" />,
    disputes: <Translate id="navigation.disputes" />,
    applications: <Translate id="navigation.applications" />,
    accounts: <Translate id="navigation.accounts" />,
    subscriptions: <Translate id="navigation.subscriptions" />,
    exports: <Translate id="navigation.exports" />,
    announcements: <Translate id="navigation.announcements" />,
    general: <Translate id="navigation.general" />,
}

interface ISidebarProps {
    open: boolean
    closeSidebar: () => void
}

export const Sidebar: React.FC<ISidebarProps> = (props) => {
    const history = useHistory()
    const location = useLocation()
    const ref = useRef<HTMLDivElement>(null)

    const handleClickOutside = (e: MouseEvent) => {
        if (ref.current && ref.current.contains(e.target as HTMLElement)) {
            return
        }
        props.closeSidebar()
    }

    useEffect(() => {
        if (props.open) {
            document.addEventListener("mousedown", handleClickOutside)
        } else {
            document.removeEventListener("mousedown", handleClickOutside)
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside)
        }
    }, [props.open])

    const handleHomeClick = useCallbackRef(() => {
        history.push("/")
        props.closeSidebar()
    })

    const handleTransactionsClick = useCallbackRef(() => {
        history.push("/transactions")
        props.closeSidebar()
    })

    const handleSettlementsClick = useCallbackRef(() => {
        history.push("/settlements")
        props.closeSidebar()
    })

    const handleDisputesClick = useCallbackRef(() => {
        history.push("/disputes")
        props.closeSidebar()
    })

    const handleApplicationsClick = useCallbackRef(() => {
        history.push("/applications")
        props.closeSidebar()
    })

    const handleAccountsClick = useCallbackRef(() => {
        history.push("/accounts")
        props.closeSidebar()
    })

    const handleSubscriptionsClick = useCallbackRef(() => {
        history.push("/subscriptions")
        props.closeSidebar()
    })

    const handleExportsClick = useCallbackRef(() => {
        history.push("/exports")
        props.closeSidebar()
    })

    const handleAnnouncementsClick = useCallbackRef(() => {
        history.push("/announcements")
        props.closeSidebar()
    })

    return (
        <div ref={ref}>
            <DSSidebar open={props.open}>
                <Grid container fluid noGutters>
                    <BoxEdges p={16}>
                        <Grid row alignItems="center" noGutters>
                            <Grid item auto>
                                <BoxEdges mr={12}>
                                    <NavigationBrandmark cornerRadius="large" size={28} link="/" />
                                </BoxEdges>
                            </Grid>
                            <Grid item>
                                <BrandText size={58} />
                            </Grid>
                            <Grid item auto>
                                <Button
                                    onClick={props.closeSidebar}
                                    color="secondary"
                                    size="small"
                                    round
                                    light
                                >
                                    <CrossIcon />
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid row noGutters>
                            <Grid item>
                                <Spacer size={16} />
                                <Separator />
                                <Spacer size={16} />
                            </Grid>
                        </Grid>
                        <Grid row noGutters>
                            <Grid item>
                                <AccountSwitcher />
                            </Grid>
                        </Grid>
                    </BoxEdges>
                    <Grid row>
                        <Grid item>
                            <Spacer size={8} />
                        </Grid>
                    </Grid>
                    <Grid row noGutters>
                        <Grid item>
                            <NavigationItem
                                icon={icons.home}
                                text={texts.home}
                                onClick={handleHomeClick}
                                active={location.pathname === "/"}
                                direction="vertical"
                                py="s"
                            />
                        </Grid>
                    </Grid>
                    <Grid row>
                        <Grid item>
                            <Spacer size={24} />
                        </Grid>
                    </Grid>
                    <Grid row noGutters>
                        <Grid item>
                            <NavigationItem
                                icon={icons.transactions}
                                text={texts.transactions}
                                onClick={handleTransactionsClick}
                                active={location.pathname.startsWith("/transactions")}
                                direction="vertical"
                                py="s"
                            />
                        </Grid>
                    </Grid>
                    <Grid row>
                        <Grid item>
                            <Spacer size={24} />
                        </Grid>
                    </Grid>
                    <Grid row noGutters>
                        <Grid item>
                            <NavigationItem
                                icon={icons.settlements}
                                text={texts.settlements}
                                onClick={handleSettlementsClick}
                                active={location.pathname.startsWith("/settlements")}
                                direction="vertical"
                                py="s"
                            />
                        </Grid>
                    </Grid>
                    <Grid row>
                        <Grid item>
                            <Spacer size={24} />
                        </Grid>
                    </Grid>
                    <Grid row noGutters>
                        <Grid item>
                            <NavigationItem
                                icon={icons.disputes}
                                text={texts.disputes}
                                onClick={handleDisputesClick}
                                active={location.pathname.startsWith("/disputes")}
                                direction="vertical"
                                py="s"
                            />
                        </Grid>
                    </Grid>
                    <BoxEdges p={16}>
                        <Grid row>
                            <Grid item>
                                <Spacer size={24} />
                                <Separator />
                                <Spacer size={24} />
                            </Grid>
                        </Grid>
                        <Grid row>
                            <Grid item>
                                <Paragraph weight="medium" light>
                                    {texts.general}
                                </Paragraph>
                            </Grid>
                        </Grid>
                    </BoxEdges>
                    <Grid row noGutters>
                        <Grid item>
                            <NavigationItem
                                icon={icons.applications}
                                text={texts.applications}
                                onClick={handleApplicationsClick}
                                active={location.pathname.startsWith("/applications")}
                                direction="vertical"
                                py="s"
                            />
                        </Grid>
                    </Grid>
                    <Grid row>
                        <Grid item>
                            <Spacer size={24} />
                        </Grid>
                    </Grid>
                    <Grid row noGutters>
                        <Grid item>
                            <NavigationItem
                                icon={icons.accounts}
                                text={texts.accounts}
                                onClick={handleAccountsClick}
                                active={location.pathname.startsWith("/accounts")}
                                direction="vertical"
                                py="s"
                            />
                        </Grid>
                    </Grid>
                    <Grid row>
                        <Grid item>
                            <Spacer size={24} />
                        </Grid>
                    </Grid>
                    <Grid row noGutters>
                        <Grid item>
                            <NavigationItem
                                icon={icons.subscriptions}
                                text={texts.subscriptions}
                                onClick={handleSubscriptionsClick}
                                active={location.pathname.startsWith("/subscriptions")}
                                direction="vertical"
                                py="s"
                            />
                        </Grid>
                    </Grid>
                    <Grid row>
                        <Grid item>
                            <Spacer size={24} />
                        </Grid>
                    </Grid>
                    <Grid row noGutters>
                        <Grid item>
                            <NavigationItem
                                icon={icons.exports}
                                text={texts.exports}
                                onClick={handleExportsClick}
                                active={location.pathname.startsWith("/exports")}
                                direction="vertical"
                                py="s"
                            />
                        </Grid>
                    </Grid>
                    <Grid row>
                        <Grid item>
                            <Spacer size={24} />
                        </Grid>
                    </Grid>
                    <Grid row noGutters>
                        <Grid item>
                            <NavigationItem
                                icon={icons.announcements}
                                text={texts.announcements}
                                onClick={handleAnnouncementsClick}
                                active={location.pathname.startsWith("/announcements")}
                                direction="vertical"
                                py="s"
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </DSSidebar>
        </div>
    )
}
