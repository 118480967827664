import { call, fork, put, takeEvery } from "@redux-saga/core/effects"
import { Problem } from "ketting"
import { MerchantApiRequest, MerchantApiResponse } from "modules/common"
import { catchError } from "modules/common/actions"
import {
    batchFetchTransaction,
    batchFetchTransactionError,
    batchFetchTransactionOk,
    fetchTransaction,
    fetchTransactionError,
    fetchTransactionOk,
    fetchTransactions,
    fetchTransactionsError,
    fetchTransactionsOk,
} from "modules/transactions/actions"
import {
    ITransaction,
    ITransactionData,
    ITransactionRelations,
    ITransactionsMetadata,
    ITransactionsRelations,
} from "modules/transactions/types"
import { ActionType, getType } from "typesafe-actions"
import UrlParse from "url-parse"

export function* handleFetchTransactions(action: ActionType<typeof fetchTransactions>) {
    const { page, query, per_page, uri: transactionsUri } = action.payload

    try {
        const queryParams = {}
        if (page) {
            Object.assign(queryParams, {
                page: page.toString(),
            })
        }
        if (query) {
            Object.assign(queryParams, {
                query: query.toString(),
            })
        }
        if (per_page) {
            Object.assign(queryParams, {
                per_page: per_page.toString(),
            })
        }
        const uri = new UrlParse(transactionsUri, true)

        uri.set("query", {
            ...uri.query,
            ...queryParams,
        })

        const merchantApiRequest = new MerchantApiRequest(uri.toString(), {
            noCache: true,
        })
        const {
            relations,
            repr,
        }: MerchantApiResponse<ITransactionsMetadata, ITransactionsRelations> = yield call([
            merchantApiRequest,
            merchantApiRequest.get,
        ])

        yield put(fetchTransactionsOk(repr, relations, transactionsUri, per_page, query))
    } catch (e) {
        if (e instanceof Problem) {
            yield put(fetchTransactionsError(e, transactionsUri, per_page, query))
        }
        yield put(catchError(e))
    }
}

export function* handleFetchTransaction(action: ActionType<typeof fetchTransaction>) {
    const { uri: transactionUri, noCache } = action.payload

    try {
        const merchantApiRequest = new MerchantApiRequest(transactionUri, { noCache })
        const {
            repr,
            relations,
        }: MerchantApiResponse<ITransactionData, ITransactionRelations> = yield call([
            merchantApiRequest,
            merchantApiRequest.get,
        ])

        yield put(fetchTransactionOk(repr, relations, transactionUri))
    } catch (e) {
        if (e instanceof Problem) {
            yield put(fetchTransactionError(e, transactionUri))
        }
        yield put(catchError(e))
    }
}

export function* handleBatchFetchTransaction(action: ActionType<typeof batchFetchTransaction>) {
    const { uris: transactionUris } = action.payload
    const transactions: ITransaction[] = []

    try {
        for (const transactionUri of transactionUris) {
            const merchantApiRequest = new MerchantApiRequest(transactionUri)
            const {
                repr,
                relations,
            }: MerchantApiResponse<ITransactionData, ITransactionRelations> = yield call([
                merchantApiRequest,
                merchantApiRequest.get,
            ])
            transactions.push({
                ...repr,
                relations,
            })
        }

        yield put(batchFetchTransactionOk(transactions, transactionUris))
    } catch (e) {
        if (e instanceof Problem) {
            yield put(batchFetchTransactionError(e, transactionUris))
        }
        yield put(catchError(e))
    }
}

export function* watchTransactionsSaga() {
    yield takeEvery(getType(fetchTransactions), handleFetchTransactions)
    yield takeEvery(getType(fetchTransaction), handleFetchTransaction)
    yield takeEvery(getType(batchFetchTransaction), handleBatchFetchTransaction)
}

export function* transactionsRootSaga() {
    yield fork(watchTransactionsSaga)
}
