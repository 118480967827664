import { useState } from "react"
import useInterval from "utils/useInterval"

export const useSlideshow = () => {
    const [activeIndex, setActiveIndex] = useState(1)
    const [isPlaying, setIsPlaying] = useState(true)
    const [progressPercentage, setProgressPercentage] = useState(0)

    useInterval(
        () => {
            if (progressPercentage === 100) {
                const newActiveIndex = activeIndex < 4 ? activeIndex + 1 : 1
                setActiveIndex(newActiveIndex)
                setProgressPercentage(0)
            } else {
                setProgressPercentage(progressPercentage + 5)
            }
        },
        isPlaying ? 250 : null
    )

    return {
        activeIndex,
        setActiveIndex,
        isPlaying,
        setIsPlaying,
        progressPercentage,
        setProgressPercentage,
    }
}
