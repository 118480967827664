import { Uri } from "modules/common/types"

export const ACTION_PREFIX = "@@contacts/"
export const FETCH_CONTACT = "@@contacts/FETCH_CONTACT"
export const FETCH_CONTACT_OK = "@@contacts/FETCH_CONTACT_OK"
export const FETCH_CONTACT_ERROR = "@@contacts/FETCH_CONTACT_ERROR"
export const UPDATE_CONTACT = "@@contacts/UPDATE_CONTACT"
export const UPDATE_CONTACT_OK = "@@contacts/UPDATE_CONTACT_OK"
export const UPDATE_CONTACT_ERROR = "@@contacts/UPDATE_CONTACT_ERROR"

export interface IContactData {
    name?: string
    email?: string
    phone?: string
    skype?: string
}

export interface IContact extends IContactData {
    relations: IContactRelations
}

export interface IContactRelations {
    application: Uri
    comments: Uri | Uri[]
    self: Uri
}
