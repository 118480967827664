import { CUTTER_API_ROOT, MerchantApiRequest, MerchantApiResponse } from "modules/common"
import { forkWatcher } from "modules/common/actions"
import { getVoucher, getVoucherError, getVoucherOk } from "modules/voucher/actions"
import { IVoucherData } from "modules/voucher/types"
import { call, put } from "redux-saga/effects"
import { ActionType, getType } from "typesafe-actions"

export function* handleGetVoucher(action: ActionType<typeof getVoucher>) {
    const { voucherCode } = action.payload
    try {
        const merchantApiRequest = new MerchantApiRequest<IVoucherData>(
            `${CUTTER_API_ROOT}/vouchers/${voucherCode}`
        )
        const { repr }: MerchantApiResponse<IVoucherData> = yield call([
            merchantApiRequest,
            merchantApiRequest.get,
        ])

        try {
            const decodedVoucherCode = window.atob(voucherCode)
            const parsedDecodedVoucherCode = JSON.parse(decodedVoucherCode)
            const data = {
                ...repr,
                ...parsedDecodedVoucherCode,
            }
            yield put(getVoucherOk(data, voucherCode))
        } catch (e) {
            yield put(getVoucherOk(repr, voucherCode))
        }
    } catch (e) {
        yield put(getVoucherError(e, voucherCode))
    }
}

export default function* voucherSaga() {
    yield put(forkWatcher(getType(getVoucher), handleGetVoucher))
}
