import { ISO4217, PaginationRelations, ResponsePaginated, Uri } from "modules/common/types"

export const ACTION_PREFIX = "@@applications/"
export const FETCH_APPLICATIONS = `@@applications/FETCH_APPLICATIONS`
export const FETCH_APPLICATIONS_OK = `@@applications/FETCH_APPLICATIONS_OK`
export const FETCH_APPLICATIONS_ERROR = `@@applications/FETCH_APPLICATIONS_ERROR`
export const CREATE_APPLICATION = `@@applications/CREATE_APPLICATION`
export const CREATE_APPLICATION_OK = `@@applications/CREATE_APPLICATION_OK`
export const CREATE_APPLICATION_ERROR = `@@applications/CREATE_APPLICATION_ERROR`
export const FETCH_APPLICATION = `@@applications/FETCH_APPLICATION`
export const FETCH_APPLICATION_OK = `@@applications/FETCH_APPLICATION_OK`
export const FETCH_APPLICATION_ERROR = `@@applications/FETCH_APPLICATION_ERROR`
export const STAMP_APPLICATION = `@@applications/STAMP_APPLICATION`
export const STAMP_APPLICATION_OK = `@@applications/STAMP_APPLICATION_OK`
export const STAMP_APPLICATION_ERROR = `@@applications/STAMP_APPLICATION_ERROR`
export const REMOVE_STAMP_APPLICATION = `@@applications/REMOVE_STAMP_APPLICATION`
export const REMOVE_STAMP_APPLICATION_OK = `@@applications/REMOVE_STAMP_APPLICATION_OK`
export const REMOVE_STAMP_APPLICATION_ERROR = `@@applications/REMOVE_STAMP_APPLICATION_ERROR`
export const UPDATE_APPLICATION = `@@applications/UPDATE_APPLICATION`
export const UPDATE_APPLICATION_OK = `@@applications/UPDATE_APPLICATION_OK`
export const SUBMIT_APPLICATION = `@@applications/SUBMIT_APPLICATION`
export const UPDATE_APPLICATION_ERROR = `@@applications/UPDATE_APPLICATION_ERROR`
export const REFRESH_APPLICATION = `@@applications/REFRESH_APPLICATION`

export type ApplicationDeliveryDelayType = "less5days" | "5to10days" | "10to20days" | "more20days"

export interface IApplicationsEmbedded {
    "ch:applications": IApplication | IApplication[]
}
export interface IApplicationsData extends ResponsePaginated {}
export interface IApplicationsRelations extends PaginationRelations {
    self: Uri
    applications: Uri[] | Uri
}
export interface IApplications extends IApplicationsData {
    relations: IApplicationsRelations
}

export interface IApplicationBusinessModel {
    trading_name?: string
    description?: string
    recurring?: boolean
    physical_delivery?: boolean
    delivery_delay?: ApplicationDeliveryDelayType
    drop_shipping?: boolean
    estimate_currency?: ISO4217
    estimated_monthly_turnover?: string
    estimated_average_transaction_amount?: string
}

export type ApplicationMetadataStateType =
    | "new"
    | "unsubmitted"
    | "submitted"
    | "under_review"
    | "needs_information"
    | "more_information"
    | "pre_accepted"
    | "needs_approval"
    | "accepted"
    | "archived"
    | "declined"

export interface IApplicationSigner {
    name?: string | null
    email?: string | null
}

export interface IApplicationData {
    additional_information?: string | null
    business_model?: IApplicationBusinessModel
    id: string
    signer?: IApplicationSigner
    readonly metadata: IApplicationMetadata
}

export interface IApplicationMetadata {
    readonly state: ApplicationMetadataStateType
    readonly created_at: string
    readonly voucher_code?: string
}

export interface IApplicationStamps {
    submitted?: Uri
    archived?: Uri
}

export interface IApplicationRelations {
    "bank-account": Uri
    collaborators: Uri
    contact: Uri
    company: Uri
    comments: Uri
    gateways: Uri
    websites: Uri
    files: Uri
    accounts?: Uri
    contracts?: Uri
    stamps?: IApplicationStamps
    subscriptions?: Uri
    record: Uri
    self: Uri
}

export interface IApplication extends IApplicationData {
    relations: IApplicationRelations
}
