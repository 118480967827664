import { ApplicationMetadataStateType } from "modules/applications/types"
import {
    ISO3166_1_alpha2,
    ISO8601DateTime,
    PaginationRelations,
    ResponsePaginated,
    Uri,
} from "modules/common/types"

export const ACTION_PREFIX = "@@records/"
export const FETCH_RECORDS = "@@records/FETCH_RECORDS"
export const FETCH_RECORDS_OK = "@@records/FETCH_RECORDS_OK"
export const FETCH_RECORDS_ERROR = "@@records/FETCH_RECORDS_ERROR"
export const FETCH_RECORD = "@@records/FETCH_RECORD"
export const FETCH_RECORD_OK = "@@records/FETCH_RECORD_OK"
export const FETCH_RECORD_ERROR = "@@records/FETCH_RECORD_ERROR"
export const BATCH_FETCH_RECORD = "@@records/BATCH_FETCH_RECORD"
export const BATCH_FETCH_RECORD_OK = "@@records/BATCH_FETCH_RECORD_OK"
export const BATCH_FETCH_RECORD_ERROR = "@@records/BATCH_FETCH_RECORD_ERROR"

export interface IRecordsData extends ResponsePaginated {}
export interface IRecordsRelations extends PaginationRelations {
    self: Uri
    application: Uri
    contract?: Uri
    account?: Uri
    records?: Uri | Uri[]
}

export interface IRecords extends IRecordsData {
    relations: IRecordsRelations
}

export type IRecordCurrencies = {
    [key: string]: number
}

type IRecordMccs = {
    [key: string]: number
}

export type IRecordStatesContracts = {
    [key: string]: number
}

export type IRecordStatesAccounts = {
    [key: string]: number
}

export interface IRecordData {
    id: string
    created_at: ISO8601DateTime
    submitted_at?: ISO8601DateTime
    name?: string
    trading_name?: string
    registration_number?: string
    country?: ISO3166_1_alpha2
    mccs?: IRecordMccs
    currencies?: IRecordCurrencies
    websites?: string[]
    voucher_code?: string
    states: {
        application: ApplicationMetadataStateType
        contracts?: Partial<IRecordStatesContracts>
        accounts?: Partial<IRecordStatesAccounts>
    }
}

export interface IRecordRelations {
    self: Uri
    application: Uri
    accounts?: Uri
    contracts?: Uri
    subscriptions?: Uri
}

export interface IRecord extends IRecordData {
    relations: IRecordRelations
}
