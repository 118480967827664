import { Problem } from "ketting"
import { RxMiddleware } from "modules/common/RxMiddleware"
import { Uri } from "modules/common/types"
import { transactionsRootSaga } from "modules/transactions/sagas"
import {
    BATCH_FETCH_TRANSACTION,
    BATCH_FETCH_TRANSACTION_ERROR,
    BATCH_FETCH_TRANSACTION_OK,
    FETCH_TRANSACTION,
    FETCH_TRANSACTIONS,
    FETCH_TRANSACTIONS_ERROR,
    FETCH_TRANSACTIONS_OK,
    FETCH_TRANSACTION_ERROR,
    FETCH_TRANSACTION_OK,
    ITransaction,
    ITransactionData,
    ITransactionRelations,
    ITransactionsMetadata,
    ITransactionsRelations,
} from "modules/transactions/types"
import { createCustomAction } from "typesafe-actions"
import { SagaRegistry } from "utils/SagaRegistry"
import * as actions from "./actions"

export const fetchTransactions = createCustomAction(
    FETCH_TRANSACTIONS,
    (uri: Uri, per_page?: number, query?: string, page?: number) => ({
        payload: {
            page,
            per_page,
            query,
            uri,
        },
    })
)
export const fetchTransactionsOk = createCustomAction(
    FETCH_TRANSACTIONS_OK,
    (
        data: ITransactionsMetadata,
        relations: ITransactionsRelations,
        uri: Uri,
        per_page?: number,
        query?: string
    ) => ({
        meta: {
            per_page,
            query,
            uri,
        },
        payload: {
            data,
            relations,
        },
    })
)
export const fetchTransactionsError = createCustomAction(
    FETCH_TRANSACTIONS_ERROR,
    (e: Problem, uri: Uri, per_page?: number, query?: string) => ({
        meta: { uri, per_page, query },
        payload: e,
    })
)

export const fetchTransaction = createCustomAction(
    FETCH_TRANSACTION,
    (uri: Uri, noCache?: boolean) => ({
        payload: { uri, noCache },
    })
)
export const fetchTransactionOk = createCustomAction(
    FETCH_TRANSACTION_OK,
    (data: ITransactionData, relations: ITransactionRelations, uri: Uri) => ({
        meta: { uri },
        payload: {
            data,
            relations,
        },
    })
)
export const fetchTransactionError = createCustomAction(
    FETCH_TRANSACTION_ERROR,
    (e: Problem, uri: Uri) => ({
        meta: { uri },
        payload: e,
    })
)

export const batchFetchTransaction = createCustomAction(BATCH_FETCH_TRANSACTION, (uris: Uri[]) => ({
    payload: { uris },
}))
export const batchFetchTransactionOk = createCustomAction(
    BATCH_FETCH_TRANSACTION_OK,
    (data: ITransaction[], uris: Uri[]) => ({
        meta: { uris },
        payload: { data },
    })
)
export const batchFetchTransactionError = createCustomAction(
    BATCH_FETCH_TRANSACTION_ERROR,
    (e: Problem, uris: Uri[]) => ({
        meta: { uris },
        payload: e,
    })
)

RxMiddleware.addActions(actions)
SagaRegistry.register(transactionsRootSaga)
