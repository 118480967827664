import { Problem } from "ketting"
import { RxMiddleware } from "modules/common/RxMiddleware"
import websitesSaga from "modules/websites/sagas"
import { createCustomAction } from "typesafe-actions"
import { SagaRegistry } from "utils/SagaRegistry"
import { Uri } from "../common/types"
import * as actions from "./actions"
import {
    IWebsite,
    IWebsiteData,
    IWebsiteRelations,
    IWebsitesData,
    IWebsitesRelations,
} from "./types"

const FETCH_WEBSITES = "@@websites/FETCH_WEBSITES"
const FETCH_WEBSITES_OK = "@@websites/FETCH_WEBSITES_OK"
const FETCH_WEBSITES_ERROR = "@@websites/FETCH_WEBSITES_ERROR"
export const fetchWebsites = createCustomAction(FETCH_WEBSITES, (uri: Uri, noCache?: boolean) => ({
    payload: { uri, noCache },
}))
export const fetchWebsitesOk = createCustomAction(
    FETCH_WEBSITES_OK,
    (data: IWebsitesData, relations: IWebsitesRelations, uri: Uri, noCache?: boolean) => ({
        payload: { data, relations },
        meta: { uri, noCache },
    })
)
export const fetchWebsitesError = createCustomAction(
    FETCH_WEBSITES_ERROR,
    (e: Problem, uri: Uri, noCache?: boolean) => ({
        payload: e,
        meta: { uri, noCache },
    })
)

const FETCH_WEBSITE = "@@websites/FETCH_WEBSITE"
const FETCH_WEBSITE_OK = "@@websites/FETCH_WEBSITE_OK"
const FETCH_WEBSITE_ERROR = "@@websites/FETCH_WEBSITE_ERROR"
export const fetchWebsite = createCustomAction(FETCH_WEBSITE, (uri: Uri) => ({
    payload: { uri },
}))

export const fetchWebsiteOk = createCustomAction(
    FETCH_WEBSITE_OK,
    (data: IWebsiteData, relations: IWebsiteRelations, uri: Uri) => ({
        payload: { data, relations },
        meta: { uri },
    })
)
export const fetchWebsiteError = createCustomAction(
    FETCH_WEBSITE_ERROR,
    (e: Problem, uri: Uri) => ({ payload: e, meta: { uri } })
)

const CREATE_WEBSITE = "@@websites/CREATE_WEBSITE"
const CREATE_WEBSITE_OK = "@@websites/CREATE_WEBSITE_OK"
const CREATE_WEBSITE_ERROR = "@@websites/CREATE_WEBSITE_ERROR"
export const createWebsite = createCustomAction(
    CREATE_WEBSITE,
    (data: Partial<IWebsiteData>, uri: Uri) => ({
        payload: { data, uri },
    })
)

export const createWebsiteOk = createCustomAction(
    CREATE_WEBSITE_OK,
    (
        data: IWebsiteData,
        relations: IWebsiteRelations,
        createData: Partial<IWebsiteData>,
        uri: Uri
    ) => ({ payload: { data, relations }, meta: { uri, data: createData } })
)
export const createWebsiteError = createCustomAction(
    CREATE_WEBSITE_ERROR,
    (e: Problem, createData: Partial<IWebsiteData>, uri: Uri) => ({
        meta: { uri, data: createData },
        payload: e,
    })
)

const DELETE_WEBSITE = "@@websites/DELETE_WEBSITE"
const DELETE_WEBSITE_OK = "@@websites/DELETE_WEBSITE_OK"
const DELETE_WEBSITE_ERROR = "@@websites/DELETE_WEBSITE_ERROR"
export const deleteWebsite = createCustomAction(DELETE_WEBSITE, (uri: Uri) => ({
    payload: { uri },
}))

export const deleteWebsiteOk = createCustomAction(DELETE_WEBSITE_OK, (uri: Uri) => ({
    meta: { uri },
}))
export const deleteWebsiteError = createCustomAction(
    DELETE_WEBSITE_ERROR,
    (e: Problem, uri: Uri) => ({ payload: e, meta: { uri } })
)

const UPDATE_WEBSITE = "@@websites/UPDATE_WEBSITE"
const UPDATE_WEBSITE_OK = "@@websites/UPDATE_WEBSITE_OK"
const UPDATE_WEBSITE_ERROR = "@@websites/UPDATE_WEBSITE_ERROR"
export const updateWebsite = createCustomAction(
    UPDATE_WEBSITE,
    (data: Partial<IWebsiteData>, uri: Uri) => ({
        payload: { data, uri },
    })
)

export const updateWebsiteOk = createCustomAction(
    UPDATE_WEBSITE_OK,
    (
        data: IWebsiteData,
        relations: IWebsiteRelations,
        updateData: Partial<IWebsiteData>,
        uri: Uri
    ) => ({ payload: { data, relations }, meta: { uri, data: updateData } })
)

export const updateWebsiteError = createCustomAction(
    UPDATE_WEBSITE_ERROR,
    (e: Problem, updateData: Partial<IWebsiteData>, uri: Uri) => ({
        meta: { data: updateData, uri },
        payload: e,
    })
)

const BATCH_FETCH = "@@websites/BATCH_FETCH_WEBSITE"
const BATCH_FETCH_OK = "@@websites/BATCH_FETCH_WEBSITE_OK"
const BATCH_FETCH_ERROR = "@@websites/BATCH_FETCH_WEBSITE_ERROR"
export const batchFetchWebsite = createCustomAction(BATCH_FETCH, (uris: Uri[]) => ({
    payload: { uris },
}))
export const batchFetchWebsiteOk = createCustomAction(
    BATCH_FETCH_OK,
    (data: IWebsite[], uris: Uri[]) => ({
        payload: { data },
        meta: { uris },
    })
)
export const batchFetchWebsiteError = createCustomAction(
    BATCH_FETCH_ERROR,
    (e: Problem, uris: Uri[]) => ({
        meta: { uris },
        payload: e,
    })
)

RxMiddleware.addActions(actions)
SagaRegistry.register(websitesSaga)
