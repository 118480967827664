import { MeContext } from "modules/me/Provider/context"
import { useRoot } from "modules/root"
import { useContext, useEffect, useRef, useState } from "react"
import { Subscription } from "rxjs"
import { useTransactions } from "../modules/transactions/hooks"

export const useShowWelcome = () => {
    const [showWelcome, setShowWelcome] = useState(false)
    const { isLoading: meIsLoading, data: me } = useContext(MeContext)
    const [hasTransactions, setHasTransactions] = useState<boolean>()
    const transactionsSubscriptionRef = useRef<Subscription>()
    const rootState = useRoot()
    const transactions = useTransactions(rootState.transactions)

    if (!transactionsSubscriptionRef.current) {
        transactionsSubscriptionRef.current = transactions.subscribe((t) => {
            if (t === null) {
                return
            }

            if (t && t.relations && t.relations.transactions) {
                setHasTransactions(true)
                return
            }

            setHasTransactions(false)
        })
    }

    useEffect(() => {
        if (hasTransactions === undefined) {
            return
        }

        if (hasTransactions) {
            if (showWelcome) {
                setShowWelcome(false)
            }

            return
        }

        const nextShowWelcome = !me || !me.email || !me.name || !me.phone || me.name === me.email
        if (nextShowWelcome !== showWelcome) {
            setShowWelcome(nextShowWelcome)
        }
    }, [meIsLoading, me, hasTransactions])

    useEffect(() => {
        return () => {
            if (
                transactionsSubscriptionRef.current &&
                !transactionsSubscriptionRef.current.closed
            ) {
                transactionsSubscriptionRef.current.unsubscribe()
            }
        }
    }, [])

    return showWelcome
}
