import { put } from "@redux-saga/core/effects"
import ibanJs from "iban"
import { State } from "ketting"
import Resource from "ketting/dist/resource"
import { catchError, forkWatcher } from "modules/common/actions"
import { HALClient } from "modules/common/HALClient"
import { IBAN_API_ROOT } from "modules/iban"
import { lookupIban, lookupIbanError, lookupIbanOk } from "modules/iban/actions"
import { IbanInfo } from "modules/iban/types"
import { ActionType, getType } from "typesafe-actions"

export function* handleLookupIban(action: ActionType<typeof lookupIban>) {
    const iban = action.payload.iban.replace(/\s+/g, "")

    try {
        if (!ibanJs.isValid(iban)) {
            throw new Error(`Invalid IBAN provided`)
        }

        const resource: Resource<IbanInfo> = yield HALClient.go(`${IBAN_API_ROOT}/iban/${iban}`)
        const ibanDto: State<IbanInfo> = yield resource.get()

        if (ibanDto.data.error) {
            throw new Error(ibanDto.data.error)
        }

        yield put(lookupIbanOk(ibanDto.data, iban))
    } catch (e) {
        const err = new Error(e.toString ? e.toString() : e)
        yield put(catchError(err))
        yield put(lookupIbanError(e, iban))
    }
}
export default function* ibanSaga() {
    yield put(forkWatcher(getType(lookupIban), handleLookupIban))
}
