import {
    BoxEdges,
    ChromeIcon,
    defaults as defaultsOrg,
    EdgeIcon,
    FirefoxIcon,
    Grid,
    LayoutColoredIcon,
    NoticeBox,
    Paragraph,
    SafariIcon,
    Separator,
    Spacer,
} from "@clearhaus/design-system"
import { EmptyState } from "components/common/EmptyState/EmptyState"
import React from "react"
import { Translate } from "react-localize-redux"

const icon = (
    <LayoutColoredIcon
        backgroundColor={defaultsOrg.colors.accents.error[300]}
        stroke={defaultsOrg.colors.accents.error[900]}
    />
)

const texts = {
    title: <Translate id="common.browser_not_supported.title" />,
    description: <Translate id="common.browser_not_supported.description" />,
    supported_browsers_title: (
        <Translate id="common.browser_not_supported.supported_browsers_title" />
    ),
}

const browsersColor = defaultsOrg.colors.neutrals[700]
const noticeTextColor = defaultsOrg.colors.accents.error[900]

export const BrowserNotSupported: React.FC = () => {
    return (
        <BoxEdges my={64}>
            <Grid container>
                <Grid row alignItems="center" justify="center">
                    <Grid item auto>
                        <NoticeBox kind="accents-error" noPadding>
                            <NoticeBox.Content>
                                <Spacer size={16} />
                                <EmptyState>
                                    <EmptyState.Icon icon={icon} />
                                    <EmptyState.Title color={noticeTextColor}>
                                        {texts.title}
                                    </EmptyState.Title>
                                    <EmptyState.Description color={noticeTextColor}>
                                        {texts.description}
                                    </EmptyState.Description>
                                </EmptyState>
                            </NoticeBox.Content>
                        </NoticeBox>
                    </Grid>
                </Grid>
                <Grid row justify="center">
                    <Grid item auto>
                        <Separator my={32} colorWeight={600} minWidth={24} />
                    </Grid>
                </Grid>
                <Grid row justify="center">
                    <Grid item auto>
                        <Paragraph color={browsersColor} weight="medium">
                            {texts.supported_browsers_title}
                        </Paragraph>
                    </Grid>
                </Grid>
                <Spacer size={32} />
                <Grid row alignItems="center" justify="center">
                    <Grid item auto>
                        <Grid row justify="center">
                            <Grid item auto>
                                <ChromeIcon size={32} color={browsersColor} />
                            </Grid>
                        </Grid>
                        <Spacer size={12} />
                        <Grid row justify="center">
                            <Grid item auto>
                                <Paragraph color={browsersColor} weight="medium">
                                    Chrome
                                </Paragraph>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Spacer size={60} />
                    <Grid item auto>
                        <Grid row justify="center">
                            <Grid item auto>
                                <FirefoxIcon size={32} color={browsersColor} />
                            </Grid>
                        </Grid>
                        <Spacer size={12} />
                        <Grid row justify="center">
                            <Grid item auto>
                                <Paragraph color={browsersColor} weight="medium">
                                    Firefox
                                </Paragraph>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Spacer size={60} />
                    <Grid item auto>
                        <Grid row justify="center">
                            <Grid item auto>
                                <SafariIcon size={32} color={browsersColor} />
                            </Grid>
                        </Grid>
                        <Spacer size={12} />
                        <Grid row justify="center">
                            <Grid item auto>
                                <Paragraph color={browsersColor} weight="medium">
                                    Safari
                                </Paragraph>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Spacer size={60} />
                    <Grid item auto>
                        <Grid row justify="center">
                            <Grid item auto>
                                <EdgeIcon size={32} color={browsersColor} />
                            </Grid>
                        </Grid>
                        <Spacer size={12} />
                        <Grid row justify="center">
                            <Grid item auto>
                                <Paragraph color={browsersColor} weight="medium">
                                    Edge
                                </Paragraph>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </BoxEdges>
    )
}
