import { BoxEdges, Grid, List, Popover, Spacer } from "@clearhaus/design-system"
import { RoundedFlagIcon } from "@clearhaus/design-system/Icon"
import { NavigationItem } from "components/common/NavigationItem"
import { supportedCountries } from "modules/common/helpers"
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { LocalizeContext } from "react-localize-redux"

interface ILanguageItem {
    caption: string
    code: string
    prefix?: JSX.Element
}

interface ILanguageSwitcherProps {
    light?: boolean
}

const getFlagPrefix = (languageCode: string) => {
    let flag = "international"
    if (languageCode === "en") {
        flag = "gb"
    } else {
        const countryCode = supportedCountries.find((country) => country.language === languageCode)
        if (countryCode) {
            if (countryCode.flag) {
                flag = countryCode.flag
            } else {
                flag = countryCode.countryCode
            }
        }
    }

    return <RoundedFlagIcon countryCode={flag} size={14} />
}

export const LanguageSwitcher: React.FC<ILanguageSwitcherProps> = (props) => {
    const { setActiveLanguage, languages, activeLanguage, translate } = useContext(LocalizeContext)
    const [selectedLanguage, setSelectedLanguage] = useState<ILanguageItem | null>()
    const [showPopover, setShowPopover] = useState(false)

    const selectableLanguages = useMemo<ILanguageItem[]>(() => {
        return languages.map((lang) => {
            return {
                caption: translate(`common.languages.${lang.code}`).toString(),
                code: lang.code,
                prefix: getFlagPrefix(lang.code),
            }
        })
    }, [languages])

    const handleItemClick = (languageCode: string) => () => {
        setShowPopover(false)
        setActiveLanguage(languageCode)
    }

    const handleOnHideEnd = useCallback(() => {
        setShowPopover(false)
    }, [showPopover])

    const handleOnItemClick = useCallback(() => {
        setShowPopover(!showPopover)
    }, [showPopover])

    useEffect(() => {
        if (!selectedLanguage || activeLanguage.code !== selectedLanguage.code) {
            setSelectedLanguage({
                caption: translate(`common.languages.${activeLanguage.code}`).toString(),
                code: activeLanguage.code,
                prefix: getFlagPrefix(activeLanguage.code),
            })
        }
    }, [activeLanguage])

    return (
        <Popover placement="bottom" show={showPopover}>
            <Popover.Reference>
                {selectedLanguage && (
                    <>
                        <NavigationItem
                            icon={selectedLanguage.prefix}
                            light={props.light}
                            chevron
                            onClick={handleOnItemClick}
                        />
                    </>
                )}
            </Popover.Reference>
            <Popover.Content onHideEnd={handleOnHideEnd}>
                <BoxEdges p={8} minWidth={200}>
                    <List>
                        {selectableLanguages.map((lang) => (
                            <List.Item
                                key={`language-switcher-${lang.code}`}
                                onClick={handleItemClick(lang.code)}
                            >
                                <Grid row wrap="nowrap" alignItems="center" noGutters>
                                    <Grid item auto>
                                        <Spacer size={8} />
                                    </Grid>
                                    {lang.prefix && (
                                        <>
                                            <Grid item auto>
                                                {lang.prefix}
                                            </Grid>
                                            <Grid item auto>
                                                <Spacer size={12} />
                                            </Grid>
                                        </>
                                    )}
                                    <Grid item auto>
                                        {lang.caption}
                                    </Grid>
                                    <Grid item auto>
                                        <Spacer size={8} />
                                    </Grid>
                                </Grid>
                            </List.Item>
                        ))}
                    </List>
                </BoxEdges>
            </Popover.Content>
        </Popover>
    )
}
