import { Problem } from "ketting"
import voucherSaga from "modules/voucher/sagas"
import { createCustomAction } from "typesafe-actions"
import { SagaRegistry } from "utils/SagaRegistry"
import { GET_VOUCHER, GET_VOUCHER_ERROR, GET_VOUCHER_OK, IVoucherData } from "./types"

export const getVoucher = createCustomAction(GET_VOUCHER, (voucherCode: string) => ({
    payload: { voucherCode },
}))
export const getVoucherOk = createCustomAction(
    GET_VOUCHER_OK,
    (data: IVoucherData, voucherCode: string) => ({
        meta: { voucherCode },
        payload: { data },
    })
)

export const getVoucherError = createCustomAction(
    GET_VOUCHER_ERROR,
    (problem: Problem, voucherCode: string) => ({
        meta: { voucherCode },
        payload: problem,
    })
)

SagaRegistry.register(voucherSaga)
