import { AnyAction } from "redux"
import setupAxiosInterceptor from "./interceptor"

export function authenticationMiddleware() {
    return (next: (action: AnyAction) => AnyAction) => {
        return (action: AnyAction) => {
            if (!(window as any).__AXIOS_INTERCEPTOR_SETUP__) {
                setupAxiosInterceptor()
            }

            next(action)
        }
    }
}
