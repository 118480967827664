export const scopes = [
    "openid",
    "email",
    "profile",

    "read:accounts",
    "update:accounts",

    "read:api_keys",
    "update:api_keys",

    "create:applications",
    "read:applications",
    "update:applications",

    "create:applications:comments",
    "read:applications:comments",
    "update:applications:comments",
    "delete:applications:comments",

    "create:applications:files",
    "read:applications:files",
    "update:applications:files",
    "delete:applications:files",

    "read:contracts",
    "update:contracts",

    "read:disputes",
    "update:disputes",

    "create:disputes:comments",
    "read:disputes:comments",
    "update:disputes:comments",
    "delete:disputes:comments",

    "create:disputes:files",
    "read:disputes:files",
    "update:disputes:files",
    "delete:disputes:files",

    "create:members",
    "read:members",
    "update:members",
    "delete:members",

    "create:collaborators",
    "read:collaborators",
    "update:collaborators",
    "delete:collaborators",

    "read:settlements",

    "read:transactions",
]
